import axios from 'axios';

// Interceptor for responses
const responseInterceptor = (response) => {
  switch(response.status) {
      case 200: 
          // console.log({ path: response.config.url, status: response.status});
          break;
      // any other cases
      default:
        console.log(response);
  }

  return response;
}

// interceptor to catch errors
const errorInterceptor = error => {

  // check if it's a server error
  if (!error.response) {
    // notify.warn('Network/Server error');
    return Promise.reject(error);
  }

  // all the error responses
  switch(error.response.status) {
      case 400:
          console.error(error.response.status, error.message);
          // notify.warn('Nothing to display','Data Not Found');
          break;

      case 401:
        localStorage.removeItem('token');
        window.location.href = '/login';

      default:
          console.error(error.response.status, error.message);
          // notify.error('Server Error');

  }
  return Promise.reject(error);
}


// ===========================================================================

export default ({ requiresAuth }) => {
  const tempToken = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOjIsImlhdCI6MTY4MjU5MjgxNywic3ViIjoiQWRtaW4ifQ.C4q9HqI0_BKzseGCrvC1hgFghpDUyd8ekwMVWRu7gj4`
  const token = localStorage.getItem('token') || tempToken
  var options = {
    // baseURL: 'https://m-api.vecabo.com/api',
    // baseURL: 'http://192.168.2.41:3000',
    // baseURL: 'http://localhost:3000',
    baseURL: 'https://vecabo-server-qxv37.ondigitalocean.app',
  }
  if (requiresAuth) {
    options.headers = { 
      Authorization: `Bearer ${token}`
    }
  }

  const instance = axios.create(options);
  instance.interceptors.response.use(responseInterceptor, errorInterceptor);

  return instance;
};