<template>
    <div class="calendar-container">
        <a-card>
            <template #title>
                <div style="display: flex;justify-content: space-between;">
                    <span>ตารางการใช้รถ</span>
                    <span>ประเภทรถ: {{ showSelectedCarType }} | ทั้งหมด: {{ resourcesDetails.total }} | จองแล้ว: {{ resourcesDetails.haveEvents }} | ว่าง: {{ resourcesDetails.noEvents }}</span>
                </div>
            </template>
            <a-row justify="space-between">
                <a-col>
                    <a-select style="width: 120px" v-model:value="date_type" @change="calendar_change" placeholder="เลือกเดือน" >
                        <a-select-option value="Day">Day</a-select-option>
                        <a-select-option value="Week">Week</a-select-option>
                        <a-select-option value="Month">Month</a-select-option>
                        <a-select-option value="Custom">Custom</a-select-option>
                    </a-select>
                </a-col>
                <a-col>
                    <a-date-picker v-if="date_type == 'Day'" style="width: 200px" v-model:value="date_day" placeholder="เลือกวัน" :locale="lang_th" format="DD MMM YYYY" />
                    <a-date-picker v-if="date_type == 'Week'" style="width: 200px" v-model:value="date_week" picker="week" placeholder="เลือกสัปดาห์" :locale="lang_th" format="DD MMMM YYYY" />
                    <a-date-picker v-if="date_type == 'Month'" style="width: 200px" v-model:value="date_month" picker="month" placeholder="เลือกเดือน" :locale="lang_th" format="MMMM YYYY" />
                    <a-range-picker v-if="date_type == 'Custom'" v-model:value="date_range" :placeholder="['วันเริ่มต้น','วันสิ้นสุด']" />
                </a-col>
                <a-col>
                    <a-select style="width: 450px" v-model:value="selected_company" placeholder="ค้นหาบริษัท">
                        <a-select-option v-for="{ id, fullname }, i in companies" :key="i"
                            :value="id">{{ fullname }}</a-select-option>
                    </a-select>
                </a-col>
                <a-col>
                    <a-dropdown v-model:visible="car_filter_visible" :trigger="['click']">
                        <template #overlay>
                            <a-menu>
                                <a-menu-item key="1">
                                    <span>1.ประเภทรถ</span>
                                    <a-select style="width: 280px" v-model:value="car_type_id" placeholder="ประเภทรถ">
                                        <a-select-option v-for="{ id, name }, i in car_type_list" :key="i"
                                            :value="id">{{ name }}
                                        </a-select-option>
                                    </a-select>
                                </a-menu-item>
                                <a-menu-item key="2">
                                    2.รหัสรถ
                                    <a-input style="width: 280px" v-model:value="car_no" placeholder="รหัสรถ" />
                                </a-menu-item>
                                <a-menu-item key="3">
                                    3.รุ่นรถ
                                    <a-input style="width: 280px" v-model:value="car_model" placeholder="รุ่นรถ" />
                                </a-menu-item>
                                <a-menu-item key="4">
                                    4.ยี่ห้อรถ
                                    <a-input style="width: 280px" v-model:value="car_brand" placeholder="ยี่ห้อรถ" />
                                </a-menu-item>
                                <a-menu-item key="5">
                                    5.ทะเบียนรถ
                                    <a-input style="width: 280px" v-model:value="plate_no" placeholder="ทะเบียนรถ" />
                                </a-menu-item>
                                <a-menu-item key="6">
                                    6.พรบ.รถ
                                    <a-input style="width: 280px" v-model:value="car_tax_no" placeholder="เลขที่พรบ.ประกันภัย" />
                                </a-menu-item>
                                <a-menu-item key="7">
                                    7.ประกันภัยรถ
                                    <a-input style="width: 280px" v-model:value="car_insure_no" placeholder="เลขที่กรมธรรม์ประกันภัย" />
                                </a-menu-item>
                            </a-menu>
                        </template>
                        <a-button style="width: 450px;">
                            <div v-if="!car_filter_title" style="display: flex;justify-content: space-between;width: 100%">
                                <span style="color: grey">ข้อมูลรถ</span>
                                <span class="material-symbols-outlined" style="font-size: 16px;color: grey">directions_car</span>
                            </div>
                            <div v-else style="display: flex;justify-content: space-between;width: 100%">  
                                {{ car_filter_title }}
                            </div>
                        </a-button>
                    </a-dropdown>
                </a-col>
                <a-col>
                    <a-button type="primary" @click="clear_filter">ล้าง</a-button>
                </a-col>
            </a-row>
            <br>
            <div id="calendar"></div>
        </a-card>
    </div>
</template>

<script>
import dayjs from 'dayjs'
import buddhistEra from 'dayjs/plugin/buddhistEra'
dayjs.extend(buddhistEra)
import CalendarService from '../../api/CalendarService'
import SystemService from '../../api/SystemService'
import lang_th from 'ant-design-vue/es/date-picker/locale/th_TH';

import { Calendar } from '@fullcalendar/core';
import thLocale from "@fullcalendar/core/locales/th";
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';

export default {
    name: 'Schedules',
    data() {
        return {
            selected_company: null,
            date_type: 'Month',
            date_day: null,
            date_week: null,
            date_month: dayjs(),
            date_range: [],
            car_filter_visible: false,
            car_filter_list: [],
            companies: [],
            resources: [],
            events: [],
            car_type_list: [],
            calendar: null,
            lang_th: lang_th,
            car_type_id: null,
            car_no: null,
            car_model: null,
            car_brand: null,
            plate_no: null,
            car_tax_no: null,
            car_insure_no: null,
            calendar_view_name: {
                day: 'ResourceTimelineDay',
                week: 'ResourceTimelineWeek',
                month: 'ResourceTimelineMonth'
            }
        }
    },
    computed: {
        resourcesDetails() {
            return {
                total: this.resources.length,
                haveEvents: this.resources.filter(resource => this.events.filter(({ resourceId }) => resourceId == resource.id).length > 0).length,
                noEvents: this.resources.filter(resource => this.events.filter(({ resourceId }) => resourceId == resource.id).length == 0).length
            }
        },
        showSelectedCarType() {
            const target = this.car_type_list.find(car_type => car_type.id == this.car_type_id)
            return target? target.name : 'ยังไม่ได้เลือก'
        },
        datetime() {
            if (this.date_type == 'Day') {
                return this.date_day
            } else if (this.date_type == 'Week') {
                return dayjs(this.date_week, 'DD MMMM YYYY').format('DD/MM/YYYY')
            } else if (this.date_type == 'Month') {
                return dayjs(this.date_month, 'MMMM YYYY').format('DD/MM/YYYY')
            } else {
                return dayjs().format('DD/MM/YYYY')
            }
        },
        car_filter_title() {
            this.car_filter_list = []
            if (this.car_type_id) {
                this.car_filter_list.push({
                    key: 'car_type_id',
                    value: this.car_type_id,
                    label: `ประเภทรถ: ${this.car_type_list.find(car_type => car_type.id == this.car_type_id)?.name}`
                })
            }
            if (this.car_no) {
                this.car_filter_list.push({
                    key: 'car_no',
                    value: this.car_no,
                    label: `รหัสรถ: ${this.car_no}`
                })
            }
            if (this.car_model) {
                this.car_filter_list.push({
                    key: 'car_model',
                    value: this.car_model,
                    label: `รุ่นรถ: ${this.car_model}`
                })
            }
            if (this.car_brand) {
                this.car_filter_list.push({
                    key: 'car_brand',
                    value: this.car_brand,
                    label: `ยี่ห้อรถ: ${this.car_brand}`
                })
            }
            if (this.plate_no) {
                this.car_filter_list.push({
                    key: 'plate_no',
                    value: this.plate_no,
                    label: `ทะเบียนรถ: ${this.plate_no}`
                })
            }
            if (this.car_tax_no) {
                this.car_filter_list.push({
                    key: 'car_tax_no',
                    value: this.car_tax_no,
                    label: `พรบ.รถ: ${this.car_tax_no}`
                })
            }
            if (this.car_insure_no) {
                this.car_filter_list.push({
                    key: 'insure_no',
                    value: this.car_insure_no,
                    label: `ประกันภัยรถ: ${this.car_insure_no}`
                })
            }
            
            return this.car_filter_list.length? this.car_filter_list.map(({ label }) => label).join(', '):null
        },
    },
    methods: {
        clear_filter() {
            this.car_filter_list = []
            this.car_type_id = null
            this.car_no = null
            this.car_model = null
            this.car_brand = null
            this.plate_no = null
            this.car_tax_no = null
            this.car_insure_no = null
            
            this.get_schedule(this.selected_company, {
                startDate: dayjs(this.datetime).startOf(this.date_type.toLowerCase()).format('DD/MM/YYYY HH:mm:ss'),
                endDate: dayjs(this.datetime).endOf(this.date_type.toLowerCase()).format('DD/MM/YYYY HH:mm:ss')
            })
        },
        calendar_change() {
            if (this.date_type == 'Custom') {

            } else {
                this.calendar.changeView('resourceTimeline'+this.date_type)
            }
        },
        date_parser(datetime) {
            return dayjs(datetime).format('DD/MM/YYYY HH:mm:ss')
        },
        init_calendar() {
            var calendarEl = document.getElementById('calendar');
            this.calendar = new Calendar(calendarEl, {
                schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source',
                businessHours: {
                    // days of week. an array of zero-based day of week integers (0=Sunday)
                    daysOfWeek: [ 1, 2, 3, 4, 5 ], // Monday - Thursday
                    startTime: '00:00', // a start time (10am in this example)
                    endTime: '24:00', // an end time (6pm in this example)
                },
                headerToolbar: null,
                nowIndicator: false,
                plugins: [ resourceTimelinePlugin ],
                locale: thLocale,
                firstDay: 0,
                height: 'auto',
                initialView: 'resourceTimeline'+this.date_type,
                views: {
                    resourceTimelineCustom: {
                        type: 'resourceTimeline',
                        duration: { days: this.count_day_duration() },
                    }
                },
                resourceAreaHeaderContent: 'เบอร์รถ | ทะเบียนรถ',
                resourceAreaWidth: '250px',
                eventBackgroundColor: '#B1E4C0',
                eventBorderColor: '#B1E4C0',
                resources: this.resources,
                events: this.events,
                eventClick: function(info) {
                    const bookingId = info.event.extendedProps.bookingId
                    const url = `app/quotation/${bookingId}?edit=true`
                    window.open(url, '_blank')
                }
            });
            this.calendar.render();
        },
        count_day_duration(){
            return dayjs(this.date_range[1]).diff(dayjs(this.date_range[0]), 'day') + 1
        },
        async get_schedule(car_company_id, { startDate, endDate }) {
            this.$message.loading({ content: 'Loading...', key: 'get_schedule', duration: 0 })
            if ((!startDate || startDate == 'Invalid Date') && (!endDate || endDate == 'Invalid Date') || this.datetime.includes('/')) {
                [startDate, endDate] = [
                    dayjs(this.datetime, 'DD/MM/YYYY').startOf(this.date_type.toLowerCase()).format('YYYY-MM-DD HH:mm:ss'),
                    dayjs(this.datetime, 'DD/MM/YYYY').endOf(this.date_type.toLowerCase()).format('YYYY-MM-DD HH:mm:ss')
                ]
            }
            if (this.calendar) this.calendar.loading = true;

            const { resources, events } = await CalendarService.get_schedule(car_company_id, { startDate, endDate })
            this.resources = resources.map((r) => {
                r.title = `${r.car_no} | ${r.plate_no}`
                return r
            })
            this.events = events
            this.init_calendar()
            this.calendar.loading = false
            this.calendar.gotoDate(this.date_type == 'Custom'? startDate:endDate)
            this.$message.destroy('get_schedule')
        },
        async init_companies() {
            this.companies = await SystemService.get_all('CarCompany')
            this.selected_company = this.companies[0].id
        },
        async get_car_type_list() {
            this.car_type_list = await SystemService.get_all('CarType')
        },
    },
    watch: {
        date_range(newVal) {
            if (newVal.length == 2) {
                this.get_schedule(this.selected_company, {
                    startDate: dayjs(newVal[0]).format('YYYY-MM-DD HH:mm:ss'),
                    endDate: dayjs(newVal[1]).format('YYYY-MM-DD HH:mm:ss')
                })
            }
        },
        selected_company(newVal) {
            if (newVal) {
                this.get_schedule(this.selected_company, {
                    startDate: dayjs(this.datetime).startOf(this.date_type.toLowerCase()).format('YYYY-MM-DD HH:mm:ss'),
                    endDate: dayjs(this.datetime).endOf(this.date_type.toLowerCase()).format('YYYY-MM-DD HH:mm:ss')
                })
            }
        },
        datetime(newVal) {
            console.log('datetime');
            const type = this.date_type.toLowerCase()
            if (newVal && this['date_'+type]) {
                this.get_schedule(this.selected_company, {
                    startDate: dayjs(this.datetime).startOf(type).format('YYYY-MM-DD HH:mm:ss'),
                    endDate: dayjs(this.datetime).endOf(type).format('YYYY-MM-DD HH:mm:ss')
                })
            }
        },
        async car_filter_title() {
            await this.get_schedule(this.selected_company, {
                startDate: dayjs(this.datetime).startOf(this.date_type.toLowerCase()).format('YYYY-MM-DD HH:mm:ss'),
                endDate: dayjs(this.datetime).endOf(this.date_type.toLowerCase()).format('YYYY-MM-DD HH:mm:ss')
            })

            this.resources = this.resources.filter(e => {
                const filter = this.car_filter_list
                const found = filter.filter(({ key, value }) => {
                    return e[key]? 
                        (typeof e[key] == 'string'? 
                            e[key].toLowerCase().includes(value.toLowerCase()):e[key] == value):
                        false
                }).length

                return filter.length? found:true
            })

            this.calendar.refetchResources()
            setTimeout(() => {
                this.init_calendar()
            }, 100)
        }
    },
    mounted() {
        this.$emit('request_fullscreen', true)
        this.init_companies()
        this.get_car_type_list()
    }
}
</script>

<style lang="scss">
.ant-dropdown-menu-title-content {
    display:flex;
    align-items: center;
    justify-content: space-between;
}
.fc {
    a {
        color: black
    }
}
.fc .fc-day-sat, .fc-day-sun {
    background-color: #ff000040
}
// .fc-scroller::-webkit-scrollbar {
//     opacity: 1;
// }

</style>