<template>
    <div class="dashboard-container">
        <div class="app-content">
            <div class="header sticky">
                <h1>แดชบอร์ด</h1>
                <div style="display: flex">
                    <a-range-picker v-model:value="dateRange"  style="margin-right: 10px" format="DD/MM/YYYY" />
                    <a-button @click="loadPage(false)" style="width: fit-content" type="primary" class="primary-button">ค้นหา</a-button>
                </div>
            </div>

            <div class="card-container">
                <div class="dashboard-card bg-blue">
                    <span class="material-symbols-outlined icon">database</span>
                    <span>จำนวน Booking ทั้งหมด</span>
                    <span>{{ bookingCount }}</span>
                </div>

                <div class="dashboard-card bg-blue">
                    <span class="material-symbols-outlined icon">database</span>
                    <span>จำนวน Booking ที่ถูกยกเลิก</span>
                    <span>{{ canceledBookings }}</span>
                </div>

                <div class="dashboard-card bg-green">
                    <span class="material-symbols-outlined icon">database</span>
                    <span>รายได้</span>
                    <span>{{ billingAmount }}</span>
                </div>

                <div class="dashboard-card bg-orange">
                    <span class="material-symbols-outlined icon">database</span>
                    <span>Biling ที่รอการเรียกเก็บ</span>
                    <span>{{ billingIncompleteAmount.toLocaleString() }}</span>
                </div>
            </div>

            <a-row>
                <a-col :span="16">
                    <div class="chart-container">
                        <div class="chart-header bg-yellow" style="color: white">รายรับ-รายจ่าย</div>
                        <div class="chart-content">
                            <canvas id="income_outcome"></canvas>
                        </div>
                    </div>
                </a-col>
                <a-col :span="8">
                    <div class="chart-container">
                        <div class="chart-header bg-yellow" style="color: white">สถานะใบแจ้งหนี้</div>
                        <div class="chart-content">
                            <canvas id="billing_summary"></canvas>
                        </div>
                    </div>
                </a-col>
            </a-row>

            <a-row>
                <a-col :span="12">
                    <div class="chart-container">
                        <div class="chart-header bg-orange" style="color: white">ประสิทธิภาพของพนักงานขาย</div>
                        <div class="chart-content-table">
                            <table>
                                <tr class="table-header">
                                    <td>ลำดับ</td>
                                    <td>ชื่อ-นามสกุล</td>
                                    <td>ยอดขาย</td>
                                </tr>
                                <tr v-for="{ name, amount }, i in top_sales" :key="i">
                                    <td>{{ i + 1 }}</td>
                                    <td>{{ name }}</td>
                                    <td>{{ amount.toLocaleString() }}</td>
                                </tr>
                                <tr class="table-divider">
                                    <td colspan="2">รวม</td>
                                    <td>{{ top_sales.reduce((a,b) => a + b.amount, 0).toLocaleString() }}</td>
                                </tr>
                                <tr v-if="!top_sales.length" style="color: #f4f4f4">
                                    <td colspan="3">ไม่มีข้อมูล</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </a-col>

                <a-col :span="12">
                    <div class="chart-container">
                        <div class="chart-header bg-orange" style="color: white">พนักงานขับรถที่มีจำนวนทริปมากที่สุด
                        </div>
                        <div class="chart-content-table">
                            <table>
                                <tr class="table-header">
                                    <td>ลำดับ</td>
                                    <td>ชื่อ-นามสกุล</td>
                                    <td>จำนวนทริป</td>
                                    <td>มูลค่างาน</td>
                                </tr>
                                <tr v-for="{ name, amount, price }, i in top_drivers" :key="i">
                                    <td>{{ i + 1 }}</td>
                                    <td>{{ name }}</td>
                                    <td>{{ amount }}</td>
                                    <td>{{ price.toLocaleString() }}</td>
                                </tr>
                                <tr class="table-divider">
                                    <td colspan="2">รวม</td>
                                    <td>{{ top_drivers.reduce((a,b) => a + b.amount, 0).toLocaleString() }}</td>
                                    <td>{{ top_drivers.reduce((a,b) => a + b.price, 0).toLocaleString() }}</td>
                                </tr>
                                <tr v-if="!top_drivers.length" style="color: #f4f4f4">
                                    <td colspan="3">ไม่มีข้อมูล</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </a-col>
            </a-row>
            <a-row justify="space-between">
                <a-col :span="6">
                    <div class="chart-container">
                        <div class="chart-header bg-orange" style="color: white">ประเภทรถที่ถูกใช้</div>
                        <div class="chart-content">
                            <canvas id="most_used_cars"></canvas>
                        </div>
                    </div>
                </a-col>
                <a-col :span="6">
                    <div class="chart-container">
                        <div class="chart-header bg-orange" style="color: white">สถานะใบเสนอราคา</div>
                        <div class="chart-content">
                            <canvas id="quotation_summary"></canvas>
                        </div>
                    </div>
                </a-col>

                <a-col :span="6">
                    <div class="chart-container">
                        <div class="chart-header bg-orange" style="color: white">การแจ้งซ่อม</div>
                        <div class="chart-content">
                            <canvas id="repair_summary"></canvas>
                        </div>
                    </div>
                </a-col>
                <a-col :span="6">
                    <div class="chart-container">
                        <div class="chart-header bg-orange" style="color: white">สถานะใบการจอง</div>
                        <div class="chart-content">
                            <canvas id="bookings_summary"></canvas>
                        </div>
                    </div>
                </a-col>
            </a-row>
            <a-row>
                <a-col :span="12">
                    <div class="chart-container">
                        <div class="chart-header bg-blue" style="color: white">ยอดขาย</div>
                        <div class="chart-content">
                            <canvas id="sale_summary"></canvas>
                        </div>
                    </div>
                </a-col>

                <a-col :span="12">
                    <div class="chart-container">
                        <div class="chart-header bg-blue" style="color: white">ค่าเชื้อเพลิง</div>
                        <div class="chart-content">
                            <canvas id="fuel_summary"></canvas>
                        </div>
                    </div>
                </a-col>
            </a-row>

            <div class="chart-container">
                <div class="chart-header bg-blue" style="color: white">พนักงานขับรถ</div>
                <div class="chart-content-table">
                    <table>
                        <tr class="table-header">
                            <td>ลำดับ</td>
                            <td>ชื่อ</td>
                            <td>นามสกุล</td>
                            <td>เบอร์โทร</td>
                            <td>Online Status</td>
                            <td>Status</td>
                            <td>Username</td>
                            <td>Rating</td>
                        </tr>
                        <tr v-for="{ firstname, lastname, phone_no, work_status, username, rating }, i in drivers"
                            :key="i">
                            <td>{{ i + 1 }}</td>
                            <td>{{ firstname }}</td>
                            <td>{{ lastname }}</td>
                            <td>{{ phone_no }}</td>
                            <td>
                                <span :style="`color: ${table_icon.color[work_status]}`"
                                    class="material-symbols-outlined">{{ table_icon.work_status[work_status] }}</span>
                            </td>
                            <td>{{ table_icon.title[work_status] }}</td>
                            <td>{{ username }}</td>
                            <td>{{ rating }}</td>
                        </tr>
                        <tr class="table-divider">
                            <td colspan="8"><br></td>
                        </tr>
                        <tr v-if="!drivers.length" style="color: #f4f4f4">
                            <td colspan="8">ไม่มีข้อมูล</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import input_primary from '../../components/input_primary.vue'
import Chart from 'chart.js/auto'
import UserService from '../../api/UserService'
import dayjs from 'dayjs'
import buddhistEra from 'dayjs/plugin/buddhistEra'
dayjs.extend(buddhistEra)
import inputDatePicker from '../../components/input_date_picker.vue'

export default {
    components: { input_primary, inputDatePicker },
    name: 'Dashboard',
    props: {
        company_data: Object
    },
    data() {
        return {
            dateRange: [
                dayjs().startOf('month'),
                dayjs().endOf('month')
            ],
            //Dashboard
            billingIncompleteAmount: 0,
            billingAmount: 0,
            bookingCount: 0,
            canceledBookings: 0,
            billingOverall: [],
            bookingOverall: [],
            top_drivers: [],
            fuelOverall: [],
            incomeOverall: [],
            incomePerExpense: [],
            mostUsedVehicles: [],
            quotationOverall: [],
            top_sales: [],
            vehicleRepairOverall: [],
            table_icon: {
                color: {
                    Offline: 'red',
                    Online: 'green'
                },
                work_status: {
                    Offline: 'cancel',
                    Online: 'check_circle'
                },
                title: {
                    Offline: 'ไม่พร้อมรับงาน',
                    Online: 'พร้อมรับงาน'
                }
            },

            //Drivers
            drivers: [],
            chart_keys: [
                'income_outcome', 
                'most_used_cars', 
                'quotation_summary', 
                'billing_summary',
                'bookings_summary',
                'sale_summary',
                'fuel_summary',
                'repair_summary'
            ],
            chart_elements: []
        }
    },
    methods: {
        init_charts(key) {
            const { type, options, data } = this[key]
            const config = {
                type: type,
                data: data,
                options: options
            };
            
            if (this.chart_elements[this.chart_elements.findIndex(el => el.key === key)]) {
                this.chart_elements[this.chart_elements.findIndex(el => el.key === key)].chart.destroy()
                const res = new Chart(document.getElementById(key), config);
                this.chart_elements[this.chart_elements.findIndex(el => el.key === key)] = { key, chart: res }

            } else {
                const res = new Chart(document.getElementById(key), config);
                this.chart_elements.push({ key, chart: res })
            }
    
        },
        async get_data() {
            this.drivers = await UserService.GetDrivers()
        },
        async get_dashboard() {
            const dashboard = await UserService.get_dashboard(
                this.dateRange[0].format('DD/MM/YYYY'),
                this.dateRange[1].format('DD/MM/YYYY')
            )

            this.create_charts(dashboard)
        },
        create_charts(dashboard) {
            for (var key in dashboard) {
                this[key] = dashboard[key]
                if (this.chart_keys.includes(key)) {
                    this.init_charts(key)
                }
            }
        },
        async loadPage() {
            this.$message.loading({ content: 'Loading...', key: 'load' })
            this.get_data()
            await this.get_dashboard()
            this.$message.destroy('load')
        }
    },
    async mounted() {
        await this.loadPage()
    }
}
</script>

<style lang="scss" scoped>
.sticky {
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: white;
    border: 1px solid #e0e0e0;
    padding: 1rem;
}
.table-divider {
    border-top: 1px solid #f4f4f4
}

.chart-content-table {
    margin: 2%;
    padding: 5px;
    border: 1px solid #f4f4f4;
}

table {
    width: 100%;
    table-layout: auto;
    text-align: center;
    border-collapse: collapse;
    border-radius: 5px;

    tr {
        border-bottom: 1px solid #e8e8e8;
        line-height: 3rem;
    }
}

.chart-content {
    padding: 10px;
}

.chart-container {
    text-align: center;
    margin: 10px;
    border: 1px solid #f4f4f4;
    border-radius: 5px;

    .chart-header {
        font-size: 16px;
        padding: 16px;
        background-color: #e5e5e5;
    }
}

.card-container {
    margin: 3%;
    display: flex;
    height: fit-content;
}

.dashboard-card {
    text-align: center;
    width: 100%;
    margin: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;

    .icon {
        font-size: 50px
    }

    span {
        margin: 1rem;
        font-size: 14px;
        color: white
    }
}

.dashboard-container {
    padding: 8px;
    height: 100%
}

.header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
</style>
