<template>
    <div style="padding: 15px;height: 100vh">
        <div class="app-content">
            <a-card :title="topic[render_type].title">
                <template #extra>
                    <a-space v-if="editable">
                        <a-button v-if="topic[render_type].button_title" style="display: flex"
                            type="primary" size="large" @click="go_create(true_render_type)">
                            <template #icon><span class="material-symbols-outlined">Add</span></template>
                            {{ topic[render_type].button_title }}
                        </a-button>

                        <a-dropdown v-if="topic[render_type].allow_import">
                            <template #overlay>
                                <a-menu @click="import_export_dropdown">
                                    <a-menu-item key="1">
                                        <a-upload
                                            style="display: flex"
                                            v-model:file-list="import_fileList"
                                            :show-upload-list="false"
                                            name="file"
                                            action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                                            method="GET"
                                            @change="import_change"
                                        >
                                            <a-button ref="import_file">
                                                <span class="material-symbols-outlined">publish</span>Import
                                            </a-button>
                                        </a-upload>
                                    </a-menu-item>
                                    <a-menu-item key="2">
                                        <a-button ref="import_file">
                                            <span class="material-symbols-outlined">download</span>Export
                                        </a-button>
                                    </a-menu-item>
                                </a-menu>
                            </template>
                            <a-button style="display: flex" size="large">
                                <template #icon><span class="material-symbols-outlined">Settings</span></template>
                                ตัวเลือก
                            </a-button>
                        </a-dropdown>
                    </a-space>
                </template>

                <a-row>
                    <a-space>
                        <a-select v-if="topic[render_type].select_client" style="wdith: 100%" v-model:value="search_car_company" placeholder="เลือกบริษัทรถ">
                            <a-select-option v-for="{ fullname, id } in car_company_list" :key="id" :value="id">{{ fullname }}
                            </a-select-option>
                        </a-select>

                        <a-input-search v-if="topic[render_type].input" v-model:value="search_input"
                            :placeholder="`ค้นหา ${topic[render_type].input}`" enter-button="ค้นหา" />

                        <a-select v-if="topic[render_type].select_expire" style="wdith: 100%" v-model:value="search_expire_type" placeholder="เลือกประเภทวันหมดอายุ">
                            <a-select-option v-for="{ text, value }, i in [
                                { text: 'วันหมดอายุก่อน 1 เดือน', value: 1 },
                                { text: 'วันหมดอายุก่อน 2 เดือน', value: 2 },
                                { text: 'วันหมดอายุก่อน 3 เดือน', value: 3 },
                            ]" :key="i" :value="value">{{ text }}
                            </a-select-option>
                        </a-select>
                        <a-button @click="search_input = null, search_car_company = null, search_expire_type = null " v-if="topic[render_type].clear" type="dashed">CLEAR</a-button>
                    </a-space>
                </a-row>

                <br>

                <a-row>
                    <a-space>
                        <a-button v-for="button, i in topic[render_type].lower_buttons" :key="i" style="display: flex"
                            type="primary" size="large" @click="button_action(button.name)">
                            {{ button.title }}
                        </a-button>
                    </a-space>

                </a-row>
                <br>
                <a-row>
                    <a-table style="width: 100%" :columns="topic[render_type].columns.filter((c)=> !editable ? !c.key.includes('id') : true)" 
                        :data-source="filtered_table(show_table_list, { topic, render_type })" 
                        @change="onTableChange"
                        bordered size="small" :scroll="{ x: 'calc(100px + 50%)', y: '100%' }">
                        <template #bodyCell="{ column, record, index }">
                            <template v-if="column.key === 'id'">
                                <div>
                                    <a @click="go_edit(record, record.modelname)"><span style="font-size: 18px" class="material-symbols-outlined">drive_file_rename_outline</span></a>
                                    <a-divider type="vertical"></a-divider>
                                    <a @click="go_remove(record.id, record.modelname)"><span style="font-size: 18px" class="material-symbols-outlined">delete</span></a>
                                </div>
                            </template>
                            <template v-else-if="column.key == 'index'" >
                                {{index + 1}}
                            </template>
                            <template v-else-if="column.key == 'odometer'" >
                                <div style="display: flex; align-items: center; justify-content: end;">
                                    <span style="font-size: 16px;">{{record[column.key]? record.odometer.toLocaleString():0}}</span>
                                    
                                    <a-divider type="vertical"></a-divider>
                                    <a-tooltip placement="topLeft" title="ล้างเลขไมล์">
                                        <a style="height: 16px;color: red" @click="clear_odo(record.id)" >
                                            <span style="font-size: 16px" class="material-symbols-outlined">restart_alt</span>
                                        </a>
                                    </a-tooltip>

                                    <a-divider type="vertical"></a-divider>
                                    <a-popover title="ประวัติเลขไมล์">
                                        <template #content>
                                            <span>รวมระยะทางทั้งหมด: {{ formatted_odos(record.odos).map(({ distance }) => distance()).reduce((a,b) => a+b, 0).toLocaleString() }} กม.</span><br>
                                            <span>การใช้น้ำมัน: {{ record.gas_refills.map(({ refill_amount }) => refill_amount).reduce((a,b) => a+b, 0) }} ลิตร</span><br>
                                            <span>เฉลี่ยระยะทาง/น้ำมัน (ลิตร): {{ 
                                                (formatted_odos(record.odos).map(({ distance }) => distance()).reduce((a,b) => a+b, 0)/
                                                record.gas_refills.map(({ refill_amount }) => refill_amount).reduce((a,b) => a+b, 0)).toLocaleString({ maximumFractionDigits: 2})
                                            }} กม./ลิตร</span><br>
                                            <a-divider></a-divider>
                                            <div style="max-height: 500px;overflow-y: scroll;">
                                                <p v-for="{ start, end, distance }, i in formatted_odos(record.odos)" :key="i">
                                                    <div style="display: flex;">
                                                        {{ render_date(start?.created_at) }}: <a-tag color="green">เริ่มต้น</a-tag> {{ start?.distance.toLocaleString() }} -
                                                        {{ render_date(end?.created_at) }}: <a-tag color="red">สิ้นสุด</a-tag> {{ end?.distance.toLocaleString() }}
                                                    </div>
                                                    <span>ระยะทาง: {{ distance().toLocaleString() }}</span>
                                                    <a-divider></a-divider>
                                                </p>
                                            </div>
                                        </template>
                                        <a style="height: 16px;"><span class="material-symbols-outlined" style="font-size: 16px">history</span></a>
                                    </a-popover>
                                </div>
                            </template>
                            <template v-else-if="column.key == 'repair_target' && record.repair_target != null" >
                                <a-badge-ribbon v-if="record.odometer >= record.repair_target" text="ซ่อม" color="red">
                                    <a-card :title="record.repair_target" size="small"></a-card>
                                </a-badge-ribbon>
                            </template>
                            <template v-else-if="column.key == 'driver'" >
                                {{record.driver?.firstname}} {{record.driver?.lastname}}
                            </template>
                            <template v-else-if="column.key == 'car_company'" >
                                {{record.car_company?.fullname}}
                            </template>
                            <template v-else-if="column.key == 'client'" >
                                {{record.client?.fullname}}
                            </template>
                            <template v-else-if="column.key == 'client_branch'" >
                                {{record.client_branch?.name}}
                            </template>
                            <template v-else-if="column.key == 'car_status'" >
                                <a-space><a-badge v-if="record.car_status_id == 1" status="success" />
                                <a-badge v-if="record.car_status_id == 2" status="error" />
                                <a-select v-model:value="record.car_status_id" @change="update_car_status($event, record.id)" style="width: 125px">
                                    <a-select-option v-for="{ id, label } in [
                                        { id: 1, label: 'พร้อมใช้งาน' },
                                        { id: 2, label: 'ไม่พร้อมใช้งาน'}
                                    ]" :key="id" :value="id">
                                        {{ label }}
                                    </a-select-option>
                                </a-select></a-space>
                            </template>
                            <template v-else-if="column.key == 'car_tax_expire' && record.car_tax_expire">
                                <a-tag :color="render_expire(compare_time(record.car_tax_expire), true)">
                                    {{render_expire(compare_time(record.car_tax_expire), false) }}
                                </a-tag>
                            </template>
                            <template v-else-if="column.key == 'car_tax_expire_parse'">
                                <a-tag :color="render_expire(compare_time(record, 'car_tax_expire'), true)">
                                    {{render_expire(compare_time(record, 'car_tax_expire'), false) }}
                                </a-tag>
                            </template>
                            <template v-else-if="column.key == 'insure_expire_parse'">
                                <a-tag :color="render_expire(compare_time(record, 'insure_expire'), true)">
                                    {{render_expire(compare_time(record, 'insure_expire'), false) }}
                                </a-tag>
                            </template>
                            <template v-else-if="column.key == 'maintenance_expire_parse'">
                                <a-tag :color="render_expire(compare_time(record, 'maintenance_expire'), true)">
                                    {{render_expire(compare_time(record, 'maintenance_expire'), false) }}
                                </a-tag>
                            </template>
                            <template v-else-if="column.key == 'car_type'" >
                                {{record.car_type? record.car_type.name:record.car_group?.car_type?.name}}
                            </template>
                            <template v-else-if="column.key == 'car_group'" >
                                {{record.car_group?.name}}
                            </template>
                            <template v-else-if="column.key == 'parking'" >
                                {{record.parking?.name}}
                            </template>
                            <template v-else-if="column.key == 'aircon'" >
                                <a-switch v-model:checked="record.aircon" :checked="true" @change="update_car(record.id, record.aircon)" />
                            </template>
                        </template>

                    </a-table>
                </a-row>
            </a-card>
        </div>
    </div>
</template>

<script>
import { exportXLSXFile, xlxsBase64toJson } from '@/components/helpers.js'
import { message } from 'ant-design-vue';
import ReportService from '../../api/ReportService'
import SystemService from '../../api/SystemService'
import Utility from '../../utility'
import Swal from 'sweetalert2'
import dayjs from 'dayjs'
import buddhistEra from 'dayjs/plugin/buddhistEra'
dayjs.extend(buddhistEra)

export default {
    name: 'manage',
    data() {
        return {
            editable: Utility.get_permission_editable_with_key('manage_cars'),
            car_company_list: [],
            show_table_list: [],

            /* Search */
            search_car_company: null,
            search_expire_type: null,
            search_input: null,
            show_odohistory: false,

            sorter_data: null,

            topic: {
                CarCompany: {
                    title: 'จัดการบริษัทรถ',
                    button_title: 'เพิ่มบริษัทรถ',
                    input: 'ชื่อบริษัท / รหัสบริษัท',
                    input_key: 'fullname',
                    clear: true,
                    columns: [
                        {
                            title: 'ชื่อบริษัท',
                            dataIndex: 'fullname',
                            key: 'fullname',
                            sorter: true,
                            width: 500
                        }, {
                            title: 'ที่อยู่',
                            dataIndex: 'address',
                            key: 'address',
                            width: 650
                        }, {
                            title: 'เบอร์โทร',
                            dataIndex: 'phone_no',
                            key: 'phone_no',
                            width: 120
                        }, {
                            title: 'เบอร์แฟกซ์',
                            dataIndex: 'fax_no',
                            key: 'fax_no',
                            width: 120
                        }, {
                            title: 'ตัวเลือก',
                            dataIndex: 'id',
                            key: 'id',
                            align: 'center',
                            width: 100,
                            fixed: 'right'
                        }
                    ],
                },
                CarType: {
                    title: 'จัดการชนิดรถ',
                    button_title: 'เพิ่มชนิดรถ',
                    input: 'ชื่อชนิดรถ',
                    input_key: 'name',
                    clear: true,
                    columns: [
                        {
                            title: 'ชื่อชนิดรถ',
                            dataIndex: 'name',
                            key: 'name',
                            sorter: true,
                            width: 620
                        }, {
                            title: 'ตัวเลือก',
                            dataIndex: 'id',
                            key: 'id',
                            width: 100,
                            fixed: 'right'
                        }
                    ]
                },
                CarGroup: {
                    title: 'จัดการประเภทที่นั่งรถ',
                    button_title: 'เพิ่มประเภทที่นั่งรถ',
                    input: 'จัดการประเภทที่นั่งรถ',
                    input_key: 'name',
                    clear: true,
                    columns: [
                        {
                            title: 'ชื่อประเภทที่นั่งรถ',
                            dataIndex: 'name',
                            key: 'name',
                            sorter: true,
                            width: 300
                        }, {
                            title: 'ชื่อชนิดรถ',
                            dataIndex: 'car_type',
                            key: 'car_type',
                            width: 250
                        }, {
                            title: 'จำนวนที่นั่ง',
                            dataIndex: 'seats',
                            key: 'seats',
                            width: 150
                        }, {
                            title: 'ราคา',
                            dataIndex: 'price',
                            key: 'price',
                            width: 150
                        }, {
                            title: 'เป็นรถปรับอากาศด้วยแอร์',
                            dataIndex: 'aircon',
                            key: 'aircon',
                            width: 170
                        }, {
                            title: 'ตัวเลือก',
                            dataIndex: 'id',
                            key: 'id',
                            width: 100,
                            fixed: 'right'
                        }
                    ]
                },
                DriverCost: {
                    title: 'จัดการเบี้ยเลี้ยง',
                    button_title: 'เพิ่มเบี้ยเลี้ยง',
                    columns: [
                        {
                            title: 'ชื่อเบี้ยเลี้ยง',
                            dataIndex: 'name',
                            key: 'name',
                            width: 50,
                            align: 'center'
                        },
                        {
                            title: 'ชื่อประเภทการจอง',
                            dataIndex: 'booking_type',
                            key: 'booking_type',
                            width: 50,
                            align: 'center'
                        },
                        {
                            title: 'จำนวนเบี้ยเลี้ยง',
                            dataIndex: 'cost_per_day',
                            key: 'cost_per_day',
                            width: 50,
                            align: 'center'
                        },
                        {
                            title: 'ตัวเลือก',
                            dataIndex: 'id',
                            key: 'id',
                            width: 50,
                            align: 'center'
                        }
                    ]
                },
                Driver: {
                    title: 'จัดการพนักงานขับรถ',
                    button_title: 'เพิ่มพนักงานขับรถ',
                    input: 'ชื่อผู้ขับ',
                    input_key: 'firstname',
                    allow_import: true,
                    clear: true,
                    columns: [
                        {
                            title: 'ลำดับ',
                            dataIndex: 'index',
                            key: 'index',
                            width: 50,
                            align: 'center'
                        }, {
                            title: 'รหัสประชาชน',
                            dataIndex: 'id_no',
                            key: 'id_no',
                            sorter: true,
                            width: 300
                        }, {
                            title: 'ชื่อผู้ขับ',
                            dataIndex: 'firstname',
                            key: 'firstname',
                            sorter: true,
                            width: 125
                        }, {
                            title: 'นามสกุลผู้ขับ',
                            dataIndex: 'lastname',
                            key: 'lastname',
                            sorter: true,
                            width: 125
                        }, {
                            title: 'เบอร์มือถือ',
                            dataIndex: 'phone_no',
                            key: 'phone_no',
                            width: 150
                        }, {
                            title: 'เบอร์สำรอง',
                            dataIndex: 'phone_backup',
                            key: 'phone_backup',
                            width: 150
                        }, {
                            title: 'ตัวเลือก',
                            dataIndex: 'id',
                            key: 'id',
                            width: 100,
                            fixed: 'right'
                        }
                    ]
                },
                Guide: {
                    title: 'จัดการไกด์',
                    button_title: 'เพิ่มไกด์',
                    input: 'ชื่อไกด์ / ชื่อลูกค้า / ชื่อบริษัท',
                    input_key: 'firstname',
                    clear: true,
                    columns: [
                        {
                            title: 'ชื่อไกด์',
                            dataIndex: 'firstname',
                            key: 'firstname',
                            sorter: true,
                            width: 300
                        }, {
                            title: 'ชื่อเล่น',
                            dataIndex: 'nickname',
                            key: 'nickname',
                            sorter: true,
                            width: 250
                        }, {
                            title: 'อีเมลล์',
                            dataIndex: 'email',
                            key: 'email',
                            width: 150
                        }, {
                            title: 'เบอร์มือถือ',
                            dataIndex: 'phone_no',
                            key: 'phone_no',
                            width: 150
                        }, {
                            title: 'เบอร์สำรอง',
                            dataIndex: 'phone_backup',
                            key: 'phone_backup',
                            width: 150
                        }, {
                            title: 'ตัวเลือก',
                            dataIndex: 'id',
                            key: 'id',
                            width: 100,
                            fixed: 'right'
                        }
                    ]
                },
                ClientContact: {
                    title: 'จัดการผู้ประสานงาน',
                    button_title: 'เพิ่มผู้ประสานงาน',
                    input: 'ผู้ประสานงาน / ชื่อบริษัท',
                    input_key: 'firstname',
                    clear: true,
                    columns: [
                        {
                            title: 'ชื่อผู้ประสานงาน',
                            dataIndex: 'firstname',
                            key: 'firstname',
                            sorter: true,
                            width: 150
                        },{
                            title: 'นามสกุลผู้ประสานงาน',
                            dataIndex: 'lastname',
                            key: 'lastname',
                            sorter: true,
                            width: 150
                        },{
                            title: 'ชื่อบริษัท',
                            dataIndex: 'client',
                            key: 'client',
                            sorter: true,
                            width: 300
                        },{
                            title: 'ชื่อสาขา',
                            dataIndex: 'client_branch',
                            key: 'client_branch',
                            sorter: true,
                            width: 300
                        },{
                            title: 'อีเมลล์',
                            dataIndex: 'email',
                            key: 'email',
                            width: 150
                        }, {
                            title: 'เบอร์มือถือ',
                            dataIndex: 'phone_no',
                            key: 'phone_no',
                            width: 150
                        },{
                            title: 'ตัวเลือก',
                            dataIndex: 'id',
                            key: 'id',
                            width: 100,
                            fixed: 'right'
                        }
                    ]
                },
                Car: {
                    title: 'จัดการรถทั้งหมด',
                    button_title: 'เพิ่มรถทั้งหมด',
                    allow_import: true,
                    select_client: true,
                    clear: true,
                    input: 'ทะเบียนรถ',
                    input_key: 'plate_no',
                    lower_buttons: [
                        { name: 'vehicle_report', title: 'รายงานการใช้รถ' },
                        { name: 'vehicle_use_report', title: 'ออกรายงานรถ' },
                        { name: 'show_car_company_id', title: 'แสดงรหัสบริษัทรถ' }
                    ],
                    columns: [
                        {
                            title: 'ลำดับ',
                            dataIndex: 'index_id',
                            key: 'index_id',
                            width: 50,
                            align: 'center'
                        }, {
                            title: 'ทะเบียนรถ',
                            dataIndex: 'plate_no',
                            key: 'plate_no',
                            width: 100,
                        }, {
                            title: 'สถานะรถ',
                            dataIndex: 'car_status',
                            key: 'car_status',
                            align: 'center',
                            width: 200,
                        }, {
                            title: 'พรบ.',
                            dataIndex: 'car_tax_expire_parse',
                            key: 'car_tax_expire_parse',
                            width: 150,
                            align: 'center'
                        }, {
                            title: 'เบอร์รถ',
                            dataIndex: 'car_no',
                            key: 'car_no',
                            width: 100,
                        }, {
                            title: 'ชนิดรถ',
                            dataIndex: 'car_type',
                            key: 'car_type',
                            width: 200,
                        }, {
                            title: 'ประเภทที่นั่งรถ',
                            dataIndex: 'car_group',
                            key: 'car_group',
                            width: 200,
                        }, {
                            title: 'ยี่ห้อรถ',
                            dataIndex: 'car_brand',
                            key: 'car_brand',
                            width: 200,
                        }, {
                            title: 'จุดจอด',
                            dataIndex: 'parking',
                            key: 'parking',
                            width: 150,
                        }, {
                            title: 'เลขไมล์รถ',
                            dataIndex: 'odometer',
                            key: 'odometer',
                            width: 200,
                            align: 'center'
                        }, {
                            title: 'เลขไมล์แจ้งซ่อม',
                            dataIndex: 'repair_target',
                            key: 'repair_target',
                            width: 150,
                        }, {
                            title: 'ชื่อผู้ขับ',
                            dataIndex: 'driver',
                            key: 'driver',
                            width: 150,
                        }, {
                            title: 'บริษัท',
                            dataIndex: 'car_company',
                            key: 'car_company',
                            width: 150,
                        },
                        {
                            title: 'ตัวเลือก',
                            dataIndex: 'id',
                            key: 'id',
                            width: 100,
                            align: 'center',
                            fixed: 'right'
                        }
                    ]
                },
                Parking: {
                    title: 'จัดการจุดจอดยานพาหนะทั้งหมด',
                    button_title: 'เพิ่มจุดจอดยานพาหนะ',
                    lower_buttons: [
                        { name: 'park_report', title: 'ออกรายงานจุดจอดยานพาหนะ' },
                        { name: 'park_booking', title: 'ออกรายการจองของจุดจอด' }
                    ],
                    columns: [
                        {
                            title: 'ชื่อจุดจอดยานพาหนะ',
                            dataIndex: 'name',
                            key: 'name',
                            width: 300
                        }, {
                            title: 'จำนวนยานพาหนะทั้งหมดในจุดจอด',
                            dataIndex: 'max_lots',
                            key: 'max_lots',
                            width: 250
                        }, {
                            title: 'Latitude',
                            dataIndex: 'lat',
                            key: 'lat',
                            width: 150
                        }, {
                            title: 'Longitude',
                            dataIndex: 'lng',
                            key: 'lng',
                            width: 150
                        }, {
                            title: 'ตัวเลือก',
                            dataIndex: 'id',
                            key: 'id',
                            width: 100,
                            fixed: 'right'
                        }
                    ]
                },
                Expense: {
                    title: 'จัดการค่าใช้จ่ายเพิ่มเติมในรถ',
                    button_title: 'เพิ่มค่าใช้จ่ายเพิ่มเติมในรถ',
                    columns: [
                        {
                            title: 'ชื่อค่าใช้จ่ายเพิ่มเติม',
                            dataIndex: 'name',
                            key: 'name',
                        }, {
                            title: 'ตัวเลือก',
                            dataIndex: 'id',
                            key: 'id',
                            width: 100,
                            align: 'center',
                            fixed: 'right'
                        }
                    ]
                },
                Extra: {
                    title: 'จัดการค่าใช้จ่ายเพิ่มเติม QT',
                    button_title: 'เพิ่มค่าใช้จ่ายเพิ่มเติม QT',
                    input: 'ชื่อรายการ',
                    input_key: 'name',
                    clear: true,
                    columns: [
                        {
                            title: 'ชื่อค่าใช้จ่ายเพิ่มเติม',
                            dataIndex: 'name',
                            key: 'name',
                            width: 300
                        }, {
                            title: 'ราคาต่อชิ้น',
                            dataIndex: 'price',
                            key: 'price'
                        }, {
                            title: 'ตัวเลือก',
                            dataIndex: 'id',
                            key: 'id',
                            width: 100,
                            fixed: 'right'
                        }
                    ]
                },
                Trip: {
                    title: 'จัดการจุดประสงค์การเดินทาง',
                    button_title: 'เพิ่มจุดประสงค์การเดินทาง',
                    input: 'ใบเสนอราคา ชื่อลูกค้า ชื่อ Sale',
                    input_key: 'name',
                    clear: true,
                    columns: [
                        {
                            title: 'ชื่อ',
                            dataIndex: 'name',
                            key: 'name'
                        }, {
                            title: 'ตัวเลือก',
                            dataIndex: 'id',
                            key: 'id',
                            width: 100,
                            align: 'center',
                            fixed: 'right'
                        }
                    ]
                },
                Expire: {
                    title: 'รายงานวันหมดอายุรถ',
                    select_client: true,
                    select_expire: true,
                    input: 'ทะเบียนรถ',
                    input_key: 'plate_no',
                    clear: true,
                    columns: [
                        {
                            title: 'ทะเบียนรถ',
                            dataIndex: 'plate_no',
                            key: 'plate_no'
                        }, {
                            title: 'เบอร์รถ',
                            dataIndex: 'car_no',
                            key: 'car_no'
                        }, {
                            title: 'บริษัท',
                            dataIndex: 'car_company',
                            key: 'car_company'
                        }, {
                            title: 'วันหมดอายุกรมธรรม์พรบ.',
                            dataIndex: 'car_tax_expire_parse',
                            key: 'car_tax_expire_parse'
                        }, {
                            title: 'วันตรวจสภาพรถกลางปี',
                            dataIndex: 'maintenance_expire_parse',
                            key: 'maintenance_expire_parse'
                        }, {
                            title: 'วันหมดอายุกรมธรรม์ประกันภัย',
                            dataIndex: 'insure_expire_parse',
                            key: 'insure_expire_parse'
                        }
                    ]
                }
            },
            import_fileList: []
        }
    },
    computed: {
        render_type() {
            return this.$route.params.type
        },
        true_render_type() {
            const { table } = this.$route.query
            return table? table:this.$route.params.type
        }
    },
    methods: {
        async update_car_status(status_id, car_id) {
            return await SystemService.update_all('Car', { data: { car_status_id: status_id } }, car_id)
        },
        filtered_table(array, { topic, render_type }) {
            const { search_input, search_car_company, search_expire_type } = this
            return array
                .filter((el, i) => {
                    if (search_input) {
                        const found_driver = () => {
                            return el.driver? el.driver.firstname.includes(search_input):false
                        }

                        const found_client = () => {
                            return el.client? el.client.fullname.includes(search_input):false
                        }

                        return el[topic[render_type].input_key].includes(search_input) || found_driver() || found_client()
                    } else {
                        return true
                    }
                })
                .filter((el, i) => {
                    if (search_car_company) {
                        return el.car_company_id == search_car_company
                    } else {
                        return true
                    }
                })
                .filter((el, i) => {
                    if (search_expire_type) {
                        const amount = this.compare_time(el.car_tax_expire)
                        console.log(el.car_tax_expire);
                        return amount > (search_expire_type * 30) && amount > 0 && el.car_tax_expire
                    } else {
                        return true
                    }
                }).sort((a, b) => {
                    if (this.sorter_data) {
                        const { field, order } = this.sorter_data
                        if (order == 'ascend') {
                            return a[field] > b[field] ? 1:-1
                        } else {
                            return a[field] < b[field] ? 1:-1
                        }
                    } else {
                        return 0
                    }
                })
        },
        render_expire(amount, tag) {
            if (tag) {
                if (amount <= 60 && amount >= 30) return 'orange'
                if (amount <= 30) return 'red'
                else return 'green'
            } else {
                if (amount < 0) return 'หมดอายุไปแล้ว ' + Math.abs(amount) + ' วัน'
                else return'หมดอายุใน ' + Math.abs(amount) + ' วัน'
            }
        },
        compare_time(timeA, is_parse) {
            if (timeA && !is_parse) {
                const timeA_moment = dayjs(timeA)
                const timeB_moment = dayjs()
                return Number(timeA_moment.diff(timeB_moment, 'days'))
                
            } else if (is_parse) {
                const date = timeA.data_raw_text? JSON.parse(timeA.data_raw_text)[is_parse]:null
                const timeA_moment = dayjs(date)
                const timeB_moment = dayjs()
                
                return isNaN(Number(timeA_moment.diff(timeB_moment, 'days')))? null:Number(timeA_moment.diff(timeB_moment, 'days'))

            } else {
                return 0
            }
        },
        formatted_odos(odos) {
            const new_odos = []
            const sorted_odos = odos.sort((a, b) => {
                return new Date(b.created_at) - new Date(a.created_at)
            })
            for (let i = 0; i < sorted_odos.length; i += 2) {
                const end_odo = sorted_odos[i].is_start? null:sorted_odos[i]
                const start_odo = sorted_odos[i + 1].is_start? sorted_odos[i + 1]:null
                const odo_obj = {
                    start: start_odo,
                    end: end_odo,
                    distance: () => {
                        if (start_odo && end_odo) {
                            return end_odo.distance - start_odo.distance
                        } else {
                            return 0
                        }
                    }
                }
                
                new_odos.push(odo_obj)
            }
            return new_odos
        },
        render_date(date) {
            return dayjs(date, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm')
        },
        import_change(event, field) {
            const file = event.file.originFileObj
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => {
                const result = xlxsBase64toJson(reader.result.split(',')[1])

                if (result && event.file.status === 'done') {
                    message.loading({ content: 'กำลังนำเข้าข้อมูล...', key: 'import', duration: 0 })
                    ReportService[`import_${this.render_type.toLowerCase()}`](result)
                        .then(res => {
                            this.init_data_table(this.render_type)
                                .then(res => {
                                    message.success({ content: `นำเข้าข้อมูล ${result.length} รายการสำเร็จ!`, key: 'import', duration: 1.5 })
                                })
                        })
                }
            }
        },
        export_file() {
            const headers = {
                Driver: {
                    firstname: 'ชื่อ',
                    lastname: 'นามสกุล',
                    driver_no: 'DriverNo',
                    salary: 'เงินเดือน',
                    id_no: 'บัตรประชาชน',
                    phone_no: 'เบอร์โทร1',
                    email: 'อีเมล',
                    username: 'Username',
                    password: 'Password',
                    register_date: 'วันเริ่มงาน (dd/mm/yyyy)',
                    bank_accno: 'บัญชีธนาคาร',
                    company_id: 'CompanyId',
                    driving_license_type: 'ประเภทใบขับขี่',
                    driver_license_no: 'เลขที่ใบขับขี่',
                    driving_license_exp: 'วันหมดอายุใบขับขี่ (dd/mm/yyyy)',
                },
                Car: {
                    plate_no: 'เลขทะเบียน',
                    tax_provider_name: 'บริษัท พรบ',
                    car_tax_no: 'พรบ.',
                    car_tax_expire: 'วันหมดอายุพรบ.',
                    // car_type_name: 'ชนิดรถ',
                    car_no: 'เบอร์รถ',
                    car_brand: 'ยี่ห้อ',
                    car_model: 'รุ่น',
                    car_year: 'ปี',
                    // car_type_id: 'VehicleDetailId (id ของประเภทรถ)',
                    created_at: 'Register Date(dd/mm/yyyy)',
                    remark: 'รายละเอียด',
                    insure_provider_name: 'บริษัทประกันภัย',
                    insure_no: 'เลขที่กรมธรรม์',
                    insure_expire: 'วันหมดอายุประกันภัย',
                    car_company_name: 'CarCompanyName',
                    parking_name: 'ชื่อจุดจอดยานพาหนะ'
                }
            }
            const fields = {
                Driver: [
                    'firstname', 'lastname', 'driver_no', 'salary', 'id_no', 'phone_no', 'email', 'username', 'password', 'register_date', 'bank_accno', 'company_id','driving_license_type','driver_license_no','driving_license_exp'
                ],
                Car: [
                    'plate_no', 'car_no','tax_provider_name', 'car_tax_no', 'car_tax_expire', 'car_brand', 'car_model', 'car_year', 
                // 'car_type_id', 
                'created_at', 'remark',, 'insure_provider_name', 'insure_no', 'insure_expire', 'car_company_name', 'parking_name'
            ]
            }

            if (this.render_type == 'Car') {
                this.show_table_list.forEach((car) => {
                    // car.car_type_name = car.car_group?.car_type?.name
                    car.parking_name = car.parking?.name
                    car.car_company_name = car.car_company?.fullname
                    car.tax_provider_name = JSON.parse(car.data_raw_text)?.tax_provider_name
                    car.insure_no = JSON.parse(car.data_raw_text)?.insure_no
                    car.insure_expire = JSON.parse(car.data_raw_text)?.insure_expire
                    car.insure_provider_name = JSON.parse(car.data_raw_text)?.insure_provider_name
                })
            }
            if (this.render_type == 'Driver') {
                this.show_table_list.forEach((driver) => {
                    driver.driving_license_type = driver.driving_license_type?.name
                })
            }

            // console.log(this.show_table_list[0]);

            exportXLSXFile( headers[this.render_type], this.show_table_list, this.render_type, fields[this.render_type]);
        },
        import_export_dropdown(e) {
            const { key } = e
            if (key == '1') {
                
            } else if (key == '2') {
                this.export_file()
            }
        },
        update_car(id, aircon) {
            SystemService.update_all('CarGroup', { data: { aircon } }, id)
        },
        clear_odo(id) {
            Swal.fire({
                title: 'คุณต้องการล้างเลขไมล์ใช่หรือไม่?',
                text: "คุณจะไม่สามารถกู้คืนข้อมูลนี้ได้หากล้างเลขไมล์แล้ว!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'ยกเลิก',
                confirmButtonText: 'ใช่, ล้างเลขไมล์!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    this.$message.loading({ content: 'กำลังล้างเลขไมล์...', key: 'clear_odo', duration: 0 })
                    await SystemService.update_all('Car', { data: { odometer: 0 } }, id)
                    this.$message.success({ content: 'ล้างเลขไมล์สำเร็จ!', key: 'clear_odo', duration: 1.5 })
                    this.init_data_table(this.render_type)
                }
            })
        },
        async go_remove(id, type) {
            Swal.fire({
                title: `คุณต้องการลบข้อมูลนี้ใช่หรือไม่? [${type}ID: ${id}]`,
                text: "คุณจะไม่สามารถกู้คืนข้อมูลนี้ได้หากลบแล้ว!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'ใช่, ลบข้อมูลนี้!',
                cancelButtonText: 'ยกเลิก'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await SystemService.delete_all(type, id)
                        .then(() => {
                            this.init_data_table(this.render_type)
                        })
                }
            })
        },
        go_edit(model_data, modelname) {
            this.$emit('apply_model_data', model_data)
            this.$router.push(`/app/${modelname}/${model_data.id}`)
        },
        go_create(modelname) {
            this.$router.push(`/app/${modelname}/create`)
        },
        async init_data_table(path) {
            const { table } = this.$route.query
            this.$message.loading({ content: 'กำลังโหลดข้อมูล...', key: 'init_data_table'  })
            if ((table? table:path) != 'all') {
                await SystemService.get_all(table? table:path)
                    .then(res => {
                        this.$message.success({ content: 'โหลดข้อมูลสำเร็จ!', key: 'init_data_table', duration: 1.5 })
                        if (res) {
                            this.show_table_list = res
                            this.show_table_list = this.show_table_list.map((el, i) => {
                                el.index_id = i+1
                                if (el.aircon == 1) el.aircon = true
                                else if (el.aircon == 0) el.aircon = false
                                return el
                            })
                            this.$message.success({ content: 'โหลดข้อมูลสำเร็จ!', key: 'init_data_table', duration: 1.5 })
                        }
                    })
            }
        },
        async init_company_default() {
            this.car_company_list = await SystemService.get_all('CarCompany')
        },
        button_action(type) {
            this[type]()
        },
        vehicle_report() {
            ReportService.vehicle_usage()
                .then(res => {
                    const { headers, data } = res
                    exportXLSXFile( headers, data, `vehicle_report`);
                })
        },
        vehicle_use_report() {
            ReportService.vehicle_use_report()
                .then(res => {
                    const { headers, data } = res
                    exportXLSXFile( headers, data, `vehicle_use_report`);
                })
        },
        show_car_company_id () {
            SystemService.get_all('CarCompany')
                .then(res => {
                    const ids = res.map((el) => {
                        return `ID (${el.id}) ${el.fullname}`
                    })
                    Swal.fire({
                        title: 'บริษัทรถ',
                        html: `${ids.join('<br>')}`
                    })
                })
        },
        park_report() {
            ReportService.park_report()
                .then(res => {
                    const { headers, data } = res
                    exportXLSXFile( headers, data, `park_report`);
                })
        },
        park_booking() {
            ReportService.park_booking()
                .then(res => {
                    const { headers, data } = res
                    exportXLSXFile( headers, data, `park_booking`);
                })
        },
        onSearch(event) {
            if (event) {
                this.show_table_list = this.show_table_list.filter((el) => {
                    return el[this.topic[this.render_type].input_key].includes(this.search_input)
                })
            }
        },
        onTableChange(pagination, filters, sorter, extra) {
            this.sorter_data = sorter
        },
    },
    watch: {
        async render_type(newVal) {
            if (newVal) {
                this.show_table_list = []
                await this.init_data_table(this.render_type)
            }
        }
    },
    mounted() {
        this.init_company_default()
        this.init_data_table(this.render_type)
    }
}
</script>