<template>
    <div style="padding: 15px;height: 100vh">
        <div class="app-content">
            <a-card v-if="render_type == 'booking'" title="การวางบิล">
                <a-row v-if="editable" style="margin: 1rem 0">
                    <a-space>
                        <a-button :disabled="selectedRowKeys.length > 1 || selectedRowKeys.length == 0" @click="show_extras_modal()" style="display: flex" type="primary" size="large">
                            <template #icon><span class="material-symbols-outlined">Add</span></template>
                            สร้างบิลค่าใช้จ่ายเพิ่มเติม
                        </a-button>
                        <a-button :disabled="selectedRowKeys.length > 1 || selectedRowKeys.length == 0" @click="show_expenses_modal()" style="display: flex" type="primary" size="large">
                            <template #icon><span class="material-symbols-outlined">Add</span></template>
                            สร้างบิลเบิกค่าส่วนเกิน
                        </a-button>
                        <a-button :disabled="selectedRowKeys.length > 1 || selectedRowKeys.length == 0" @click="show_create_billing_modal(selectedRowKeys)" style="display: flex" type="primary" size="large">
                            <template #icon><span class="material-symbols-outlined">Add</span></template>
                            สร้างใบวางบิล
                        </a-button>
                        <a-button :disabled="selectedRowKeys.length == 0" @click="update_admin(selectedRowKeys)" style="display: flex" type="primary" size="large">
                            <template #icon><span class="material-symbols-outlined">Add</span></template>
                            Admin Confirm
                        </a-button>
                    </a-space>
                </a-row>
                <a-row v-if="editable"  style="margin: 1rem 0">
                    <a-space>
                        <a-button :disabled="selectedRowKeys.length != 1" @click="gu_modal = true" style="display: flex" type="primary" size="large">
                            <template #icon><span class="material-symbols-outlined">download</span></template>
                            ออกใบค้ำประกัน
                        </a-button>
                        <a-button :disabled="selectedRowKeys.length != 1" @click="create_invoice(false, true)" style="display: flex" type="primary" size="large">
                            <template #icon><span class="material-symbols-outlined">download</span></template>
                            ออกใบวางบิล
                        </a-button>
                        <a-button :disabled="selectedRowKeys.length != 1" @click="create_receipt(true)" style="display: flex" type="primary" size="large">
                            <template #icon><span class="material-symbols-outlined">download</span></template>
                            ออกใบเสร็จรับเงิน
                        </a-button>
                    </a-space>
                </a-row>

                <a-row>
                    <a-col :span="8">
                        <a-select style="width: 100%" 
                            v-model:value="search_client" 
                            placeholder="เลือกลูกค้า/บริษัท"
                            :options="company_list.map(({ id, fullname }) => ({ value: id, label: fullname }))"
                            :filter-option="filterOption"
                            show-search
                        >
                        </a-select>
                    </a-col>
                    <a-col :span="4">
                        <a-button @click="search_client = null" type="dashed">CLEAR</a-button>
                    </a-col>
                </a-row>
                <br>
                <a-row>
                    <a-table rowKey="id" :columns="[
                        {
                            title: 'QTC',
                            dataIndex: 'booking_no',
                            key: 'booking_no',
                            width: 100,
                            sorter :{
                                compare: (a, b) => a.booking_no.localeCompare(b.booking_no),
                                multiple: 1,
                            }
                        }, {
                            title: 'วันเวลารับ',
                            dataIndex: 'time_start',
                            key: 'time_start',
                            width: 150,
                        }, {
                            title: 'วันเวลาส่ง',
                            dataIndex: 'time_end',
                            key: 'time_end',
                            width: 150,
                        }, {
                            title: 'ลูกค้า/บริษัท',
                            dataIndex: 'client',
                            key: 'client',
                            width: 304
                        }, {
                            title: 'รายละเอียดชำระเงิน',
                            dataIndex: 'pay_extend',
                            key: 'pay_extend',
                            width: 200,
                            align: 'center',
                        }, {
                            title: 'หลักประกัน',
                            dataIndex: 'guarantee',
                            key: 'guarantee',
                            width: 100,
                            align: 'center'
                        }, {
                            title: 'รวมค่ารถ',
                            dataIndex: 'booking_car_types',
                            key: 'booking_car_types',
                            width: 125,
                            align: 'center'
                        }, {
                            title: 'ค่าใช้จ่ายเพิ่มเติม',
                            dataIndex: 'extras',
                            key: 'extras',
                            width: 125,
                            align: 'center'
                        }, {
                            title: 'ภาษีมูลค่าเพิ่ม',
                            dataIndex: 'vat',
                            key: 'vat',
                            width: 125,
                            align: 'center'
                        }, {
                            title: 'ยอดเต็ม',
                            dataIndex: 'summary',
                            key: 'summary',
                            width: 100,
                            align: 'center'
                        }, {
                            title: 'ส่วนลด',
                            dataIndex: 'discount',
                            key: 'discount',
                            width: 100,
                            align: 'center'
                        }, {
                            title: 'ยอดเรียกเก็บทั้งหมด',
                            dataIndex: 'total_price',
                            key: 'total_price',
                            width: 100
                        }, {
                            title: 'ยอดที่กำลังดำเนินการเรียกเก็บ',
                            dataIndex: 'total_billing',
                            key: 'total_billing',
                            width: 84
                        }, {
                            title: 'วันที่ - เวลา',
                            dataIndex: 'updated_at',
                            key: 'updated_at',
                            width: 150,
                            sorter :{
                                compare: (a, b) => a.updated_at.localeCompare(b.updated_at),
                                multiple: 1,
                            }
                        }, {
                            title: 'ผู้โดยสาร',
                            dataIndex: 'person_qty',
                            key: 'person_qty',
                            width: 100
                        }, {
                            title: 'Admin Approved',
                            dataIndex: 'admin_proved',
                            key: 'admin_proved',
                            width: 100
                        }, {
                            title: 'ตัวเลือก',
                            dataIndex: 'id',
                            key: 'id',
                            width: 65,
                            align: 'center',
                            fixed: 'right'
                        }
                    ]" :data-source="filterBookingBillings(billable_bookings)" bordered size="small" :scroll="{ x: 'calc(100px + 50%)', y: '100%' }"
                        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
                        :pagination="{ total: pagination.total, current: pagination.onpage, pageSize: pagination.perPage }"
                        @change="handleTableChange"
                        >
                            <template #bodyCell="{ column, record }" >
                                <template v-if="(column.key == 'client')">
                                    {{ record.client?.fullname }}
                                </template>
                                <template v-else-if="column.key == 'time_start' || column.key == 'time_end' ">
                                    <span class="tag">{{ render_date(record[column.key]) }} {{ render_time(record[column.key]) }}</span>
                                </template>
                                <template v-else-if="column.key === 'booking_car_types'">
                                    {{ sum_car_price(record, 'booking_car_types')?.toLocaleString() }}
                                </template>
                                <template v-else-if="column.key === 'extras'">
                                    {{ sum_car_price(record, 'extras')?.toLocaleString() }}
                                </template>
                                <template v-else-if="column.key === 'vat'">
                                    {{ sum_car_price(record, 'vat')?.toLocaleString() }}
                                </template>
                                <template v-else-if="column.key === 'summary'">
                                    {{ (Number(record.total_price) 
                                        + (record.inc_vat? 
                                            Number((record.total_price - (record.extras.map(({ price, amount }) => price * amount).reduce((a,b) => a+b,0))) * 0.07)
                                            :0))?.toLocaleString() }}
                                </template>
                                <template v-else-if="column.key == 'total_price'">
                                    {{ (Number(record.total_price) 
                                        + (record.inc_vat? 
                                            Number((record.total_price - (record.extras.map(({ price, amount }) => price * amount).reduce((a,b) => a+b,0))) * 0.07)
                                            :0) - Number(record.discount))?.toLocaleString() }}
                                </template>
                                <!-- <template v-else-if="column.key === 'summary'">
                                    {{ (Number(record.total_price) - Number((record.total_price - record.extras.map(({ price, amount }) => price * amount).reduce((a,b) => a+b, 0)) * (record.car_company? withholding_type(record.car_company, 'number'):0) ))?.toLocaleString() }}
                                </template> -->
                                <template v-else-if="column.key === 'guarantee'">
                                    <a-tag v-if="record.guarantee" color="green">มี</a-tag>
                                    <a-tag v-else color="red">ไม่มี</a-tag>
                                </template>
                                <template v-else-if="column.key === 'discount'">
                                    {{ (Number(record.discount) )?.toLocaleString() }}
                                </template>
                                <template v-else-if="column.key === 'id'">
                                    <div>
                                        <a @click="update_booking(record.id)"><span style="font-size: 14px"
                                                class="material-symbols-outlined">delete</span></a>
                                    </div>
                                </template>
                                <template v-else-if="column.key === 'admin_proved'">
                                    {{record.admin_proved? 'ยืนยันแล้ว':'ยังไม่ยืนยัน'}}
                                </template>
                                <template v-else-if="column.key === 'updated_at'">
                                    {{ render_date(record[column.key]) }}
                                </template>
                                <template v-else-if="column.key === 'pay_extend'">
                                    {{ display_pay_extend(record) }}
                                </template>
                                <template v-else>
                                    {{ record[column.key]?.toLocaleString()}}
                                </template>
                            </template>
                    </a-table>
                </a-row>
            </a-card>

            <a-card v-else-if="render_type == 'billing'" title="บิลค้างชำระ">
                <a-space style="margin: 5px">
                    <a-col>
                        <a-input-search v-model:value="search_booking_no" placeholder="ค้นหา Booking Number"
                            enter-button="ค้นหา" />
                    </a-col>
                    <a-col>
                        <a-select style="width: 36rem" v-model:value="search_client" placeholder="เลือกลูกค้า/บริษัท" :options="company_list.map(({ id, fullname }) => ({ value: id, label: fullname }))"
                        :filter-option="filterOption"
                        show-search>
                        </a-select>
                    </a-col>
                    <a-col>
                        <a-select style="width: 18rem" v-model:value="search_biling_status"
                            placeholder="เลือกสถานะของ billing">
                            <a-select-option v-for="{ name, id } in billing_statuses" :key="id" :value="id">{{ name }}
                            </a-select-option>
                        </a-select>
                    </a-col>
                    <a-col>
                        <a-range-picker v-model:value="search_datetime" />
                    </a-col>
                    <a-col>
                        <a-button @click="search_client = null, search_datetime = null, search_booking_no = null,selected_billing = null, search_biling_status = null" type="dashed">CLEAR</a-button>
                    </a-col>
                </a-space>
                <br>
                <a-row>
                    <a-space v-if="editable" style="width: 100%;justify-content: end">
                        <a-button @click="approve(selected_billing.id, 2)" :disabled="!selected_billing" style="display: flex" type="primary" size="large">
                            <template #icon><span class="material-symbols-outlined">description</span></template>
                            ยืนยันบิล
                        </a-button>
                        <a-button @click="approve(selected_billing.id, 3)" :disabled="!selected_billing" style="display: flex" type="primary" size="large">
                            <template #icon><span class="material-symbols-outlined">description</span></template>
                            บังคับให้บิลเสร็จสิ้น
                        </a-button>
                        <a-button @click="create_invoice()" :disabled="!selected_billing" style="display: flex" type="primary" size="large">
                            <template #icon><span class="material-symbols-outlined">description</span></template>
                            ออกใบวางบิล
                        </a-button>
                        <a-button @click="create_receipt()" :disabled="!selected_billing" style="display: flex" type="primary" size="large">
                            <template #icon><span class="material-symbols-outlined">description</span></template>
                            ออกใบเสร็จรับเงิน
                        </a-button>
                        <a-button  @click="approve(selected_billing.id, 5)" :disabled="!selected_billing" style="display: flex" type="primary" size="large">
                            <template #icon><span class="material-symbols-outlined">description</span></template>
                            จัดส่งบิล
                        </a-button>
                    </a-space>
                </a-row>
                <br>
                <a-row>
                    <a-table rowKey="id" :columns="[
                        {
                            title: 'Billing NO.',
                            dataIndex: 'billing_no',
                            key: 'billing_no',
                            width: 125,
                            sorter: {
                                compare: (a, b) => a.billing_no.localeCompare(b.billing_no),
                                multiple: 4
                            }
                        }, {
                            title: 'QTC',
                            dataIndex: 'booking_no',
                            key: 'booking_no',
                            width: 125,
                            sorter: {
                                compare: (a, b) => a.booking.booking_no.localeCompare(b.booking.booking_no),
                                multiple: 3
                            }
                        }, {
                            title: 'วันเวลารับ',
                            dataIndex: 'time_start',
                            key: 'time_start',
                            width: 150,
                        }, {
                            title: 'วันเวลาส่ง',
                            dataIndex: 'time_end',
                            key: 'time_end',
                            width: 150,
                        }, {
                            title: 'ลูกค้า',
                            dataIndex: 'client',
                            key: 'client',
                            width: 200,
                        }, {
                            title: 'สถานะ',
                            dataIndex: 'billing_status',
                            key: 'billing_status',
                            width: 125,
                            align: 'center',
                        }, {
                            title: 'ประเภทบิล',
                            dataIndex: 'billing_type',
                            key: 'billing_type',
                            width: 125,
                            align: 'center',
                        }, {
                            title: 'วันที่ออกบิล',
                            dataIndex: 'issue_date',
                            key: 'issue_date',
                            width: 125,
                            align: 'center',
                            sorter: {
                                compare: (a, b) => new Date(a.issue_date) - new Date(b.issue_date),
                                multiple: 1
                            }
                        }, {
                            title: 'วันที่ครบกำหนด',
                            dataIndex: 'expire_date',
                            key: 'expire_date',
                            width: 125,
                            align: 'center',
                            sorter: {
                                compare: (a, b) => new Date(a.expire_date) - new Date(b.expire_date),
                                multiple: 2
                            }
                        }, {
                            title: 'ยอดวางบิลรวม',
                            dataIndex: 'total_price',
                            key: 'total_price',
                            width: 150,
                            align: 'center',
                        }, {
                            title: 'ยอดที่ชำระแล้ว',
                            dataIndex: 'billing_payments',
                            key: 'billing_payments',
                            width: 150,
                            align: 'center',
                        }, {
                            title: 'ตัวเลือก',
                            dataIndex: 'id',
                            key: 'id',
                            width: 100,
                            align: 'center',
                            fixed: 'right'
                        }
                    ]" :data-source="filtered_list(billings)" bordered size="small" :scroll="{ x: 'calc(100px + 50%)', y: '100%' }"
                    :row-selection="{ type: 'radio', onSelect: select_row }"
                    :pagination="{ total: pagination.total, current: pagination.onpage, pageSize: pagination.perPage }"
                    @change="handleTableChange"
                    >
                        <template #bodyCell="{ column, record }" >
                            <template v-if="column.key == 'issue_date' || column.key == 'expire_date'">
                                {{ render_date(record[column.key])}}
                            </template>
                            <template v-else-if="column.key == 'time_start' || column.key == 'time_end' ">
                                <span class="tag">{{ render_date(record.booking?.[column.key]) }} {{ render_time(record.booking?.[column.key]) }}</span>
                            </template>
                            <template v-else-if="column.key == 'id'" >
                                <a @click="edit_billing(record)">
                                    <span style="font-size: 18px" class="material-symbols-outlined">edit</span>
                                </a>
                                <a-divider type="vertical" />
                                <a @click="delete_billing(record.id)">
                                    <span style="font-size: 18px" class="material-symbols-outlined">delete</span>
                                </a>
                            </template>
                            <template v-else-if="column.key == 'total_price'" >
                                <!-- {{ sum_car_price({ 
                                    booking_car_types: record.booking?.booking_car_types,
                                    extras: record.booking?.extras,
                                    discount: record.booking?.discount,
                                    cost: record.total_price, 
                                    inc_vat: record.booking?.inc_vat, 
                                    withholding: record.booking?.withholding, 
                                    client: record.booking?.client,
                                    car_company: record.booking?.car_company,
                                }, 'total')?.toLocaleString() }} -->
                                {{ record.total_price?.toLocaleString() }}
                            </template>
                            <template v-else-if="column.key == 'billing_type'" >
                                {{ record.billing_type }} {{ record.is_deposit? '(มัดจำ)' : '' }}
                            </template>
                            <template v-else-if="column.key == 'client'" >
                                {{ record.booking?.client?.fullname }} 
                            </template>
                            <template v-else-if="column.key == 'booking_no'" >
                                {{ record.booking?.booking_no }}
                            </template>
                            <template v-else-if="column.key == 'billing_payments'" >
                                {{ sum_payment(record.billing_payments)?.toLocaleString() }}
                            </template>
                        </template>
                    </a-table>
                </a-row>
            </a-card>

            <a-card v-else-if="render_type == 'monthly-report'" title="รายงานประจำเดือน">
                <a-space style="margin: 5px">
                    <a-col>
                        <a-select style="width: 36rem" v-model:value="search_client" placeholder="เลือกลูกค้า/บริษัท" :options="company_list.map(({ id, fullname }) => ({ value: id, label: fullname }))"
                        :filter-option="filterOption"
                        show-search>
                        </a-select>
                    </a-col>
                    <a-col>
                        <a-date-picker v-model:value="search_datetime" picker="month" placeholder="เลือกเดือน" />
                    </a-col>
                    <a-col>
                        <a-select style="width: 18rem" v-model:value="selected_status"
                            placeholder="เลือกสถานะของ billing">
                            <a-select-option v-for="{ name, id } in billing_statuses" :key="id" :value="id">{{ name }}
                            </a-select-option>
                        </a-select>
                    </a-col>
                    <a-col>
                        <a-button @click="search_client = null, search_datetime = null, selected_status = null" type="dashed">CLEAR</a-button>
                    </a-col>
                </a-space>
                <a-row>
                    <a-table rowKey="id" :columns="[
                        {
                            title: 'Billing NO.',
                            dataIndex: 'billing_no',
                            key: 'billing_no',
                            sorter: true,
                            width: 150
                        }, {
                            title: 'ชื่อ',
                            dataIndex: 'client',
                            key: 'client',
                        }, {
                            title: 'วันที่',
                            dataIndex: 'created_at',
                            key: 'created_at'
                        }, {
                            title: 'สถานะบิล',
                            dataIndex: 'billing_status',
                            key: 'billing_status'
                        }, {
                            title: 'ยอดเต็มทั้งหมด',
                            dataIndex: 'booking_total_price',
                            key: 'booking_total_price'
                        }, {
                            title: 'ยอดวางบิลรวม',
                            dataIndex: 'total_price',
                            key: 'total_price'
                        }, {
                            title: 'ยอดที่ชำระแล้ว',
                            dataIndex: 'billing_payments',
                            key: 'billing_payments'
                        }, {
                            title: 'ยอดที่ค้างชำระ',
                            dataIndex: 'unpaid',
                            key: 'unpaid'
                        }, {
                            title: 'ยอดที่ค้างชำระจากยอดรวม',
                            dataIndex: 'booking_unpaid',
                            key: 'booking_unpaid'
                        }, {
                            title: 'บัญชีรับเงิน',
                            dataIndex: 'bank_account',
                            key: 'bank_account',
                            width: 200,
                            align: 'center',
                            fixed: 'right'
                        }
                    ]" :data-source="billings
                        .filter(({ booking }) => {
                            if (search_client && booking) {
                                return booking?.client_id == search_client
                            } else {
                                return true
                            }
                        })
                        .filter(({ created_at }) => {
                            if (search_datetime) {
                                return at_month(created_at) == search_datetime.format('MM/YYYY')
                            } else {
                                return true
                            }
                        })
                        .filter(({ billing_status_id }) => {
                            return selected_status? billing_status_id == selected_status: true
                        })"
                    bordered size="small" :scroll="{ x: 'calc(100px + 50%)', y: '100%' }">
                        <template #bodyCell="{ column, record }" >
                            <template v-if="column.key == 'client'">
                                {{ record.booking?.client?.fullname }}
                            </template>
                            <template v-else-if="column.key == 'created_at'">
                                    {{ render_date(record[column.key]) }}
                            </template>
                            <template v-else-if="column.key == 'billing_payments'">
                                {{ sum_payment(record.billing_payments)?.toLocaleString() }}
                            </template>
                            <template v-else-if="column.key == 'unpaid'">
                                {{ (record.total_price - sum_payment(record.billing_payments))?.toLocaleString() }}
                            </template>
                            <template v-else-if="column.key == 'bank_account'">
                                {{ record.booking?.booking_cars[0]?.car?.car_company?.bank_account_no }} - {{ record.booking?.booking_cars[0]?.car?.car_company?.bank_account_name }}
                            </template>
                            <template v-else-if="column.key == 'booking_total_price'">
                                {{ get_total_value(record.booking?.booking_car_types)?.toLocaleString() }}
                            </template>
                            <template v-else-if="column.key == 'booking_unpaid'">
                                {{ (get_total_value(record.booking?.booking_car_types) - sum_payment(record.billing_payments))?.toLocaleString() }}
                            </template>
                            <template v-else-if="column.key == 'billing_status'" >
                                {{ record.billing_status }}
                            </template>
                            <template v-else>
                                {{ record[column.key]?.toLocaleString() }}
                            </template> 
                        </template>
                    </a-table>
                </a-row>
            </a-card>

            <a-card v-else-if="render_type == 'guarantee'" title="หลักค้ำประกันสัญญางาน">
                <a-space style="margin: 5px">
                    <a-col>
                        <a-input-search v-model:value="search_booking_no" placeholder="ค้นหา Booking Number"
                            enter-button="ค้นหา" />
                    </a-col>
                    
                    <a-col>
                        <a-button @click="search_client = null, search_datetime = null, search_booking_no = null,selected_billing = null, search_biling_status = null" type="dashed">CLEAR</a-button>
                    </a-col>
                </a-space>
                <br>
                <a-row>
                    <a-space v-if="editable" style="width: 100%;justify-content: end">
                        <a-button @click="create_invoice(true)" :disabled="!selected_billing" style="display: flex" type="primary" size="large">
                            <template #icon><span class="material-symbols-outlined">description</span></template>
                            ออกใบวางบิล
                        </a-button>
                        <a-button @click="create_receipt(false, true)" :disabled="!selected_billing" style="display: flex" type="primary" size="large">
                            <template #icon><span class="material-symbols-outlined">description</span></template>
                            ออกใบเสร็จรับเงิน
                        </a-button>
                    </a-space>
                </a-row>
                <br>
                <a-row>
                    <a-table rowKey="id" :columns="[
                        {
                            title: 'Guarantee',
                            dataIndex: 'guarantee_no',
                            key: 'guarantee_no',
                            width: 125,
                            sorter: {
                                compare: (a, b) => a.billing_no.localeCompare(b.billing_no),
                                multiple: 3
                            }
                        }, {
                            title: 'QTC',
                            dataIndex: 'booking_no',
                            key: 'booking_no',
                            width: 125,
                            sorter: {
                                compare: (a, b) => a.booking.booking_no.localeCompare(b.booking.booking_no),
                                multiple: 2
                            }
                        }, {
                            title: 'วันที่สร้าง',
                            dataIndex: 'created_at',
                            key: 'created_at',
                            width: 125,
                            align: 'center',
                            sorter: {
                                compare: (a, b) => new Date(a.created_at) - new Date(b.created_at),
                                multiple: 1
                            }
                        }, {
                            title: 'ตัวเลือก',
                            dataIndex: 'id',
                            key: 'id',
                            width: 60,
                            align: 'center',
                            fixed: 'right'
                        }
                    ]" :data-source="filtered_list(guarantee)" bordered size="small" :scroll="{ x: 'calc(100px + 50%)', y: '100%' }"
                    :row-selection="{ type: 'radio', onSelect: select_row }">
                        <template #bodyCell="{ column, record }" >
                            <template v-if="column.key == 'id'" >
                                <a @click="delete_guarantee(record.id)">
                                    <span style="font-size: 18px" class="material-symbols-outlined">delete</span>
                                </a>
                            </template>
                            <template v-else-if="column.key == 'booking_no'" >
                                {{ record.booking?.booking_no }}
                            </template> 
                            <template v-else-if="column.key == 'created_at'" >
                                {{ render_date(record.created_at) }}
                            </template> 
                        </template>
                    </a-table>
                </a-row>
            </a-card>
        </div>

        <a-modal v-model:visible="gu_modal" title="สร้างใบค้ำประกันสัญญา" :footer="null">
            <a-form :model="create_gu_form" name="create_gu_form" layout="vertical">
                <a-row>
                    <a-col :span="24">
                        <a-form-item label="ยอดเงินค้ำประกัน" name="amount" >
                            <a-input-number  v-model:value="create_gu_form.amount"  />
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="24">
                        <a-form-item label="ผู้สร้าง Billing" name="sale_id" >
                            <a-select v-model:value="create_gu_form.sale_id">
                                <a-select-option v-for="{ id, firstname, lastname } in sale_list" :value="id">{{ firstname + ' ' + lastname }}</a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row justify="end">
                    <a-space>
                        <a-button key="back" @click="gu_modal = false">ปิด</a-button>
                        <a-button type="primary" @click="submit_create_gu()" :loading="loading">ตกลง</a-button>
                    </a-space>
                </a-row>
            </a-form>
        </a-modal>

        <a-modal v-model:visible="extras_modal" title="เพิ่มค่าใช้จ่ายเพิ่มเติม">
            <a-table :columns="[
                {
                    title: 'ประเภทค่าใช้จ่าย',
                    dataIndex: 'name',
                    key: 'name'
                }, {
                    title: 'ราคา',
                    dataIndex: 'price',
                    key: 'price'
                }, {
                    title: 'ตัวเลือก',
                    dataIndex: 'index',
                    key: 'index',
                    align: 'center',
                    width: 80
                }
            ]" :data-source="extra_form.extras_list" :pagination="false" >
                <template #bodyCell="{ column, record, index }">
                    <template v-if="column.key == 'index'">
                        <a-button type="primary" @click="delete_table_el(extra_form.extras_list, index)">ลบ</a-button>
                    </template>
                    <template v-else-if="column.key == 'name'">
                        {{show_name_from_id(extras_list, record.extra_id)}}
                    </template>
                </template>
            </a-table>
            <br>
            <a-form :model="extra_form" name="create_booking" autocomplete="off" layout="vertical">
                <a-row>
                    <a-col :span="24">
                        <a-form-item label="ค่าใช้จ่ายเพิ่มเติม"
                            :rules="[{ required: true, message: 'โปรดเลือกรายการค่าใช้จ่าย' }]">
                            <a-select v-model:value="extra_id" placeholder="ค่าใช้จ่ายเพิ่มเติม">
                                <a-select-option v-for="{ name, id } in extras_list" :value="id">{{name}}</a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                    <a-col :span="24">
                        <a-form-item label="ราคารวม" :rules="[{ required: true, message: 'โปรดระบุจำนวนเงิน' }]">
                            <a-input-number  v-model:value="price" />
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row justify="end">
                    <a-button @click="add_extras()" type="primary" size="large" style="margin: 4px">
                        <span class="material-symbols-outlined"
                            style="font-size: 16px;margin-right: 4px">Add</span>
                        เพิ่มรายการ
                    </a-button>
                </a-row>
                <a-divider orientation="left">ข้อมูลใบวางบิล</a-divider>
                <a-row justify="space-between">
                    <a-col :span="11">
                        <a-form-item label="วันที่สร้างบิล" :rules="[{ required: true, message: 'โปรดระบุผู้วางบิล' }]">
                            <a-date-picker v-model:value="extra_form.issue_date" />
                        </a-form-item>
                    </a-col>
                    <a-col :span="11">
                        <a-form-item label="จำนวนวันของเครดิต" :rules="[{ required: true, message: 'โปรดระบุผู้วางบิล' }]">
                            <a-input-number  v-model:value="extra_form.expire_date" />
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="24">
                        <a-form-item label="ชื่อผู้วางบิล"
                            :rules="[{ required: true, message: 'โปรดระบุผู้วางบิล' }]">
                            <a-select v-model:value="extra_form.sale_id" placeholder="ชื่อผู้วางบิล">
                                <a-select-option v-for="{ username, firstname, lastname, id } in users_list" :value="id">
                                    {{`${firstname} ${lastname} (${username})`}}
                                </a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                </a-row>
            </a-form>
            <template #footer>
                <a-button key="back" @click="extras_modal = false">ปิด</a-button>
                <a-button :disabled="extra_form.extras_list.length == 0" key="submit" type="primary" :loading="loading" @click="create_billings(2)">ตกลง</a-button>
            </template>
        </a-modal>

        <a-modal v-model:visible="expenses_modal" title="ค่าใช้จ่ายส่วนเกินตามใบเสนอราคา">
            <a-table :columns="[
                {
                    title: 'ชื่อรายการ',
                    dataIndex: 'extra',
                    key: 'extra'
                }, {
                    title: 'จำนวน',
                    dataIndex: 'amount',
                    align: 'center',
                    key: 'amount'
                }, {
                    title: 'ราคา',
                    dataIndex: 'price',
                    align: 'center',
                    key: 'price'
                }, {
                    title: 'ยอดรวม',
                    dataIndex: 'total',
                    align: 'center',
                    key: 'total'
                }
            ]" :data-source="extra_list" :pagination="false" >
                <template #bodyCell="{ column, record }">
                    <template v-if="column.key == 'total'">
                        {{record.amount * (record.price? record.price : 0)}}
                    </template>
                    <template v-else-if="column.key == 'extra'">
                        {{show_name_from_id(extras_list, record.extra_id)}}
                    </template>
                    <template v-else-if="column.key == 'price'">
                        {{record.price? record.price : 0}}
                    </template>
                </template>
            </a-table>
            <template #footer>
                <a-button key="back" @click="expenses_modal = false">ปิด</a-button>
                <a-button :disabled="billed_expenses || !extra_list.length" key="submit" type="primary" :loading="loading" @click="create_billings(3)">ตกลง</a-button>
            </template>
        </a-modal>

        <a-modal v-model:visible="create_billing_modal" title="สร้างใบวางบิล" :footer="null">
            <a-form :model="create_billing_form" name="create_billing_form" autocomplete="off" layout="vertical"
                @finish="create_billings(1, create_billing_form.billing_amount)"
            >
                <a-divider orientation="left"> วิธีการแบ่งจ่ายเงิน</a-divider>
                <a-row justify="space-between">
                    <a-col :span="10">
                        <a-form-item label="ยอดค้างชำระ" name="remaining_amount" disabled>
                            <a-input-number  v-model:value="create_billing_form.remaining_amount" disabled />
                        </a-form-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-item label="ประเภทการจ่ายเงิน" name="bill_type" :rules="[{ required: true, message: `โปรดเลือกวิธีการชำระเงิน` }]" >
                            <a-select v-model:value="create_billing_form.bill_type" @change="use_remaining_amount">
                                <a-select-option :value="1">ราคาเต็ม</a-select-option>
                                <a-select-option :value="2">จำนวน</a-select-option>
                                <a-select-option :value="3">เปอร์เซ็น</a-select-option>
                            </a-select>
                            <a-checkbox style="margin-top: 1rem" v-model:checked="create_billing_form.is_deposit" >วางบิลมัดจำ</a-checkbox>
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row justify="space-between">
                    <a-col :span="10">
                        <a-form-item label="วันที่สร้างบิล" name="billing_date" :rules="[{ required: true, message: `โปรดเลือกวันที่ออกบิล` }]" >
                            <a-date-picker v-model:value="create_billing_form.billing_date" placeholder="เลือกวันที่ออกบิล"
                                format="DD/MM/YYYY"
                            ></a-date-picker>
                        </a-form-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-item label="จำนวนวันเครดิต" disabled name="credit_duration">
                            <a-input-number v-model:value="create_billing_form.credit_duration" />
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row justify="space-between" v-if="create_billing_form.bill_type >= 2">
                    <a-col v-if="create_billing_form.bill_type == 2" :span="24">
                        <a-form-item label="จำนวนเงินที่ต้องการวางบิล" name="billing_amount" >
                            <a-input-number v-model:value="create_billing_form.billing_amount" :min="0" :max="create_billing_form.remaining_amount" />
                        </a-form-item>
                    </a-col>
                    <a-col v-if="create_billing_form.bill_type == 3" :span="10">
                        <a-form-item label="จำนวนเงินที่คำนวณได้" name="billing_amount" >
                            <a-input-number v-model:value="create_billing_form.billing_amount" disabled />
                        </a-form-item>
                    </a-col>
                    <a-col v-if="create_billing_form.bill_type == 3" :span="12">
                        <a-form-item label="เปอร์เซ็นจ่ายเงิน" >
                            <a-select @change="recalculate_bill_amount" >
                                <a-select-option :value="0.1">10%</a-select-option>
                                <a-select-option :value="0.2">20%</a-select-option>
                                <a-select-option :value="0.3">30%</a-select-option>
                                <a-select-option :value="0.4">40%</a-select-option>
                                <a-select-option :value="0.5">50%</a-select-option>
                                <a-select-option :value="0.6">60%</a-select-option>
                                <a-select-option :value="0.7">70%</a-select-option>
                                <a-select-option :value="0.8">80%</a-select-option>
                                <a-select-option :value="0.9">90%</a-select-option>
                                <a-select-option :value="1">100%</a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="24">
                        <a-form-item label="ผู้สร้าง Billing" name="sale_id" >
                            <a-select v-model:value="create_billing_form.sale_id">
                                <a-select-option v-for="{ id, firstname, lastname } in sale_list" :value="id">{{ firstname + ' ' + lastname }}</a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row justify="end">
                    <a-space>
                        <a-button key="back" @click="create_billing_modal = false">ปิด</a-button>
                        <a-button html-type="submit" type="primary" :loading="loading" :disabled="create_billing_form.remaining_amount <= 0" >ตกลง</a-button>
                    </a-space>
                </a-row>
            </a-form>
        </a-modal>

        <a-modal v-model:visible="show_select_sale" :title="sale_title_modal[sale_bill_type]">
            <a-form layout="vertical">
                    <a-col>
                        <a-form-item :label="sale_title_modal[sale_bill_type]" :rules="[{ required: true, message: 'โปรดเลือก' }]" >
                            <a-select 
                                v-model:value="selected_sale_id"
                                @change="onSaleChanged"
                            >
                            <a-select-option v-for="{ id, firstname, lastname } in sale_list" :value="id" :key="id">
                                {{ `${firstname} ${lastname}` }}
                            </a-select-option>
                            </a-select>
                            <a-image v-if="selected_sale?.signature" :src="selected_sale.signature" style="margin-top: 1rem; width: 400px;" />
                        </a-form-item>
                     </a-col>
            </a-form>
            <template #footer>
                <a-button key="back" @click="show_select_sale = false">ปิด</a-button>
                <a-button ref="saleSubmit" name="saleSubmit" :disabled="!selected_sale_id" key="submit" type="primary">ตกลง</a-button>
            </template>
        </a-modal>

        <Invoice
            v-if="get_invoice"
            :selected_billing="selected_billing"
            :billed_amount="billed_amount(selected_billing)"
            :user_name="user_name"
            @close="get_invoice = false, selected_billing = JSON.parse(JSON.stringify(export_state)), this.get_booking()"
        />

        <Receipt
            v-if="get_receipt"
            :selected_billing="selected_billing"
            :billed_amount="billed_amount(selected_billing)"
            :user_name="user_name"
            @close="get_receipt = false, selected_billing = JSON.parse(JSON.stringify(export_state)), this.get_booking()"
        />

        <FakeReceipt
            v-if="get_fake_receipt"
            :selected_billing="selected_billing"
            :billed_amount="billed_amount(selected_billing)"
            :user_name="user_name"
            @close="get_fake_receipt = false, selected_billing = JSON.parse(JSON.stringify(export_state)), this.get_booking()"
        />

        <FakeInvoice
            v-if="get_fake_invoice"
            :selected_billing="selected_billing"
            :billed_amount="billed_amount(selected_billing)"
            :user_name="user_name"
            @close="get_fake_invoice = false, selected_billing = JSON.parse(JSON.stringify(export_state)), this.get_booking()"
        />

    </div>
</template>

<script>
import BillingService from '../../api/BillingService';
import SystemService from '../../api/SystemService';
import UserService from '../../api/UserService';
import Invoice from '@/components/modals/invoice'
import Receipt from '@/components/modals/receipt'
import FakeReceipt from '@/components/modals/fake_receipt'
import FakeInvoice from '@/components/modals/fake_invoice'

import Utility from '../../utility'
import dayjs from 'dayjs'
import buddhistEra from 'dayjs/plugin/buddhistEra'
dayjs.extend(buddhistEra)
import Swal from 'sweetalert2'

export default {
    name: 'manage',
    components: { Invoice, Receipt, FakeReceipt, FakeInvoice },
    props: {
        user_name: String,
    },
    data() {
        return {
            editable: Utility.get_permission_editable_with_key('billing'),
            company_list: [],
            search_client: null,
            billable_bookings: [],
            billings: [],
            guarantee: [],
            billing_payments: [],

            selectedRowKeys: [],
            status_options: [],
            billing_statuses: [],
            selected_status: null,

            /* Modal */
            loading: false,
            extras_modal: false,
            expenses_modal: false,
            create_billing_modal: false,
            billed_expenses: false,
            extra_list: [],
            users_list: [],

            selected_role: null,
            extras_list: [],
            sale_list: [],
            extra_id: null,
            price: null,
            extra_form: {
                extras_list: [],
                issue_date: null,
                expire_date: null,
            },
            create_billing_form: {
                remaining_amount: 0,
                billing_amount: 0,
                bill_type: null,
                billing_date: null,
                credit_duration: 1,
                sale_id: null,
            },
            billing_portion: null,
            selected_billing: null,
            search_datetime: null,
            search_booking_no: null,
            search_biling_status: null,

            get_invoice: false,
            get_receipt: false,
            get_fake_receipt: false,
            get_fake_invoice: false,

            create_gu_form: {},
            gu_modal: false,
            export_state: null,

            show_select_sale: false,
            selected_sale_id: null,
            selected_sale: null,
            sale_list: [],
            sale_title_modal: {
                invoice: 'ผู้ออกใบวางบิล',
                receipt: 'ผู้ออกใบเสร็จรับเงิน',
            },
            sale_bill_type: null,
            pagination: {
                total: 0,
                onpage: 1,
                perPage: 10
            },
        }
    },
    computed: {
        render_type() {
            return this.$route.params.type
        }
    },
    methods: {
        handleTableChange(pagination, filters, sorter) {
            this.pagination.onpage = pagination.current
            this.pagination.perPage = pagination.pageSize;

            this.get_booking(this.render_type)
        },
        display_pay_extend(record) {
            return JSON.parse(record.qt_setting)?.pay_extend
        },
        filterBookingBillings(billing = []){
            return billing.filter(({ client }) => this.search_client? client?.id == this.search_client:true)
        },
        get_total_value(booking_car_types = []) {
            return booking_car_types.map(({ price, quantity }) => price * quantity).reduce((a, b) => a + b, 0)
        },
        billed_amount(selected_billing) {
            var billed_amount = this.billings
                .filter(({ booking_id }) => booking_id == selected_billing.booking_id)
                .filter(({ id }) => id < selected_billing.id)
                .reduce((a,b) => a+b.total_price, 0)

            if (selected_billing.booking.billings) {
                billed_amount = selected_billing.booking.billings.reduce((a,b) => a+b.total_price, 0)
            }

            return billed_amount
        },
        withholding_type(car_company, type) {
            if (car_company) {
                const { withholding_id } = car_company
                if (withholding_id == 1) {
                    return type == 'text'? `(1%)`: 0.01
                } else if (withholding_id == 2) {
                    return type == 'text'? `(5%)`: 0.05
                } else {
                    return type == 'text'? `(3%)`: 0.03
                }
            }
        },
        sum_car_price({ discount, booking_car_types, extras = [], inc_vat, withholding, car_company }, type) {
            const sum_extras = Number(extras.map(({ amount, price }) => amount*price).reduce((a,b) => a+b, 0))
            var total = booking_car_types.map(({ cost }) => cost).reduce((a,b) => a+b, 0) + sum_extras
            const vat = inc_vat? (total - sum_extras) * 0.07 : 0
            const withholding_type = this.withholding_type(car_company, 'number')
            const with_holding = withholding? (total - sum_extras) * withholding_type : 0

            const result = {
                total: total - discount + vat,
                vat,
                withholding: with_holding,
                discount: discount? discount : 0,
                booking_car_types: total - sum_extras,
                extras: sum_extras
            }
            
            return result[type]
        },
        submit_create_gu() {
            const booking_id = this.selectedRowKeys[0]
            const body = {
                ...this.create_gu_form,
                booking_id,
                company_id: JSON.parse(localStorage.getItem('companyData')).id
            }
            this.$message.loading({ content: 'Loading...', duration: 0, key: 'submit_create_gu' });
            SystemService.create_all('Guarantee', { data: [body]})
                .then(res => {
                    this.$message.success({ content: 'สร้างใบค้ำประกันสำเร็จ', key: 'submit_create_gu' });
                    this.gu_modal = false
                    this.get_booking()
                })
        },
        showSelectSale(bill_type){
            this.show_select_sale = true
            this.sale_bill_type = bill_type
            this.onSaleChanged(this.selected_billing?.booking?.sale?.id)
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    const saleSubmit = this.$refs.saleSubmit.$el
                    saleSubmit.addEventListener('click', () => {
                        if(this.selected_sale_id) {
                            this.show_select_sale = false
                            resolve()
                        }
                    })
                }, 100);
            })
        },
        async create_invoice(guarantee, fake) {
            if (!this.export_state) {
                this.export_state = JSON.parse(JSON.stringify(this.selected_billing))
            }
            
            if (fake) {
                const booking_id = this.selectedRowKeys[0]
                const target = this.billable_bookings.find(x => x.id == booking_id)
                const { inc_vat, withholding, car_company, extras, discount } = target
                const sum_extra = extras.map(({ price, amount }) => Number(price) * Number(amount)).reduce((a, b) => a + b, 0)
                const sum = target.booking_car_types.map(({ cost }) => cost).reduce((a, b) => a + b, 0)
                const car_cost_excluded = target.booking_car_types.map(({ cost, expenses }) => {
                    const sum_expenses = expenses.reduce((a,b) => a + (b.price * b.amount), 0)
                    return cost - sum_expenses
                }).reduce((a, b) => a + b, 0)

                this.selected_billing = { booking: target, company: target.company, stops: target.booking_cars.map(({ stops }) => stops).flat() }
                this.selected_billing.billing_no = target.billings[0]?.billing_no
                this.selected_billing.billing_payments = []
                this.selected_billing.inc_vat = inc_vat? sum * 0.07 : 0
                this.selected_billing.withholding = withholding? (car_cost_excluded * this.withholding_type(car_company)) : 0
                this.selected_billing.extra_sum = sum_extra
                this.selected_billing.total_price = sum + (this.selected_billing.inc_vat + sum_extra - discount)
                    // - this.selected_billing.withholding

                console.log(this.selected_billing);

                await this.showSelectSale('invoice')

                console.log(this.selected_billing);

                this.get_fake_invoice = true

            } else if (guarantee) {
                const { amount } = this.selected_billing
                this.selected_billing.company = this.selected_billing.booking.company
                this.selected_billing.total_price = amount
                this.selected_billing.is_guarantee = true
      
                await this.showSelectSale('invoice')
                this.get_invoice = true

            } else {
                const { inc_vat, withholding, car_company, extras, discount, booking_car_types } = this.selected_billing.booking
                const total_price = this.selected_billing.total_price
                const sum_extra = extras.map(({ price, amount }) => Number(price) * Number(amount)).reduce((a, b) => a + b, 0)
                const original_price = booking_car_types.map(({ cost }) => cost ).reduce((a, b) => a + b, 0) + sum_extra
                const car_cost_excluded = booking_car_types.map(({ cost, expenses }) => {
                    const sum_expenses = expenses.reduce((a,b) => a + (b.price * b.amount), 0)
                    return cost - sum_expenses
                }).reduce((a, b) => a + b, 0) - sum_extra
                const deposit_percent = (total_price/original_price)
                
                this.selected_billing.deposit_percent = deposit_percent
                this.selected_billing.discount = discount * deposit_percent
                this.selected_billing.total_price = total_price
                this.selected_billing.extra_sum = sum_extra * deposit_percent
                this.selected_billing.inc_vat = (inc_vat? original_price * 0.07 : 0) * deposit_percent
                this.selected_billing.withholding = (withholding? ((total_price - this.selected_billing.extra_sum) * this.withholding_type(car_company)) : 0)
   
                await this.showSelectSale('invoice')
                this.get_invoice = true
            }

            console.log({
                inc_vat: this.selected_billing.inc_vat,
                withholding: this.selected_billing.withholding,
                extra_sum: this.selected_billing.extra_sum,
                total_price: this.selected_billing.total_price,
                discount: this.selected_billing.booking.discount
            });
        },
        async create_receipt(fake, guarantee) {
            if (!this.export_state) {
                this.export_state = JSON.parse(JSON.stringify(this.selected_billing))
            } 
            
            if (fake) {
                const booking_id = this.selectedRowKeys[0]
                const target = this.billable_bookings.find(x => x.id == booking_id)
                const { inc_vat, withholding, car_company, extras, discount } = target
                const sum = target.booking_car_types.map(({ cost }) => cost).reduce((a, b) => a + b, 0)
                const car_cost_excluded = target.booking_car_types.map(({ cost, expenses }) => {
                    const sum_expenses = expenses.reduce((a,b) => a + (b.price * b.amount), 0)
                    return cost - sum_expenses
                }).reduce((a, b) => a + b, 0)

                this.selected_billing = {
                    booking_no: target.booking_no,
                    extras: extras.map(({ name, price, amount }) => ({ name, price, amount })),
                    client: target.client,
                    booking: target, 
                    car_company: car_company, 
                    booking_car_types: target.booking_car_types }
                this.selected_billing.billing_payments = []
                this.selected_billing.total_price = sum
                this.selected_billing.inc_vat = inc_vat? sum * 0.07 : 0
                this.selected_billing.withholding = withholding? (car_cost_excluded * this.withholding_type(car_company)) : 0
                this.selected_billing.extra_sum = extras.map(({ price, amount }) => price * amount).reduce((a, b) => a + b, 0)
                this.selected_billing.total_price = sum + this.selected_billing.inc_vat + this.selected_billing.extra_sum - discount
                    // - this.selected_billing.withholding
                
                setTimeout(async() => {
                    await this.showSelectSale('receipt')
                    this.get_fake_receipt = true
                },500)

            } else if (guarantee) {
                const { amount } = this.selected_billing
                this.selected_billing.company = this.selected_billing.booking.company
                this.selected_billing.total_price = amount
                this.selected_billing.is_guarantee = true
                await this.showSelectSale('receipt')
                this.get_receipt = true
            } else {
                const { inc_vat, withholding, car_company, extras, discount, booking_car_types } = this.selected_billing.booking
                const total_price = this.selected_billing.total_price
                const sum_extra = extras.map(({ price, amount }) => Number(price) * Number(amount)).reduce((a, b) => a + b, 0)
                const original_price = booking_car_types.map(({ cost }) => cost ).reduce((a, b) => a + b, 0) + sum_extra
                const car_cost_excluded = booking_car_types.map(({ cost, expenses }) => {
                    const sum_expenses = expenses.reduce((a,b) => a + (b.price * b.amount), 0)
                    return cost - sum_expenses
                }).reduce((a, b) => a + b, 0) + sum_extra

                const deposit_percent = (total_price/original_price)
                console.log(deposit_percent);

                this.selected_billing.deposit_percent = deposit_percent
                this.selected_billing.discount = discount * deposit_percent
                this.selected_billing.total_price = total_price
                this.selected_billing.extra_sum = sum_extra * deposit_percent
                this.selected_billing.inc_vat = (inc_vat? original_price * 0.07 : 0) * deposit_percent
                this.selected_billing.withholding = (withholding? (car_cost_excluded * this.withholding_type(car_company)) : 0) * deposit_percent
                await this.showSelectSale('receipt')
                this.get_receipt = true

            }

            console.log({
                inc_vat: this.selected_billing.inc_vat,
                withholding: this.selected_billing.withholding,
                extra_sum: this.selected_billing.extra_sum,
                total_price: this.selected_billing.total_price,
            });
        },
        async update_booking(id) {
            Swal.fire({
                title: 'คุณต้องการลบข้อมูลนี้ใช่หรือไม่?',
                text: "คุณจะไม่สามารถกู้คืนข้อมูลนี้ได้!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'ใช่, ลบข้อมูลนี้!',
                cancelButtonText: 'ยกเลิก'
            }).then(async (result) => {
                this.$message.loading({ content: 'Loading...', duration: 0, key: 'update_booking' });
                if (result.isConfirmed) {
                    await SystemService.update_all('Booking', { data: { billing_status: 0 } }, id)
                    this.$message.success({ content: 'ลบข้อมูลสำเร็จ', key: 'update_booking' });
                    await this.get_booking()
                }
            })
        },
        go_remove(id, modelname) {
            Swal.fire({
                title: 'คุณต้องการลบข้อมูลนี้ใช่หรือไม่?',
                text: "คุณจะไม่สามารถกู้คืนข้อมูลนี้ได้!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'ใช่, ลบข้อมูลนี้!',
                cancelButtonText: 'ยกเลิก'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await SystemService.delete_all(modelname, id)
                    this.get_booking()
                    Swal.fire(
                        'ลบข้อมูลสำเร็จ!',
                        'ข้อมูลของคุณถูกลบแล้ว',
                        'success'
                    )
                }
            })
        },
        filtered_list (array) {
            return array.filter((item) => {
                if (this.search_booking_no) {
                    return item.booking?.booking_no.includes(this.search_booking_no)
                } else if (this.search_client) {
                    return item.booking?.client_id == this.search_client
                } else if (this.search_biling_status) {
                    return item.billing_status_id == this.search_biling_status
                } else if (this.search_datetime) {
                    const [time_start, time_end] = this.search_datetime.map(item => dayjs(item).unix())
                    
                    const issue_range = (dayjs(item.issue_date).unix() >= time_start && dayjs(item.booking.booking_date).unix() <= time_end)
                    const expire_range = (dayjs(item.expire_date).unix() >= time_start && dayjs(item.expire_date).unix() <= time_end)
                    
                    return issue_range || expire_range
                } else {
                    return true
                }
            })
        },
        filterOption (input, option) {
            return option.label.toUpperCase().indexOf(input.toUpperCase()) >= 0;
        },
        update_admin(id) {
            Swal.fire({
                title: `ยืนยันในฐานะ Admin ?`,
                text: "ยืนยันบิล!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'ยืนยัน',
                cancelButtonText: 'ยกเลิก'
            }).then((result) => {
                if (result.isConfirmed) {
                    SystemService.update_all('Booking', { data: { admin_proved: 1 } }, id).then(res => {
                        if (res) {
                            this.init_modal_options()
                            this.init_company_list()
                            this.get_booking()
                            Swal.fire(
                                'สำเร็จ!',
                                'อัพเดทสถานะเป็น Admin สำเร็จ',
                                'success'
                            )
                        }
                    })
                }
            })
        },
        at_month(date) {
            return dayjs(date).format('MM/YYYY')
        },
        sum_payment(array) {
            return array.reduce((a, b) => a + (b['amount'] || 0), 0)
        },
        edit_billing(record) {
            this.$router.push(`/app/billing-payment/${record.id}`)
        },
        delete_guarantee (id) {
            Swal.fire({
                title: `คุณต้องการลบบิลนี้หรือไม่?`,
                text: "คุณจะไม่สามารถกู้คืนได้หากลบแล้ว!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'ยืนยัน',
                cancelButtonText: 'ยกเลิก'
            }).then((result) => {
                if (result.isConfirmed) {
                    SystemService.delete_all('Guarantee', id)
                        .then(res => {
                            this.get_booking()
                            Swal.fire(
                                'ลบบิลเสร็จสิ้น!',
                                'ข้อมูลของคุณถูกลบแล้ว',
                                'success'
                            )
                        })
                }
            })
        },
        delete_billing(id) {
            Swal.fire({
                title: `คุณต้องการลบบิลนี้หรือไม่?`,
                text: "คุณจะไม่สามารถกู้คืนได้หากลบแล้ว!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'ยืนยัน',
                cancelButtonText: 'ยกเลิก'
            }).then((result) => {
                if (result.isConfirmed) {
                    SystemService.delete_all('Billing', id)
                        .then(res => {
                            this.get_booking()
                            Swal.fire(
                                'ลบบิลเสร็จสิ้น!',
                                'ข้อมูลของคุณถูกลบแล้ว',
                                'success'
                            )
                        })
                }
            })
        },
        approve(id, status) {
            SystemService.update_all('Billing',{ data: { billing_status_id: status } }, id)
                .then(res => {
                    if (res) {
                        this.$message.success('อัพเดทสถานะสำเร็จ')
                        this.get_booking()
                    }
                })
        },
        select_row(selectedRowKeys) {
            this.selected_billing = selectedRowKeys
            this.billing_payments = this.billings.filter(({ booking_id }) => booking_id == selectedRowKeys.booking_id).map(({ billing_payments }) => billing_payments).flat()
        },
        render_time(datetime) {
            return dayjs(datetime).format('HH:mm')
        },
        render_date(datetime) {
            return dayjs(datetime).format('DD/MM/YYYY')
        },
        show_name_from_id(array, id) {
            return array.find(el => el.id == id)?.name
        },
        add_extras() {
            this.extra_form.extras_list.push({
                extra_id: this.extra_id,
                price: this.price
            })
            this.extra_id = null
            this.price = 0
        },
        delete_table_el(target_list, index) {
            target_list.splice(index, 1)
        },
        show_extras_modal() {
            this.extras_modal = true
        },
        recalculate_bill_amount(value) {
            const result = Number(this.create_billing_form.remaining_amount) * Number(value)
            this.create_billing_form.billing_amount = result <= this.create_billing_form.remaining_amount? result : this.create_billing_form.remaining_amount
        },
        show_create_billing_modal(selectedRowKeys) {
            this.create_billing_modal = true
            this.create_billing_form.remaining_amount = this.billable_bookings.filter(({ id }) => selectedRowKeys.includes(id))
                .reduce((a,b) => {
                    const sumextras = b.extras.map(({ price, amount }) => price * amount).reduce((a,b) => a + b, 0)
                    const sum = b.total_price - sumextras
                    const vat = b.inc_vat? (sum) * 0.07 : 0
                    const with_holding = b.withholding? (sum * this.withholding_type(b.car_company)):0

                    return a + ((sum + sumextras + vat) - b.total_billing - b.discount)
                }, 0)
        },
        show_expenses_modal() {
            this.expenses_modal = true
            this.billed_expenses = this.billable_bookings.find(el => el.id == this.selectedRowKeys[0]).is_billed_expense
            this.extra_list = this.billable_bookings.find(el => el.id == this.selectedRowKeys[0]).extras
        },
        use_remaining_amount(option) {
            if (option == 1) {
                this.create_billing_form.billing_amount = this.create_billing_form.remaining_amount
            }
        },
        async create_billings(billing_type, fixed_price) {
            if (billing_type == 1) {
                const { billing_date, credit_duration, sale_id, is_deposit } = this.create_billing_form
                this.extra_form = {
                    issue_date: billing_date,
                    expire_date: credit_duration,
                    sale_id: sale_id,
                    extras_list: [],
                    is_deposit: is_deposit
                }
            }
            var { issue_date, expire_date, sale_id, extras_list } = this.extra_form

            issue_date = issue_date? issue_date : dayjs().format('YYYY-MM-DD HH:mm:ss')

            const sum_bookings = this.billable_bookings.filter(({ id }) => this.selectedRowKeys.includes(id)).map(({ total_price }) => total_price).reduce((a, b) => a + b, 0)
            const sum_expense_list = this.extra_list.map(({ amount, price }) => (amount * price? price:0)).reduce((a, b) => a + b, 0)
            const total_price = {
                1: sum_bookings,
                2: extras_list.map(({ price }) => price).reduce((a, b) => a + b, 0),
                3: sum_expense_list
            }
            const body = {
                booking_id: this.selectedRowKeys,
                billing_type_id: billing_type,
                sale_id,
                issue_date: dayjs(issue_date).format('YYYY-MM-DD HH:mm:ss'),
                expire_date: dayjs(issue_date).add(expire_date? expire_date : 7, 'days').format('YYYY-MM-DD HH:mm:ss'),
                total_price: fixed_price? fixed_price:total_price[billing_type],
                is_deposit: this.create_billing_form.is_deposit? this.create_billing_form.is_deposit:false,
            }
            
            await BillingService.create_billing(body)
                .then(res => {
                    if (res.err) {
                        Swal.fire({
                            icon: 'error',
                            title: 'เกิดข้อผิดพลาด',
                            text: res.err
                        })
                    } else {
                        Swal.fire({
                            icon: 'success',
                            title: 'สร้างบิลสำเร็จ',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        this.create_billing_modal = false
                        this.extras_modal = false
                        this.expenses_modal = false
                        this.get_booking()
                        this.clear_extras()
                    }
                })

        },
        clear_extras() {
            this.extra_id = null
            this.price = 0
            this.extra_form = {
                extras_list: [],
                issue_date: null,
                expire_date: null,
            }
        },
        async init_modal_options() {
            this.extras_list = await SystemService.get_all('Extra')
            this.users_list = await UserService.get_users()
            this.billing_statuses = await SystemService.get_all('BillingStatus')
        },
        async init_company_list() {
            this.company_list = await SystemService.get_all('Client')
            this.sale_list = await SystemService.get_all('Sale')
        },
        async get_booking(render_type) {
            if (!render_type) {
                render_type = this.render_type
            }
            this.$message.loading({ content: 'Loading...', duration: 0, key: 'get_booking' });
            
            if (render_type == 'booking') {
                const billabled_booking = await BillingService.get_billable_bookings(this.pagination.onpage, this.pagination.perPage)
                this.pagination = {
                    total: billabled_booking.total,
                    onpage: billabled_booking.page,
                    perPage: billabled_booking.perPage
                }
                this.billable_bookings = billabled_booking.data
            } else if (render_type == 'billing' || render_type == 'monthly-report') {
                const billings = await BillingService.get_billings(this.pagination.onpage, this.pagination.perPage)
                this.pagination = {
                    total: billings.total,
                    onpage: billings.page,
                    perPage: billings.perPage
                }
                this.billings = billings.data
                this.billings = this.billings.sort((a, b) => b.id - a.id)
            } else if (render_type == 'guarantee') {
                this.guarantee = await SystemService.get_all('Guarantee')
            }
            this.$message.success({ content: 'โหลดข้อมูลสำเร็จ', key: 'get_booking' });
        },
        onSelectChange(selectedRowKeys) {
            this.selectedRowKeys = selectedRowKeys;
        },
        onSaleChanged(v){
            this.selected_sale_id = v
            this.selected_sale = this.sale_list.find(x => x.id == v)
            this.selected_billing.booking.sale = this.selected_sale
        }
    },
    watch: {
        render_type: {
            handler: function (val) {
                if (val) {
                    this.get_booking(val)
                }
            },
            deep: true
        },
    },
    mounted() {
        this.init_modal_options()
        this.init_company_list()
        this.get_booking()
    }
}
</script>

<style scope>
    .ant-modal-footer {
        display: flex;
        justify-content: flex-ends;
    }
</style>