<template>
    <div>
        <a-modal v-model:visible="show_export_modal" :title="form.topic_name? form.topic_name:'ออกใบเสนอราคา'" :afterClose="afterClose" width="1000px"
            :footer="null">
            <a-form :model="form" layout="vertical" @finish="export_select">
                <a-row justify="end">
                    <a-col>
                        <a-form-item :label="`ภาษาของ${form.topic_name? form.topic_name:'ใบเสนอราคา / Quotation'}`">
                            <a-radio-group v-model:value="form.lang">
                                <a-radio value="th">ภาษาไทย</a-radio>
                                <a-radio value="en">ภาษาอังกฤษ</a-radio>
                            </a-radio-group>
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-divider orientation="left">บริษัทรถ</a-divider>
                <a-row>
                    <a-col>
                        <a-form-item label="ชื่อบริษัทรถ" name="car_company_id"
                            :rules="[{ required: true, message: 'โปรดเลือกบริษัท' }]">
                            <a-select v-model:value="form.car_company_id" @change="auto_fill_car_company">
                                <a-select-option v-for="{ id, fullname } in car_company_list" :value="id" :key="id">{{
                                        fullname
                                }}</a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col>
                        <a-form-item label="ที่อยู่">
                            <p v-if="form.car_company">{{ form.car_company.address }}</p>
                        </a-form-item>

                    </a-col>
                </a-row>
                <a-row>
                    <a-col>
                        <a-form-item label="เลขประจำตัวผู้เสียภาษี/เลขบัตรประจำตัวประชาชน">
                            <p v-if="form.car_company">{{ form.car_company.tax_id }}</p>
                        </a-form-item>

                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="12">
                        <a-form-item label="เบอร์โทรศัพท์">
                            <p v-if="form.car_company">{{ form.car_company.phone_no }}</p>
                        </a-form-item>

                    </a-col>
                    <a-col :span="12">
                        <a-form-item label="เบอร์แฟกซ์">
                            <p v-if="form.car_company">{{ form.car_company.fax_no }}</p>
                        </a-form-item>

                    </a-col>
                </a-row>
                <a-divider orientation="left">ลูกค้า</a-divider>
                <a-row>
                    <a-col>
                        <a-form-item label="ชื่อบริษัทลูกค้า" name="client_id"
                            :rules="[{ required: true, message: 'โปรดเลือกลูกค้า' }]">
                            <a-select v-model:value="form.client_id" @change="auto_fill_client">
                                <a-select-option v-for="{ id, fullname } in client_list" :value="id" :key="id">{{
                                        fullname
                                }}</a-select-option>
                            </a-select>
                        </a-form-item>

                    </a-col>
                </a-row>
                <a-row>
                    <a-col>
                        <a-form-item label="ที่อยู่" />
                        <p v-if="form.client">{{ form.client.address }}</p>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col>
                        <a-form-item label="เลขประจำตัวผู้เสียภาษี/เลขบัตรประจำตัวประชาชน" >
                            <p v-if="form.client">{{ form.client.tax_id }}</p>
                        </a-form-item>
                    </a-col>
                    <a-col>
                        <a-form-item label="เบอร์โทรศัพท์" />
                        <p v-if="form.client">{{ form.client.phone_no }}</p>
                    </a-col>
                </a-row>
                <a-divider orientation="left">ผู้ประสานงาน{{ form.client_contact_id }}</a-divider>
                <a-row>
                    <a-col>
                        <a-form-item label="ชื่อผู้ประสานงาน">
                            <a-select v-model:value="form.client_contact_id" @change="auto_fill_client_contact">
                                <a-select-option v-for="{ id, firstname, lastname } in getClientContactList(client_contact_list)" :value="id"
                                    :key="id">
                                    {{ `${firstname} ${lastname? lastname:'-'}` }}
                                </a-select-option>
                            </a-select>
                        </a-form-item>

                    </a-col>
                </a-row>
                <a-row>
                    <a-col>
                        <a-form-item label="เบอร์โทรศัพท์" >
                            <p v-if="form.client_contact">{{ form.client_contact?.phone_no }}</p>
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-divider orientation="left">รายละเอียดการ{{ form.topic_name? form.topic_name:'ออกใบเสนอราคา' }}</a-divider>
                <a-row>
                    <a-col>
                        <a-form-item label="หัวข้อเอกสาร" >
                            <a-input v-model:value="form.topic_name" />
                        </a-form-item>
                    </a-col>
                </a-row>

                <a-row>
                    <a-col>
                        <a-checkbox v-model:checked="form.show_flight">แสดงรายละเอียดของการเดินทางโดนสังเขป</a-checkbox>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col>
                        <a-form-item label="ช่องใส่ข้อความอิสระ" >
                            <a-textarea v-model:value="form.remark" placeholder="ใส่ข้อความอิสระ" />
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-divider orientation="left">เงื่อนไขการชำระเงิน</a-divider>
                <a-row>
                    <a-col v-if="form.pay_type == 3">
                        <a-form-item label="เปอร์เซ็นต์จ่าย">
                            <a-input type="number" v-model:value="price_percent" :min="1" :max="100"
                                @change="cal_price(form.pay_type)" disabled />
                        </a-form-item>
                    </a-col>
                    <a-col>
                        <a-form-item label="จำนวนเงิน">
                            <a-input type="number" v-model:value="form.total_price"
                                disabled />
                        </a-form-item>
                    </a-col>
                    <a-col>
                        <a-form-item label="ประเภทการจ่ายเงิน">
                            <a-select v-model:value="form.pay_type" @change="cal_price" disabled>
                                <a-select-option :value="1">ราคาเต็ม</a-select-option>
                                <a-select-option :value="2">จำนวน</a-select-option>
                                <a-select-option :value="3">เปอร์เซ็น</a-select-option>
                            </a-select>
                        </a-form-item>
                        <a-form-item label="รายละเอียดชำระเงิน">
                            <a-input v-model:value="form.pay_extend" disabled> </a-input>
                        </a-form-item>
                    </a-col>
                    <a-col>
                        <a-form-item label="ส่วนลด">
                            <a-input v-model:value="form.discount" disabled />
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-divider orientation="left">ข้อมูลภาษี</a-divider>
                <a-row>
                    <a-col>
                        <a-checkbox disabled v-model:checked="form.inc_vat" >มีการคิดภาษีมูลค่าเพิ่ม</a-checkbox>
                        <a-checkbox disabled v-model:checked="form.withholding" >มีการหักภาษี ณ ที่จ่าย {{ withholding_type(form.car_company, 'text') }}</a-checkbox>
                    </a-col>
                </a-row>
                
                <a-row>
                    <a-col>
                        <a-table :pagination="{
                            hideOnSinglePage: true
                        }" :columns="[{
                            title: 'หมายเหตุ',
                            dataIndex: 'remark',
                            key: 'remark',
                            width: 150
                        }]" :data-source="form.selected_remarks_list" bordered size="small">
                            <template #bodyCell="{ column, record }">
                                <a-row justyfy="space-between">
                                    <a-col>
                                        <a style="font-size: 14px;" @click="sort_up(record.id)"
                                            class="material-symbols-outlined">arrow_upward</a>

                                        <a style="font-size: 14px;color: red" @click="remove_remark(record.id)"
                                            class="material-symbols-outlined">delete</a> 

                                        <a style="font-size: 14px;" @click="sort_down(record.id)"
                                            class="material-symbols-outlined">arrow_downward</a>
                                            
                                        {{ record[column.key] }}
                                    </a-col>
                                </a-row>
                            </template>
                        </a-table>
                    </a-col>
                    <a-col>
                        <a-form-item label="เลือกหมายเหตุเพิ่มเติม" >
                            <a-select @change="add_remark">
                                <a-select-option v-for="{ id, remark } in remarks_list" :value="id" :key="id">
                                    {{ remark }}
                                </a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                </a-row>

                <a-row justify="space-between">
                    <a-col>
                        <a-form-item>
                            <a-checkbox v-model:checked="is_th" >รูปแบบวันที่ภาษาไทย</a-checkbox>                            
                            <a-checkbox v-model:checked="show_qt_date" >แสดงวันที่เอกสาร</a-checkbox>                            
                            <a-checkbox v-model:checked="show_issue_date" >แสดงวันที่ออก</a-checkbox>
                            <a-checkbox v-model:checked="show_due_date" >แสดงวันที่ใช้ได้ถึง</a-checkbox>
                            <a-checkbox v-model:checked="show_refno" >แสดงเลขที่เอกสาร</a-checkbox>
                        </a-form-item>
                    </a-col>
                    <a-col :span="7">
                        <a-form-item label="วันที่ออก" :rules="[{ required: true, message: 'โปรดระบุวันที่ออก' }]" >
                            <a-date-picker v-model:value="form.created_at" 
                                :show-time="{ format: 'HH:mm' }"
                                format="DD/MM/YYYY HH:mm"
                                :minuteStep="1"
                                :locale="lang_th"
                                :disabled="!show_issue_date"
                                />
                        </a-form-item>
                    </a-col>
                    <a-col :span="7">
                        <a-form-item label="ใช้ได้ถึง" :rules="[{ required: true, message: 'โปรดระบุใช้ได้ถึง' }]">
                            <a-date-picker v-model:value="form.take_order_at" 
                                :show-time="{ format: 'HH:mm' }"
                                format="DD/MM/YYYY HH:mm"
                                :minuteStep="1"
                                :locale="lang_th"
                                :disabled="!show_due_date"
                                />
                        </a-form-item>
                    </a-col>
                    <a-col :span="7">
                        <a-form-item label="เอกสารเลขที่" :rules="[{ required: true, message: 'โปรดระบุเอกสารเลขที่' }]">
                            <a-input v-model:value="form.booking_no"  :disabled="!show_refno" />
                        </a-form-item>
                    </a-col>
                    <a-col :span="7" style="margin-top: 1rem;">
                        <a-form-item label="ผู้เสนอราคา" :rules="[{ required: true, message: 'โปรดเลือกผู้เสนอราคา' }]">
                            <a-select 
                                v-model:value="selected_sale_id"
                                @change="onSaleChanged"
                            >
                                <a-select-option v-for="{ id, firstname, lastname } in sale_list" :value="id" :key="id">
                                    {{ `${firstname} ${lastname}` }}
                                </a-select-option>
                            </a-select>
                            <a-image v-if="form.sale?.signature" :src="form.sale.signature" style="margin-top: 1rem; width: 400px;" />
                        </a-form-item>
                    </a-col>
                </a-row>

                <a-row justify="end">
                    <a-button type="primary" html-type="submit">ตกลง</a-button>
                </a-row>
            </a-form>
        </a-modal>

        <div v-if="show_pdf" id="content" style="height: 1754px;width: 1240px;display: flex;flex-direction: column;justify-content: space-between;padding:1em 4em">
            <div style="height: 95%">
                <div id="header" style="display:flex;justify-content: space-between;align-items:end;border-bottom: 2px solid black;">
                    <div>
                        <span style="font-size: 18px;">
                            <h1>{{ form.car_company.fullname }}</h1>
                            <h2>{{ form.car_company.address }}</h2>
                            <h2>เลขประจำตัวผู้เสียภาษี : {{ form.car_company.tax_id }}</h2>
                            <h2>โทรศัพท์ : {{ form.car_company.phone_no }} อีเมล : {{ form.car_company.email }} Line ID: {{ form.car_company.line_id }}</h2>
                        </span>
                    </div>
                    <img crossorigin="anonymous" style="height: 120px" :src="logo_url" />
                </div>
                <br>
                <h1 style="text-align: center;">{{ form.topic_name? form.topic_name:'ใบเสนอราคา / Quotation' }}</h1>
                <div
                    style="display: flex;flex-direction: column;justify-content: space-between;border-left: 1px solid black;border-right: 1px solid black;">
                    <table class="doc-table" style="border-bottom: none;border-left: none;border-right: none">
                        <tr>
                            <td style="width: 7%;white-space: nowrap;">เรียน: {{ form.client.fullname }}</td>
                            <td style="width: 28%;"></td>
                            <td style="width: 35%"></td>
                            <td :colspan="3" style="width: 30%;border-left: 1px solid black;">
                                <span v-if="show_refno">ใบเสนอราคาที่: {{ form.booking_no }}</span>
                            </td>
                        </tr>
                        <tr>
                            <td :colspan="3">เลขประจำตัวผู้เสียภาษี: {{ form.client.tax_id }}</td>
                            <td :colspan="3" style="border-left: 1px solid black;">
                                วันที่:<span v-if="show_qt_date"> {{ time_now }}</span>
                            </td>
                        </tr>
                        <tr>
                            <td :colspan="3">ที่อยู่: {{ form.client.address }}</td>
                            <td :colspan="3" style="border-left: 1px solid black;">
                                <span v-if="show_issue_date">วันที่ออก: {{ render_date(form.created_at) }}</span>
                            </td>
                        </tr>
                        <tr>
                            <td :colspan="3">ชื่อผู้ประสานงาน: {{ form.client_contact? form.client_contact.firstname:'-' }}
                                {{ form.client_contact? form.client_contact.lastname:'-' }}</td>
                            <td :colspan="3" style="border-left: 1px solid black;">
                                <span v-if="show_due_date">ใช้ได้ถึง: {{ render_date(form.take_order_at) }}</span>
                            </td>
                        </tr>
                        <tr>
                            <td :colspan="3">เบอร์โทรผู้ประสานงาน: {{ form.client_contact?.phone_no }}</td>
                            <td :colspan="3" style="border-left: 1px solid black;">เบอร์โทรผู้เสนอราคา:
                                {{ form.sale?.phone_no }}</td>
                        </tr>
                        <tr>
                            <td :colspan="3">อีเมลผู้ประสานงาน: {{ form.client_contact?.email }}</td>
                            <td :colspan="3" style="border-left: 1px solid black;">ผู้เสนอราคา: 
                                {{ (form.sale? form.sale.firstname:user_profile.username) }}
                                        {{ (form.sale? form.sale.lastname:user_profile.username) }} 
                                </td>
                        </tr>
                        <tr style="text-align:center">
                            <td style="width: 2rem;border-top:1px solid black;border-bottom: 1px solid black;">รายการที่
                            </td>
                            <td :colspan="2"
                                style="border-top:1px solid black;border-bottom: 1px solid black;text-align:start">
                                รายละเอียดงาน</td>
                            <td style="border-top:1px solid black;border-bottom: 1px solid black">หน่วย</td>
                            <td style="border-top:1px solid black;border-bottom: 1px solid black">ราคา/หน่วย</td>
                            <td style="border-top:1px solid black;border-bottom: 1px solid black;text-align: end;">
                                รวมเป็นเงิน</td>
                        </tr>
                        <tr v-if="form.show_flight">
                            <td></td>
                            <td :colspan="2">วันที่เริ่มสัญญา : {{ render_time(form.booking_detail.time_start,'DD-MM-YYYY HH:mm')}} วันที่สิ้นสุดสัญญา : {{ render_time(form.booking_detail.time_end,'DD-MM-YYYY HH:mm')}} ({{ form.booking_detail.total_days }} วัน)<br>
                                จุดรับ: {{ form.booking_detail?.start_location_name }}<br>
                                จุดส่ง: {{ form.booking_detail?.end_location_name }}
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr style="text-align:center;border-top: 2px dashed #c2c2c2;" v-for="{ time_start, time_end, car_type, car_group, quantity, cost, start_point, end_point, remark }, i in this.form.booking_detail.booking_car_types" :key="i">
                            <td >{{ (i + 1) }}</td>
                            <td :colspan="2" style="text-align:start;">
                                <span v-if="remark">
                                    <span v-for="line in remark.split('\n')">
                                        {{ line }}<br>
                                    </span>
                                </span>
                            </td>
                            <td>{{ quantity }}</td>
                            <td>{{ (Number((cost/quantity).toFixed(2))).toLocaleString() }}</td>
                            <td style="text-align: end">{{ (cost).toLocaleString() }}</td>
                        </tr>
                        <tr style="text-align:center;border-top: 2px dashed #c2c2c2;" v-for="{ price, extra: { name }, amount }, i in this.form.booking_detail.extras" :key="i">
                            <td >{{ (this.form.booking_detail.booking_car_types.length + i + 1) }}</td>
                            <td :colspan="2" style="text-align:start;word-break: break-all;">
                                {{ name }}
                            </td>
                            <td>{{ amount }}</td>
                            <td>{{ price.toLocaleString() }}</td>
                            <td style="text-align: end">{{ (price * amount).toLocaleString() }}</td>
                        </tr>
                    </table>
                </div>
                <table class="doc-table">
                    <!-- footer -->
                    <tr>
                        <td style="font-weight: bold;border-top:1px solid black">ชื่อธนาคาร</td>
                        <td style="border-top:1px solid black;text-align: end;">{{ form.car_company.bank?.name }}</td>
                        <td style="border-top:1px solid black;border-left: 1px solid black;">รวมเป็นเงิน :</td>
                        <td :colspan="4" style="width: 30%;text-align:end;border-top:1px solid black">{{ (form.price_for_withholding).toLocaleString() }}</td>
                        <!-- <td :colspan="4" style="width: 30%;text-align:end;border-top:1px solid black">{{ (form.total_price + (form.discount? form.discount:0)).toLocaleString() }}</td> -->
                    </tr>
                    <tr>
                        <td style="font-weight: bold">สาขา</td>
                        <td style="text-align: end">{{ form.car_company.bank_branch }}</td>
                        <td style="border-left: 1px solid black">ส่วนลด :</td>
                        <td :colspan="4" style="text-align:end">-{{ (form.discount? form.discount:'0.00').toLocaleString() }}</td>
                    </tr>
                    <tr>
                        <td style="font-weight: bold">ชื่อบัญชี</td>
                        <td style="text-align: end">{{ form.car_company.bank_account_no }}</td>
                        <td style="border-left: 1px solid black">ภาษีมูลค่าเพิ่ม 7% :</td>
                        <td :colspan="4" style="text-align:end">
                            {{ form.inc_vat ? (
                                (form.total_price + (form.discount? form.discount:0) - sum_extras) * 0.07).toLocaleString() : '0.00'
                            }}
                        </td>
                    </tr>
                    <tr>
                        <td style="font-weight: bold">เลขที่บัญชี</td>
                        <td style="text-align: end;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">{{ form.car_company.bank_account_name }}</td>
                        <td style="font-weight: bold;border-left: 1px solid black">รวมเป็นเงินทั้งสิ้น :</td>
                        <td :colspan="4" style="font-weight: bold;text-align: end;">{{ (form.price_for_withholding).toLocaleString() }}</td>
                        <!-- <td :colspan="4" style="font-weight: bold;text-align: end;">{{ (form.total_price + Number(form.inc_vat? ((form.total_price + form.discount - sum_extras) * 0.07):0)).toLocaleString() }}</td> -->
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td colspan="2" style="font-weight: bold;border-left: 1px solid black">({{ price_to_word((form.total_price + Number(form.inc_vat? ((form.total_price + form.discount - sum_extras) * 0.07):0)))}})
                        </td>
                    </tr>
                    <tr v-if="form.withholding">
                        <td :colspan="7" style="border-top: 1px solid black;text-align: end">สามารถหักภาษี ณ ที่จ่าย {{withholding_type(form.car_company, 'text')}}
                            (ค่าขนส่ง) : {{ ((form.price_for_withholding) * withholding_type(form.car_company, 'number')).toLocaleString() }} บาท</td>
                    </tr>
                    <tr>
                        <td :colspan="7" style="border-top: 1px solid black;text-align: start;font-size: 16px;">
                            <div style="text-overflow: ellipsis; overflow: hidden;height: 15%;">
                                <span>หมายเหตุ: </span><br>
                                <span v-for="{ remark }, i in form.selected_remarks_list" >{{ `${i+1}. ${remark}` }} <br></span>
                            </div>
                        </td>
                    </tr>
                    <tr v-if="form.pay_extend && form.pay_extend != ''">
                        <td :colspan="7" style="border-top: 1px solid black;text-align: start;font-size: 16px;">
                            <span>เงื่อนไขการชำระเงิน</span><br>
                            <span>{{ ['ราคาเต็ม','จำนวน','เปอร์เซ็นต์'][form.pay_type-1] }} {{ form.pay_type == 3? `(${price_percent} %)`:'' }}</span><br>
                            <span>รายละเอียดการชำระเงิน</span><br>
                            <span>{{ form.pay_extend }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td :colspan="7" style="border-top:1px solid black;border-left: 1px solid black;">
                            <div style="display: flex;justify-content: space-between;text-align: center;line-height: 2; align-items: center;">
                                <div style="margin-top: 3rem;">
                                    <div v-if="form.sale?.signature" style="height: 60px;"></div>
                                    <p>ลงชื่อ.................................................................................</p>
                                    <p>(...................................................................................)</p>
                                    <p>ผู้มีอำนาจลงนาม</p>
                                    <p>{{ form.client.fullname }}</p>
                                </div>
                                <div style="margin-top: 5rem;width: 105px;height: 105px;border: 1px dotted black;display: flex;align-items: center;justify-content: center;">
                                    <p>ประทับตรา <br>(ถ้ามี)</p>
                                </div>
                                <div style="margin-top: 5rem; position: relative;">
                                    <img v-if="form.sale?.signature" crossorigin="anonymous" style="width: 400px; height: 60px; position: absolute; left: 5rem;" :src="form.sale?.signature+'?not-from-cache-please'" />
                                    <p style="padding-top: 3rem;">ลงชื่อ.................................................................................</p>
                                    <p>( {{ (form.sale? form.sale.firstname:user_profile.username) }}
                                        {{ (form.sale? form.sale.lastname:user_profile.username) }} )</p>
                                    <p>ผู้เสนอราคา</p>
                                    <p>{{ form.car_company.fullname }}</p>
                                </div>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
            <div style="display: flex;justify-content: space-between;">
                <span>Thanatwit Travel Group CO., LTD.</span>
                <span>F-AC-0054 ฉบับแก้ไขครั้งที่ 1 วันที่อนุมัติใช้ 1 กันยายน 2559</span>
            </div>
        </div>
        <div v-if="show_pdf" id="payment">
            <div>
                <div style="font-size: 18px; font-weight: bold;">โอนเงิน</div>
                <div style="border: 1px solid #dbdbdb; padding: 1rem; border-radius: 5px; width: 420px; display: flex; justify-content: space-around;">
                    <img crossorigin="anonymous" style="width: 200px; height: 150px;" src="@/assets/payment.png" />
                    <img crossorigin="anonymous" style="width: 150px; height: 150px;" src="@/assets/qr_payment.jpg" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CarCompanyService from '../../api/CarCompanyService';
import SystemService from '../../api/SystemService';
import ReportService from '../../api/ReportService';
import { jsPDF } from 'jspdf'
import html2canvas from 'html2canvas'
import dayjs from 'dayjs'
import buddhistEra from 'dayjs/plugin/buddhistEra'
dayjs.extend(buddhistEra);
import { ArabicNumberToText } from '../helpers.js'
import lang_th from 'ant-design-vue/es/date-picker/locale/th_TH';
import { message } from 'ant-design-vue';
import Swal from 'sweetalert2';

export default {
    props: {
        show_export_modal: Boolean,
        booking_detail: Object,
        user_name: String,
    },
    name: 'Export-Modal',
    data() {
        return {
            show_qt_date: true,
            show_issue_date: true,
            show_due_date: true,
            show_refno: true,
            show_pdf: false,
            logo_url: require('@/assets/logo.jpg'),
            price_percent: 100,
            car_company_list: [],
            client_list: [],
            client_contact_list: [],
            sale_list: [],
            remarks_list: [],
            lang_th: lang_th,
            companyData: localStorage.getItem('companyData') ? JSON.parse(localStorage.getItem('companyData')) : {},
            user_profile: localStorage.getItem('user_profile') ? JSON.parse(localStorage.getItem('user_profile')) : {},
            selected_sale_id: null,
            form: {
                lang: 'th',
                topic_name: 'ใบเสนอราคา / Quotation',
                car_company_id: null,
                car_company: null,
                client_id: null,
                client: null,
                client_contact_id: null,
                client_contact: {

                },
                show_flight: false,
                options: ['reducted', 'no_tax'],
                total_price: null,
                pay_type: 1,
                discount: 0,
                pay_extend: null,
                selected_remarks_list: [],
                sale: null,
            },

            is_th: true,
            qr_payment: require('@/assets/qr_payment.jpg'),
            paymentImg: require('@/assets/payment.png'),
        }
    },
    computed: {
        sum_extras() {
            return this.form.booking_detail.extras.map(({ price, amount }) => amount * price).reduce((a, b) => a + b, 0)
        },
        time_now() {
            return this.is_th? dayjs().locale('th').format('DD MMMM BBBB'):dayjs().format('DD/MM/YYYY HH:mm')
        }
    },
    methods: {
        remarkFormatted(remark) {
            return remark.replace(/\n/g, '<br>');
        },
        getClientContactList(list){
            return list.filter(({ client_id }) => client_id == this.form.client_id)
        },
        cal_price(e) {
            if (e == 1) {
                this.form.total_price = this.booking_detail.total_price
            } else if (e == 3) {
                this.form.total_price = Number((this.booking_detail.total_price * this.price_percent / 100).toFixed(2))
            }
            
        },
        render_date(datetime) {
            return this.is_th? dayjs(datetime).locale('th').format('DD MMMM BBBB'):dayjs(datetime).format('DD/MM/YYYY HH:mm')
        },
        price_to_word(price) {
            return ArabicNumberToText(price ? price : 0)
        },
        render_time(datetime, format) {
            return this.is_th? dayjs().locale('th').format('DD MMMM BBBB') : dayjs(datetime).format(format)
        },
        export_select() {
            this.save_settings()
            this.form.booking_detail = this.booking_detail

            Swal.fire({
                title: 'เลือกการส่งออก',
                icon: 'question',
                showCancelButton: true,
                showDenyButton: true,
                confirmButtonText: 'PDF',
                denyButtonText: 'DOCX',
                confirmButtonColor: '#3085d6',
                denyButtonColor: '#d33',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.export_pdf()
                } else if (result.isDenied) {
                    this.export_docx()
                }
            })
        },
        async export_docx() {
            try {
                this.$message.loading({
                    content: 'กำลังสร้างไฟล์...',
                    key: 'export_docx',
                    duration: 0
                });
                const { base64 } = await ReportService.export_qt({
                    form: this.form,
                    companyData: this.companyData,
                    show_options: {
                        show_qt_date: this.show_qt_date,
                        show_issue_date: this.show_issue_date,
                        show_due_date: this.show_due_date,
                        show_refno: this.show_refno,
                    },
                })
                
                const buffer = Buffer.from(base64, 'base64')
                saveAs(new Blob([buffer]), `QUOTATION-${this.form.booking_no}.docx`)
                this.$message.success({
                    content: 'สร้างไฟล์เรียบร้อย',
                    key: 'export_docx',
                    duration: 1.5
                });
                this.$emit('close')
            } catch (err) {
                console.log(err);
                this.$message.warning({
                    content: 'สร้างไฟล์ล้มเหลว',
                    key: 'export_docx',
                    duration: 1.5
                });
                this.$emit('close')
            }
        },
        export_pdf() {
            message.loading({
                content: 'กำลังสร้าง PDF...',
                key: 'export_pdf',
                duration: 0
            });
            this.show_pdf = true

            setTimeout(() => {
                html2canvas(document.getElementById('content'), {
                    allowTaint: true,
                    useCORS: true,
                    dpi: 72,
                    scale: 2
                }).then(async(canvas) => {
                    function headerDoc(doc, imgData){
                      return doc.addImage(imgData, 'PNG', 30, 10, width-60, 60);
                    }

                    function paymentDoc(doc, imgData){
                      return doc.addImage(imgData, 'PNG', 30, 100);
                    }

                    ///preparing image
                    var imgData = canvas.toDataURL('image/png');
                    var doc = new jsPDF('p', 'pt', 'a4', true);
                    var width = doc.internal.pageSize.getWidth();
                    var height = doc.internal.pageSize.getHeight();


                    ///header 
                    const header_canvas =  await html2canvas(document.getElementById('header'), {
                        allowTaint: true,
                        useCORS: true,
                        dpi: 72,
                        scale: 2
                    })
                    var headerImgData = header_canvas.toDataURL('image/png');
             
                    const payment_canvas =  await html2canvas(document.getElementById('payment'), {
                        allowTaint: true,
                        useCORS: true,
                        dpi: 72,
                        scale: 1
                    })
                    var paymentImgData = payment_canvas.toDataURL('image/png');


                    //#1
                    doc.addImage(imgData, 'PNG', 4, 4, width - 8, height - 8);

                    //#2
                    doc.addPage();
                    headerDoc(doc, headerImgData);
                    paymentDoc(doc, paymentImgData);


                    // var imgWidth = 200;
                    // var imgHeight = 200;

                    // var x = ((width - imgWidth) / 2) - 150
                    // var y = ((height - imgHeight) / 2)
                    // var x2 = ((height - imgHeight) / 2) + 35;

                    // doc.addImage(this.paymentImg, 'PNG', x, y, imgWidth, imgHeight);
                    // doc.addImage(this.qr_payment, 'JPG', x2, y, imgWidth, imgHeight);

                    doc.save(`Quotation-${this.booking_detail.booking_no}.pdf`);
                    message.success({
                        content: 'สร้าง PDF เรียบร้อย',
                        key: 'export_pdf',
                        duration: 2
                    });
                });
            }, 100)
        },
        add_remark(e) {
            this.form.selected_remarks_list.push(this.remarks_list.find(remark => remark.id === e));
        },
        sort_down(id) {
            const index = this.form.selected_remarks_list.findIndex(remark => remark.id === id);
            if (index < this.form.selected_remarks_list.length - 1) {
                const temp = this.form.selected_remarks_list[index + 1];
                this.form.selected_remarks_list[index + 1] = this.form.selected_remarks_list[index];
                this.form.selected_remarks_list[index] = temp;
            }
        },
        sort_up(id) {
            const index = this.form.selected_remarks_list.findIndex(remark => remark.id === id);
            if (index > 0) {
                const temp = this.form.selected_remarks_list[index - 1];
                this.form.selected_remarks_list[index - 1] = this.form.selected_remarks_list[index];
                this.form.selected_remarks_list[index] = temp;
            }
        },
        remove_remark(id) {
            this.form.selected_remarks_list.splice(this.form.selected_remarks_list.findIndex(remark => remark.id === id), 1);
        },
        afterClose() {
            this.$emit('afterClose', true)
        },
        auto_fill_car_company() {
            const { address, tax_id, phone_no, fax_no, email, fullname, logo,
                bank, bank_id, bank_branch, bank_account_no, bank_account_name, line_id, withholding_id } = this.car_company_list.find(x => x.id == this.form.car_company_id);
            this.form.car_company = {
                address, tax_id, phone_no, fax_no, email, fullname, logo, bank,
                bank_id, bank_branch, bank_account_no, bank_account_name, line_id, withholding_id
            }
        },
        auto_fill_client_contact() {
            const { id, firstname, lastname, phone_no } = this.client_contact_list.find(x => x.id == this.form.client_contact_id);
            this.form.client_contact = {
                firstname, lastname, phone_no
            }
            SystemService.update_all('Booking', { data: { client_contact_id: id } }, this.booking_detail.id)
        },
        auto_fill_client() {
            const { address, tax_id, phone_no, fullname, fax_no } = this.client_list.find(x => x.id == this.form.client_id);
            this.form.client = {
                address, tax_id, phone_no, fullname, fax_no
            }
        },
        async init_options() {
            this.$message.loading({
                content: 'กำลังโหลดข้อมูล...',
                key: 'init_options',
                duration: 0
            });

            this.form = {
                lang: 'th',
                topic_name: 'ใบเสนอราคา / Quotation',
                car_company_id: null,
                car_company: null,
                client_id: null,
                client: null,
                client_contact_id: null,
                client_contact: {

                },
                show_flight: false,
                options: ['reducted', 'no_tax'],
                total_price: null,
                pay_type: 1,
                discount: 0,
                selected_remarks_list: [],
                sale: null,
            }

            this.car_company_list = await CarCompanyService.get_car_companies();
            this.client_list = await SystemService.get_all('Client')
            this.client_contact_list = await SystemService.get_all('ClientContact')
            this.remarks_list = await SystemService.get_all('BookingRemark')
            this.form.selected_remarks_list = this.remarks_list.filter(x => x.is_default)

            this.sale_list = await SystemService.get_all('Sale')

            this.form.car_company_id = this.car_company_list[0].id
            this.form.car_company = this.car_company_list[0]

            this.form.total_price = this.booking_detail.total_price;
            this.form.price_for_withholding = this.booking_detail.booking_car_types.reduce((a, b) => a + (b.cost - b.expenses.reduce((c,d) => c + (d.amount * d.price), 0) ), 0);
            this.form.remark = this.booking_detail.remark;
            this.form.created_at = dayjs(this.booking_detail.created_at);
            this.form.take_order_at = dayjs(this.booking_detail.created_at).add(30, 'days');
            this.form.booking_no = this.booking_detail.booking_no;
            this.form.start_location_name = this.booking_detail.start_location_name;
            this.form.end_location_name = this.booking_detail.end_location_name;
            const { client_id, client_contact_id, sale_id } = this.booking_detail

            if (client_id) {
                this.form.client_id = client_id
                this.form.client = this.client_list.find(({ id }) => id == client_id)
            }

            if (client_contact_id) {
                const target = this.getClientContactList(this.client_contact_list).find(({ id }) => id == client_contact_id)
                if (target) {
                    this.form.client_contact_id = client_contact_id
                    this.form.client_contact = target
                } else {
                    this.form.client_contact_id = null
                }
            }

            if (sale_id) {
                this.onSaleChanged(sale_id)
            }

            if (this.booking_detail.qt_setting) {
                const isDate = ['created_at','take_order_at']
                const excludedCars = ['booking_cars','booking_car_types', 'client_contact']
                const setting = JSON.parse(this.booking_detail.qt_setting)
                const selected_remarks_list = setting.selected_remarks_list
                
                for (var key in setting) {
                    if (isDate.includes(key)) {
                        this.form[key] = dayjs(setting[key])
                    } else if (excludedCars.includes(key)) {
                        continue
                    }
                }

                this.price_percent = setting? Number(setting.price_percent):0
                this.form.price_percent = this.price_percent
                this.form.total_price = setting?.total_price;
                this.form.pay_type = setting?.pay_type;
                this.form.pay_extend = setting?.pay_extend;
                this.form.discount = setting?.discount;

                console.log(setting);
                console.log(this.form);
                
                if (selected_remarks_list) {
                    this.form.selected_remarks_list = selected_remarks_list
                }
            }

            this.cal_price(this.form.pay_type)
            this.form.inc_vat = this.booking_detail.inc_vat? true : false;
            this.form.withholding = this.booking_detail.withholding? true : false;
            this.form.discount = this.booking_detail.discount

            // console.log(this.form);

            this.auto_fill_car_company()
            this.$message.success({
                content: 'โหลดข้อมูลเรียบร้อย',
                key: 'init_options',
                duration: 2
            });
        },
        save_settings() {
            const id = this.booking_detail.id
            const setting = JSON.stringify(this.form)
            SystemService.update_all('Booking', { data: { qt_setting: setting } }, id)
                .then(res => {
                    this.$message.success({
                        content: 'บันทึกข้อมูลเรียบร้อย',
                        key: 'save_settings',
                        duration: 2
                    });
                })
        },
        withholding_type(car_company, type) {
            if (car_company) {
                const { withholding_id } = car_company
                if (withholding_id == 1) {
                    return type == 'text'? `(1%)`: 0.01
                } else if (withholding_id == 2) {
                    return type == 'text'? `(5%)`: 0.05
                } else {
                    return type == 'text'? `(3%)`: 0.03
                }
            }
        },
        onSaleChanged(v){
            this.selected_sale_id = v
            this.form.sale = this.sale_list.find(({ id }) => id == this.selected_sale_id)
        }
    },
    mounted() {
        this.init_options()
    }
}
</script>

<style scoped>
p {
    margin: 0;
    color: black !important;
}

h1 {
    font-size: 26px;
    margin: 5px 0;
}

h2 {
    font-size: 18px;
    margin: 5px 0;
}

.doc-table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    border: 1px solid black;
    font-size: 20px;
}

tr,
td {
    padding: 0px 5px;
}

.showing-for-dev {
    display: block;
    background: white;
    z-index: 9999;
    position: absolute;
    top: 0;
    left: 0;
    padding: 15px;
    border: 2px solid black;
    height: 100vh;
    overflow-y: scroll;
}

.ant-row {
    margin-bottom: 8px;
}

p {
    color: grey
}

.ant-col-12 {
    min-width: 300px;
    padding: 4px
}

.ant-select,
.ant-picker {
    width: 100%
}

.ant-col {
    width: 100%
}

.ant-form-item {
    margin-bottom: 0;
}

.quotation-container {
    padding: 15px;
    height: 100vh;
}
</style>