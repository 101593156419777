<template>
	<div class="quotation-container">
		<a-spin :spinning="loading">
			<div v-if="render_type == 'all'" class="app-content">
				<div class="app-header">
					<h2>จัดการ Quotation</h2>
					<router-link v-if="editable" :to="`/app/quotation/add`">
						<a-button @click="init_create_booking_options" style="display: flex" type="primary" size="large">
							<template #icon><span class="material-symbols-outlined">Add</span></template>
							เพิ่ม Quotaion
						</a-button>
					</router-link>
				</div>
				<a-divider />
				<a-space style="width: 100%; margin: 4px">
					<a-input-search v-model:value="search_input" placeholder="ค้นหาใบเสนอราคา ชื่อลูกค้า ชื่อรถ"
						enter-button="Search" @search="handleTableChange" />

					<a-select :dropdownMatchSelectWidth="false" style="width: 160px" v-model:value="search_booking_type_id"
						@change="handleTableChange"
						placeholder="ประเภทการเดินทาง">
						<a-select-option v-for="{ name, id } in booking_types" :key="id" :value="id">
							{{name}}
						</a-select-option>
					</a-select>

					<a-select v-model:value="search_progress" style="width: 160px" placeholder="สถานะของใบเสนอราคา" @change="handleTableChange">
						<a-select-option :value="3">ยกเลิก</a-select-option>
						<a-select-option :value="0">ใหม่</a-select-option>
						<a-select-option :value="1">กำลังดำเนินการ</a-select-option>
						<a-select-option :value="2">เสร็จสิ้น</a-select-option>
					</a-select>

					<a-range-picker v-model:value="datetime" @change="handleTableChange" picker="day" />

					<a-button @click="clear_search()" type="dashed">CLEAR</a-button>
				</a-space>
				<a-space v-if="editable" style="width: 100%; justify-content: end; margin: 4px">
					<a-button style="display: flex" type="primary" size="large"
						@click="open_create_invoice(selected_quotation, 'ออกสัญญาการเช่า')">
						ออกสัญญาการเช่า
					</a-button>
					<a-button style="display: flex" type="primary" size="large"
						@click="open_create_invoice(selected_quotation)">
						ออกใบเสนอราคา
					</a-button>
					<a-button @click="booking_progress()" style="display: flex" type="primary" size="large">
						<template #icon><span class="material-symbols-outlined">Add</span></template>
						Confirm งาน
					</a-button>
				</a-space>
				<a-table rowKey="id" :columns="[
					{
						title: 'QTC',
						dataIndex: 'booking_no',
						key: 'booking_no',
						width: 150
					},
					{
						title: 'แก้ไขล่าสุด',
						dataIndex: 'qt_updated_at',
						key: 'qt_updated_at',
						width: 175,
						align: 'center'
					},
					{
						title: 'สถานะ',
						dataIndex: 'progress',
						key: 'progress',
						width: 118,
						align: 'center'
					},
					{
						title: 'บริษัท',
						dataIndex: 'client_name',
						key: 'client_name',
						width: 216
					},
					{
						title: 'วันที่ - เวลา รับ',
						dataIndex: 'time_start',
						key: 'time_start',
						width: 150
					},
					{
						title: 'วันที่ - เวลา ส่ง',
						dataIndex: 'time_end',
						key: 'time_end',
						width: 150
					},
					{
						title: 'จุดรับ',
						dataIndex: 'start_location_name',
						key: 'start_location_name',
						width: 150,
						align: 'center',
						background: 'lightblue'
					},
					{
						title: 'จุดส่ง',
						dataIndex: 'end_location_name',
						key: 'end_location_name',
						width: 150,
						align: 'center'
					},
					{
						title: 'ราคาสุทธิ',
						dataIndex: 'total_price',
						key: 'total_price',
						width: 100
					},
					{
						title: 'ชื่อ sale',
						dataIndex: 'sale_name',
						key: 'sale_name',
						width: 150
					},
					{
						title: 'จำนวนวัน',
						dataIndex: 'total_days',
						key: 'total_days',
						width: 100
					},
					{
						title: 'ผู้โดยสาร',
						dataIndex: 'person_qty',
						key: 'person_qty',
						width: 100
					},
					{
						title: 'ประเภท',
						dataIndex: 'booking_type_id',
						key: 'booking_type_id',
						width: 200,
						align: 'center'
					},
					{
						title: 'เหตุผลในการยกเลิก',
						dataIndex: 'booking_cancel_text',
						key: 'booking_cancel_text',
						width: 200
					},
					{
						title: 'ตัวเลือก',
						dataIndex: 'id',
						key: 'id',
						width: 160,
						align: 'center',
						fixed: 'right'
					}
				]" :data-source="filtered_booking_list(booking_list)" bordered size="small"
					:scroll="{ x: 'calc(100px + 50%)', y: '500px' }" :row-selection="{
						type: 'radio',
						onSelect: select_row
					}" 
					:pagination="{
						total: pagination.total,
						current: pagination.onpage,
						pageSize: pagination.perpage
					}" 
					@change="handleTableChange">
					<template #bodyCell="{ column, record }">
						<template v-if="column.key === 'booking_type_id' && record[column.key]">
							<span><a-tag v-if="tag_option.booking_type_id[record.booking_type_id]" :color="tag_option.booking_type_id[record.booking_type_id].color
								">
									{{ tag_option.booking_type_id[record.booking_type_id].txt }}
								</a-tag></span>
						</template>
						<template v-else-if="column.key === 'progress' && record[column.key] != null
							">
							<span><a-tag v-if="tag_option.progress[record.progress]" :color="tag_option.progress[record.progress].color">
									{{ tag_option.progress[record.progress].txt }}
								</a-tag></span>
						</template>
						<template v-else-if="column.key === 'total_price' && record[column.key]">
							<b>{{ record.total_price.toLocaleString() }}</b>
						</template>
						<template v-else-if="column.key === 'time_start' && record[column.key]">
							{{ render_date(record.time_start) }}
						</template>
						<template v-else-if="column.key === 'time_end' && record[column.key]">
							{{ render_date(record.time_end) }}
						</template>
						<template v-else-if="column.key === 'total_days'">
							{{ record.total_days + 1 }}
						</template>
						<template v-else-if="column.key === 'id' && record[column.key]">
							<div>
								<a @click="edit_quotation(record.id, record)"><span style="font-size: 18px"
										class="material-symbols-outlined">drive_file_rename_outline</span></a>
								<a-divider type="vertical"></a-divider>
								<a @click="remove_booking(record.id, record.booking_no)"><span style="font-size: 18px"
										class="material-symbols-outlined">delete</span></a>
								<a-divider type="vertical"></a-divider>
								<a @click="duplicate_booking(record.id)"><span style="font-size: 18px"
										class="material-symbols-outlined">file_copy</span></a>
							</div>
						</template>
						<template v-if="column.key == 'qt_updated_at'">
							<!-- {{ record.qt_updated_at? record.qt_updated_at:record.updated_at }} <a-tag v-if="compare_timeGreater(record.update_at, record.billings)" :bordered="false" color="error">!</a-tag> -->
							{{
								record.qt_updated_at ? record.qt_updated_at : record.updated_at
							}}
							<a-tag v-if="record.qt_updated_at" :bordered="false" color="error">!</a-tag>
						</template>
						<!-- <template v-else>
                            <span>{{record[column.key]}}</span>
                        </template> -->
					</template>
				</a-table>
			</div>

			<div v-else class="app-content">
				<a-card :title="render_type == 'add'
						? from_booking
							? 'สร้าง Booking'
							: 'ออกใบ quotation'
						: on_booking
							? `แก้ไข Booking: ${create_booking.booking_no}`
							: `แก้ไขใบเสนอราคา: ${create_booking.booking_no}`
					">
					<template #extra>
						<a-button style="display: flex" @click="$router.go(-1)" size="large">
							<template #icon>
								<span class="material-symbols-outlined">arrow_back_ios</span>
							</template>
							ย้อนกลับ
						</a-button>
					</template>
				</a-card>
				<div style="margin: 8px 0">
					<a-form :model="create_booking" name="create_booking" autocomplete="off" layout="vertical"
						@finish="submit_create_booking">
						<a-collapse style="border-radius: 4px" v-model:activeKey="activeKey">
							<a-collapse-panel key="1" :header="`ลูกค้าบริษัท/กรุ๊ปทัวร์/ไกด์/ผู้ประสานงาน`">
								<template #extra>
									<p v-if="[create_booking.client_id].filter((e) => e == null).length
										" style="margin: 0; color: grey">
										{{
											`(ต้องระบุอีก ${[create_booking.client_id].filter((e) => e == null)
													.length
												})`
										}}
									</p>
								</template>
								<a-divider orientation="left">บริษัทลูกค้า</a-divider>

								<a-row>
									<a-button v-if="render_type == 'add'" type="primary"
										style="margin-bottom: 5px; height: 32px" @click="add_company()" size="large">
										<template #icon>
											<span class="material-symbols-outlined">Add</span>
										</template>
										เพิ่มบริษัท
									</a-button>
								</a-row>

								<a-row :gutter="16">
									<a-col :span="24">
										<a-form-item label="บริษัท" name="client_id"
											:rules="[{ required: true, message: 'โปรดเลือกบริษัท' }]">
											<!-- <a-select v-model:value="create_booking.client_id" placeholder="เลือกบริษัท" @change="select_client">
                                                    <a-select-option v-for="{ id, fullname }, i in client_company_list" :key="i" :value="id">{{ fullname }}</a-select-option>
                                                </a-select> -->
											<a-select v-model:value="create_booking.client_id" show-search
												placeholder="เลือกบริษัท" :options="client_company_list.map(({ id, fullname }) => ({
													value: id,
													label: fullname
												}))
													" :filter-option="filterOption" @change="select_client"></a-select>
										</a-form-item>
									</a-col>

									<a-col :span="8">
										<a-form-item label="สาขา" name="company_branch">
											<a-select v-model:value="create_booking.client_branch_id"
												@change="select_branch">
												<a-select-option v-for="{ id, name } in client_branches_list" :key="id"
													:value="id">
													{{ name }}
												</a-select-option>
											</a-select>
										</a-form-item>
									</a-col>

									<a-col :span="8">
										<a-form-item label="อีเมลล์" name="email">
											<a-input disabled placeholder="อีเมลล์"
												v-model:value="create_booking.email"></a-input>
										</a-form-item>
									</a-col>

									<a-col :span="8">
										<a-form-item label="ประเภทกลุ่มลูกค้า" name="tour_type">
											<a-select disabled placeholder="ประเภทกลุ่มลูกค้า"
												v-model:value="create_booking.tour_type_id">
												<a-select-option v-for="{ id, name } in tour_types_list" :key="id"
													:value="id">
													{{ name }}
												</a-select-option>
											</a-select>
										</a-form-item>
									</a-col>

									<a-col :span="12">
										<a-form-item label="เบอร์โทรศัพท์" name="phone_no">
											<a-input disabled placeholder="เบอร์โทรศัพท์"
												v-model:value="create_booking.phone_no"></a-input>
										</a-form-item>
									</a-col>

									<a-col :span="12">
										<a-form-item label="เลขผู้เสียภาษี" name="tax_id">
											<a-input disabled placeholder="เลขผู้เสียภาษี"
												v-model:value="create_booking.tax_id"></a-input>
										</a-form-item>
									</a-col>

									<a-col :span="24">
										<a-form-item label="ที่อยู่" name="address">
											<a-textarea disabled placeholder="ที่อยู่"
												v-model:value="create_booking.address"></a-textarea>
										</a-form-item>
									</a-col>
								</a-row>

								<a-divider orientation="left">ผู้ประสานงาน</a-divider>
								<a-row>
									<a-button v-if="render_type == 'add'" type="primary"
										style="margin-bottom: 5px; height: 32px"
										@click="add_client(create_booking.client_id)" size="large"
										:disabled="!create_booking.client_id">
										<template #icon>
											<span class="material-symbols-outlined">Add</span>
										</template>
										เพิ่มผู้ประสานงาน
									</a-button>
								</a-row>
								<a-row>
									<a-col :span="12">
										<a-form-item label="ผู้ประสานงาน" name="client_contact_id">
											<a-select v-if="create_booking.client_id" placeholder="ผู้ประสานงาน"
												v-model:value="create_booking.client_contact_id"
												@change="selectClientContact">
												<a-select-option v-for="{
													id,
													firstname,
													lastname
												} in client_contact_list" :key="id" :value="id" selected>
													{{ firstname }} {{ lastname }}
												</a-select-option>
											</a-select>
											<a-select v-else disabled placeholder="โปรดเลือกบริษัท">
											</a-select>
										</a-form-item>
									</a-col>
									<a-col :span="6">
										<a-form-item label="ชื่อผู้ประสานงาน" name="firstname">
											<a-input disabled placeholder="ชื่อผู้ประสานงาน" v-model:value="create_booking.client_contact.firstname
												"></a-input>
										</a-form-item>
									</a-col>
									<a-col :span="6">
										<a-form-item label="นามสกุลผู้ประสานงาน" name="lastname">
											<a-input disabled placeholder="นามสกุลผู้ประสานงาน" v-model:value="create_booking.client_contact.lastname
												"></a-input>
										</a-form-item>
									</a-col>
								</a-row>
								<a-row>
									<a-col :span="12">
										<a-form-item label="เบอร์โทร" name="phone_no">
											<a-input disabled placeholder="เบอร์โทร" v-model:value="create_booking.client_contact.phone_no
												"></a-input>
										</a-form-item>
									</a-col>

									<a-col :span="12">
										<a-form-item label="อีเมล" name="email">
											<a-input disabled placeholder="อีเมล" v-model:value="create_booking.client_contact.email
												"></a-input>
										</a-form-item>
									</a-col>
								</a-row>
								<a-row>
									<!-- <a-col :span="12">
										<a-form-item
											label="ประเภทกลุ่มลูกค้า"
											name="group_name">
											<a-select
												placeholder="ประเภทกลุ่มลูกค้า"
												v-model:value="create_booking.tour_id">
												<a-select-option
													v-for="{ id, name } in tour_list"
													:key="id"
													:value="id">
													{{ name }}
												</a-select-option>
											</a-select>
										</a-form-item>
									</a-col> -->

									<a-col :span="12">
										<a-form-item label="เลขอ้างอิง" name="booking_no">
											<a-input placeholder="เลขอ้างอิง"
												v-model:value="create_booking.booking_no"></a-input>
										</a-form-item>
									</a-col>
								</a-row>
							</a-collapse-panel>

							<a-collapse-panel key="2" :header="`การจอง`">
								<template #extra>
									<p v-if="[
											create_booking.booking_type_id,
											create_booking.datetime
										].filter((e) => e == null).length
										" style="margin: 0; color: grey">{{
		`(ต้องระบุอีก ${[
				create_booking.booking_type_id,
				create_booking.datetime
			].filter((e) => e == null).length
			})`
	}}</p>
								</template>
								<a-row>
									<a-col :span="12">
										<a-form-item label="ประเภทการจอง" :rules="[
											{ required: true, message: 'โปรดเลือกประเภทการจอง' }
										]">
											<a-select v-model:value="create_booking.booking_type_id"
												:style="{ marginBottom: '8px' }">
												<a-select-option v-for="{ id, name } in booking_types" :key="id"
													:value="id">{{ name }}</a-select-option>
											</a-select>
										</a-form-item>
									</a-col>

									<a-col :span="4">
										<a-form-item label="จำนวนคน" name="person_qty">
											<a-input v-model:value="create_booking.person_qty" />
										</a-form-item>
									</a-col>

									<a-col :span="4">
										<a-form-item label="ไฟท์รับ" name="flight_pick">
											<a-input v-model:value="create_booking.flight_pick" placeholder="ระบุไฟท์รับ" />
										</a-form-item>
									</a-col>

									<a-col :span="4">
										<a-form-item label="ไฟท์ส่ง" name="flight_drop">
											<a-input v-model:value="create_booking.flight_drop" placeholder="ระบุไฟท์ส่ง" />
										</a-form-item>
									</a-col>
								</a-row>
								<a-row>
									<a-col :span="12">
										<a-form-item label="วันเวลารับ - ส่ง" name="datetime" :rules="[
											{ required: true, message: 'โปรดระบุวัน-เวลา รับส่ง' }
										]">
											<a-range-picker v-model:value="create_booking.datetime" @change="auto_fill_days"
												:show-time="{ format: 'HH:mm' }" format="DD/MM/YYYY HH:mm"
												:placeholder="['Start Time', 'End Time']" :minuteStep="1" />
										</a-form-item>
									</a-col>

									<a-col :span="4">
										<a-form-item label="จำนวนวัน" name="total_days">
											<a-input disabled v-model:value="create_booking.total_days"
												placeholder="จำนวนวัน" />
										</a-form-item>
									</a-col>

									<a-col :span="4">
										<a-form-item label="จำนวนวันที่ค้างคืน" name="full_day_amount">
											<a-input-number v-model:value="create_booking.full_day_amount"
												placeholder="จำนวนวันที่ค้างคืน" />
										</a-form-item>
									</a-col>

									<a-col :span="4">
										<a-form-item label="จุดประสงค์การเดินทาง" name="trip">
											<a-select v-model:value="create_booking.trip_id">
												<a-select-option v-for="{ id, name } in company_trip_list" :key="id">{{ name
												}}</a-select-option>
											</a-select>
										</a-form-item>
									</a-col>
								</a-row>
								<a-row>
									<a-col :span="12">
										<a-form-item label="ชื่อจุดรับ" name="start_location_name">
											<vue-google-autocomplete id="start_location_name" classname="form-control"
												placeholder="ค้นหาสถานที่" v-on:input="
													create_booking.start_location_name =
													$event.target.value
													" v-on:placechanged="
		select_stop_name($event, 'start_location_name')
		" types="establishment" country="th">
											</vue-google-autocomplete>
										</a-form-item>
									</a-col>

									<a-col :span="12">
										<a-form-item label="ชื่อจุดส่ง" name="end_location_name">
											<vue-google-autocomplete id="end_location_name" classname="form-control"
												placeholder="ค้นหาสถานที่" v-on:input="
													create_booking.end_location_name = $event.target.value
													" v-on:placechanged="
		select_stop_name($event, 'end_location_name')
		" types="establishment" country="th">
											</vue-google-autocomplete>
										</a-form-item>
									</a-col>
								</a-row>
								<a-row>
									<a-col :span="24">
										<a-form-item label="หมายเหตุ (ภายใน)" name="remark">
											<a-textarea v-model:value="create_booking.remark" placeholder="ระบุหมายเหตุ" />
										</a-form-item>
									</a-col>
								</a-row>
								<a-divider orientation="left">เงื่อนไขการชำระเงิน</a-divider>
								<a-row>
									<a-col v-if="form.pay_type == 3">
										<a-form-item label="เปอร์เซ็นต์จ่าย">
											<a-input type="number" v-model:value="price_percent" :min="1" :max="100"
												@change="cal_price(form.pay_type)" />
										</a-form-item>
									</a-col>
									<a-col>
										<a-form-item label="จำนวนเงิน">
											<a-input type="number" v-model:value="form.total_price"
												:disabled="form.pay_type == 1" />
										</a-form-item>
									</a-col>
									<a-col>
										<a-form-item label="ประเภทการจ่ายเงิน">
											<a-select v-model:value="form.pay_type" @change="cal_price">
												<a-select-option :value="1">ราคาเต็ม</a-select-option>
												<a-select-option :value="2">จำนวน</a-select-option>
												<a-select-option :value="3">เปอร์เซ็น</a-select-option>
											</a-select>
										</a-form-item>
										<a-form-item label="รายละเอียดชำระเงิน">
											<a-input v-model:value="form.pay_extend"> </a-input>
										</a-form-item>
									</a-col>
									<a-col>
										<a-form-item label="ส่วนลด">
											<a-input v-model:value="form.discount" />
										</a-form-item>
									</a-col>
								</a-row>
								<a-row>
									<a-divider orientation="left">ตั้งค่าภาษี</a-divider>
									<a-col :span="24">
										<a-space>
											<span>ภาษีมูลค่าเพิ่ม: </span>
											<a-switch v-model:checked="create_booking.inc_vat" />
											<span>หัก ณ ที่จ่าย: </span>
											<a-switch v-model:checked="create_booking.withholding" />
											<span>บริษัทรถ: </span>
											<a-select style="width: 500px" placeholder="บริษัทรถ"
												v-model:value="create_booking.car_company_id" @change="
													create_booking.car_company = car_company_list.find(
														({ id }) => $event == id
													)
													">
												<a-select-option v-for="(
														{ id, fullname, withholding }, i
													) in car_company_list" :key="i" :value="id">
													{{ fullname }} {{ `(${withholding?.name})` }}
												</a-select-option>
											</a-select>
											<!-- <a-select style="width: 500px" placeholder="บริษัทรถ" v-model:value="create_booking.car_company_id" 
                                                :disabled=!create_booking.withholding 
                                                @change="create_booking.car_company = car_company_list.find(({ id }) => $event == id)">
                                                <a-select-option v-for="{ id, fullname, withholding }, i in car_company_list" :key="i" :value="id">
                                                    {{fullname}} {{ `(${withholding?.name})` }}
                                                </a-select-option>
                                            </a-select> -->
										</a-space>
									</a-col>
								</a-row>
							</a-collapse-panel>

							<a-collapse-panel key="3" header="รถที่เลือกใช้งาน">
								<a-row v-if="on_booking">
									<a-col v-if="create_booking.booking_no">
										<h1 style="color: red">ข้อมูลอ้างอิงจาก Quotation</h1>
										<div v-for="(
												{
													car_type,
													car_group,
													quantity,
													time_start,
													time_end,
													cost,
													expenses,
													price,
													start_point,
													end_point,
													remark
												},
													i
											) in create_booking.booking_car_types" :key="i">
											<h4>{{ i + 1 }}. ประเภทยานพาหนะ {{ car_type?.name }}
												{{ car_group?.name }} {{ car_group?.seats }} ที่นั่ง
												จำนวน {{ quantity }} คัน
												{{ price.toLocaleString() }} บาท/คัน</h4>
											<h4>( {{ render_time(time_start) }} -
												{{ render_time(time_end) }} )</h4>
											<h4 v-if="start_point && end_point">{{ start_point }} - {{ end_point }}</h4>
											<p>หมายเหตุ: {{ remark }}</p>

											<ul>
												<li>
													<h4>ราคารถ {{ cost?.toLocaleString() }} บาท</h4>
												</li>
												<li v-for="{ expense, amount, price } in expenses">
													<h4>{{ expense.name }} ({{ price }} บาท) จำนวน
														{{ amount }} ราคารวม
														{{ (amount * price).toLocaleString() }}</h4>
												</li>
											</ul>
											<div style="text-align: end">
												<a-popover title="รายละเอียดราคารถ">
													<template #content>
														<p>ภาษีมูลค่าเพิ่ม (VAT):
															{{
																sum_car_price(
																	{
																		cost,
																		expenses,
																		inc_vat: create_booking.inc_vat,
																		withholding: create_booking.withholding
																	},
																	'vat'
																).toLocaleString()
															}}
															บาท</p>
														<p>หัก ณ ที่จ่าย
															{{
																withholding_type(
																	create_booking.car_company,
																	'text'
																)
															}}:
															{{
																sum_car_price(
																	{
																		cost,
																		expenses,
																		inc_vat: create_booking.inc_vat,
																		withholding: create_booking.withholding
																	},
																	'withholding'
																)?.toLocaleString()
															}}
															บาท</p>
													</template>
													<a style="font-size: 20px">รวมราคา
														{{
															sum_car_price(
																{
																	cost,
																	expenses,
																	inc_vat: create_booking.inc_vat,
																	withholding: create_booking.withholding
																},
																'total'
															).toLocaleString()
														}}
														บาท</a>
												</a-popover>
											</div>
											<a-divider />
										</div>
										<br />
										<h2>--- ของแถมที่ผูกกับใบเสนอราคา ---</h2>
										<ul>
											<li v-for="extra in create_booking.extras">
												<h4>{{ extra.extra.name }} จำนวน
													{{ extra.amount?.toLocaleString() }} ราคารวม
													{{ (extra.amount * extra.price)?.toLocaleString() }}
													บาท</h4>
											</li>
										</ul>
										<h4 style="text-align: end">รวมราคา
											{{
												Number(
													create_booking.extras
														.map(({ amount, price }) => amount * price)
														.reduce((a, b) => a + b, 0)
												).toLocaleString()
											}}
											บาท</h4>
										<a-divider />
										<br />
										<h2 style="text-align: end">ราคารวม {{ price_formula().revenue }}</h2>
										<h2 style="text-align: end">ส่วนลด {{ price_formula().discount }}</h2>
										<h2 style="text-align: end">ราคารวมหลังหักส่วนลด
											{{ price_formula().total_discounted }}</h2>
										<h2 style="text-align: end">หัก ณ ที่จ่าย
											{{
												withholding_type(create_booking.car_company, 'text')
											}}: {{ price_formula().withholding }}</h2>
										<h2 style="text-align: end">ภาษีมูลค่าเพิ่ม {{ price_formula().vat }}</h2>
										<h2 style="text-align: end">ราคาสุทธิ {{ price_formula().revenue_net }}</h2>
										<a-divider />
										<h3 style="text-align: end">ต้นทุนค่าเช่ารถ {{ price_formula().cost_sum }}</h3>
										<h3 style="text-align: end">กำไรขั้นต้น {{ price_formula().profit }}</h3>
									</a-col>
								</a-row>
								<a-row v-else>
									<a-col>
										<a-table :columns="[
											{
												title: 'ประเภทยานพาหนะ',
												dataIndex: 'car_type_id',
												key: 'car_type_id',
												width: 150
											},
											{
												title: 'รายละเอียดยานพาหนะ',
												dataIndex: 'car_group_id',
												key: 'car_group_id',
												width: 150
											},
											{
												title: 'จำนวนคัน',
												dataIndex: 'quantity',
												key: 'quantity',
												width: 150
											},
											{
												title: 'วันที่/เวลา รับลูกค้า',
												dataIndex: 'time_start',
												key: 'time_start',
												width: 150
											},
											{
												title: 'วันที่/เวลา ส่งลูกค้า',
												dataIndex: 'time_end',
												key: 'time_end',
												width: 150
											},
											{
												title: 'หมายเหตุ',
												dataIndex: 'remark',
												key: 'remark',
												width: 150
											},
											{
												title: 'ราคารวม',
												dataIndex: 'cost',
												key: 'cost',
												width: 150
											},
											{
												title: 'ตัวเลือก',
												dataIndex: 'id',
												key: 'id',
												align: 'center',
												width: 100,
												fixed: 'right'
											}
										]" :data-source="create_booking.booking_car_types" bordered size="small" :scroll="{ x: 400, y: 200 }"
											:pagination="false">
											<template #bodyCell="{ column, record, index }">
												<template v-if="column.key === 'id'">
													<a-tooltip placement="topLeft" title="ใช้วันเวลาใบเสนอราคา">
														<a @click="sync_booking_car_with_quotation(record)">
															<span style="font-size: 18px"
																class="material-symbols-outlined">sync</span>
														</a>
													</a-tooltip>
													<a-divider type="vertical" />
													<a @click="add_booking_car_types(index)">
														<span style="font-size: 18px"
															class="material-symbols-outlined">edit</span>
													</a>
													<a-divider type="vertical" />
													<a @click="remove_booking_car_types(index)">
														<span style="font-size: 18px"
															class="material-symbols-outlined">delete</span>
													</a>
												</template>
												<template v-if="column.key === 'time_start' ||
													column.key === 'time_end'
													">
													{{ render_date(record[column.key]) }}
												</template>
												<template v-if="column.key === 'car_group_id'">
													{{
														car_group_list.find(
															({ id }) => id == record.car_group_id
														)?.name
													}}
												</template>
												<template v-if="column.key === 'car_type_id'">
													{{
														car_type_list.find(
															({ id }) => id == record.car_type_id
														)?.name
													}}
												</template>
											</template>
										</a-table>
									</a-col>
								</a-row>
								<a-row v-if="on_booking">
									<a-col>
										<a-table rowKey="id" :columns="[
											{
												title: 'ลำดับ',
												dataIndex: 'order_pos',
												key: 'order_pos',
												align: 'center',
												width: 70
											},
											{
												title: 'เบอร์รถ',
												dataIndex: 'car_no',
												key: 'car_no',
												width: 100,
												align: 'center'
											},
											{
												title: 'ทะเบียนรถ',
												dataIndex: 'plate_no',
												key: 'plate_no',
												width: 150,
												align: 'start'
											},
											{
												title: 'คนขับ',
												dataIndex: 'driver',
												key: 'driver',
												width: 200
											},
											{
												title: 'ประเภทรถ',
												dataIndex: 'car_type',
												key: 'car_type',
												width: 150,
												align: 'center'
											},
											{
												title: 'วันเวลารับ-ส่ง',
												dataIndex: 'start_end',
												key: 'start_end',
												width: 300,
												align: 'center'
											},
											{
												title: 'RollCall',
												dataIndex: 'rollcall',
												key: 'rollcall',
												width: 150,
												align: 'center'
											},
											{
												title: 'สถานะของยานพาหนะ',
												dataIndex: 'car_status',
												key: 'car_status',
												width: 200,
												align: 'center'
											},
											{
												title: 'เวลารับงาน',
												dataIndex: 'last_booking_status',
												key: 'last_booking_status',
												width: 200
											},
											{
												title: 'อัพเดท',
												dataIndex: 'last_update',
												key: 'last_update',
												align: 'center',
												width: 100
											},
											{
												title: 'ราคาเช่า',
												dataIndex: 'rental_price',
												key: 'rental_price',
												align: 'right',
												width: 150
											},
											{
												title: `จำนวนรถ (${create_booking.booking_cars.length
													}/${create_booking.booking_car_types
														.map(({ quantity }) => (quantity ? quantity : 0))
														.reduce((a, b) => a + b, 0)})`,
												dataIndex: 'total_car',
												key: 'total_car',
												align: 'right',
												width: 150
											},
											{
												title: `จำนวนผู้โดยสาร (${create_booking.booking_cars
													.map(({ passengers }) => passengers)
													.reduce(
														(a, b) => a + b,
														0
													)}/${create_booking.booking_car_types
														.map(({ car_group }) =>
															car_group ? car_group.seats : 0
														)
														.reduce((a, b) => a + b, 0)}) `,
												dataIndex: 'total_seats',
												key: 'total_seats',
												align: 'right',
												width: 150
											},
											{
												title: 'ค่าเบี้ยเลี้ยง',
												dataIndex: 'driver_cost',
												key: 'driver_cost',
												align: 'right',
												width: 150
											},
											{
												title: 'ตัวเลือก',
												dataIndex: 'id',
												align: 'center',
												width: 220,
												key: 'id',
												fixed: 'right'
											}
										]" :data-source="create_booking.booking_cars" bordered size="small" :pagination="false"
											:scroll="{ x: 'calc(100px + 50%)', y: '100%' }">
											<template #bodyCell="{ column, record, index }">
												<template v-if="column.key === 'id'">
													<a-tooltip v-if="record.order_pos > 1" placement="topLeft"
														title="เลื่อนรถขึ้น">
														<a @click="
															adjust_car_pos(
																record.order_pos,
																record.id,
																'up'
															)
															" stlye="width: 1rem">
															<span style="font-size: 18px"
																class="material-symbols-outlined">arrow_drop_up</span>
														</a>
													</a-tooltip>
													<a-tooltip v-if="record.order_pos == 1" placement="topLeft"
														title="เลื่อนรถลง">
														<a @click="
															adjust_car_pos(
																record.order_pos,
																record.id,
																'down'
															)
															" stlye="width: 1rem">
															<span style="font-size: 18px"
																class="material-symbols-outlined">arrow_drop_down</span>
														</a>
													</a-tooltip>
													<a-divider type="vertical" />
													<a @click="duplicate(record)">
														<span style="font-size: 18px"
															class="material-symbols-outlined">difference</span>
													</a>
													<a-divider type="vertical" />
													<a-tooltip placement="topLeft" title="ใช้วันเวลาจากการจอง">
														<a @click="sync_booking_datetime(record)">
															<span style="font-size: 18px"
																class="material-symbols-outlined">sync</span>
														</a>
													</a-tooltip>
													<a-divider type="vertical" />
													<a @click="edit_booking_car(record, record.id)">
														<span style="font-size: 18px"
															class="material-symbols-outlined">edit</span>
													</a>
													<a-divider type="vertical" />
													<a @click="
														remove_booking_car(record.id, {
															booking_car_type_id:
																create_booking.booking_car_types[
																	create_booking.booking_car_types.length - 1
																]?.id,
															is_last:
																create_booking.booking_cars.length ==
																create_booking.booking_car_types.length
														})
														">
														<span style="font-size: 18px"
															class="material-symbols-outlined">delete</span>
													</a>
												</template>
												<template v-else-if="column.key === 'rollcall'">
													<a-space><a-tag v-if="record.roll_call?.has_roll_call"
															:color="record.roll_call?.allow ? 'green' : 'red'">{{
																record.roll_call.allow ? 'ผ่าน' : 'ไม่ผ่าน'
															}}</a-tag>
														<a-tag :color="record.roll_call?.has_roll_call
																? 'green'
																: 'yellow'
															">{{
		record.roll_call?.has_roll_call
		? 'เช็คแล้ว'
		: 'รอเช็ค'
	}}</a-tag></a-space>
												</template>
												<template v-else-if="column.key === 'order_pos'">
													<a-select v-model:value="record.order_pos" @change="
														select_order_pos(record.order_pos, record.id)
														" style="width: 100%">
														<a-select-option v-for="i in create_booking.booking_cars.length"
															:value="i">{{ i }}</a-select-option>
													</a-select>
												</template>
												<template v-if="column.key === 'car_no'">
													{{
														record.car
														? record.car[column.key]
															? record.car[column.key]
															: ''
														: ''
													}}
												</template>
												<template v-else-if="column.key === 'plate_no'">
													<a-badge-ribbon v-if="compare_time(
														create_booking.created_at,
														record.created_at,
														30,
														'seconds'
													)
														" text="เปลี่ยน" color="red">
														<a-card :title="record.car
																? record.car.plate_no
																	? record.car.plate_no
																	: ''
																: ''
															" size="small"></a-card>
													</a-badge-ribbon>
													<a-card v-else :title="record.car
															? record.car.plate_no
																? record.car.plate_no
																: ''
															: ''
														" size="small"></a-card>
												</template>
												<template v-else-if="column.key === 'car_type'">
													{{ record.car?.car_group?.car_type?.name }}
												</template>
												<template v-else-if="column.key === 'car_status'">
													<a-space><span>{{
														record.car ? record.car.car_status : ''
													}}</span>
														<a-button type="primary" :disabled="record.is_confirm == 1"
															@click="confirm_booking_car(record)">ยืนยัน</a-button></a-space>
												</template>
												<template v-else-if="column.key === 'total_car'">
													{{ get_total_car(index) }}
												</template>
												<template v-else-if="column.key === 'driver' && driver_list.length
													">
													{{ get_driver_fullname(record.driver_id) }}
												</template>
												<template v-else-if="column.key === 'last_update'">
													<a-badge :count="record.logs?.length - record.last_update">
														<a-popover trigger="hover">
															<template #title>
																ประวัติการอัพเดท
																<a @click="
																	upto_log_date(record.id, record.logs.length)
																	">อ่านแล้ว</a>
															</template>
															<template #content>
																<div style="height: 450px; overflow-y: scroll">
																	<p v-for="data in render_log(record.logs)">{{
																		data
																	}}</p>
																</div>
															</template>
															<a-button>ดูอัพเดท</a-button>
														</a-popover>
													</a-badge>
												</template>
												<template v-else-if="column.key === 'last_booking_status'">
													<a-tag v-if="record.logs.filter(
														({ model_name }) =>
															model_name == 'BookingCarStatus'
													).length
														" color="green">
														{{
															render_date(
																record.logs.filter(
																	({ model_name }) =>
																		model_name == 'BookingCarStatus'
																)[0].created_at,
																'DD/MM/YYYY HH:mm:ss'
															)
														}}
													</a-tag>
													<a-tag color="red" v-else>
														ยังไม่ได้รับงาน
													</a-tag>
												</template>
												<template v-else-if="column.key === 'total_seats'">
													{{ record.passengers }}
												</template>
												<template v-else-if="column.key === 'start_end'">
													{{ render_time(record.time_start) }} -
													{{ render_time(record.time_end) }}
												</template>
											</template>
										</a-table>
									</a-col>
								</a-row>
								<a-row v-if="on_booking">
									<a-space>
										<a-button type="primary" style="display: flex" @click="add_car_to_booking()"
											size="large">
											<template #icon>
												<span class="material-symbols-outlined">Add</span>
											</template>
											เพิ่มรถ
										</a-button>
										<a-button type="primary" style="display: flex" @click="
											booking_car_re_order(this.create_booking.booking_cars)
											" size="large">
											<template #icon>
												<span class="material-symbols-outlined">manage_history</span>
											</template>
											จัดรถตามเวลาเริ่ม
										</a-button>
									</a-space>
								</a-row>
								<a-row justify="end" v-else>
									<a-button type="primary" style="display: flex" @click="add_booking_car_types()"
										size="large">
										<template #icon>
											<span class="material-symbols-outlined">Add</span>
										</template>
										เพิ่มรถ
									</a-button>
								</a-row>
								<!-- BookingCar Drawer -->
								<a-drawer v-model:visible="add_car_drawer" class="custom-class" style="color: red"
									title="รถที่รับงานได้" placement="right">
									<a-row>
										<a-col>
											<a-select v-model:value="preselect_car_type_id">
												<a-select-option v-for="{
													id,
													car_type
												} in create_booking.booking_car_types" :key="id" :value="car_type?.id">
													{{ car_type ? car_type.name : '' }}
												</a-select-option>
											</a-select>
										</a-col>
									</a-row>
									<a-space>
										<a-input-search v-model:value="search_car" enter-button="ค้นหา"
											placeholder="ค้นหารถ" style="margin-bottom: 10px" />
										<a-button @click="clear_search()" type="dashed">CLEAR</a-button>
									</a-space>
									<a-card @click="
										car_status_id == 1 || car_status_id == 7
											? assign_car(filtered_cars[i], { car_status_id })
											: show_message('รถไม่ว่าง', 'error')
										" v-for="(
											{
												car_no,
												driver,
												plate_no,
												parking,
												id,
												car_status_id,
												car_group,
												stops
											},
												i
										) in filtered_cars" size="small" hoverable :key="id" :class="car_status_id == 1 || car_status_id == 7
												? car_group?.car_type_id == preselect_car_type_id
													? 'blue-card'
													: 'yellow-card'
												: 'gray-card'
											" :bordered="false">
										<p>{{
											`เลขรถ ${car_no} | ทะเบียน ${plate_no} | สาขา ${parking ? parking.name : ''
												}`
										}}</p>
										<p v-if="driver">คนขับ: {{ driver.firstname }} {{ driver.lastname }}</p>
										<p v-else>คนขับ: ไม่มี</p>
										<p style="max-height: 10rem; overflow-y: scroll">มีการจองรถในช่วงเวลา:
											<a-tag style="
													max-width: 20rem;
													overflow: hidden;
													text-overflow: ellipsis;
													white-space: nowrap;
												" v-for="{ id, name, expected_timestamp } in stops
													.filter(
														({ name, expected_timestamp }) =>
															name && expected_timestamp
													)
													.sort(
														(a, b) =>
															new Date(a.expected_timestamp) -
															new Date(b.expected_timestamp)
													)" :key="id" color="red">
												{{ render_time(expected_timestamp) }} : {{ name }}
											</a-tag>
										</p>
									</a-card>
								</a-drawer>

								<!-- Assign Car Drawer -->
								<a-drawer v-model:visible="assign_car_drawer" @close="assign_car_form = {}"
									lass="custom-class" style="color: red" title="ข้อมูลรถ" placement="right" width="650">
									<a-form name="assign_car" autocomplete="off" layout="vertical" :model="assign_car_form"
										@finish="submit_assign_car">
										<a-form-item label="ทะเบียนรถ" name="plate_no">
											<a-select v-model:value="assign_car_form.car_id" @change="changeCar" :options="filtered_cars.map(({ id, plate_no, car_no }) => ({
												value: id,
												label: `${plate_no} (${car_no})`
											}))
												">
											</a-select>
										</a-form-item>

										<a-form-item label="คนขับรถ" name="driver_id"
											:rules="[{ required: true, message: 'เลือกคนขับรถ' }]">
											<a-select v-model:value="assign_car_form.driver_id" show-search
												placeholder="เลือกคนขับรถ" :options="driver_list.map(({ id, firstname, lastname }) => ({
													value: id,
													label: `${firstname} ${lastname}`
												}))
													" :filter-option="filterOption"></a-select>
										</a-form-item>

										<a-form-item label="ราคาเช่า" name="rental_price" :rules="[
											{ required: true, message: 'โปรดระบุราคาเช่า' }
										]">
											<a-input-number v-model:value="assign_car_form.rental_price" :precision="2" />
										</a-form-item>

										<a-divider orientation="left">เบี้ยเลี้ยง</a-divider>
										<a-form-item label="ค่าเบี้ยเลี้ยง" name="driver_cost" :rules="[
											{ required: true, message: 'โปรดระบุราคาเช่า' }
										]">
											<a-input-number v-model:value="assign_car_form.driver_cost"
												:disabled="assign_car_form.driver_cost_as_percent" :precision="2" />
										</a-form-item>

										<br />
										<a-space>
											<a-checkbox @change="calculate_driver_cost" v-model:checked="assign_car_form.driver_cost_as_percent
												">
												คิดราคาเป็น(%)
											</a-checkbox>
											<a-input-number style="width: 2rem"
												v-model:value="assign_car_form.driver_cost_percent"
												:disabled="!assign_car_form.driver_cost_as_percent" />
											<a-button type="primary" @click="calculate_driver_cost()">คำนวณ</a-button>
										</a-space>
										<a-divider />

										<a-form-item label="วันเวลา รับ - ส่ง" name="datetime" :rules="[
											{ required: true, message: 'โปรดระบุราคาเช่า' }
										]">
											<a-range-picker v-model:value="assign_car_form.datetime"
												:show-time="{ format: 'HH:mm' }" format="DD/MM/YYYY HH:mm"
												:placeholder="['เวลาเริ่ม', 'เวลาสิ้นสุด']" :minuteStep="1" />
										</a-form-item>

										<a-row>
											<a-col :span="12">
												<a-form-item label="เงินสำรองจ่าย[เริ่มงาน]">
													<a-input-number v-model:value="assign_car_form.deposit" :disabled="assign_car_form.deposit_photo != null
														" /> </a-form-item><br />
												<img v-if="assign_car_form.deposit_photo"
													:src="assign_car_form.deposit_photo"
													:style="`width: 100%;object-fit: cover;height: 10rem`" alt="avatar" />
											</a-col>
											<a-col :span="12">
												<a-form-item label="เงินสำรองจ่าย[รับคืน]">
													<a-input-number v-model:value="assign_car_form.refund" disabled />
												</a-form-item><br />
												<img v-if="assign_car_form.refund_photo" :src="assign_car_form.refund_photo"
													:style="`width: 100%;object-fit: cover;height: 10rem`" alt="avatar" />
											</a-col>
										</a-row>

										<a-row>
											<a-col>
												<a-form :model="add_stop" layout="vertical" @finish="add_stop_to_table">
													<a-divider></a-divider>
													<a-row>
														<a-col :span="24">
															<a-form-item label="ชื่อสถานที่" name="name">
																<vue-google-autocomplete id="map" classname="form-control"
																	placeholder="ค้นหาสถานที่"
																	v-on:placechanged="select_stop_name($event)"
																	types="establishment" country="th">
																</vue-google-autocomplete>
															</a-form-item>
														</a-col>
													</a-row>
													<a-row justify="space-between">
														<a-col :span="5">
															<a-form-item label="ประเภทจุดแวะ" name="stop_type_id" :rules="[
																{ required: true, message: 'โปรดเลือกจุดแวะ' }
															]">
																<a-select v-model:value="add_stop.stop_type_id">
																	<a-select-option v-for="{ name, id } in stop_type_list"
																		:value="id">{{ name }}</a-select-option>
																</a-select>
															</a-form-item>
														</a-col>
														<a-col :span="10">
															<a-form-item label="เวลาไปถึง" name="expected_timestamp" :rules="[
																{ required: true, message: 'ระบุเวลาถึง' }
															]">
																<a-date-picker v-model:value="add_stop.expected_timestamp"
																	:show-time="{ format: 'HH:mm' }"
																	format="DD/MM/YYYY HH:mm" placeholder="เลือกเวลา"
																	:minuteStep="1" />
															</a-form-item>
														</a-col>
														<a-col :span="7" style="
																display: flex;
																justify-content: space-evenlya;
															">
															<a-space>
																<a-button v-if="on_edit_stop == null" html-type="submit"
																	type="primary">เพิ่ม</a-button>
																<a-button v-if="on_edit_stop != null" type="primary"
																	@click="save_edit_stop(on_edit_stop)">บันทึก</a-button>
																<a-button @click="cancel_edit_stop()">ยกเลิก</a-button>
															</a-space>
														</a-col>
													</a-row>
													<p>วัน เวลา สถานที่ ส่วนนี้จะแสดงให้คนขับเห็นผ่านทาง
														Vecabo Driver Application หรือในใบงาน (Job Order)</p>
													<a-table :columns="[
														{
															title: 'ประเภทจุดแวะ',
															dataIndex: 'stop_type_id',
															key: 'stop_type_id',
															align: 'center',
															width: 120
														},
														{
															title: 'ชื่อสถานที่',
															dataIndex: 'name',
															key: 'name',
															align: 'center'
														},
														{
															title: 'เวลา',
															dataIndex: 'expected_timestamp',
															key: 'expected_timestamp',
															align: 'center',
															width: 150
														},
														{
															title: 'Action',
															key: 'action',
															align: 'center',
															width: 100
														}
													]" :data-source="assign_car_form.stops?.filter(
	({ booking_car_id }) =>
		booking_car_id
			? booking_car_id == assign_car_form.id
			: true
)
	" :pagination="false">
														<template #bodyCell="{ column, record, index }">
															<template v-if="column.key === 'stop_type_id'">
																<a-tag :color="tag_option.stop_type[record.stop_type_id]
																		.color
																	">
																	{{
																		tag_option.stop_type[record.stop_type_id]
																			.txt
																	}}
																</a-tag>
															</template>
															<template v-else-if="column.key === 'expected_timestamp'">
																{{ render_date(record.expected_timestamp) }}
															</template>
															<template v-else-if="column.key === 'action'">
																<a @click="edit_stop_from_table(index)">
																	<span style="font-size: 18px"
																		class="material-symbols-outlined">edit</span>
																</a>
																<a @click="
																	remove_stop_from_table(index, record.id)
																	">
																	<span style="font-size: 18px"
																		class="material-symbols-outlined">delete</span>
																</a>
															</template>
														</template>
													</a-table>
												</a-form>
											</a-col>
										</a-row>
										<!-- Pickup Item -->
										<a-divider orientation="left" orientation-margin="0px">
											<a-space>รายการรับของ
												<a-button v-if="!(
														assign_car_form.pickup_items &&
														assign_car_form.pickup_items.length
													)
													" @click="show_create_pickup_item = true" type="primary">เพิ่มรายการรับของ</a-button></a-space>
										</a-divider>

										<a-row>
											<a-col>
												<a-table :columns="[
													{
														title: 'รายการ',
														dataIndex: 'items',
														key: 'items',
														align: 'center'
													},
													{
														title: 'สถานะ',
														dataIndex: 'pickup_status',
														key: 'pickup_status',
														align: 'center'
													},
													{
														title: 'เวลารับ',
														dataIndex: 'pickup_at',
														key: 'pickup_at',
														align: 'center'
													},
													{
														title: 'รูปภาพ',
														dataIndex: 'pickup_photo',
														key: 'pickup_photo',
														align: 'center'
													},
													{
														title: 'หมายเหตุ',
														dataIndex: 'pickup_remark',
														key: 'pickup_remark',
														align: 'center'
													},
													{
														title: 'Action',
														key: 'action',
														align: 'center',
														width: 100
													}
												]" :data-source="assign_car_form.pickup_items" :pagination="false">
													<template #bodyCell="{ column, record, index }">
														<template v-if="column.key === 'action'">
															<a @click="
																edit_list_in_table(
																	record,
																	'create_pickup_item',
																	'show_create_pickup_item',
																	index
																)
																">
																<span style="font-size: 18px"
																	class="material-symbols-outlined">edit</span>
															</a>
															<a-divider type="vertical" />
															<a @click="
																remove_list_from_table(
																	index,
																	'pickup_items',
																	'PickupItem'
																)
																">
																<span style="font-size: 18px"
																	class="material-symbols-outlined">delete</span>
															</a>
														</template>
														<template v-if="column.key === 'pickup_status' && record.id
															">
															<a-tag :color="tag_option.pickup_status[record[column.key]]
																	.color
																">
																{{
																	tag_option.pickup_status[record[column.key]]
																		.txt
																}}
															</a-tag>
														</template>
														<template v-if="column.key === 'pickup_at' && record.pickup_at
																">
															{{ render_date(record[column.key]) }}
														</template>
														<template v-if="column.key === 'pickup_photo' &&
															record.pickup_photo
															">
															<a-image :src="record[column.key]" width="100px" />
														</template>
													</template>
												</a-table>
											</a-col>
										</a-row>

										<!-- Pickup Item Drawer -->
										<a-drawer v-model:visible="show_create_pickup_item" placement="right"
											title="เพิ่มรายการรับของ" @close="create_pickup_item = {}">
											<a-form name="create_pickup_item" autocomplete="off" layout="vertical"
												:model="create_pickup_item" @finish="submit_create_pickup_item()">
												<a-form-item label="รายการ" name="items"
													:rules="[{ required: true, message: 'รายการ' }]">
													<a-textarea v-model:value="create_pickup_item.items" />
												</a-form-item>

												<a-row justify="end">
													<a-button html-type="submit" type="primary" size="large"
														style="margin: 4px; text-align: right">
														<span class="material-symbols-outlined"
															style="font-size: 16px; margin-right: 4px">Edit</span>
														บันทึก
													</a-button>
												</a-row>
											</a-form>
										</a-drawer>

										<!-- Guide Section -->
										<a-divider orientation="left" orientation-margin="0px">
											<a-space>ไกด์/คนนำเที่ยว
												<a-button @click="add_guide_drawer = true"
													type="primary">เพิ่มไกด์</a-button></a-space>
										</a-divider>

										<a-row>
											<a-col>
												<a-table :columns="[
													{
														title: 'ชื่อ-นามสกุล',
														dataIndex: 'name',
														key: 'name',
														align: 'center'
													},
													{
														title: 'เบอร์โทร',
														dataIndex: 'phone_no',
														key: 'phone_no',
														align: 'center'
													},
													{
														title: 'ราคาบริการ',
														dataIndex: 'price',
														key: 'price',
														align: 'center'
													},
													{
														title: 'หมายเหตุ',
														dataIndex: 'remark',
														key: 'remark',
														align: 'center'
													},
													{
														title: 'Action',
														key: 'action',
														align: 'center',
														width: 100
													}
												]" :data-source="assign_car_form.guides" :pagination="false">
													<template #bodyCell="{ column, record, index }">
														<template v-if="column.key === 'action'">
															<a @click="
																edit_list_in_table(
																	record,
																	'create_guide',
																	'add_guide_drawer',
																	index
																)
																">
																<span style="font-size: 18px"
																	class="material-symbols-outlined">edit</span>
															</a>
															<a-divider type="vertical" />
															<a @click="
																remove_list_from_table(
																	index,
																	'guides',
																	'BookingCarGuide'
																)
																">
																<span style="font-size: 18px"
																	class="material-symbols-outlined">delete</span>
															</a>
														</template>
														<template v-if="column.key === 'name'">
															{{
																guide_list.find(
																	(guide) => guide.id === record.guide_id
																)?.firstname
															}}
															{{
																guide_list.find(
																	(guide) => guide.id === record.guide_id
																)?.lastname
															}}
														</template>
														<template v-if="column.key === 'phone_no'">
															{{
																guide_list.find(
																	(guide) => guide.id === record.guide_id
																)?.phone_no
															}}
														</template>
													</template>
												</a-table>
											</a-col>
										</a-row>

										<!-- Guide Drawer -->
										<a-drawer v-model:visible="add_guide_drawer" placement="right" title="เพิ่มไกด์"
											@close="create_guide = {}">
											<a-form name="create_guide" autocomplete="off" layout="vertical"
												:model="create_guide" @finish="
													submit_drawer(
														'create_guide',
														'guides',
														'add_guide_drawer',
														'BookingCarGuide'
													)
													">
												<a-form-item label="เลือกไกด์" name="guide_id" :rules="[
													{ required: true, message: 'กรุณาเลือกไกด์' }
												]">
													<a-select v-model:value="create_guide.guide_id" placeholder="เลือกไกด์">
														<a-select-option v-for="{ id, firstname, lastname } in guide_list"
															:key="id" :value="id">
															{{ firstname }} {{ lastname }}
														</a-select-option>
													</a-select>
												</a-form-item>

												<a-form-item label="ราคาบริการ" name="price"
													:rules="[{ required: true, message: 'ระบุราคา' }]">
													<a-input-number v-model:value="create_guide.price" :min="0"
														:max="999999" />
												</a-form-item>

												<a-form-item label="หมายเหตุ" name="remark">
													<a-textarea v-model:value="create_guide.remark" />
												</a-form-item>

												<a-row justify="end">
													<a-button html-type="submit" type="primary" size="large"
														style="margin: 4px; text-align: right">
														<span class="material-symbols-outlined"
															style="font-size: 16px; margin-right: 4px">Edit</span>
														บันทึกข้อมูล
													</a-button>
												</a-row>
											</a-form>
										</a-drawer>

										<!-- Gas Refill Section -->
										<a-divider orientation="left" orientation-margin="0px">
											<a-space>
												รายการใช้น้ำมัน
												<a-button @click="init_gas_refill()"
													type="primary">เพิ่มรายการใช้น้ำมัน</a-button>
											</a-space>
										</a-divider>

										<a-row>
											<a-col>
												<a-table :columns="[
													{
														title: 'วันเวลา',
														dataIndex: 'datetime',
														key: 'datetime',
														align: 'center',
														width: 150
													},
													{
														title: 'จำนวนลิตร',
														dataIndex: 'refill_amount',
														key: 'refill_amount',
														align: 'center'
													},
													{
														title: 'ยอดจ่าย',
														dataIndex: 'amount',
														key: 'amount',
														align: 'center'
													},
													{
														title: 'ประเภทการจ่ายเงิน',
														dataIndex: 'pay_method_id',
														key: 'pay_method_id',
														align: 'center'
													},
													{
														title: 'หมายเหตุ',
														dataIndex: 'remark',
														key: 'remark',
														align: 'center'
													},
													{
														title: 'Action',
														key: 'action',
														align: 'center',
														width: 120
													}
												]" :data-source="assign_car_form.gas_refills" :pagination="false">
													<template #bodyCell="{ record, index, column }">
														<template v-if="column.key === 'action'">
															<a @click="
																edit_list_in_table(
																	record,
																	'create_gas_refill',
																	'add_gas_refill_drawer',
																	index
																),
																init_gas_refill()
																">
																<span style="font-size: 18px"
																	class="material-symbols-outlined">edit</span>
															</a>
															<a-divider type="vertical" />
															<a @click="
																remove_list_from_table(
																	index,
																	'gas_refills',
																	'GasRefill'
																)
																">
																<span style="font-size: 18px"
																	class="material-symbols-outlined">delete</span>
															</a>
														</template>
														<template v-else-if="column.key === 'datetime'">
															{{ render_time(record.datetime) }}
														</template>
														<template v-else-if="column.key === 'pay_method_id'">
															{{
																pay_method_list.find(
																	(pay_method) =>
																		pay_method.id === record.pay_method_id
																)?.name
															}}
														</template>
														<template v-else-if="column.key === 'amount'">
															{{ record.amount.toLocaleString() }}
														</template>
													</template>
												</a-table>
											</a-col>
										</a-row>

										<!-- Gas Refill Drawer -->
										<a-drawer v-model:visible="add_gas_refill_drawer" placement="right"
											title="บันทึกค่าน้ำมัน" @close="create_gas_refill = {}">
											<a-form name="create_gas_refill" autocomplete="off" layout="vertical"
												:model="create_gas_refill" @finish="submit_create_gas_refill">
												<a-switch v-model:checked="create_gas_refill.use_gas_station"
													checkedChildren="ปั้มที่ร่วมกับบริษัท" unCheckedChildren="ปั้มนอก" />
												<a-form-item v-if="create_gas_refill.use_gas_station"
													label="เลือกปั้มน้ำมัน" name="gas_station_id" :rules="[
														{ required: true, message: 'เลือกปั้มน้ำมัน' }
													]">
													<a-select v-model:value="create_gas_refill.gas_station_id"
														placeholder="เลือกปั้มน้ำมัน">
														<a-select-option v-for="{ id, name } in gas_station_list" :key="id"
															:value="id">
															{{ name }}
														</a-select-option>
													</a-select>
												</a-form-item>
												<a-form-item v-else label="ระบุชื่อปั้ม" name="station_name" :rules="[
													{ required: true, message: 'เลือกปั้มน้ำมัน' }
												]">
													<a-textarea v-model:value="create_gas_refill.station_name" />
												</a-form-item>

												<a-row>
													<a-col :span="12">
														<a-form-item label="จำนวนลิตร" name="refill_amount" :rules="[
															{
																required: true,
																message: 'กรุณากรอกจำนวนลิตร'
															}
														]">
															<a-input-number v-model:value="create_gas_refill.refill_amount"
																:min="0" :max="1000" />
														</a-form-item>
													</a-col>
													<a-col :span="12">
														<a-form-item label="ยอดจ่าย" name="amount" :rules="[
															{ required: true, message: 'กรุณายอดจ่าย' }
														]">
															<a-input-number v-model:value="create_gas_refill.amount"
																:min="0" :max="100000" />
														</a-form-item>
													</a-col>
												</a-row>

												<a-form-item label="ประเภทการจ่ายเงิน" name="pay_method_id" :rules="[
													{
														required: true,
														message: 'เลือกประเภทการจ่ายเงิน'
													}
												]">
													<a-select v-model:value="create_gas_refill.pay_method_id"
														placeholder="เลือกประเภทการจ่ายเงิน">
														<a-select-option v-for="{ id, name } in pay_method_list" :key="id"
															:value="id">
															{{ name }}
														</a-select-option>
													</a-select>
												</a-form-item>

												<a-form-item label="วันเวลา" name="datetime" :rules="[
													{ required: true, message: 'กรุณากรอกวันเวลา' }
												]">
													<a-date-picker v-model:value="create_gas_refill.datetime"
														:show-time="{ format: 'HH:mm' }" format="DD/MM/YYYY HH:mm"
														placeholder="เลือกเวลา" :minuteStep="1" />
												</a-form-item>

												<a-form-item label="หมายเหตุ" name="remark">
													<a-textarea v-model:value="create_gas_refill.remark" />
												</a-form-item>

												<a-row justify="end">
													<a-button html-type="submit" type="primary" size="large"
														style="margin: 4px; text-align: right">
														<span class="material-symbols-outlined"
															style="font-size: 16px; margin-right: 4px">Edit</span>
														บันทึกข้อมูล
													</a-button>
												</a-row>
											</a-form>
										</a-drawer>

										<a-divider orientation="left" orientation-margin="0px">
											<a-space>
												รายการค่าทางด่วน
												<a-button @click="show_create_toll_way = true"
													type="primary">เพิ่มรายการค่าทางด่วน</a-button>
											</a-space>
										</a-divider>

										<a-row>
											<a-col>
												<a-table :columns="[
													{
														title: 'ผู้ชำระ',
														dataIndex: 'driver_paid',
														key: 'driver_paid'
													},
													{
														title: 'จำนวนเงิน',
														dataIndex: 'amount',
														key: 'amount'
													},
													{
														title: 'วันเวลา',
														key: 'datetime',
														dataIndex: 'datetime'
													},
													{
														title: 'สถานะงาน',
														key: 'booking_status',
														dataIndex: 'booking_status'
													},
													{
														title: 'หมายเหตุ',
														key: 'remark',
														dataIndex: 'remark'
													},
													{
														title: 'Action',
														key: 'action',
														width: 120,
														align: 'center'
													}
												]" :data-source="assign_car_form.toll_ways" :pagination="false">
													<template #bodyCell="{ column, record, index }">
														<template v-if="column.key === 'action'">
															<a @click="
																edit_list_in_table(
																	record,
																	'create_toll_way',
																	'show_create_toll_way',
																	index
																)
																">
																<span style="font-size: 18px"
																	class="material-symbols-outlined">edit</span>
															</a>
															<a-divider type="vertical" />
															<a @click="
																remove_list_from_table(
																	index,
																	'toll_ways',
																	'TollWay'
																)
																">
																<span style="font-size: 18px"
																	class="material-symbols-outlined">delete</span>
															</a>
														</template>
														<template v-else-if="column.key === 'datetime'">
															{{ render_time(record.datetime) }}
														</template>
														<template v-else-if="column.key == 'booking_status'">
															{{
																booking_status_list.find(
																	({ status }) =>
																		status == record.booking_status
																)?.name
															}}
														</template>
														<template v-else-if="column.key === 'driver_paid'">
															{{
																record.driver_paid == '1' ? 'คนขับ' : 'บริษัท'
															}}
														</template>
													</template>
												</a-table>
											</a-col>
										</a-row>

										<a-drawer v-model:visible="show_create_toll_way" placement="right"
											title="เพิ่มรายการค่าทางด่วน">
											<a-form :model="create_toll_way" layout="vertical"
												@finish="submit_create_toll_way">
												<a-form-item label="ผู้ชำระ" name="driver_paid" :rules="[
													{ required: true, message: 'กรุณากรอกผู้ชำระ' }
												]">
													<a-radio-group v-model:value="create_toll_way.driver_paid">
														<a-radio-button value="1">คนขับ</a-radio-button>
														<a-radio-button value="0">บริษัท</a-radio-button>
													</a-radio-group>
												</a-form-item>

												<a-form-item label="สถานะงาน" name="booking_status" :rules="[
													{ required: true, message: 'เลือกสถานะงาน' }
												]">
													<a-select v-model:value="create_toll_way.booking_status"
														placeholder="เลือกสถานะงาน">
														<a-select-option :value="8">ก่อนเริ่มงาน</a-select-option>
														<a-select-option :value="7">ระหว่างงาน</a-select-option>
														<a-select-option :value="11">หลังจบงาน</a-select-option>
													</a-select>
												</a-form-item>

												<a-form-item label="จำนวนเงิน" name="amount" :rules="[
													{ required: true, message: 'กรุณากรอกจำนวนเงิน' }
												]">
													<a-input-number v-model:value="create_toll_way.amount" :min="0"
														:max="1000000" />
												</a-form-item>

												<a-form-item label="วันเวลา" name="datetime" :rules="[
													{ required: true, message: 'กรุณากรอกวันเวลา' }
												]">
													<a-date-picker v-model:value="create_toll_way.datetime"
														:show-time="{ format: 'HH:mm' }" format="DD/MM/YYYY HH:mm"
														placeholder="เลือกเวลา" :minuteStep="1" />
												</a-form-item>

												<a-form-item label="หมายเหตุ" name="remark">
													<a-textarea v-model:value="create_toll_way.remark" />
												</a-form-item>

												<a-row justify="end">
													<a-button html-type="submit" type="primary" size="large"
														style="margin: 4px; text-align: right">
														<span class="material-symbols-outlined"
															style="font-size: 16px; margin-right: 4px">Edit</span>
														บันทึกข้อมูล
													</a-button>
												</a-row>
											</a-form>
										</a-drawer>

										<a-divider orientation="left" orientation-margin="0px">
											<a-space>ค่าใช้จ่ายเพิ่มเติม
												<a-button @click="show_create_booking_car_expense = true"
													type="primary">เพิ่มค่าใช้จ่ายเพิ่มเติม</a-button></a-space>
										</a-divider>

										<a-row>
											<a-col>
												<a-table :columns="[
													{
														title: 'ผู้ชำระ',
														key: 'driver_paid',
														dataIndex: 'driver_paid'
													},
													{
														title: 'ประเภทการจ่ายเงิน',
														dataIndex: 'pay_method_id',
														key: 'pay_method_id',
														align: 'center'
													},
													{
														title: 'รายการ',
														dataIndex: 'expense_id',
														key: 'expense_id'
													},
													{
														title: 'จำนวนเงิน',
														dataIndex: 'amount',
														key: 'amount'
													},
													{
														title: 'หมายเหตุ',
														key: 'remark',
														dataIndex: 'remark'
													},
													{
														title: 'Action',
														key: 'action',
														width: 120,
														align: 'center'
													}
												]" :data-source="assign_car_form.expenses" :pagination="false">
													<template #bodyCell="{ column, record, index }">
														<template v-if="column.key === 'action'">
															<a @click="
																edit_list_in_table(
																	record,
																	'create_booking_car_expense',
																	'show_create_booking_car_expense',
																	index
																)
																">
																<span style="font-size: 18px"
																	class="material-symbols-outlined">edit</span>
															</a>
															<a-divider type="vertical" />
															<a @click="
																remove_list_from_table(
																	index,
																	'expenses',
																	'BookingCarExpense'
																)
																">
																<span style="font-size: 18px"
																	class="material-symbols-outlined">delete</span>
															</a>
														</template>
														<template v-else-if="column.key === 'pay_method_id'">
															{{
																pay_method_list.find(
																	(pay_method) =>
																		pay_method.id === record.pay_method_id
																)?.name
															}}
														</template>
														<template v-else-if="column.key === 'expense_id'">
															{{
																expense_list.find(
																	(expense) => expense.id === record.expense_id
																)?.name
															}}
														</template>
														<template v-else-if="column.key === 'driver_paid'">
															{{ record.driver_paid == 1 ? 'คนขับ' : 'บริษัท' }}
														</template>
													</template>
												</a-table>
											</a-col>
										</a-row>

										<a-drawer v-model:visible="show_create_booking_car_expense" placement="right"
											title="เพิ่มค่าใช้จ่ายเพิ่มเติม">
											<a-form :model="create_booking_car_expense" layout="vertical" @finish="
												submit_drawer(
													'create_booking_car_expense',
													'expenses',
													'show_create_booking_car_expense',
													'BookingCarExpense'
												)
												">
												<a-row>
													<a-col :span="12">
														<a-form-item label="ผู้ชำระ" name="driver_paid" :rules="[
															{ required: true, message: 'กรุณาเลือกผู้ชำระ' }
														]">
															<a-radio-group v-model:value="create_booking_car_expense.driver_paid
																">
																<a-radio-button value="1">คนขับ</a-radio-button>
																<a-radio-button value="0">บริษัท</a-radio-button>
															</a-radio-group>
														</a-form-item>
													</a-col>

													<a-col :span="12">
														<a-form-item label="ประเภทการจ่ายเงิน" name="pay_method_id" :rules="[
															{
																required: true,
																message: 'เลือกประเภทการจ่ายเงิน'
															}
														]">
															<a-select v-model:value="create_booking_car_expense.pay_method_id
																" placeholder="เลือกประเภทการจ่ายเงิน">
																<a-select-option v-for="{ id, name } in pay_method_list"
																	:key="id" :value="id">
																	{{ name }}
																</a-select-option>
															</a-select>
														</a-form-item>
													</a-col>
												</a-row>

												<a-form-item label="รายการ" name="expense_id" :rules="[
													{ required: true, message: 'กรุณากรอกรายการ' }
												]">
													<a-select v-model:value="create_booking_car_expense.expense_id
														">
														<a-select-option v-for="expense in expense_list" :key="expense.id"
															:value="expense.id">
															{{ expense.name }}
														</a-select-option>
													</a-select>
												</a-form-item>

												<a-form-item label="จำนวนเงิน" name="amount" :rules="[
													{ required: true, message: 'กรุณากรอกจำนวนเงิน' }
												]">
													<a-input-number v-model:value="create_booking_car_expense.amount" />
												</a-form-item>

												<a-form-item label="หมายเหตุ" name="remark">
													<a-textarea v-model:value="create_booking_car_expense.remark" />
												</a-form-item>

												<a-form-item label="รูปประกอบ" name="remark">
													<a-upload v-model:file-list="create_booking_car_expense.fileList
														" list-type="picture-card" class="avatar-uploader" :show-upload-list="false"
														action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
														@change="
															handleUpload($event, 'create_booking_car_expense')
															" method="GET">
														<img v-if="create_booking_car_expense.photo"
															:src="create_booking_car_expense.photo"
															:style="`width: 100%;object-fit: cover`" alt="avatar" />
														<div v-else>
															<p class="ant-upload-drag-icon">
																<span class="material-symbols-outlined"
																	style="font-size: 5rem">upload_file</span>
															</p>
															คลิกเพื่ออัพโหลดรูปภาพ
														</div>
													</a-upload>
												</a-form-item>

												<a-row justify="end">
													<a-button html-type="submit" type="primary" size="large"
														style="margin: 4px; text-align: right">
														<span class="material-symbols-outlined"
															style="font-size: 16px; margin-right: 4px">Edit</span>
														บันทึกข้อมูล
													</a-button>
												</a-row>
											</a-form>
										</a-drawer>

										<a-row>
											<a-col>
												<a-form-item label="หมายเหตุ" name="remark">
													<a-textarea v-model:value="assign_car_form.remark" />
												</a-form-item>
											</a-col>
										</a-row>

										<!-- <a-row justify="end">
                                            <a-col :span="12">
                                                <a-form-item label="สถานะยานพาหนะ" name="car_status_id">
                                                    <a-select v-model:value="assign_car_form.car_status_id" @change="update_car_status_id($event, assign_car_form.car_id)">
                                                        <a-select-option v-for="{ name, id } in car_status_list" :value="id">{{ name }}</a-select-option>
                                                    </a-select>
                                                </a-form-item>
                                            </a-col>
                                        </a-row> -->
										<a-row justify="end">
											<a-button html-type="submit" :disabled="loading" type="primary" size="large"
												style="margin: 4px; text-align: right">
												<span class="material-symbols-outlined"
													style="font-size: 16px; margin-right: 4px">Edit</span>
												บันทึกข้อมูล
											</a-button>
										</a-row>
									</a-form>
								</a-drawer>

								<!-- BookingCarType Drawer -->
								<a-drawer v-model:visible="show_add_booking_car_types" class="custom-class"
									style="color: red" title="รถที่รับงานได้" placement="right">
									<a-form name="booking_car_types" autocomplete="off" layout="vertical"
										:model="booking_car_types" @finish="submit_add_booking_car_types">
										<a-row>
											<a-col>
												<a-form-item label="ประเภทรถ" name="car_type_id" :rules="[
													{ required: true, message: 'โปรดเลือกประเภทรถ' }
												]">
													<a-select v-model:value="booking_car_types.car_type_id"
														@change="cal_total_seats" placeholder="เลือกประเภทรถ">
														<a-select-option v-for="car_type in car_type_list"
															:key="car_type.id" :value="car_type.id">
															{{ car_type.name }}
														</a-select-option>
													</a-select>
												</a-form-item>
											</a-col>
											<a-col>
												<a-form-item label="ประเภทที่นั่งรถ" name="car_group_id" :rules="[
													{
														required: true,
														message: 'โปรดเลือกประเภทที่นั่งรถ'
													}
												]">
													<a-select v-model:value="booking_car_types.car_group_id"
														@change="cal_total_seats" placeholder="เลือกประเภทที่นั่งรถ">
														<a-select-option v-for="car_group in car_group_list.filter(
															({ car_type_id }) =>
																car_type_id == booking_car_types.car_type_id
														)" :key="car_group.id" :value="car_group.id">
															{{ car_group.name }}
														</a-select-option>
													</a-select>
												</a-form-item>
											</a-col>
											<a-col>
												<a-form-item label="ระบุราคารถ" name="price" :rules="[
													{ required: true, message: 'โปรดระบุราคารถ' }
												]">
													<a-input-number v-model:value="booking_car_types.price"
														placeholder="ระบุราคารถ" />
												</a-form-item>
											</a-col>
											<a-col :span="12">
												<a-form-item label="ระบุจำนวนรถ" name="quantity" :rules="[
													{ required: true, message: 'โปรดระบุจำนวนรถ' }
												]">
													<a-input-number v-model:value="booking_car_types.quantity"
														@change="cal_total_seats" placeholder="ระบุจำนวนรถ" />
												</a-form-item>
											</a-col>
											<a-col :span="12">
												<a-form-item label="จำนวนที่นั่ง" name="seats">
													<a-input-number :disabled="true" v-model:value="show_total_seats"
														placeholder="ระบุจำนวนรถ" />
												</a-form-item>
											</a-col>
											<a-col>
												<a-form-item label="วันเวลารับ - ส่ง" name="datetime" :rules="[
													{
														required: true,
														message: 'โปรดระบุวันเวลารับ - ส่ง'
													}
												]">
													<a-range-picker v-model:value="booking_car_types.datetime"
														:show-time="{ format: 'HH:mm' }" format="DD/MM/YYYY HH:mm"
														:placeholder="['Start Time', 'End Time']" :minuteStep="1" />
												</a-form-item>
											</a-col>
											<a-col>
												<a-form-item label="ชื่อจุดรับ" name="start_point">
													<vue-google-autocomplete id="start_point" classname="form-control"
														placeholder="ค้นหาสถานที่" v-on:input="
															booking_car_types.start_point =
															$event.target.value
															" v-on:placechanged="
		select_stop_name($event, 'start_point', true)
		" types="establishment" country="th">
													</vue-google-autocomplete>
												</a-form-item>
												<a-form-item label="ชื่อจุดส่ง" name="end_point">
													<vue-google-autocomplete id="end_point" classname="form-control"
														placeholder="ค้นหาสถานที่" v-on:input="
															booking_car_types.end_point = $event.target.value
															" v-on:placechanged="
		select_stop_name($event, 'end_point', true)
		" types="establishment" country="th">
													</vue-google-autocomplete>
												</a-form-item>
											</a-col>
											<a-col>
												<a-form-item label="หมายเหตุ" name="destination">
													<a-textarea v-model:value="booking_car_types.remark"
														placeholder="ระบุหมายเหตุ" />
												</a-form-item>
											</a-col>

											<a-col>
												<a-form-item label="ค่าใช้จ่ายรถ" name="car_expense">
													<a-table :columns="[
														{
															title: 'ชื่อ',
															dataIndex: 'name',
															key: 'name'
														},
														{
															title: 'ราคาต่อหน่วย',
															dataIndex: 'price',
															key: 'price'
														},
														{
															title: 'จำนวน',
															dataIndex: 'amount',
															key: 'amount'
														},
														{
															title: 'ตัวเลือก',
															dataIndex: 'id',
															align: 'center',
															width: 100,
															key: 'id',
															fixed: 'right'
														}
													]" :data-source="booking_car_types.expenses" bordered size="small" :pagination="false">
														<template #bodyCell="{ column, record, index }">
															<template v-if="column.key === 'id'">
																<a @click="
																	remove_booking_car_types_expense(
																		index,
																		record.id
																	)
																	"><span style="font-size: 18px" class="material-symbols-outlined">delete</span></a>
															</template>
															<template v-if="column.key === 'name'">
																{{
																	record.name
																	? record.name
																	: record.expense
																		? record.expense.name
																		: ''
																}}
															</template>
														</template>
													</a-table>
												</a-form-item>

												<a-col>
													<a-row justify="end">
														<a-button @click="show_add_expense = true" type="primary"
															size="small">
															<span class="material-symbols-outlined" style="
																	font-size: 14px;
																	margin-right: 4px;
																	margin-bottom: 4px;
																">Add</span>
															เพิ่มค่าใช้จ่ายรถ
														</a-button>
													</a-row>
												</a-col>

												<a-drawer v-model:visible="show_add_expense" title="เพิ่มค่าใช้จ่ายรถ"
													width="320" :closable="true">
													<a-form :model="add_expense" name="add_expense" autocomplete="off"
														layout="vertical" @finish="submit_add_expense"
														@finish-failed="submit_add_expense">
														<a-row>
															<a-col>
																<a-form-item label="ค่าใช้จ่ายรถ" name="expense_id" :rules="[
																	{ required: true, message: 'โปรดใส่ข้อมูล' }
																]">
																	<a-select v-model:value="add_expense.expense_id"
																		placeholder="เลือกรายการค่าใช้จ่าย">
																		<a-select-option
																			v-for="{ id, name } in expense_list" :key="id"
																			:value="id">
																			{{ name }}
																		</a-select-option>
																	</a-select>
																</a-form-item>
															</a-col>
															<a-col>
																<a-form-item label="จำนวน" name="amount" :rules="[
																	{ required: true, message: 'โปรดใส่ข้อมูล' }
																]">
																	<a-input v-model:value="add_expense.amount"
																		placeholder="ระบุจำนวน" />
																</a-form-item>
															</a-col>
															<a-col>
																<a-form-item label="ราคาต่อหน่วย" name="price" :rules="[
																	{ required: true, message: 'โปรดใส่ข้อมูล' }
																]">
																	<a-input v-model:value="add_expense.price"
																		placeholder="ระบุราคาต่อหน่วย" />
																</a-form-item>
															</a-col>
															<a-col>
																<a-row justify="end">
																	<a-col>
																		<a-button type="primary" html-type="submit"
																			size="small">
																			<span class="material-symbols-outlined" style="
																					font-size: 14px;
																					margin-right: 4px;
																					margin-bottom: 4px;
																				">Add</span>
																			เพิ่มค่าใช้จ่ายรถ
																		</a-button>
																	</a-col>
																</a-row>
															</a-col>
														</a-row>
													</a-form>
												</a-drawer>
											</a-col>
										</a-row>
										<a-col>
											<a-row justify="end">
												<a-button type="primary" html-type="submit" size="large">
													<span class="material-symbols-outlined"
														style="font-size: 16px; margin-right: 4px">Add</span>
													บันทึก
												</a-button>
											</a-row>
										</a-col>
									</a-form>
								</a-drawer>
							</a-collapse-panel>

							<a-collapse-panel v-if="!on_booking" key="4" header="ค่าใช้จ่ายเพิ่มเติม">
								<a-drawer v-model:visible="show_add_booking_extra" class="custom-class" style="color: red"
									title="บันทึกรายการค่าใช้จ่ายเพิ่มเติม" placement="right">
									<a-form name="extra" autocomplete="off" layout="vertical" :model="extra"
										@finish="submit_extra">
										<a-row>
											<a-col>
												<a-form-item label="ค่าใช้จ่ายเพิ่มเติม" name="extra_id" :rules="[
													{
														required: true,
														message: `โปรดเลือกรายการค่าใช้จ่าย`
													}
												]">
													<a-select v-model:value="extra.extra_id"
														placeholder="เลือกรายการค่าใช้จ่าย">
														<a-select-option v-for="{ id, name } in extra_list" :key="id"
															:value="id">
															{{ name }}
														</a-select-option>
													</a-select>
												</a-form-item>
											</a-col>
											<a-col>
												<a-form-item label="ราคาต่อหน่วย" name="price" :rules="[
													{ required: true, message: `โปรดระบุราคาต่อหน่วย` }
												]">
													<a-input-number v-model:value="extra.price"
														placeholder="ระบุราคาต่อหน่วย" />
												</a-form-item>
											</a-col>
											<a-col>
												<a-form-item label="จำนวน" name="amount" :rules="[
													{ required: true, message: `โปรดระบุจำนวน` }
												]">
													<a-input-number v-model:value="extra.amount" placeholder="ระบุจำนวน" />
												</a-form-item>
											</a-col>
											<a-col>
												<a-row justify="end">
													<a-button type="primary" html-type="submit" size="large">
														บันทึก
													</a-button>
												</a-row>
											</a-col>
										</a-row>
									</a-form>
								</a-drawer>
								<a-row>
									<a-col>
										<a-table :columns="[
											{
												title: 'ชื่อ',
												dataIndex: 'name',
												key: 'name'
											},
											{
												title: 'จำนวน',
												dataIndex: 'amount',
												key: 'amount',
												align: 'center',
												width: 150
											},
											{
												title: 'ราคาต่อหน่วย',
												dataIndex: 'price',
												key: 'price',
												align: 'center',
												width: 150
											},
											{
												title: 'ราคารวม',
												dataIndex: 'total',
												key: 'total',
												align: 'center',
												width: 150
											},
											{
												title: 'ตัวเลือก',
												dataIndex: 'action',
												key: 'action',
												align: 'center',
												width: 120
											}
										]" :data-source="create_booking.extras" bordered size="small" :scroll="{ x: 400, y: 200 }"
											:pagination="false">
											<template #bodyCell="{ column, record, index }">
												<template v-if="column.key === 'action'">
													<a @click="edit_booking_expense(index, record.id)"><span
															style="font-size: 18px"
															class="material-symbols-outlined">edit</span></a>
													<a-divider type="vertical" />
													<a @click="remove_booking_expense(index, record.id)"><span
															style="font-size: 18px"
															class="material-symbols-outlined">delete</span></a>
												</template>
												<template v-if="column.key === 'name'">
													{{ record.id ? record.extra.name : record.name }}
												</template>
												<template v-if="column.key === 'total'">
													{{ record.price * record.amount }}
												</template>
											</template>
										</a-table>
									</a-col>
								</a-row>
								<a-row>
									<a-button type="primary" style="display: flex; margin: 1.25rem 1rem"
										@click="add_booking_expense()" size="large">
										<template #icon>
											<span class="material-symbols-outlined">Add</span>
										</template>
										เพิ่มค่าใช้จ่ายเพิ่ม
									</a-button>
								</a-row>
							</a-collapse-panel>

							<a-collapse-panel v-if="!on_booking" key="5" header="ส่วนลด">
								<a-row>
									<a-col>
										<a-form-item label="ระบุส่วนลด" name="discount">
											<a-input-number v-model:value="create_booking.discount" />
										</a-form-item>
									</a-col>
								</a-row>
							</a-collapse-panel>

							<a-collapse-panel key="6" header="เอกสารที่เกี่ยวข้อง">
								<a-space>
									<a-upload v-model:file-list="create_booking.program" list-type="picture-card"
										class="avatar-uploader"
										action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
										@preview="handlePreview" @change="upload_files($event, 'create_photo')" method="GET"
										@remove="remove_uploaded_file($event)">
										<div v-if="create_booking.program?.length < 1"
											style="height: 104px; width: 104px; padding: 2rem">
											<span style="font-size: 20px" class="material-symbols-outlined">add</span>
											<div style="margin-top: 8px">โปรแกรม</div>
										</div>
									</a-upload>
									<a-upload v-model:file-list="create_booking.map" list-type="picture-card"
										class="avatar-uploader"
										action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
										@preview="handlePreview" @change="upload_files($event, 'create_photo')" method="GET"
										@remove="remove_uploaded_file($event)">
										<div v-if="create_booking.map?.length < 1"
											style="height: 104px; width: 104px; padding: 2rem">
											<span style="font-size: 20px" class="material-symbols-outlined">add</span>
											<div style="margin-top: 8px">แผนที่</div>
										</div>
									</a-upload>
									<a-upload v-model:file-list="create_booking.booking_files" list-type="picture-card"
										class="avatar-uploader"
										action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
										@preview="handlePreview" @change="upload_files($event, 'create_photo')" method="GET"
										@remove="remove_uploaded_file($event)">
										<div v-if="create_booking.booking_files?.length < 3"
											style="height: 104px; width: 104px; padding: 1rem">
											<span style="font-size: 20px; margin-top: 8px"
												class="material-symbols-outlined">add</span>
											<div>รายละเอียดการใช้รถ ({{
												create_booking.booking_files.length + 1
											}}/3)</div>
										</div>
									</a-upload>
								</a-space>
							</a-collapse-panel>

							<a-collapse-panel key="7" header="พนักงานบริการลูกค้า CS">
								<template #extra>
									<p v-if="[create_booking.sale_id].filter((e) => e == null).length
											" style="margin: 0; color: grey">
										{{
											`(ต้องระบุอีก ${[create_booking.sale_id].filter((e) => e == null).length
												})`
										}}
									</p>
								</template>
								<a-form-item name="sale_id" label="พนักงานบริการลูกค้า CS"
									:rules="[{ required: true, message: 'โปรดเลือกคนขาย' }]">
									<a-select v-model:value="create_booking.sale_id">
										<a-select-option v-for="{
											id,
											firstname,
											lastname,
											nickname
										} in sales_list" :key="id" :value="id">
											{{ `${firstname} ${lastname} (${nickname})` }}
										</a-select-option>
									</a-select>
								</a-form-item>
							</a-collapse-panel>

							<a-collapse-panel v-if="on_booking" key="8" header="รายงานสถานะหน้างาน">
								<a-collapse style="border-radius: 4px" v-model:activeKey="activeSubKeys">
									<a-collapse-panel v-for="car in create_booking.booking_cars" :key="car.id"
										:header="`ทะเบียนรถ: ${car.car.plate_no}`">
										<div style="
												display: flex;
												justify-content: space-between;
												width: 100%;
											">
											<a-form-item name="onsite_photo" label="1. ถ่ายภาพเมื่อถึงหน้างานแล้ว">
												<a-upload v-model:file-list="car.service.photo_1_show"
													list-type="picture-card" class="avatar-uploader" accept="image/*"
													action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
													@preview="handlePreview" @change="
														upload_files($event, 'photo_1', car.service, {
															model: 'Service',
															id: car.service.id
														})
														" method="GET" @remove="remove_uploaded_file($event)">
													<a-button v-if="!car.service.photo_1_show.length" type="primary">
														แนบรูปภาพ
													</a-button>
												</a-upload>
											</a-form-item>
											<a-form-item name="security_photo" label="2. รูปการเปิด VTR ความปลอดภัย">
												<a-upload v-model:file-list="car.service.photo_2_show"
													list-type="picture-card" class="avatar-uploader" accept="image/*"
													action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
													@preview="handlePreview" @change="
														upload_files($event, 'photo_2', car.service, {
															model: 'Service',
															id: car.service.id
														})
														" method="GET" @remove="remove_uploaded_file($event)">
													<a-button v-if="!car.service.photo_2_show.length" type="primary">
														แนบรูปภาพ
													</a-button>
												</a-upload>
											</a-form-item>
											<a-form-item name="service_photo" label="3. การเสิร์ฟน้ำ">
												<a-upload v-model:file-list="car.service.photo_3_show"
													list-type="picture-card" class="avatar-uploader" accept="image/*"
													action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
													@preview="handlePreview" @change="
														upload_files($event, 'photo_3', car.service, {
															model: 'Service',
															id: car.service.id
														})
														" method="GET" @remove="remove_uploaded_file($event)">
													<a-button v-if="!car.service.photo_3_show.length" type="primary">
														แนบรูปภาพ
													</a-button>
												</a-upload>
											</a-form-item>
											<a-form-item name="cleaning_photo" label="4. การกดเจลแอลกอฮอล์">
												<a-upload v-model:file-list="car.service.photo_4_show"
													list-type="picture-card" class="avatar-uploader" accept="image/*"
													action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
													@preview="handlePreview" @change="
														upload_files($event, 'photo_4', car.service, {
															model: 'Service',
															id: car.service.id
														})
														" method="GET" @remove="remove_uploaded_file($event)">
													<a-button v-if="!car.service.photo_4_show.length" type="primary">
														แนบรูปภาพ
													</a-button>
												</a-upload>
											</a-form-item>
											<a-form-item name="assist_driver_photo" label="5. รูปภาพพนักงานขับรถคนที่ 2">
												<a-upload v-model:file-list="car.service.photo_5_show"
													list-type="picture-card" class="avatar-uploader" accept="image/*"
													action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
													@preview="handlePreview" @change="
														upload_files($event, 'photo_5', car.service, {
															model: 'Service',
															id: car.service.id
														})
														" method="GET" @remove="remove_uploaded_file($event)">
													<a-button v-if="!car.service.photo_5_show.length" type="primary">
														แนบรูปภาพ
													</a-button>
												</a-upload>
											</a-form-item>
										</div>
									</a-collapse-panel>
								</a-collapse>
							</a-collapse-panel>

							<a-collapse-panel v-if="on_booking" key="9" header="บันทึกค่าน้ำมัน">
								<a-collapse style="border-radius: 4px" v-model:activeKey="activeSubKeys2">
									<a-collapse-panel v-for="car in create_booking.booking_cars" :key="car.id"
										:header="`ทะเบียนรถ: ${car.car.plate_no}`">
										<div v-if="car.gas_refills.length">
											<a-card v-for="(gas, i) in car.gas_refills" :key="gas.id" :title="`หลักฐานการเติม: #${i + 1} - ${render_date(
												gas.created_at
											)}`">
												<div style="
														display: flex;
														justify-content: space-between;
														width: 100%;
													">
													<a-form-item name="gas1" label="1. ใบเสร็จ / Slip">
														{{ car.servicegas }}
														<a-upload v-model:file-list="gas.photo_1_show"
															list-type="picture-card" class="avatar-uploader"
															accept="image/*"
															action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
															@preview="handlePreview" @change="
																upload_files($event, 'photo_1', gas, {
																	model: 'GasRefill',
																	id: car.servicegas?.id
																})
																" method="GET" @remove="remove_uploaded_file($event)">
															<a-button v-if="!gas.photo_1_show.length" type="primary">
																แนบรูปภาพ
															</a-button>
														</a-upload>
													</a-form-item>
													<a-form-item name="gas2" label="2. รูปหน้าปัดก่อนเติมน้ำมัน">
														<a-upload v-model:file-list="gas.photo_2_show"
															list-type="picture-card" class="avatar-uploader"
															accept="image/*"
															action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
															@preview="handlePreview" @change="
																upload_files($event, 'photo_2', gas, {
																	model: 'GasRefill',
																	id: car.id
																})
																" method="GET" @remove="remove_uploaded_file($event)">
															<a-button v-if="!gas.photo_2_show.length" type="primary">
																แนบรูปภาพ
															</a-button>
														</a-upload>
													</a-form-item>
													<a-form-item name="gas3" label="3. รูปรถคู่กับหัวจ่าย">
														<a-upload v-model:file-list="gas.photo_3_show"
															list-type="picture-card" class="avatar-uploader"
															accept="image/*"
															action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
															@preview="handlePreview" @change="
																upload_files($event, 'photo_3', gas, {
																	model: 'GasRefill',
																	id: car.id
																})
																" method="GET" @remove="remove_uploaded_file($event)">
															<a-button v-if="!gas.photo_3_show.length" type="primary">
																แนบรูปภาพ
															</a-button>
														</a-upload>
													</a-form-item>
													<a-form-item name="gas4" label="4. รูปเติมน้ำมันครั้งที่ 1">
														<a-upload v-model:file-list="gas.photo_4_show"
															list-type="picture-card" class="avatar-uploader"
															accept="image/*"
															action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
															@preview="handlePreview" @change="
																upload_files($event, 'photo_4', gas, {
																	model: 'GasRefill',
																	id: car.id
																})
																" method="GET" @remove="remove_uploaded_file($event)">
															<a-button v-if="!gas.photo_4_show.length" type="primary">
																แนบรูปภาพ
															</a-button>
														</a-upload>
													</a-form-item>
													<a-form-item name="gas5" label="5. รูปคอน้ำมัน">
														<a-upload v-model:file-list="gas.photo_5_show"
															list-type="picture-card" class="avatar-uploader"
															accept="image/*"
															action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
															@preview="handlePreview" @change="
																upload_files($event, 'photo_5', gas, {
																	model: 'GasRefill',
																	id: car.id
																})
																" method="GET" @remove="remove_uploaded_file($event)">
															<a-button v-if="!gas.photo_5_show.length" type="primary">
																แนบรูปภาพ
															</a-button>
														</a-upload>
													</a-form-item>
													<a-form-item name="gas6" label="6. รูปหัวจ่าย">
														<a-upload v-model:file-list="gas.photo_6_show"
															list-type="picture-card" class="avatar-uploader"
															accept="image/*"
															action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
															@preview="handlePreview" @change="
																upload_files($event, 'photo_6', gas, {
																	model: 'GasRefill',
																	id: car.id
																})
																" method="GET" @remove="remove_uploaded_file($event)">
															<a-button v-if="!gas.photo_6_show.length" type="primary">
																แนบรูปภาพ
															</a-button>
														</a-upload>
													</a-form-item>
													<a-form-item name="gas7" label="7. รูปหน้าปัดหลังเติม">
														<a-upload v-model:file-list="gas.photo_7_show"
															list-type="picture-card" class="avatar-uploader"
															accept="image/*"
															action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
															@preview="handlePreview" @change="
																upload_files($event, 'photo_7', gas, {
																	model: 'GasRefill',
																	id: car.id
																})
																" method="GET" @remove="remove_uploaded_file($event)">
															<a-button v-if="!gas.photo_7_show.length" type="primary">
																แนบรูปภาพ
															</a-button>
														</a-upload>
													</a-form-item>
													<a-form-item name="gas8" label="8. บิลเชื้อเพลิง">
														<a-upload v-model:file-list="gas.photo_8_show"
															list-type="picture-card" class="avatar-uploader"
															accept="image/*"
															action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
															@preview="handlePreview" @change="
																upload_files($event, 'photo_8', gas, {
																	model: 'GasRefill',
																	id: car.id
																})
																" method="GET" @remove="remove_uploaded_file($event)">
															<a-button v-if="!gas.photo_8_show.length" type="primary">
																แนบรูปภาพ
															</a-button>
														</a-upload>
													</a-form-item>
												</div>
											</a-card>
										</div>
										<a-empty description="ยังไม่มีการเติมน้ำมันจากคนขับ" v-else />
									</a-collapse-panel>
								</a-collapse>
							</a-collapse-panel>
						</a-collapse>

						<a-row justify="end">
							<a-form-item :span="24" style="text-align: right">
								<a-button type="primary" html-type="submit" size="large" style="margin: 4px">
									<span class="material-symbols-outlined"
										style="font-size: 16px; margin-right: 4px">edit_square</span>
									บันทึกข้อมูล
								</a-button>
							</a-form-item>
						</a-row>
					</a-form>
				</div>

				<!-- Modals -->
				<CreateCompany :show_create_company="show_create_company" :tour_types="tour_types_list"
					@onCreated="init_create_booking_options" @afterClose="create_company_close" />
				<CreateClient :selectedClient_id="create_booking.client_id" :show_create_client="show_create_client"
					:client_company_list="client_company_list" @onCreated="init_create_booking_options"
					@afterClose="create_client_close" />
				<a-modal v-if="previewVisible" :visible="previewVisible" footer="" :width="800" @cancel="preview_close">
					<img :src="previewImage" style="width: 100%" />
				</a-modal>
			</div>

			<ExportPdf v-if="show_export_modal" :show_export_modal="show_export_modal" :booking_detail="booking_detail"
				:user_name="user_name" @afterClose="create_export_close" />
		</a-spin>
	</div>
</template>

<script>
import inputDatePicker from '../../components/input_date_picker.vue';
import CreateCompany from '../../components/modals/create_company.vue';
import CreateClient from '../../components/modals/create_client.vue';
import ExportPdf from '../../components/modals/export_modal.vue';
import VueGoogleAutocomplete from 'vue-google-autocomplete';

import BookingService from '../../api/BookingService.js';
import SystemService from '../../api/SystemService.js';

import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
dayjs.extend(buddhistEra);

import Swal from 'sweetalert2';
import { message } from 'ant-design-vue';

import Utility from '../../utility';
export default {
	props: {
		user_name: String
	},
	computed: {
		from_booking() {
			return this.$route.query.booking == 'true';
		},
		render_type() {
			return this.$route.params?.type;
		},
		on_booking() {
			return this.$route.query.edit == 'true';
		},
		filtered_cars() {
			return this.filtered_car(this.car_list);
		}
	},
	components: {
		inputDatePicker,
		CreateCompany,
		CreateClient,
		ExportPdf,
		VueGoogleAutocomplete
	},
	data() {
		return {
			editable: Utility.get_permission_editable_with_key('quotation'),
			booking_detail: null,
			show_export_modal: false,
			datetime: null,
			price_percent: 0,

			search_input: null,
			search_booking_type_id: null,
			person_qty: 1,
			flight_pick: null,
			flight_drop: null,
			full_day_amount: 0,
			total_days: 0,
			discount: 0,
			booking_list: [],
			pagination: {
				total: 0,
				onpage: 1,
				perpage: 20
			},
			create_photo: [],
			search_progress: null,
			selected_quotation: null,
			loading: false,
			tag_option: {
				booking_type_id: {
					1: { txt: 'เที่ยวเดียว (ขาเดียว)', color: 'pink' },
					2: { txt: 'ไป-กลับ', color: 'red' },
					3: { txt: 'รายวัน (เต็มวัน)', color: 'orange' },
					4: { txt: 'รายวัน (ครึ่งวัน)', color: 'green' },
					5: { txt: 'เฉพาะวัน (ส่ง-รับ วันกลางไม่ใช้รถ)', color: 'cyan' },
					6: { txt: 'เฉพาะวัน (ระบุวัน)', color: 'blue' },
					7: { txt: 'ใช้รถทุกวัน', color: 'purple' },
					8: { txt: 'รายเดือน', color: 'green' }
				},
				progress: {
					0: { txt: 'ใหม่', color: 'green' },
					1: { txt: 'กำลังดำเนินการ', color: 'yellow' },
					2: { txt: 'เสร็จสิ้น', color: 'green' },
					3: { txt: 'ยกเลิก', color: 'red' }
				},
				booking_status: {
					0: { txt: 'ใหม่', color: 'green' },
					1: { txt: 'กำลังดำเนินการ', color: 'yellow' },
					2: { txt: 'เสร็จสิ้น', color: 'green' },
					3: { txt: 'ยกเลิก', color: 'red' }
				},
				stop_type: {
					1: { txt: 'จุดรับ', color: 'green' },
					2: { txt: 'จุดรับย่อย', color: 'blue' },
					3: { txt: 'จุดแวะ', color: 'blue' },
					4: { txt: 'จุดแวะขาไป', color: 'red' },
					5: { txt: 'จุดหมายหลัก', color: 'red' },
					6: { txt: 'จุดส่ง', color: 'green' },
					7: { txt: 'จุดส่งย่อย', color: 'blue' },
					8: { txt: 'จุดแวะขากลับ', color: 'blue' },
					9: { txt: 'จุดเติมน้ำมัน', color: 'red' }
				},
				pickup_status: {
					0: { txt: 'รอรับ', color: 'yellow' },
					1: { txt: 'รับแล้ว', color: 'green' },
					2: { txt: 'ไม่รับของ', color: 'red' }
				}
			},
			on_edit_stop: null,
			create_booking: {
				onsite_photo_show: [],
				security_photo_show: [],
				service_photo_show: [],
				cleaning_photo_show: [],
				assist_driver_photo_show: [],

				gas1_show: [],
				gas2_show: [],
				gas3_show: [],
				gas4_show: [],
				gas5_show: [],
				gas5_show: [],
				gas6_show: [],

				booking_car_types: [],
				program: [],
				map: [],
				booking_cars: [],
				expenses: [],
				booking_files: [],
				map: [],
				booking_services_files: [],
				stops: [],
				client_contact_id: null,
				client_contact: {
					firstname: null
				},
				inc_vat: false,
				withholding: false,
				selected_remarks_list: []
			},
			activeKey: ['2'],
			activeSubKeys: null,
			activeSubKeys2: null,
			remarks_list: [],

			/* part 1 */
			tour_types_list: [],
			client_company_list: [],
			client_branches_list: [],
			client_contact_list: [],
			booking_types: [],
			form: {},

			/* part 2 */
			company_trip_list: [],
			tour_list: [],

			/* part 3 */
			show_add_booking_car_types: false,
			booking_car_types: {
				expenses: []
			},
			car_list: [],

			/* part 4 */
			show_add_booking_extra: false,

			//Modal
			show_create_company: false,
			show_create_client: false,

			/* Drawer */
			car_type_list: [],
			car_group_list: [],
			expense_list: [],
			driver_list: [],
			places: [],
			gas_station_list: [],
			pay_method_list: [],
			extra_list: [],

			/* Sub Drawer */
			show_add_expense: false,
			add_expense: {
				name: null,
				expense_id: null,
				price: null,
				amount: null
			},
			extra: {
				name: null,
				extra_id: null,
				price: null,
				amount: null
			},
			preselect_car_type_id: null,
			add_car_drawer: false,
			assign_car_drawer: false,
			stop_type_list: [],
			car_status_list: [],
			sales_list: [],
			search_car: null,
			add_stop: {},
			guide_list: [],
			assign_car_form: {},
			add_guide_drawer: false,
			create_guide: {},
			add_gas_refill_drawer: false,
			create_gas_refill: {},

			show_create_toll_way: false,
			create_toll_way: {},

			show_create_booking_car_expense: false,
			create_booking_car_expense: {
				fileList: []
			},
			previewImage: '',
			previewVisible: false,

			create_pickup_item: {},
			show_create_pickup_item: false,

			driver_costs_list: [],
			booking_status_list: [],
			car_company_list: [],
			show_total_seats: 0
		};
	},
	methods: {
		handleTableChange(pagination, filters, sorter) {
			// console.log({pagination, filters, sorter});
			this.pagination.onpage = pagination.current;
			this.pagination.perpage = pagination.pageSize;
			this.init_all_bookings();
		},
		cal_total_seats() {
			var { car_type_id, car_group_id } = this.booking_car_types;
			if (car_type_id && car_group_id) {
				if (
					!this.car_group_list
						.filter(
							({ car_type_id }) =>
								car_type_id == this.booking_car_types.car_type_id
						)
						.map(({ id }) => id)
						.includes(car_group_id)
				) {
					this.booking_car_types.car_group_id = this.car_group_list.filter(
						({ car_type_id }) =>
							car_type_id == this.booking_car_types.car_type_id
					)[0].id;
					car_group_id = this.booking_car_types.car_group_id;

					const car = this.car_group_list
						.filter((car) => car.car_type_id == car_type_id)
						.filter(({ id }) => id == car_group_id)[0];
					if (car) {
						this.show_total_seats = (car.seats ? car.seats : 0) * 1;
					} else {
						this.show_total_seats = 0;
					}
				} else {
					const car = this.car_group_list
						.filter((car) => car.car_type_id == car_type_id)
						.filter(({ id }) => id == car_group_id)[0];
					if (car) {
						this.show_total_seats = (car.seats ? car.seats : 0) * 1;
					} else {
						this.show_total_seats = 0;
					}
				}
			} else {
				this.show_total_seats = 0;
			}

			if (this.show_total_seats) {
				if (this.booking_car_types.remark.includes('ที่นั่ง')) {
					this.booking_car_types.remark = this.booking_car_types.remark.replace(
						/ค่าขนส่งโดยสารปรับอากาศ\s\d+\sที่นั่ง/,
						`ค่าขนส่งโดยสารปรับอากาศ ${this.show_total_seats} ที่นั่ง`
					);
				} else {
					this.booking_car_types.remark = this.booking_car_types.remark.replace(
						'ค่าขนส่งโดยสารปรับอากาศ',
						`ค่าขนส่งโดยสารปรับอากาศ ${this.show_total_seats} ที่นั่ง`
					);
				}
			}
		},
		calculate_driver_cost() {
			const cost_target = this.driver_costs_list.filter(
				({ booking_type_id }) =>
					booking_type_id == this.create_booking.booking_type_id
			)[0];
			const cost_per_day = cost_target ? cost_target.cost_per_day : 0;
			const total_day = this.create_booking.total_days;

			this.assign_car_form.driver_cost = this.assign_car_form
				.driver_cost_as_percent
				? this.create_booking.booking_car_types
					.map(({ cost }) => cost)
					.reduce((a, b) => a + b, 0) *
				(this.assign_car_form.driver_cost_percent / 100)
				: cost_per_day * total_day;
		},
		price_formula() {
			var {
				extras,
				booking_car_types,
				discount,
				booking_cars,
				inc_vat,
				withholding,
				car_company
			} = this.create_booking;
			if (!discount) discount = 0;

			const sum_car_type = Number(
				booking_car_types
					.map(
						({ cost, expenses }) =>
							cost +
							expenses
								.map(({ amount, price }) => amount * price)
								.reduce((a, b) => a + b, 0)
					)
					.reduce((a, b) => a + b, 0)
			);
			const revenue =
				Number(
					extras
						.map(({ amount, price }) => amount * price)
						.reduce((a, b) => a + b, 0)
				) + sum_car_type;
			const total_discounted = revenue - discount;
			const cost_sum =
				Number(
					booking_cars
						.map(({ driver_cost, rental_price }) => driver_cost + rental_price)
						.reduce((a, b) => a + b, 0)
				) +
				booking_cars
					.map(({ toll_ways, gas_refills, expenses }) => {
						return (
							toll_ways
								.filter(({ granted }) => granted == 1)
								.reduce((a, b) => a + b.amount, 0) +
							gas_refills
								.filter(({ granted }) => granted == 1)
								.reduce((a, b) => a + b.amount, 0) +
							expenses
								.filter(({ granted }) => granted == 1)
								.reduce((a, b) => a + b.amount, 0)
						);
					})
					.reduce((a, b) => a + b, 0);
			const sum_withholding = withholding
				? Number((sum_car_type * this.withholding_type(car_company)).toFixed(2))
				: 0;
			const vat = inc_vat ? Number((sum_car_type * 0.07).toFixed(2)) : 0;

			return {
				revenue: this.formatPrice(revenue),
				discount: this.formatPrice(discount),
				total_discounted: this.formatPrice(total_discounted),
				cost_sum: this.formatPrice(cost_sum),
				withholding: this.formatPrice(sum_withholding),
				vat: this.formatPrice(vat),
				profit: this.formatPrice(revenue - cost_sum),
				revenue_net: this.formatPrice(revenue - sum_withholding + vat)
			};
		},
		selectClientContact(v) {
			this.create_booking.client_contact = this.client_contact_list.find(
				({ id }) => id == v
			);
			this.create_booking.client_contact_id = v;
		},
		confirm_booking_car(record) {
			const { id } = record;
			if (id) {
				SystemService.update_all(
					'BookingCar',
					{ data: { is_confirm: true } },
					id
				).then(async (res) => {
					if (res) {
						this.refresh_data();
						Swal.fire(
							'ยืนยันรถสำเร็จ!',
							'ระบบได้ยืนยันรถสำเร็จแล้ว',
							'success'
						);
					}
				});
			} else {
				record.is_confirm = true;
			}
		},
		handleUpload(e, key) {
			const file = e.file.originFileObj;
			const vue = this;
			var reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = function () {
				vue[key].photo = reader.result;
			};
		},
		formatPrice(price = 0) {
			return price.toLocaleString() + ' บาท';
		},
		withholding_type(car_company, type) {
			if (car_company) {
				const { withholding_id } = car_company;
				if (withholding_id == 1) {
					return type == 'text' ? `(1%)` : 0.01;
				} else if (withholding_id == 2) {
					return type == 'text' ? `(5%)` : 0.05;
				} else {
					return type == 'text' ? `(3%)` : 0.03;
				}
			}
		},
		sum_car_price({ cost, expenses, inc_vat, withholding }, type) {
			var total =
				Number(cost) +
				Number(
					expenses
						.map(({ amount, price }) => amount * price)
						.reduce((a, b) => a + b, 0)
				);
			const vat = inc_vat ? total * 0.07 : 0;
			const withholding_type = this.withholding_type(
				this.create_booking.car_company,
				'number'
			);
			const with_holding = withholding ? total * withholding_type : 0;

			const result = {
				total: total + vat - with_holding,
				vat,
				withholding: with_holding
			};

			return result[type];
		},
		init_gas_refill() {
			this.add_gas_refill_drawer = true;
			this.create_gas_refill.use_gas_station = this.create_gas_refill
				.gas_station_id
				? true
				: false;
		},
		show_message(text, type = 'success') {
			message[type](text);
		},
		filterOption(input, option) {
			return option.label.toUpperCase().indexOf(input.toUpperCase()) >= 0;
		},
		filtered_car(array) {
			const searchTerm = this.search_car;
			var result;
			if (!searchTerm) {
				if (this.assign_car_form && this.assign_car_form.car_id) {
					result = array.filter(({ id }) =>
						id == this.assign_car_form.car_id
							? true
							: !this.create_booking.booking_cars
								.map(({ car_id }) => car_id)
								.includes(id)
					);
				} else {
					result = array.filter(
						({ id }) =>
							!this.create_booking.booking_cars
								.map(({ car_id }) => car_id)
								.includes(id)
					);
				}
			} else {
				result = array
					.filter((item) => {
						return Object.keys(item).some((key) => {
							if (key == 'driver' && item[key]) {
								return (
									String(item[key].firstname)
										.toLowerCase()
										.includes(searchTerm.toLowerCase()) ||
									String(item[key].lastname)
										.toLowerCase()
										.includes(searchTerm.toLowerCase())
								);
							} else {
								return String(item[key])
									.toLowerCase()
									.includes(searchTerm.toLowerCase());
							}
						});
					})
					.filter(
						({ id }) =>
							!this.create_booking.booking_cars
								.map(({ car_id }) => car_id)
								.includes(id)
					);
			}

			result = result.map((e) => {
				const { car_group, car_status_id } = e;
				e.class =
					car_group?.car_type_id == this.preselect_car_type_id
						? car_status_id == 1 || car_status_id == 7
							? '1'
							: '3'
						: '2';
				return e;
			});
			result = result.sort((a, b) => {
				return a.class - b.class;
			});
			result = result.map((e) => {
				delete e.class;
				return e;
			});

			return result;
		},
		init_create_booking() {
			this.create_booking = {
				onsite_photo_show: [],
				security_photo_show: [],
				service_photo_show: [],
				cleaning_photo_show: [],
				assist_driver_photo_show: [],

				gas1_show: [],
				gas2_show: [],
				gas3_show: [],
				gas4_show: [],
				gas5_show: [],
				gas6_show: [],

				booking_car_types: [],
				booking_cars: [],
				extras: [],
				booking_files: [],
				program: [],
				map: [],
				booking_services_files: [],
				stops: [],
				start_location_name: null,
				end_location_name: null,
				client_contact_id: null,
				client_contact: {
					firstname: null
				},
				sale_id:
					localStorage.getItem('role') == 'Sale'
						? JSON.parse(localStorage.getItem('user_profile')).id
						: null
			};
		},
		get_total_seats(index) {
			return `${index + 1} / ${this.create_booking.booking_cars
				.map(({ passenger }) => (passenger ? passenger : 0))
				.reduce((a, b) => a + b, 0)}`;
		},
		get_total_car(index) {
			return `${index + 1} / ${this.create_booking.booking_car_types
				.map(({ quantity }) => (quantity ? quantity : 0))
				.reduce((a, b) => a + b, 0)}`;
		},
		render_log(log) {
			return log.map(({ model_name, created_at }) => {
				return `${dayjs(created_at, 'YYYY-MM-DD HH:mm:ss').format(
					'DD/MM/YYYY HH:mm'
				)}: ${model_name}`;
			});
		},
		upto_log_date(id, amount) {
			SystemService.update_all(
				'BookingCar',
				{ data: { last_update: amount } },
				id
			);
		},
		get_driver_fullname(id) {
			const target = this.driver_list.find(
				({ id: driver_id }) => driver_id === id
			);
			return target ? `${target.firstname} ${target.lastname}` : '';
		},
		edit_list_in_table(obj, instance, drawer, index) {
			obj.index = index;
			this[instance] = obj;
			this[drawer] = true;
		},
		submit_create_pickup_item() {
			const { items } = this.create_pickup_item;
			if (this.create_pickup_item.id) {
				const id = this.create_pickup_item.id;
				SystemService.update_all('PickupItem', { data: { items } }, id);
			} else {
				this.assign_car_form.pickup_items = [
					{
						items,
						booking_id: this.assign_car_form.booking_id,
						booking_car_id: this.assign_car_form.booking_car_id
					}
				];
			}
			this.show_create_pickup_item = false;
			this.create_pickup_item = {};
		},
		submit_create_toll_way() {
			this.create_toll_way.created_at = dayjs(
				this.create_toll_way.datetime
			).format('DD/MM/YYYY HH:mm:ss');
			const { created_at, amount, remark, driver_paid, booking_status } =
				this.create_toll_way;

			if (this.create_toll_way.id) {
				const id = this.create_toll_way.id;
				SystemService.update_all(
					'TollWay',
					{ data: { created_at, amount, remark, driver_paid, booking_status } },
					id
				);
				this.show_create_toll_way = false;
			} else {
				if (this.assign_car_form.toll_ways) {
					if (!this.create_toll_way.index) {
						//check if duplicate
						const duplicate = this.assign_car_form.toll_ways.find((e) => {
							return (
								e.created_at == created_at &&
								e.amount == amount &&
								e.remark == remark &&
								e.driver_paid == driver_paid &&
								e.booking_status == booking_status
							);
						});
						if (!duplicate) {
							this.assign_car_form.toll_ways.push({
								created_at,
								amount,
								remark,
								driver_paid,
								booking_status
							});
						}
					}
				} else {
					this.assign_car_form.toll_ways = [
						{ created_at, amount, remark, driver_paid, booking_status }
					];
				}
			}

			this.create_toll_way = {};
		},
		submit_create_gas_refill() {
			this.create_gas_refill.created_at = dayjs(
				this.create_gas_refill.datetime
			).format('DD/MM/YYYY HH:mm:ss');
			const {
				created_at,
				pay_method_id,
				refill_amount,
				amount,
				remark,
				gas_station_id,
				station_name
			} = this.create_gas_refill;

			if (this.create_gas_refill.id) {
				const id = this.create_gas_refill.id;
				SystemService.update_all(
					'GasRefill',
					{
						data: {
							created_at,
							pay_method_id,
							refill_amount,
							amount,
							remark,
							gas_station_id,
							station_name
						}
					},
					id
				);
				this.add_gas_refill_drawer = false;
			} else {
				if (this.assign_car_form.gas_refills) {
					if (!this.create_gas_refill.index) {
						this.assign_car_form.gas_refills.push({
							created_at,
							pay_method_id,
							refill_amount,
							amount,
							remark,
							gas_station_id,
							station_name,
							photo_1_show: [],
							photo_2_show: [],
							photo_3_show: [],
							photo_4_show: [],
							photo_5_show: [],
							photo_6_show: [],
							photo_7_show: [],
							photo_8_show: [],
							booking_car_id: this.assign_car_form.booking_car_id
						});
					}
				} else {
					this.assign_car_form.gas_refills = [
						{
							created_at,
							pay_method_id,
							refill_amount,
							amount,
							remark,
							gas_station_id,
							station_name,
							photo_1_show: [],
							photo_2_show: [],
							photo_3_show: [],
							photo_4_show: [],
							photo_5_show: [],
							photo_6_show: [],
							photo_7_show: [],
							photo_8_show: [],
							booking_car_id: this.assign_car_form.booking_car_id
						}
					];
				}
			}
			this.create_gas_refill = {};
		},
		async remove_list_from_table(index, key, modelname) {
			if (this.assign_car_form[key][index].id) {
				Swal.fire({
					title: 'คุณต้องการลบรายการนี้ใช่หรือไม่?',
					text: 'คุณจะไม่สามารถกู้คืนรายการนี้ได้หากลบแล้ว!',
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'ใช่, ลบเดี๋ยวนี้!',
					cancelButtonText: 'ยกเลิก'
				}).then((result) => {
					if (result.isConfirmed) {
						SystemService.delete_all(
							modelname,
							this.assign_car_form[key][index].id
						).then((res) => {
							if (res) {
								this.refresh_data();
								Swal.fire('ลบเรียบร้อย!', 'รายการของคุณถูกลบแล้ว', 'success');
								this.assign_car_form[key].splice(index, 1);
							}
						});
					}
				});
			} else {
				this.assign_car_form[key].splice(index, 1);
			}
		},
		submit_drawer(form_model, array_name, drawer_name, modelname) {
			if (this[form_model].id) {
				const id = this[form_model].id;
				SystemService.update_all(modelname, { data: this[form_model] }, id);
				this[form_model] = {};
			} else {
				if (this.assign_car_form[array_name]) {
					if (!this[form_model].index) {
						this.assign_car_form[array_name].push(this[form_model]);
					}
				} else {
					this.assign_car_form[array_name] = [this[form_model]];
				}

				this[form_model] = {};
			}

			this[drawer_name] = false;
		},
		async update_car_status_id(status_id, car_id) {
			return await SystemService.update_all(
				'Car',
				{ data: { car_status_id: status_id } },
				car_id
			);
		},
		async adjust_car_pos(order_pos, car_id, direction) {
			const new_pos = direction === 'up' ? order_pos - 1 : order_pos + 1;
			SystemService.update_all(
				'BookingCar',
				{ data: { order_pos: new_pos } },
				car_id
			).then(async (res) => {
				if (res) {
					this.refresh_data();
					Swal.fire(
						'ปรับเปลี่ยนลำดับสำเร็จ!',
						'ระบบได้ปรับเปลี่ยนลำดับสำเร็จแล้ว',
						'success'
					);
				}
			});
		},
		async select_order_pos(order_pos, car_id) {
			SystemService.update_all(
				'BookingCar',
				{ data: { order_pos } },
				car_id
			).then(async (res) => {
				if (res) {
					this.refresh_data();
					Swal.fire(
						'ปรับเปลี่ยนลำดับสำเร็จ!',
						'ระบบได้ปรับเปลี่ยนลำดับสำเร็จแล้ว',
						'success'
					);
				}
			});
		},
		async duplicate(obj) {
			if (!this.car_list.length) {
				this.$message.loading({
					content: 'กำลังตรวจสอบรถ',
					duration: 0,
					key: 'add_car_to_booking'
				});
				this.preselect_car_type_id = null;
				this.car_list = await SystemService.get_all('Car');
				this.$message.success({
					content: `เลือกรถ ${this.filtered_cars[0].plate_no} สำหรับรายการซ้ำ`,
					duration: 1,
					key: 'add_car_to_booking'
				});
			}
			const {
				approve_status,
				booking_id,
				car_id,
				driver_cost,
				driver_id,
				remark,
				rental_price,
				time_start,
				time_end
			} = obj;
			const body = {
				approve_status,
				booking_id,
				car_id: this.filtered_cars[0].id,
				driver_cost,
				driver_id,
				remark,
				rental_price,
				time_start,
				time_end
			};

			SystemService.create_all('BookingCar', { data: [body] }).then(
				async (res) => {
					if (res) {
						this.refresh_data();
						Swal.fire(
							'สร้างรายการซ้ำสำเร็จ!',
							'ระบบได้สร้างรายการซ้ำสำเร็จแล้ว',
							'success'
						);
					}
				}
			);
		},
		compare_timeGreater(time, array) {
			const lastupdate = array.map(({ created_at }) => created_at);
			//if time is greater than any of lastupdate and no any of lastupdate is greater than time
			return array
				? lastupdate.some((lastupdate) => dayjs(time).isAfter(lastupdate))
				: false;
		},
		compare_time(timeA, timeB, max, unit) {
			const timeA_moment = dayjs(timeA);
			const timeB_moment = dayjs(timeB);
			const diff = timeB_moment.diff(timeA_moment, unit);
			return diff > max;
		},
		async sync_booking_datetime(booking_car) {
			const [time_start, time_end] = this.create_booking.datetime;
			await SystemService.update_all(
				'Booking',
				{ data: { time_start, time_end } },
				this.create_booking.id
			);
			SystemService.update_all(
				'BookingCar',
				{ data: { time_start, time_end } },
				booking_car.id
			).then(async (res) => {
				if (res) {
					this.refresh_data();
					Swal.fire(
						'แก้ไขเวลาสำเร็จ!',
						'ระบบได้แก้ไขเวลาสำเร็จแล้ว',
						'success'
					);
				}
			});
		},
		edit_booking_car(obj, id) {
			this.assign_car(obj, { booking_car_id: id });
			const { time_end, time_start } = obj;
			console.log(obj);

			this.assign_car_form.plate_no = obj.car.plate_no;
			this.assign_car_form.car_id = obj.car.id;
			if (time_start != 'Invalid date' && time_end != 'Invalid date') {
				this.assign_car_form.datetime = [dayjs(time_start), dayjs(time_end)];
			} else {
				this.assign_car_form.datetime = this.create_booking.datetime;
			}
			this.assign_car_form.car_status_id = obj.car.car_status_id;
		},
		edit_booking_expense(index, id) {
			if (id) {
				this.extra = this.create_booking.extras.find(
					(extra) => extra.id === id
				);
				this.extra.name = this.extra_list.find(
					({ id }) => id == this.extra.extra_id
				)?.name;
				this.add_booking_expense();
			} else {
				this.extra = this.create_booking.extras[index];
				this.extra.name = this.extra_list.find(
					({ id }) => id == this.extra.extra_id
				)?.name;
				this.add_booking_expense();
			}
		},
		remove_booking_car_types_expense(index, id) {
			if (id) {
				Swal.fire({
					title: 'คุณต้องการลบรายการนี้ใช่หรือไม่?',
					text: 'คุณจะไม่สามารถกู้คืนรายการนี้ได้หากลบแล้ว!',
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'ใช่, ลบเดี๋ยวนี้!',
					cancelButtonText: 'ยกเลิก'
				}).then((result) => {
					if (result.isConfirmed) {
						SystemService.delete_all('BookingCarTypeExpense', id).then(
							(res) => {
								if (res) {
									Swal.fire('ลบแล้ว!', 'รายการของคุณถูกลบแล้ว', 'success');
									this.booking_car_types.expenses.splice(index, 1);
									this.refresh_data();
								}
							}
						);
					}
				});
			} else {
				this.booking_car_types.expenses.splice(index, 1);
			}
		},
		remove_booking_car(id, { booking_car_type_id, is_last }) {
			Swal.fire({
				title: 'คุณต้องการลบรายการนี้ใช่หรือไม่?',
				text: 'คุณจะไม่สามารถกู้คืนรายการนี้ได้หากลบแล้ว!',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'ใช่, ลบเดี๋ยวนี้!',
				cancelButtonText: 'ยกเลิก'
			}).then((result) => {
				if (result.isConfirmed) {
					SystemService.delete_all('BookingCar', id).then((res) => {
						if (res) {
							if (is_last) {
								this.remove_booking_car_type_id(booking_car_type_id);
							} else {
								this.refresh_data();
								Swal.fire('ลบเรียบร้อย!', 'รายการของคุณถูกลบแล้ว', 'success');
							}
						}
					});
				}
			});
		},
		cancel_edit_stop() {
			this.add_stop = {};
			this.on_edit_stop = null;
			document.getElementById('map').value = null;
		},
		save_edit_stop(index) {
			this.assign_car_form.stops[index] = this.add_stop;
			if (this.add_stop.id) {
				SystemService.update_all(
					'Stop',
					{ data: this.add_stop },
					this.add_stop.id
				).then((res) => {
					if (res) {
						this.refresh_data();
						Swal.fire(
							'แก้ไขสำเร็จ!',
							'ระบบได้แก้ไขข้อมูลสำเร็จแล้ว',
							'success'
						);
					}
				});
			}
			this.add_stop = {};
			document.getElementById('map').value = null;
			this.on_edit_stop = null;
		},
		edit_stop_from_table(index) {
			this.on_edit_stop = index;
			this.add_stop = this.assign_car_form.stops[index];
			this.add_stop.expected_timestamp = dayjs(
				this.add_stop.expected_timestamp
			);
			document.getElementById('map').value = this.add_stop.name;
		},
		remove_stop_from_table(index, id) {
			this.assign_car_form.stops.splice(index, 1);
			if (id) {
				SystemService.delete_all('Stop', id);
			}
		},
		add_stop_to_table(e) {
			if (this.assign_car_form.stops) {
				this.assign_car_form.stops.push(this.add_stop);
			} else {
				this.assign_car_form.stops = [this.add_stop];
			}
			this.add_stop = {};
			document.getElementById('map').value = null;
		},
		async select_stop_name(e, target, is_car) {
			const { latitude, longitude } = e;
			if (target && !is_car) {
				this.create_booking[target] = document
					.getElementById(target)
					.value.split(',')[0];
			} else if (is_car) {
				this.booking_car_types[target] = document
					.getElementById(target)
					.value.split(',')[0];
			} else {
				this.add_stop.lat = latitude;
				this.add_stop.lng = longitude;
				this.add_stop.name = document.getElementById('map').value.split(',')[0];
			}
		},
		getPrice() {
			return (
				this.create_booking.extras
					.map(({ price, amount }) => price * amount)
					.reduce((a, b) => a + b, 0) +
				this.create_booking.booking_car_types
					.map(({ price, quantity }) => price * quantity)
					.reduce((a, b) => a + b, 0)
			);
		},
		cal_price(e = 1) {
			console.log({cal_price: e});
			if (e == null) {
				e = 1
			}
			if (e == 1) {
				this.form.total_price = this.getPrice();
			} else if (e == 3) {
				this.form.total_price = Number(
					((this.getPrice() * this.price_percent) / 100).toFixed(2)
				);
			}
		},
		async submit_assign_car() {
			if (!this.from_booking) {
				this.loading = true;
				const booking_id = this.create_booking.id;
				const {
					datetime,
					driver_id,
					stops,
					car_id,
					car_status_id,
					driver_cost,
					rental_price,
					guides,
					gas_refills,
					toll_ways,
					remark,
					expenses,
					pickup_items,
					deposit
				} = this.assign_car_form;
				const body = {
					booking_id,
					time_start: datetime[0].format('YYYY-MM-DD HH:mm:ss'),
					time_end: datetime[1].format('YYYY-MM-DD HH:mm:ss'),
					driver_cost,
					rental_price,
					driver_id,
					car_id,
					stops,
					guides,
					gas_refills: gas_refills
						? gas_refills.map(
							({
								id,
								amount,
								booking_car_id,
								created_at,
								gas_station_id,
								pay_method_id,
								refill_amount,
								remark,
								station_name
							}) => ({
								id,
								amount,
								booking_car_id,
								created_at,
								gas_station_id,
								pay_method_id,
								refill_amount,
								remark,
								station_name
							})
						)
						: [],
					toll_ways,
					expenses: expenses
						? expenses.map(
							({ id, amount, driver_paid, expense_id, pay_method_id }) => ({
								id,
								amount,
								driver_paid,
								expense_id,
								pay_method_id
							})
						)
						: [],
					remark,
					pickup_items,
					deposit
				};

				var res;
				const booking_car_id = this.assign_car_form.booking_car_id;
				if (booking_car_id) {
					res = await SystemService.update_all(
						'BookingCar',
						{ data: body },
						booking_car_id
					);
				} else {
					res = await SystemService.create_all('BookingCar', { data: [body] });
				}
				if (res) {
					this.assign_car_drawer = false;
					this.assign_car_form = {};
					Swal.fire({
						icon: 'success',
						title: 'บันทึกข้อมูลสำเร็จ',
						showConfirmButton: false,
						timer: 1500
					});
					this.refresh_data();
				}
			} else {
				const {
					datetime,
					driver_id,
					stops,
					car_id,
					car_status_id,
					driver_cost,
					rental_price,
					guides,
					gas_refills,
					toll_ways,
					remark,
					expenses,
					plate_no,
					car_no,
					pickup_items
				} = this.assign_car_form;

				if (!this.create_booking.booking_cars.find((e) => e.car_id == car_id)) {
					this.create_booking.booking_cars.push({
						time_start: datetime[0].format('YYYY-MM-DD HH:mm:ss'),
						time_end: datetime[1].format('YYYY-MM-DD HH:mm:ss'),
						driver_cost,
						rental_price,
						driver_id,
						car_id,
						stops,
						car: { car_status_id, car_no, plate_no },
						guides,
						gas_refills,
						toll_ways,
						expenses,
						remark,
						pickup_items
					});
				}

				this.assign_car_drawer = false;
				this.assign_car_form = {};
				Swal.fire({
					icon: 'success',
					title: 'บันทึกข้อมูลสำเร็จ',
					showConfirmButton: false,
					timer: 1500
				});
			}
			this.assign_car_drawer = false;
		},
		changeCar(id) {
			const target_carObj = this.car_list.find((car) => id == car.id);
			target_carObj.car_id = target_carObj.id;

			this.assign_car_form.car_id = target_carObj.id;
			this.assign_car_form.plate_no = target_carObj.plate_no;
		},
		async assign_car(booking_car, { status_id, booking_car_id }) {
			console.log(booking_car);
			const car_id = booking_car_id ? booking_car.car_id : booking_car.id;
			// delete booking_car.id

			console.log({ booking_car_id: booking_car_id, car_id: car_id });
			this.add_car_drawer = false;

			this.car_list = await SystemService.get_all('Car');

			if (!booking_car.driver_id) booking_car.driver_id = null;
			if (status_id == 1 || status_id == 7) {
				this.assign_car_drawer = true;
				this.assign_car_form = booking_car;
				this.assign_car_form.car_id = car_id;
			} else if (status_id) {
				message.info('รถไม่สามารถใช้งานได้', 0.5);
			} else {
				this.assign_car_drawer = true;
				this.assign_car_form = booking_car;
				this.assign_car_form.car_id = car_id;

				if (booking_car_id) {
					this.assign_car_form.booking_car_id = booking_car_id;
				} else {
					booking_car.gas_refills = [];
				}
			}

			if (!this.assign_car_form.remark) {
				const bookType = this.booking_types.find(
					({ id }) => id == this.create_booking.booking_type_id
				).name;
				this.assign_car_form.remark = `ค่าขนส่งโดยสารปรับอากาศ\nเดินทาง\n${bookType}\nวันที่`;
			}

			if (booking_car.car)
				this.assign_car_form.plate_no = booking_car.car.plate_no;
			if (this.create_booking.datetime?.length)
				this.assign_car_form.datetime = [
					this.create_booking.datetime[0],
					this.create_booking.datetime[1]
				];
			if (!this.assign_car_form.driver_cost) this.calculate_driver_cost();
		},
		render_time(datetime) {
			return dayjs(datetime).format('DD/MM/YYYY HH:mm');
		},
		async add_car_to_booking() {
			this.$message.loading({
				content: 'กำลังตรวจสอบรถ',
				duration: 0,
				key: 'add_car_to_booking'
			});
			this.preselect_car_type_id = null;
			this.car_list = await SystemService.get_all('Car');
			this.$message.success({
				content: `พบรถว่าง ${this.car_list.length} คัน`,
				duration: 1,
				key: 'add_car_to_booking'
			});
			this.add_car_drawer = true;
			this.preselect_car_type_id =
				this.create_booking.booking_car_types[0]?.car_type_id;
		},
		filtered_booking_list(array) {
			return array
		},
		clear_search() {
			this.search_input = null;
			this.search_progress = null;
			this.datetime = null;
			this.search_booking_type_id = null;
			this.preselect_car_type_id = null;
			this.init_all_bookings(	)
		},
		async booking_progress() {
			await BookingService.booking_progress(this.selected_quotation.id).then(
				() => {
					Swal.fire({
						icon: 'success',
						title: 'ดำเนินการเสร็จสิ้น',
						showConfirmButton: false,
						timer: 1500
					});
					SystemService.update_all(
						'Booking',
						{ data: { billing_status: 1 } },
						this.selected_quotation.id
					);
					this.refresh_data();
				}
			);
		},
		render_date(datetime) {
			return dayjs(datetime).format('DD/MM/YYYY HH:mm');
		},
		async submit_create_booking() {
			message.loading({
				content: 'กำลังบันทึกข้อมูล...',
				key: 'create_booking'
			});
			if (!this.create_booking.program && !this.create_booking.map) {
				this.create_booking.program = [];
				this.create_booking.map = [];
			}
			const photo_pool = [
				...this.create_booking.booking_files,
				...this.create_booking.program,
				...this.create_booking.map
			];
			if (photo_pool.length) {
				for (var i = 0; i < photo_pool.length; i++) {
					const target = photo_pool[i];
					this.create_booking['photo_' + (i + 1)] = await this.getBase64(
						target.originFileObj
					);
				}
			}

			const { datetime } = this.create_booking;
			this.create_booking.time_start = datetime[0].format(
				'YYYY-MM-DD HH:mm:ss'
			);
			this.create_booking.time_end = datetime[1].format('YYYY-MM-DD HH:mm:ss');
			this.create_booking.company_id = JSON.parse(
				localStorage.getItem('companyData')
			).id;

			const {
				booking_car_types,
				extras,
				photo_1,
				photo_2,
				photo_3,
				photo_4,
				photo_5,
				client_id,
				client_contact_id,
				company_id,
				time_start,
				time_end,
				discount,
				full_day_amount,
				person_qty,
				booking_type_id,
				sale_id,
				flight_pick,
				flight_drop,
				stops,
				trip_id,
				remark,
				booking_cars,
				inc_vat,
				withholding,
				start_location_name,
				end_location_name,
				car_company_id,
				qt_setting
			} = this.create_booking;

			const booking_body = {
				booking_car_types,
				extras,
				photo_1,
				photo_2,
				photo_3,
				photo_4,
				photo_5,
				client_id,
				car_company_id,
				client_contact_id,
				company_id,
				time_start,
				time_end,
				discount,
				full_day_amount,
				person_qty,
				booking_type_id,
				sale_id,
				flight_pick,
				flight_drop,
				stops,
				trip_id,
				remark,
				booking_cars,
				progress: this.from_booking ? 2 : null,
				inc_vat,
				withholding,
				start_location_name,
				end_location_name,
				qt_setting
			};

			if (this.on_booking == false && this.render_type != 'add') {
				booking_body.qt_updated_at = dayjs().format('YYYY-MM-DD HH:mm:ss');
			}

			if (qt_setting) {
				const new_json = JSON.parse(qt_setting);
				new_json.price_percent = this.price_percent
				new_json.total_price = this.form.total_price;
				new_json.pay_type = this.form.pay_type;
				new_json.pay_extend = this.form.pay_extend;
				new_json.discount = this.form.discount;

				console.log(new_json);

				booking_body.qt_setting = JSON.stringify(new_json);
				this.cal_price(this.form.pay_type)
			} else {
				const new_json = {}
				new_json.price_percent = this.price_percent
				new_json.total_price = this.form.total_price;
				new_json.pay_type = this.form.pay_type;
				new_json.pay_extend = this.form.pay_extend;
				new_json.discount = this.form.discount;

				console.log(new_json);

				booking_body.qt_setting = JSON.stringify(new_json);
				this.cal_price(this.form.pay_type)
			}

			if (this.render_type == 'add') {
				await SystemService.create_all('Booking', {
					data: [booking_body]
				}).then((res) => {
					if (res) {
						message.success({
							content: 'บันทึกข้อมูลสำเร็จ',
							key: 'create_booking'
						});
						this.refresh_data();

						const taget_url = `/app/${this.from_booking || this.on_booking ? 'booking' : 'quotation'
							}/all`;
						this.$router.push(taget_url);

						// this.$router.go(-1)
					}
				});
			} else {
				await SystemService.update_all(
					'Booking',
					{ data: booking_body },
					this.create_booking.id
				).then(async (res) => {
					if (res) {
						message.success({
							content: 'บันทึกข้อมูลสำเร็จ',
							key: 'create_booking'
						});

						const taget_url = `/app/${this.from_booking || this.on_booking ? 'booking' : 'quotation'
							}/all`;
						this.$router.push(taget_url);

						// this.$router.go(-1)
						this.refresh_data();
					}
				});
			}
		},
		async upload_files(e, key, target, id) {
			if (target && !id) {
				this.getBase64(e.file.originFileObj, key, target);
			} else if (id) {
				this.getBase64(e.file.originFileObj, key, target, id);
			} else {
				this.getBase64(e.file.originFileObj, key);
			}

			return `https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6`;
		},
		async remove_uploaded_file({ uid }) {
			this.create_booking[uid] = '';
		},
		async getBase64(file, key, direct, id) {
			try {
				if (key && !direct) {
					const vue = this;
					var reader = new FileReader();
					reader.readAsDataURL(file);
					reader.onload = function () {
						vue[key] = reader.result;
					};
				} else if (direct && !id) {
					var reader = new FileReader();
					reader.readAsDataURL(file);
					reader.onload = function () {
						direct[key] = reader.result;
					};
				} else if (id) {
					var reader = new FileReader();
					reader.readAsDataURL(file);
					reader.onload = function () {
						SystemService.update_all(
							id.model,
							{ data: { [key]: reader.result } },
							id.id
						);
					};
				} else {
					return await new Promise((resolve, reject) => {
						const reader = new FileReader();
						reader.readAsDataURL(file);
						reader.onload = () => resolve(reader.result);
						reader.onerror = (error) => reject(error);
					});
				}
			} catch (err) { }
		},
		add_booking_expense() {
			this.show_add_booking_extra = true;
		},
		remove_booking_expense(index, id) {
			if (id) {
				Swal.fire({
					title: 'คุณต้องการลบรายการนี้ใช่หรือไม่?',
					text: 'คุณจะไม่สามารถกู้คืนรายการนี้ได้หากลบแล้ว!',
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'ใช่, ลบเดี๋ยวนี้!',
					cancelButtonText: 'ยกเลิก'
				}).then((result) => {
					if (result.isConfirmed) {
						SystemService.delete_all('BookingExtra', id).then((res) => {
							if (res) {
								Swal.fire('ลบแล้ว!', 'รายการของคุณถูกลบแล้ว', 'success');
								this.create_booking.extras.splice(index, 1);
								this.refresh_data();
							}
						});
					}
				});
			} else {
				this.create_booking.extras.splice(index, 1);
			}
		},
		remove_booking_car_type_id(id) {
			Swal.fire({
				title: 'คุณต้องการลบประเภทรถด้วยหรือไม่?',
				text: 'รถที่เลือกเป็นรายการสุดท้ายของประเภทรถ',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'ใช่, ลบเดี๋ยวนี้!',
				cancelButtonText: 'ยกเลิก'
			}).then((result) => {
				if (result.isConfirmed) {
					SystemService.delete_all('BookingCarType', id).then((res) => {
						if (res) {
							Swal.fire('ลบแล้ว!', 'รายการของคุณถูกลบแล้ว', 'success');
							this.refresh_data();
						}
					});
				} else {
					this.refresh_data();
				}
			});
		},
		async sync_booking_car_with_quotation(booking_car_type) {
			const [qt_time_start, qt_time_end] = this.create_booking.datetime;
			var time_start = dayjs(qt_time_start).format('YYYY-MM-DD HH:mm:ss');
			var time_end = dayjs(qt_time_end).format('YYYY-MM-DD HH:mm:ss');

			await SystemService.update_all(
				'Booking',
				{ data: { time_start, time_end } },
				this.create_booking.id
			);
			SystemService.update_all(
				'BookingCarType',
				{
					data: {
						time_start,
						time_end
					}
				},
				booking_car_type.id
			).then(async (res) => {
				if (res) {
					this.refresh_data();
					Swal.fire(
						'แก้ไขเวลาสำเร็จ!',
						'ระบบได้แก้ไขเวลาสำเร็จแล้ว',
						'success'
					);
				}
			});
		},
		remove_booking_car_types(index) {
			const { id } = this.create_booking.booking_car_types[index];
			if (id) {
				SystemService.delete_all('BookingCarType', id).then((res) => {
					if (res) {
						this.create_booking.booking_car_types.splice(index, 1);
						this.refresh_data();
					}
				});
			} else {
				this.create_booking.booking_car_types.splice(index, 1);
			}
		},
		booking_car_re_order(array) {
			const new_order = [];
			const sortable =
				array.filter(({ stops }) => stops.length > 0).length == array.length;
			if (sortable) {
				const sorted = array.sort(
					(a, b) =>
						new Date(
							a.stops[
								a.stops.indexOf(({ stop_type_id }) => stop_type_id == 1)
							].expected_timestamp
						) -
						new Date(
							b.stops[
								b.stops.indexOf(({ stop_type_id }) => stop_type_id == 1)
							].expected_timestamp
						)
				);
				for (let i = 0; i < sorted.length; i++) {
					const { id } = sorted[i];
					SystemService.update_all(
						'BookingCar',
						{ data: { order_pos: i + 1 } },
						id
					).then((res) => {
						if (res) {
							this.$message.success(`รายการที่ ${i + 1} ถูกเรียงลำดับใหม่แล้ว`);
						}
					});
				}
				this.refresh_data();
			} else {
				this.$message.error(
					'ไม่สามารถเรียงลำดับได้ เนื่องจากมีรถบางคันยังไม่มีจุดหมาย'
				);
			}
		},
		submit_add_booking_car_types(e) {
			const { datetime, price, quantity, expenses, id } =
				this.booking_car_types;
			this.booking_car_types.time_start = datetime[0].format(
				'YYYY-MM-DD HH:mm:ss'
			);
			this.booking_car_types.time_end = datetime[1].format(
				'YYYY-MM-DD HH:mm:ss'
			);

			this.booking_car_types.cost =
				quantity * price +
				(expenses
					? expenses
						.map(({ price, amount }) => price * amount)
						.reduce((a, b) => a + b, 0)
					: 0);

			if (id) {
				Swal.fire({
					title: 'คุณต้องการแก้ไขรายการนี้ใช่หรือไม่?',
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'ใช่, แก้ไขเดี๋ยวนี้!',
					cancelButtonText: 'ยกเลิก'
				}).then((result) => {
					if (result.isConfirmed) {
						SystemService.update_all(
							'BookingCarType',
							{ data: this.booking_car_types },
							id
						).then((res) => {
							if (res) {
								Swal.fire('แก้ไขแล้ว!', 'รายการของคุณถูกแก้ไขแล้ว', 'success');
								this.booking_car_types = {
									expenses: []
								};
								this.refresh_data();
							}
						});
					}
				});
			} else {
				const target = this.create_booking.booking_car_types.find(
					({ car_group_id, start_point, end_point, price }) => {
						return (
							car_group_id == this.booking_car_types.car_group_id &&
							start_point == this.booking_car_types.start_point &&
							end_point == this.booking_car_types.end_point &&
							price == this.booking_car_types.price
						);
					}
				);
				if (target) {
					this.create_booking.booking_car_types[
						this.create_booking.booking_car_types.findIndex(
							({ car_group_id }) =>
								car_group_id == this.booking_car_types.car_group_id
						)
					] = this.booking_car_types;
					this.show_add_booking_car_types = false;
					message.success({
						content: 'แก้ไขรายการสำเร็จ',
						key: 'create_booking'
					});
				} else {
					this.show_add_booking_car_types = false;
					message.success({
						content: 'เพิ่มรายการสำเร็จ',
						key: 'create_booking'
					});
					this.create_booking.booking_car_types.push(this.booking_car_types);
					// this.create_booking_cars.push(this.booking_car_types)
					this.booking_car_types = {
						expenses: []
					};
				}
			}
		},
		submit_extra() {
			this.extra.name = this.extra_list.find(
				({ id }) => id == this.extra.extra_id
			)?.name;
			const { id, name, price, amount, extra_id } = this.extra;
			if (this.create_booking.extras) {
				//check if not duplicate
				const target = this.create_booking.extras.find(
					({ price, amount, extra_id }) => {
						return (
							price == this.extra.price &&
							amount == this.extra.amount &&
							extra_id == this.extra.extra_id
						);
					}
				);
				if (!target) {
					this.create_booking.extras.push({ name, price, amount, extra_id });
				}
				if (id) {
					SystemService.update_all('BookingExtra', { data: this.extra }, id);
				}
			} else {
				this.create_booking.extras = [{ name, price, amount, extra_id }];
			}
			this.extra = {};
			this.show_add_booking_extra = false;
		},
		submit_add_expense() {
			this.add_expense.name = this.expense_list.find(
				({ id }) => id == this.add_expense.expense_id
			)?.name;
			const { name, price, amount, expense_id } = this.add_expense;
			this.booking_car_types.expenses.push({ name, price, amount, expense_id });
			this.add_expense = {};
		},
		add_booking_car_types(index) {
			this.show_add_booking_car_types = true;
			if (index != null) {
				setTimeout(() => {
					this.booking_car_types = this.create_booking.booking_car_types[index];

					const { time_start, time_end, start_point, end_point } =
						this.booking_car_types;
					this.booking_car_types.datetime = [
						dayjs(time_start),
						dayjs(time_end)
					];

					if (!this.booking_car_types.remark) {
						const bookType = this.booking_types.find(
							({ id }) => id == this.create_booking.booking_type_id
						).name;
						this.booking_car_types.remark = `ค่าขนส่งโดยสารปรับอากาศ\nเดินทาง\n${bookType}\nวันที่ ${this.booking_car_types.datetime
							.map((e) => e.locale('th').format('DD MMMM BBBB HH:mm'))
							.join(' - ')})}`;
					}

					if (start_point) {
						document.getElementById('start_point').value = start_point;
					}
					if (end_point) {
						document.getElementById('end_point').value = end_point;
					}
				}, 300);
			} else {
				this.booking_car_types = {
					expenses: [],
					price: 0
				};
				setTimeout(() => {
					const {
						time_start,
						time_end,
						datetime,
						start_location_name,
						end_location_name
					} = this.create_booking;
					this.booking_car_types.datetime = datetime
						? datetime
						: [dayjs(time_start), dayjs(time_end)];
					if (!this.booking_car_types.remark) {
						const bookType = this.booking_types.find(
							({ id }) => id == this.create_booking.booking_type_id
						).name;
						this.booking_car_types.remark = `ค่าขนส่งโดยสารปรับอากาศ\nเดินทาง\n${bookType}\nวันที่ ${this.booking_car_types.datetime
							.map((e) => e.locale('th').format('DD MMMM BBBB'))
							.join(' - ')}`;
					}

					if (!this.booking_car_types.remark) {
						this.booking_car_types.remark = `ค่าขนส่งโดยสารปรับอากาศ\nเดินทาง\nวันที่`;
					}

					if (start_location_name) {
						document.getElementById('start_point').value = start_location_name;
					}
					if (end_location_name) {
						document.getElementById('end_point').value = end_location_name;
					}
				}, 300);
			}
		},
		async remove_booking(id, quotation_no) {
			await Swal.fire({
				icon: 'error',
				title: 'ลบใบเสนอราคา',
				text: `คุณต้องการลบใบเสนอราคาเลขที่ ${quotation_no} หรือไม่`,
				showCancelButton: true,
				confirmButtonText: 'ตกลง',
				cancelButtonText: 'ยกเลิก'
			}).then(async ({ isConfirmed }) => {
				if (isConfirmed) {
					await BookingService.delete_booking(id).then((res) => {
						if (res) {
							Swal.fire({
								icon: 'success',
								title: 'สำเร็จ',
								text: 'ลบข้อมูลสำเร็จ',
								showConfirmButton: false,
								timer: 1500
							});
							this.refresh_data();
						}
					});
				}
			});
		},
		async duplicate_booking(id) {
			message.loading({ content: 'กำลังโหลด...', key: 'loading' });
			const data = await BookingService.duplicate_booking(id);
			await this.refresh_data();
			message.success({ content: 'โหลดข้อมูลสำเร็จ', key: 'loading' });
		},
		edit_quotation(id) {
			this.$router.push(`/app/quotation/${id}`);
			this.init_edit_mode();
		},
		create_export_close() {
			this.booking_detail = null;
			this.show_export_modal = false;
			this.refresh_data();
		},
		open_create_invoice(quotation, alter_title) {
			SystemService.update_all(
				'Booking',
				{ data: { progress: 1 } },
				quotation.id
			);
			this.show_export_modal = true;
			this.booking_detail = quotation;
			this.booking_detail.alter_title = alter_title;
		},
		select_row(selectedRowKeys) {
			this.selected_quotation = selectedRowKeys;
		},
		async init_all_bookings() {
			this.loading = true;

			const timeRange = this.datetime
			const searchBody = {
				search: this.search_input,
				booking_type_id: this.search_booking_type_id
			}

			if (timeRange) {
				searchBody.time_start = dayjs(timeRange[0]).format('YYYY-MM-DD HH:mm:ss')
				searchBody.time_end = dayjs(timeRange[1]).format('YYYY-MM-DD HH:mm:ss')
			}

			const datas = await BookingService.get_bookings(this.pagination.onpage, this.pagination.perpage, this.search_progress, searchBody);
			this.pagination = {
				total: datas.total,
				onpage: datas.page,
				perpage: datas.perpaperPagege
			};
			this.booking_list = datas.data;
			this.loading = false;
		},
		async init_create_booking_options() {
			this.loading = true;
			this.show_create_client = false;
			this.show_create_company = false;

			const [
				client_company_list,
				client_contact_list,
				tour_types_list,
				company_trip_list,
				car_type_list,
				car_group_list,
				expense_list,
				sales_list,
				booking_types,
				driver_list,
				stop_type_list,
				car_status_list,
				guide_list,
				gas_station_list,
				pay_method_list,
				extra_list,
				tour_list,
				driver_costs_list,
				booking_status_list,
				car_company_list,
				remarks_list
			] = await Promise.all([
				SystemService.get_all('Client'),
				SystemService.get_all('ClientContact'),
				SystemService.get_all('TourType'),
				SystemService.get_all('Trip'),
				SystemService.get_all('CarType'),
				SystemService.get_all('CarGroup'),
				SystemService.get_all('Expense'),
				SystemService.get_all('Sale'),
				SystemService.get_all('BookingType'),
				SystemService.get_all('Driver'),
				SystemService.get_all('StopType'),
				SystemService.get_all('CarStatus'),
				SystemService.get_all('Guide'),
				SystemService.get_all('GasStation'),
				SystemService.get_all('PayMethod'),
				SystemService.get_all('Extra'),
				SystemService.get_all('Tour'),
				SystemService.get_all('DriverCost'),
				SystemService.get_all('BookingStatusText'),
				SystemService.get_all('CarCompany'),
				SystemService.get_all('BookingRemark')
			]);

			this.car_company_list = car_company_list;
			this.client_company_list = client_company_list;
			this.client_contact_list = client_contact_list;
			this.tour_types_list = tour_types_list;
			this.company_trip_list = company_trip_list;
			this.car_type_list = car_type_list;
			this.car_group_list = car_group_list;
			this.expense_list = expense_list;
			this.sales_list = sales_list;
			this.booking_types = booking_types;
			this.driver_list = driver_list;
			this.stop_type_list = stop_type_list;
			this.car_status_list = car_status_list;
			this.guide_list = guide_list;
			this.gas_station_list = gas_station_list;
			this.pay_method_list = pay_method_list;
			this.extra_list = extra_list;
			this.tour_list = tour_list;
			this.driver_costs_list = driver_costs_list;
			this.booking_status_list = booking_status_list;
			this.remarks_list = remarks_list;

			if (this.create_booking.client_id) {
				this.select_client();
			}
			this.loading = false;
		},
		async select_client() {
			this.loading = true;
			this.create_booking.client_branch_id = null;
			const target = await SystemService.get_id(
				'Client',
				this.create_booking.client_id
			).then((res) => {
				this.loading = false;
				return res;
			});
			const {
				address,
				branches,
				contacts,
				tour_type_id,
				tax_id,
				email,
				phone_no
			} = target;

			this.client_branches_list = branches;
			this.client_contact_list = contacts.filter(
				({ client_id }) => client_id == this.create_booking.client_id
			);

			this.create_booking.address = address;
			this.create_booking.tour_type_id = tour_type_id;
			this.create_booking.client_contact_id = this.client_contact_list
				.map(({ id }) => id)
				.includes(this.create_booking.client_contact_id)
				? this.create_booking.client_contact_id
				: null;
			this.create_booking.tax_id = tax_id;
			this.create_booking.email = email;
			this.create_booking.phone_no = phone_no;
			this.create_booking.tourname;
		},
		async select_branch() {
			this.loading = true;
			const target = await SystemService.get_id(
				'Client',
				this.create_booking.client_id
			).then((res) => {
				this.loading = false;
				return res;
			});
			const {
				address,
				branches,
				contacts,
				tour_type_id,
				tax_id,
				email,
				phone_no
			} = target;

			if (this.create_booking.client_branch_id) {
				this.client_contact_list = contacts.filter(
					({ client_branch_id }) =>
						client_branch_id == this.create_booking.client_branch_id
				);
				this.create_booking.client_contact_id = this.client_contact_list
					.map(({ id }) => id)
					.includes(this.create_booking.client_contact_id)
					? this.create_booking.client_contact_id
					: null;
			}
		},
		create_company_close() {
			this.show_create_company = false;
		},
		create_client_close() {
			this.show_create_client = false;
		},
		add_company() {
			this.show_create_company = true;
		},
		add_client(id) {
			this.show_create_client = true;
		},
		auto_fill_days() {
			const { datetime } = this.create_booking;
			this.create_booking.total_days =
				datetime[1].diff(datetime[0], 'days') + 1;
		},
		handlePreview(file) {
			this.previewImage = file.url || file.thumbUrl;
			this.previewVisible = true;
			window.open(this.previewImage);
		},
		preview_close() {
			this.previewVisible = false;
		},
		async init_edit_mode() {
			if (this.render_type != undefined && this.render_type != 'all') {
				// await this.init_create_booking_options()
				this.create_booking = await SystemService.get_id(
					'Booking',
					this.render_type
				);
				if (this.create_booking.booking_cars) {
					this.create_booking.booking_cars =
						this.create_booking.booking_cars.sort(
							(a, b) => a.order_pos - b.order_pos
						);
				}
				const {
					time_start,
					time_end,
					booking_car_types,
					booking_cars,
					photo_1,
					photo_2,
					photo_3,
					photo_4,
					photo_5,
					address,
					phone_no,
					email,
					tax_id,
					client_contact_id,
					person_qty,
					flight_pick,
					flight_drop,
					extras,
					inc_vat,
					withholding,
					start_location_name,
					end_location_name,
					client_contact,
					qt_setting
				} = this.create_booking;

				if (this.create_booking != '') {
					this.create_booking.extras = extras ? extras : [];
					this.create_booking.datetime = [dayjs(time_start), dayjs(time_end)];
					this.create_booking.address = address;
					this.create_booking.phone_no = phone_no;
					this.create_booking.email = email;
					this.create_booking.tax_id = tax_id;
					this.create_booking.person_qty = person_qty;
					this.create_booking.booking_car_types = booking_car_types;
					this.create_booking.flight_pick = flight_pick;
					this.create_booking.flight_drop = flight_drop;
					this.create_booking.inc_vat = inc_vat ? true : false;
					this.create_booking.withholding = withholding ? true : false;
					if (
						start_location_name &&
						document.getElementById('start_location_name')
					) {
						document.getElementById('start_location_name').value =
							start_location_name;
						this.create_booking.start_location_name = start_location_name;
					}
					if (
						end_location_name &&
						document.getElementById('end_location_name')
					) {
						document.getElementById('end_location_name').value =
							end_location_name;
						this.create_booking.end_location_name = end_location_name;
					}

					if (!client_contact) this.create_booking.client_contact = {};

					this.create_booking.total_days =
						this.create_booking.datetime[1].diff(
							this.create_booking.datetime[0],
							'days'
						) + 1;
					this.create_booking.booking_files = [
						photo_1,
						photo_2,
						photo_3,
						photo_4,
						photo_5
					]
						.filter((e) => e)
						.map((e, i) => {
							return {
								uid: `photo_${i + 1}`,
								name: e.split('/').pop(),
								status: 'done',
								url: e
							};
						});
				}

				if (booking_cars) {
					booking_cars.forEach(async (booking_car, i) => {
						try {
							const { id, service, gas_refills } = booking_car;
							for (var key in service) {
								if (key.includes('photo')) {
									this.create_booking.booking_cars[i].service[key + '_show'] =
										service[key]
											? [
												{
													uid: key,
													name: '',
													status: 'done',
													url: service[key]
												}
											]
											: [];
								}
							}

							if (!service) {
								const create_service = await SystemService.create_all(
									'Service',
									{ data: [{ booking_car_id: id }] }
								);
								console.log(create_service);
							}

							gas_refills.forEach((gas, g) => {
								for (var key in gas) {
									if (key.includes('photo') && gas[key]) {
										this.create_booking.booking_cars[i].gas_refills[g][
											key + '_show'
										] = [
												{
													uid: key,
													name: '',
													status: 'done',
													url: gas[key]
												}
											];
									} else {
										this.create_booking.booking_cars[i].gas_refills[g][
											key + '_show'
										] = [];
									}
								}
							});

							return booking_car;
						} catch (err) {
							console.log(err);
							return booking_car;
						}
					});
				}

				if (qt_setting) {
					// this.create_booking.qt_setting = qt_setting
					const setting = JSON.parse(qt_setting);
					const selected_remarks_list = setting.selected_remarks_list;

					// console.log(setting);

					this.create_booking.selected_remarks_list = selected_remarks_list;
					this.price_percent = setting.price_percent? Number(setting.price_percent):0;
					this.form.total_price = setting.total_price;
					if (setting.pay_type) {
						this.form.pay_type = setting.pay_type;
					} else {
						this.form.pay_type = 1;
					}
					this.cal_price(this.form.pay_type);
					this.form.pay_extend = setting.pay_extend;
					this.form.discount = setting.discount;
				} else {
					this.form.pay_type = 1;
					this.cal_price(this.form.pay_type);
				}
			}
		},
		async init_mode() {
			this.form = {}
			this.price_percent = 0
			if (this.render_type != 'add' && this.render_type != 'all') {
				await this.init_edit_mode();
			} else {
				this.booking_files = [];
				this.init_create_booking()
			}
		},
		async refresh_data() {
			this.loading = true;
			await this.init_mode();
			await this.init_all_bookings();
			this.loading = false;

			if (this.selected_quotation) {
				this.selected_quotation = this.booking_list.find(
					({ id }) => id == this.selected_quotation.id
				);
			}
		}
	},
	watch: {
		render_type() {
			this.init_mode();
		}
	},
	mounted() {
		// if (this.render_type != 'all') {
		// 	this.init_create_booking_options();
		// }
		this.init_create_booking_options();
		this.refresh_data();
	}
};
</script>

<style scoped>
.ant-row {
	margin: 5px 0;
}

.ant-col-12,
.ant-col-24,
.ant-col-4 {
	padding: 0 4px;
}

.ant-select,
.ant-picker {
	width: 100%;
}

.ant-col {
	width: 100%;
}

.ant-form-item {
	margin-bottom: 0;
}

.quotation-container {
	padding: 15px;
	height: 100vh;
}

.blue-card {
	background-color: #1890ff;
	color: white;
	border-radius: 5px;
	margin: 5px;
}

.yellow-card {
	background-color: #faad14;
	color: white;
	border-radius: 5px;
	margin: 5px;
}

.gray-card {
	background-color: #a2a2a2;
	color: white;
	border-radius: 5px;
	margin: 5px;
}
</style>
