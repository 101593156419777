let car_fields = {
    title: {
        create: 'เพิ่มรถ',
        edit: 'แก้ไขรถ'
    },
    inputs: {
        divider_1: {
            input_type: 'divider',
            label: 'ข้อมูลรถ',
            col_span: 23
        },
        car_company_id: {
            input_type: 'select',
            search_select: true,
            modelname: 'CarCompany',
            label_key: 'fullname',
            placeholder: 'บริษัทรถ',
            // required: true,
            required: false,
            col_span: 11
        },
        car_type_id: {
            input_type: 'select',
            search_select: true,
            modelname: 'CarType',
            label_key: 'name',
            placeholder: 'ชนิดรถ',
            // required: true,
            required: false,
            col_span: 11
        },
        car_group_id: {
            input_type: 'sub_select',
            placeholder: 'ประเภทที่นั่งรถ',
            modelname: 'CarGroup',
            label_key: 'name',
            parent: {
                list: 'CarType',
                sub_list_name: 'car_groups',
                key: 'car_type_id'
            },
            // required: true,
            required: false,
            col_span: 11,
        },
        car_no: {
            input_type: 'text',
            placeholder: 'เบอร์รถ',
            // required: true,
            required: false,
            col_span: 11
        },
        plate_no: {
            input_type: 'text',
            placeholder: 'เลขทะเบียนรถ',
            // required: true,
            required: false,
            col_span: 11
        },
        driver_id: {
            input_type: 'select',
            search_select: true,
            modelname: 'Driver',
            label_key: 'firstname',
            placeholder: 'คนขับรถ',
            search_select: true,
            // required: true,
            required: false,
            col_span: 11
        },
        car_brand: {
            input_type: 'text',
            placeholder: 'ยี่ห้อรถ',
            // required: true,
            required: false,
            col_span: 11
        },
        car_year: {
            input_type: 'text',
            placeholder: 'ปีรถ',
            // required: true,
            required: false,
            col_span: 11
        },
        car_model: {
            input_type: 'text',
            placeholder: 'รุ่นรถ',
            // required: true,
            required: false,
            col_span: 11
        },
        parking_id: {
            input_type: 'select',
            search_select: true,
            modelname: 'Parking',
            label_key: 'name',
            placeholder: 'ที่จอดรถ',
            // required: true,
            required: false,
            col_span: 11
        },
        car_source_id: {
            input_type: 'select',
            search_select: true,
            modelname: 'CarSource',
            label_key: 'name',
            placeholder: 'ที่มารถ',
            required: false,
            col_span: 11
        },
        remark: {
            input_type: 'textarea',
            placeholder: 'รายละเอียดรถเพิ่มเติม',
            required: false,
            col_span: 23
        },
        divider_2: {
            input_type: 'divider',
            label: 'ข้อมูลด้านประกันภัย',
            col_span: 23
        },
        tax_label: {
            input_type: 'label',
            label: 'พ.ร.บ.',
            col_span: 23,
        },
        tax_provider_name: {
            input_type: 'text',
            placeholder: 'บริษัทประกันภัย',
            // required: true,
            required: false,
            col_span: 10
        },
        car_tax_no: {
            input_type: 'text',
            placeholder: 'เลขที่กรมธรรม์',
            // required: true,
            required: false,
            col_span: 10
        },
        car_tax_start: {
            input_type: 'date',
            placeholder: 'วันเริ่ม',
            // required: true,
            required: false,
            col_span: 6
        },
        car_tax_expire: {
            input_type: 'date',
            placeholder: 'วันสิ้นสุด',
            // required: true,
            required: false,
            col_span: 6
        },
        car_tax_premium_net: {
            input_type: 'number',
            placeholder: 'เบี้ยประกันภัยสุทธิ',
            // required: true,
            required: false,
            col_span: 8
        },
        car_tax_premium_total_added: {
            input_type: 'number',
            placeholder: 'เบี้ยประกันภัยสุทธิรวมภาษีอากร',
            // required: true,
            required: false,
            col_span: 8
        },
        car_tax_stamps: {
            input_type: 'number',
            placeholder: 'อากรแสตมป์',
            // required: true,
            required: false,
            col_span: 6
        },
        car_tax_vat: {
            input_type: 'number',
            placeholder: 'ภาษีมูลค่าเพิ่ม',
            // required: true,
            required: false,
            col_span: 6
        },
        car_tax_total: {
            input_type: 'number',
            placeholder: 'ยอดสุทธิ',
            // required: true,
            required: false,
            col_span: 6
        },
        insure_label: {
            input_type: 'label',
            label: 'ภาคสมัครใจ',
            col_span: 23,
        },
        insure_provider_name: {
            input_type: 'text',
            placeholder: 'บริษัทประกันภัย',
            // required: true,
            required: false,
            col_span: 10
        },
        insure_no: {
            input_type: 'text',
            placeholder: 'เลขที่กรมธรรม์',
            // required: true,
            required: false,
            col_span: 10
        },
        insure_start: {
            input_type: 'date',
            placeholder: 'วันเริ่ม',
            format: 'DD/MM/YYYY',
            // required: true,
            required: false,
            col_span: 6
        },
        insure_expire: {
            input_type: 'date',
            placeholder: 'วันสิ้นสุด',
            format: 'DD/MM/YYYY',
            required: false,
            col_span: 6
        },
        insure_premium_net: {
            input_type: 'number',
            placeholder: 'เบี้ยประกันภัยสุทธิ',
            // required: true,
            required: false,
            col_span: 8
        },
        insure_premium_total_added: {
            input_type: 'number',
            placeholder: 'เบี้ยประกันภัยสุทธิรวมภาษีอากร',
            // required: true,
            required: false,
            col_span: 8
        },
        insure_stamps: {
            input_type: 'number',
            placeholder: 'อากรแสตมป์',
            // required: true,
            required: false,
            col_span: 6
        },
        insure_vat: {
            input_type: 'number',
            placeholder: 'ภาษีมูลค่าเพิ่ม',
            // required: true,
            required: false,
            col_span: 6
        },
        insure_total: {
            input_type: 'number',
            placeholder: 'ยอดสุทธิ',
            // required: true,
            required: false,
            col_span: 6
        },
        maintenance_expire: {
            input_type: 'date',
            placeholder: 'วันตรวจสภาพรถประจำปี',
            // required: true,
            required: false,
            col_span: 8
        },
        repair_target: {
            input_type: 'number',
            placeholder: 'เลขไมล์ซ่อมถัดไป',
            required: false,
            col_span: 6
        },
        divider_3: {
            input_type: 'divider',
            label: 'ข้อมูลด้านทะเบียน / ชำระภาษี',
            col_span: 23
        },
        car_register_date: {
            input_type: 'date', 
            placeholder: 'วันที่จดทะเบียน',
            // required: true,
            required: false,
            col_span: 6
        },
        car_tax_rate: {
            input_type: 'number',
            placeholder: 'อัตราภาษี',
            // required: true,
            required: false,
            col_span: 6
        },
        car_appearance: {
            input_type: 'text',
            placeholder: 'ลักษณะ/มาตรฐานรถ',
            // required: true,
            required: false,
            col_span: 6
        },
        car_inspect_1: {
            input_type: 'month',
            placeholder: 'รอบตรวจสภาพ 1',
            // required: true,
            required: false,
            col_span: 6
        },
        car_inspect_2: {
            input_type: 'month',
            placeholder: 'รอบตรวจสภาพ 2',
            // required: true,
            required: false,
            col_span: 6
        },
        sub_divider_3_0: {
            input_type: 'sub-divider',
            label: 'Fleet oil & Sim GPS',
            col_span: 23
        },
        oil_1_1: {
            input_type: 'text',
            placeholder: 'ประเภทปั๊ม',
        },
        oil_1_2: {
            input_type: 'text',
            placeholder: 'Fleetcard',
        },
        oil_1_3: {
            input_type: 'text',
            placeholder: 'GPS simcard',
        },
        sub_divider_3_1: {
            input_type: 'sub-divider',
            label: 'เครื่องยนต์',
            col_span: 23
        },
        engin_label_1: {
            input_type: 'label',
            label: 'คัทซี',
            col_span: 23
        },
        engin_1_1: {
            input_type: 'text',
            placeholder: 'ชื่อ',
        },
        engin_1_2: {
            input_type: 'text',
            placeholder: 'รุ่น',
        },
        engin_1_3: {
            input_type: 'text',
            placeholder: 'ถุงลมใน',
        },
        engin_1_4: {
            input_type: 'text',
            placeholder: 'น็อต',
        },
        engin_1_5: {
            input_type: 'text',
            placeholder: 'เบรค',
        },
        engin_1_6: {
            input_type: 'text',
            placeholder: 'อู่ประกอบคัสซี',
        },
        engin_label_2: {
            input_type: 'label',
            label: 'เครื่องยนต์',
            col_span: 23
        },
        engin_2_1: {
            input_type: 'text',
            placeholder: 'ชื่อ',
        },
        engin_2_2: {
            input_type: 'text',
            placeholder: 'ปั๊มลมเล็ก',
        },
        engin_2_3: {
            input_type: 'text',
            placeholder: 'สูบ',
        },
        engin_2_4: {
            input_type: 'text',
            placeholder: 'แรงม้า คอมมอลเรล',
        },
        engin_label_3: {
            input_type: 'label',
            label: 'เกียร์',
            col_span: 23
        },
        engin_3_1: {
            input_type: 'text',
            placeholder: 'bus',
        },
        engin_3_2: {
            input_type: 'text',
            placeholder: 'เกียร์+ถอย',
        },
        engin_3_3: {
            input_type: 'text',
            placeholder: 'เกียร์',
        },
        engin_label_4: {
            input_type: 'label',
            label: 'แผ่นคลัส',
            col_span: 23
        },
        engin_4_1: {
            input_type: 'text',
            placeholder: 'นิ้ว',
        },
        engin_4_2: {
            input_type: 'text',
            placeholder: 'แผ่น เฟืองละเอียด',
        },
        engin_label_5: {
            input_type: 'label',
            label: 'จานกดคลัส',
            col_span: 23
        },
        engin_5_1: {
            input_type: 'text',
            placeholder: 'ยี่ห้อ',
        },
        engin_5_2: {
            input_type: 'text',
            placeholder: 'รุ่น',
        },
        engin_label_6: {
            input_type: 'label',
            label: 'ลูกปืนคลัส',
            col_span: 23
        },
        engin_ุุ6_1: {
            input_type: 'text',
            placeholder: 'ยี่ห้อ',
        },
        engin_6_2: {
            input_type: 'text',
            placeholder: 'รุ่น',
        },
        engin_label_7: {
            input_type: 'label',
            label: 'เฟืองท้าย',
            col_span: 23
        },
        engin_7_1: {
            input_type: 'text',
            placeholder: 'ยี่ห้อ',
        },
        engin_7_2: {
            input_type: 'text',
            placeholder: 'รุ่น',
        },
        engin_label_8: {
            input_type: 'label',
            label: 'ถุงลม',
            col_span: 23
        },
        engin_8_1: {
            input_type: 'text',
            placeholder: 'ด้านหน้า',
        },
        engin_8_2: {
            input_type: 'text',
            placeholder: 'ล้อจักร',
        },
        engin_8_3: {
            input_type: 'text',
            placeholder: 'ล้อพ่วง',
        },
        engin_label_9: {
            input_type: 'label',
            label: 'วาวล์ยกถุงลม',
            col_span: 23
        },
        engin_9_1: {
            input_type: 'text',
            placeholder: ' ',
        },
        engin_label_10: {
            input_type: 'label',
            label: 'ผ้าเบรค',
            col_span: 23
        },
        engin_10_1: {
            input_type: 'text',
            placeholder: 'ด้านหน้า',
        },
        engin_10_2: {
            input_type: 'text',
            placeholder: 'ด้านหลัง',
        },
        engin_10_3: {
            input_type: 'text',
            placeholder: 'ล้อพ่วง',
        },
        engin_label_11: {
            input_type: 'label',
            label: 'กันโคลง',
            col_span: 23
        },
        engin_11_1: {
            input_type: 'text',
            placeholder: 'ด้านหน้า-ด้านหลัง',
        },
        engin_label_12: {
            input_type: 'label',
            label: 'โช้คอัพ',
            col_span: 23
        },
        engin_12_1: {
            input_type: 'text',
            placeholder: 'ด้านหน้า',
        },
        engin_12_2: {
            input_type: 'text',
            placeholder: 'ด้านหลัง',
        },
        engin_12_3: {
            input_type: 'text',
            placeholder: 'ล้อพ่วง',
        },
        engin_label_13: {
            input_type: 'label',
            label: 'สายพาน',
            col_span: 23
        },
        engin_13_1: {
            input_type: 'text',
            placeholder: ' ',
        },
        engin_13_2: {
            input_type: 'text',
            placeholder: 'หม้อน้ำ',
        },
        engin_13_3: {
            input_type: 'text',
            placeholder: 'ไดชาร์ทเครื่อง',
        },
        engin_13_4: {
            input_type: 'text',
            placeholder: 'สายยาว',
        },
        engin_13_5: {
            input_type: 'text',
            placeholder: 'ไดชาร์ทแอร์',
        },
        engin_13_6: {
            input_type: 'text',
            placeholder: 'เครื่องเสียง',
        },
        engin_13_7: {
            input_type: 'text',
            placeholder: 'คอมแอร์',
        },
        engin_label_14: {
            input_type: 'label',
            label: 'ไดชาร์ทเครื่อง',
            col_span: 23
        },
        engin_14_1: {
            input_type: 'text',
            placeholder: ' ',
        },
        engin_label_15: {
            input_type: 'label',
            label: 'ไดชาร์ทแอร์',
            col_span: 23
        },
        engin_15_1: {
            input_type: 'text',
            placeholder: ' ',
        },
        engin_label_16: {
            input_type: 'label',
            label: 'ไดชาร์ทเครื่องเสียง',
            col_span: 23
        },
        engin_16_1: {
            input_type: 'text',
            placeholder: 'ยี่ห้อ',
        },
        engin_16_2: {
            input_type: 'text',
            placeholder: 'รุ่น',
        },
        engin_label_17: {
            input_type: 'label',
            label: 'ถังน้ำมัน',
            col_span: 23
        },
        engin_17_1: {
            input_type: 'text',
            placeholder: 'จำนวน',
        },
        engin_17_2: {
            input_type: 'text',
            placeholder: 'ลิตร',
        },
        engin_label_18: {
            input_type: 'label',
            label: 'หม้อน้ำ',
            col_span: 23
        },
        engin_18_1: {
            input_type: 'text',
            placeholder: ' ',
        },
        engin_18_2: {
            input_type: 'text',
            placeholder: 'หม้อน้ำ',
        },
        engin_label_19: {
            input_type: 'label',
            label: 'ใบพักหม้อน้ำ',
            col_span: 23
        },
        engin_19_1: {
            input_type: 'text',
            placeholder: 'ยี่ห้อ',
        },
        engin_label_20: {
            input_type: 'label',
            label: 'ท่อพักไอเสีย',
            col_span: 23
        },
        engin_20_1: {
            input_type: 'text',
            placeholder: 'ขนาด',
        },
        engin_20_2: {
            input_type: 'text',
            placeholder: 'ใบ',
        },
        engin_label_21: {
            input_type: 'label',
            label: 'ข้ออ่อนท่อไอเสีย',
            col_span: 23
        },
        engin_21_1: {
            input_type: 'text',
            placeholder: 'ขนาด',
        },
        engin_21_2: {
            input_type: 'text',
            placeholder: 'อัน',
        },
        engin_label_22: {
            input_type: 'label',
            label: 'ยางแท่นเครื่อง',
            col_span: 23
        },
        engin_22_1: {
            input_type: 'text',
            placeholder: 'ด้านหน้า (สูบ)',
        },
        engin_22_2: {
            input_type: 'text',
            placeholder: 'ด้านหลัง (สูบ)',
        },
        engin_label_23: {
            input_type: 'label',
            label: 'กระปุกพวงมาลัย',
            col_span: 23
        },
        engin_23_1: {
            input_type: 'text',
            placeholder: ' ',
        },
        engin_label_24: {
            input_type: 'label',
            label: 'กรองอากาศลูกนอก',
            col_span: 23
        },
        engin_24_1: {
            input_type: 'text',
            placeholder: ' ',
        },
        engin_label_25: {
            input_type: 'label',
            label: 'กรองอากาศลูกใน',
            col_span: 23
        },
        engin_25_1: {
            input_type: 'text',
            placeholder: ' ',
        },
        engin_label_26: {
            input_type: 'label',
            label: 'กรองโซล่า',
            col_span: 23
        },
        engin_26_1: {
            input_type: 'text',
            placeholder: 'ปะเก็น',
        },
        engin_26_2: {
            input_type: 'text',
            placeholder: ' ',
        },
        engin_label_27: {
            input_type: 'label',
            label: 'กรองดักน้ำ',
            col_span: 23
        },
        engin_27_1: {
            input_type: 'text',
            placeholder: ' ',
        },
        engin_label_28: {
            input_type: 'label',
            label: 'กรองเกียร์',
            col_span: 23
        },
        engin_28_1: {
            input_type: 'text',
            placeholder: ' ',
        },
        engin_label_29: {
            input_type: 'label',
            label: 'กรองแอร์ไดเออร์',
            col_span: 23
        },
        engin_29_1: {
            input_type: 'text',
            placeholder: ' ',
        },
        engin_label_30: {
            input_type: 'label',
            label: 'พาวเวอร์ชิพ',
            col_span: 23
        },
        engin_30_1: {
            input_type: 'text',
            placeholder: 'ยี่ห้อ',
        },
        engin_30_2: {
            input_type: 'text',
            placeholder: 'รุ่น',
        },
        engin_label_31: {
            input_type: 'label',
            label: 'ปั๊มน้ำ',
            col_span: 23
        },
        engin_31_1: {
            input_type: 'text',
            placeholder: 'ยี่ห้อ',
        },
        engin_31_2: {
            input_type: 'text',
            placeholder: 'รุ่น',
        },
        engin_label_32: {
            input_type: 'label',
            label: 'ปั๊มลม',
            col_span: 23
        },
        engin_32_1: {
            input_type: 'text',
            placeholder: 'ยี่ห้อ',
        },
        engin_32_2: {
            input_type: 'text',
            placeholder: 'รุ่น',
        },
        engin_label_33: {
            input_type: 'label',
            label: 'ปั๊มโซล่า',
            col_span: 23
        },
        engin_33_1: {
            input_type: 'text',
            placeholder: 'ยี่ห้อ',
        },
        engin_33_2: {
            input_type: 'text',
            placeholder: 'รุ่น',
        },
        engin_label_34: {
            input_type: 'label',
            label: 'มู่เล่',
            col_span: 23
        },
        engin_34_1: {
            input_type: 'text',
            placeholder: 'เครื่อง',
        },
        engin_34_2: {
            input_type: 'text',
            placeholder: 'ไดร์เครื่อง',
        },
        engin_34_3: {
            input_type: 'text',
            placeholder: 'ไดร์แอร์',
        },
        engin_34_4: {
            input_type: 'text',
            placeholder: 'ไดร์',
        },
        engin_34_5: {
            input_type: 'text',
            placeholder: 'เครื่องเสียง',
        },
        sub_divider_3_2: {
            input_type: 'sub-divider',
            label: 'ตัวถัง',
            col_span: 23
        },
        body_label_1: {
            input_type: 'label',
            label: 'ตัวถัง',
            col_span: 23
        },
        body_1_1: {
            input_type: 'text',
            placeholder: 'ทรง',
        },
        body_label_2: {
            input_type: 'label',
            label: 'อู่ประกอบ',
            col_span: 23
        },
        body_2_1: {
            input_type: 'text',
            placeholder: 'ชื่ออู่',
        },
        body_2_2: {
            input_type: 'date',
            placeholder: 'วันที่ผลิต',
        },
        body_2_3: {
            input_type: 'textarea',
            placeholder: 'ที่อยู่',
        },
        body_label_3: {
            input_type: 'label',
            label: 'สี',
            col_span: 23
        },
        body_3_1: {
            input_type: 'text',
            placeholder: 'สี',
        },
        body_label_4: {
            input_type: 'label',
            label: 'กระจก',
            col_span: 23
        },
        body_4_1: {
            input_type: 'text',
            placeholder: 'หน้าล่าง รุ่น/ขนาด/สี',
            col_span: 8,
        },
        body_4_2: {
            input_type: 'text',
            placeholder: 'หน้าบน รุ่น/ขนาด/สี',
            col_span: 8,
        },
        body_4_3: {
            input_type: 'text',
            placeholder: 'ข้าง Lt/Rt สีฟ้าชา',
            col_span: 8,
        },
        body_4_4: {
            input_type: 'text',
            placeholder: 'ท้ายสี',
            col_span: 8,
        },
        body_label_5: {
            input_type: 'label',
            label: 'กระจกงวงช้าง',
            col_span: 23
        },
        body_5_1: {
            input_type: 'text',
            placeholder: 'ยี่ห้อ',
            col_span: 6,
        },
        body_5_2: {
            input_type: 'text',
            placeholder: 'รุ่น',
            col_span: 6,
        },
        body_label_6: {
            input_type: 'label',
            label: 'ไฟหน้า',
            col_span: 23
        },
        body_6_1: {
            input_type: 'text',
            placeholder: 'ยี่ห้อ',
            col_span: 6,
        },
        body_6_2: {
            input_type: 'text',
            placeholder: 'รุ่น',
            col_span: 6,
        },
        body_label_7: {
            input_type: 'label',
            label: 'ไฟท้าย หลังคา',
            col_span: 23
        },
        body_7_1: {
            input_type: 'text',
            placeholder: 'ยี่ห้อ',
            col_span: 6,
        },
        body_7_2: {
            input_type: 'text',
            placeholder: 'รุ่น',
            col_span: 6,
        },
        body_7_3: {
            input_type: 'text',
            placeholder: 'เล็ก',
            col_span: 6,
        },
        body_label_8: {
            input_type: 'label',
            label: 'ภายใน',
            col_span: 23
        },
        body_8_1: {
            input_type: 'text',
            placeholder: 'เพดาน สี',
            col_span: 6,
        },
        body_8_2: {
            input_type: 'text',
            placeholder: 'ม่านสี',
            col_span: 6,
        },
        body_8_3: {
            input_type: 'text',
            placeholder: 'หัวเบาะ ลายสี',
            col_span: 6,
        },
        body_8_4: {
            input_type: 'text',
            placeholder: 'เพิ่มเติม',
            col_span: 6,
        },
        body_label_9: {
            input_type: 'label',
            label: 'แอร์',
            col_span: 23
        },
        body_9_1: {
            input_type: 'text',
            placeholder: 'ยี่ห้อ',
            col_span: 6,
        },
        body_9_2: {
            input_type: 'text',
            placeholder: 'รุ่น',
            col_span: 6,
        },
        body_9_3: {
            input_type: 'text',
            placeholder: 'ระบบคอยร้อน',
            col_span: 6,
        },
        body_9_4: {
            input_type: 'text',
            placeholder: 'น้ำยาแอร์ รุ่น',
            col_span: 6,
        },
        body_9_5: {
            input_type: 'text',
            placeholder: 'ระบบคอยเย็น',
            col_span: 6,
        },
        body_9_6: {
            input_type: 'date',
            placeholder: 'รับประกัน ถึงวันที่',
            col_span: 6,
        },
        body_10_1: {
            input_type: 'date',
            placeholder: 'วันที่รับรถจริง ',
            col_span: 23,
        },
        body_11_1: {
            input_type: 'date',
            placeholder: 'วันที่ PDI รถใหม่',
            col_span: 23,
        },
        body_12_1: {
            input_type: 'date',
            placeholder: 'วันเริ่มรับประกัน',
            col_span: 23,
        },
        documents: {
            input_type: 'label',
            label: 'เอกสารทะเบียนครบชุด',
            col_span: 23
        },
        doc_1: {
            input_type: 'file',
            placeholder: 'ทะเบียนรถ/การต่อภาษี',
            col_span: 23
        },
        doc_2: {
            input_type: 'file',
            placeholder: 'พ.ร.บ.',
            col_span: 23
        },
        doc_3: {
            input_type: 'file',
            placeholder: 'ประกันภัยประเภท 3',
            col_span: 23
        },
        doc_4: {
            input_type: 'file',
            placeholder: 'หนังสือรับรอง GPS',
            col_span: 23
        },
        doc_5: {
            input_type: 'file',
            placeholder: 'ใบขับขี่',
            col_span: 23
        },
        doc_6: {
            input_type: 'file',
            placeholder: 'ครบชุด pdf',
            col_span: 23
        },
        divider_4: {
            input_type: 'divider',
            label: 'เอกสารสำคัญต่างๆ',
            col_span: 23
        },
        file_asset: {
            input_type: 'table_files',
            placeholder: '',
            col_span: 23
        },
        divider_5: {
            input_type: 'divider',
            label: 'ข้อมูลออฟชั่นรถ',
            col_span: 23
        },
        create_options: {
            input_type: 'checkbox',
            placeholder: 'ออฟชั่นรถ',
            required: false,
            modelname: 'Option',
            label_key: 'name',
            col_span: 23
        },
        divider_6: {
            input_type: 'divider',
            label: 'รูปพรรณ',
            col_span: 23
        },
        photo_1: {
            input_type: 'photo',
            label: 'ด้านหน้า',
            col_span: 10,
            layout: 'vertical'
        },
        photo_2: {
            input_type: 'photo',
            label: 'ด้านหลัง',
            col_span: 10,
            layout: 'vertical'
        },
        photo_3: {
            input_type: 'photo',
            label: 'ด้านซ้าย',
            col_span: 10,
        },
        photo_4: {
            input_type: 'photo',
            label: 'ด้านขวา',
            col_span: 10,
            layout: 'vertical'
        },
        photo_5: {
            input_type: 'photo',
            label: 'ชั้นบน',
            col_span: 10,
            layout: 'vertical'
        },
        photo_6: {
            input_type: 'photo',
            label: 'ชั้นล่าง',
            col_span: 10,
            layout: 'vertical'
        },
        photo_7: {
            input_type: 'photo',
            label: 'แผ่นเพลทตัวรถ',
            col_span: 10,
            layout: 'vertical'
        },
        photo_8: {
            input_type: 'photo',
            label: 'แผ่นเพลทหมายเลขตัวถัง',
            col_span: 10,
            layout: 'vertical'
        },
    }
};

export default car_fields