<template>
    <div class="calendar-container">
        <div class="app-content">
            <a-calendar v-model:value="value" @select="openModal" @panelChange="onPanelChange" :locale="lang_th">
                <template #dateCellRender="{ current }">
                    <a-badge :class="`badge badge-${item.bg}`" v-for="item, i in getListData(current)" :key="i" :status="item.type"
                        :text="item.content" />
                </template>
            </a-calendar>
        </div>

        <a-modal v-model:visible="visible" width="1000px">
            <template #title>
                <span>รายการจองวันที่ {{ selected_date }}</span>
            </template>
            <div v-for="{ bookingId, bookingNo, dropOffDate, dropOffPoint, pickupDate, pickupPoint, cusName, phoneNumber, progress,
                booking_car_types, vehicles, extras, sale, client_contact, client, client_branch, raw, discount, status
            }, i in selected_list" :key="i">
                <a-card>
                    <template #title>
                        NO: <span><router-link target="_blank" :to="`/app/quotation/${bookingId}`">[{{ bookingNo }}]</router-link></span>
                    </template>
                    <template #extra>
                        QT <a-badge :class="`badge badge-${booking_status[progress].bg}`" :status="booking_status[progress].badge" :text="booking_status[progress].title" />
                        Booking <a-badge :class="`badge badge-${text_status[status].bg}`" :status="text_status[status].badge" :text="text_status[status].title" />
                    </template>
                    <a-row>
                        <a-divider style="line-height: 0" orientation="left">ลูกค้าบริษัท/กรุ๊ปทัวร์/ผู้ประสานงาน/ไกด์</a-divider>
                        <a-col :span="12">
                            <ul>
                                <li>บริษัท : {{ cusName }}  สาขา : {{ client_branch? client_branch.name:'-' }}  ประเภทลูกค้า : {{ client.client_type?.name }}</li>
                                <li>อีเมล : {{ client.email }} </li>
                                <li>เบอร์โทรศัพท์ : {{ client.phone_no }} </li>
                                <li>เลขผู้เสียภาษี : {{ client.tax_no }} </li>
                                <li>ที่อยู่ : {{ client.address }} </li>
                            </ul>
                        </a-col>
                        <a-col :span="12">
                            <ul>
                                <li>ผู้ประสานงาน : {{ client_contact?.username }}  ชื่อผู้ประสานงาน : {{ client_contact?.firstname }} {{ client_contact?.lastname }}</li>
                                <li>อีเมล : {{ client_contact?.email }} </li>
                                <li>เบอร์โทรศัพท์ : {{ client_contact?.phone_no }} </li>
                                <li>ชื่อประเภทกลุ่มลูกค้า : {{ client_contact?.tour?.name }} </li>
                                <li>เลขที่อ้างอิง : {{ client_contact?.address }} </li>
                            </ul>
                        </a-col>
                    </a-row>
                    <a-row>
                        <a-divider style="line-height: 0" orientation="left">การจอง</a-divider>
                        <a-col :span="12">
                            <ul>
                                <li>ประเภทการจอง : {{ raw.booking_type }}</li>
                                <li>จำนวนคน : {{ raw.person_qty }} </li>
                                <li>ไฟท์รับ : {{ pickupPoint }} </li>
                                <li>ไฟท์ส่ง : {{ dropOffPoint }} </li>
                            </ul>
                        </a-col>
                        <a-col :span="12">
                            <ul>
                                <li>วันเวลารับส่ง : {{ render_date(raw.time_start) }} - {{ render_date(raw.time_end) }}</li>
                                <li>จำนวนวัน : {{ total_days(raw.time_start, raw.time_end) }} </li>
                                <li>จำนวนวันที่ค้างคืน : {{ raw.full_day_amount }} </li>
                                <li>ทริป : {{ raw.trip?.name }} </li>
                                <li>หมายเหตุ : {{ raw.remark }} </li>
                            </ul>
                        </a-col>
                    </a-row>
                    <a-row>
                        <a-divider style="line-height: 0" orientation="left">รถที่เลือกใช้งาน</a-divider>
                        <a-col :span="24">
                            <a-card v-for="{ car_group, car_type, time_start, time_end, remark, quantity, cost }, i in booking_car_types" :key="i" :title="`ประเภทยานพาหนะ : ${car_group? car_group.name:'-'}`">
                                <a-row>
                                    <a-col :span="12">
                                        <ul>
                                            <li>รายละเอียดยานพาหนะ : {{ car_type?.name }} - {{ `ทะเบียน: ${vehicles[i]?.vehicleTabien}` }} </li>
                                            <li>วันที่/เวลา รับลูกค้า : {{ render_date(time_start) }} </li>
                                            <li>วันที่/เวลา รับลูกค้า : {{ render_date(time_end) }} </li>
                                        </ul>
                                    </a-col>
                                    <a-col :span="12">
                                        <ul>
                                            <li>จำนวนคัน : {{ quantity }}</li>
                                            <li>ราคารวม : {{ cost }} </li>
                                            <li>จุดหมาย : {{ remark }} </li>
                                        </ul>
                                    </a-col>
                                </a-row>
                            </a-card>
                        </a-col>
                    </a-row>
                    <a-row>
                        <a-col :span="12" v-if="extras.length">
                            <a-divider style="line-height: 0" orientation="left">ค่าใช้จ่ายเพิ่มเติม</a-divider>
                            <ul>
                                <li v-for="{ extra, price, amount}, i in extras" :key="i">รายการที่ {{ i+1 }} : {{ extra?.name }} (จำนวน {{ amount }}) (ราคาต่อหน่วย {{ price }})</li>
                            </ul>
                        </a-col>
                        <a-col :span="12" v-if="discount">
                            <a-divider style="line-height: 0" orientation="left">ส่วนลด</a-divider>
                            <ul v-if="discount">
                                <li>{{ discount }} บาท</li>
                            </ul>
                        </a-col>
                    </a-row>
                    <a-row justify="end" v-if="sale">
                        <a-col :span="12">
                            <a-divider style="line-height: 0" orientation="left">คนขาย</a-divider>
                            <ul>
                                <li>{{ sale?.firstname }} {{ sale?.lastname }}</li>
                            </ul>
                        </a-col>
                    </a-row>
                </a-card>

                <a-divider></a-divider>
            </div>
            <template #footer>
                <a-button type="primary" @click="visible = false">ปิด</a-button>
            </template>
        </a-modal>
    </div>
</template>

<script>
import lang_th from 'ant-design-vue/es/date-picker/locale/th_TH';
import dayjs from 'dayjs'
import buddhistEra from 'dayjs/plugin/buddhistEra'
dayjs.extend(buddhistEra);
import CalendarService from '../../api/CalendarService';

export default {
    data() {
        return {
            value: dayjs(),
            lang_th: lang_th,
            visible: false,
            selected_date: null,
            events: [],
            booking_status: [
                { title: 'ใหม่', progress: 0, badge: 'green', bg: 'success'},
                { title: 'กำลังดำเนินการ', progress: 1, badge: 'processing', bg: 'success'},
                { title: 'เสร็จสิ้น', progress: 2, badge: 'green', bg: 'success'},
                { title: 'ยกเลิก', progress: 3, badge: 'orange', bg: 'error' },
            ],
            text_status: [
                { title: 'แนะนำการจัดรถ', status: 0, badge: 'green', bg: 'success' },//0
                { title: 'รอจัดรถ', status: 1, badge: 'processing', bg: 'success' },//1
                { title: 'จัดรถแล้ว', status: 2, badge: 'green', bg: 'success' },//2
                { title: 'ถึงจุดรับแล้ว', status: 3, badge: 'green', bg: 'success' },//3
                { title: 'ส่งลูกค้าแล้ว', status: 4, badge: 'green', bg: 'success' },//4
                { title: 'วิ่งรถเรียบร้อยแล้ว', status: 5, badge: 'success', bg: 'success' },//5
                { title: 'ยกเลิกการเดินทาง', status: 6, badge: 'error', bg: 'error' },//6
                { title: 'กำลังเดินทาง', status: 7, badge: 'processing', bg: 'success' },//7
                { title: 'รับงาน', status: 8, badge: 'green', bg: 'success',},//8
                { title: 'เริ่มงาน', status: 9, badge: 'green', bg: 'success', },//9
                { title: 'รับลูกค้าขึ้นยานพาหนะ', status: 10, badge: 'green', bg: 'success', },//10
                { title: 'พร้อมเริ่มงาน', status: 11, badge: 'green', bg: 'success', },//11
                { title: 'รับของ', status: 12, badge: 'green', bg: 'success', },//12
                { title: 'รับของเสร็จสิ้น', status: 13, badge: 'green', bg: 'success', },//13
            ]
        }
    },
    methods: {
        check_booking(progress, status) {
            if (progress >= 0) {
                return ''
            }
        },
        total_days(start, end) {
            return dayjs(end).diff(dayjs(start), 'day') +1 
        },
        render_date(date) {
            return dayjs(date).format('DD-MM-YYYY HH:mm')
        },
        async get_data(date) {
            this.events = await CalendarService.get_calendar(date)
        },
        getListData(value) {
            const pool = []
            this.events
                .filter(({ date }) => value.format('DD/MM/YYYY') == dayjs(date).format('DD/MM/YYYY'))
                .map(({ lists }) => {
                    lists.map(({ status, bookingNo, progress }) => {
                        pool.push({
                            bg: this.booking_status[progress]? this.booking_status[progress].bg : 'error',
                            type: this.booking_status[progress]? this.booking_status[progress].badge : 'red',
                            content: `[${this.render_status(progress)?.title}] ${bookingNo}`
                        })
                    })
                })
            return pool
        },
        render_status(progress) {
            return this.booking_status.find((e) => e.progress == progress)
        },
        openModal(value) {
            try {
                const { lists } = this.events.filter(({ date }) => value.format('DD/MM/YYYY') == dayjs(date).format('DD/MM/YYYY'))[0]
                this.selected_date = value.format('DD/MM/YYYY')
                this.selected_list = lists
                this.visible = true
            } catch (err) {
                this.selected_date = value.format('DD/MM/YYYY')
                this.selected_list = []
            }
        },
        onPanelChange(event) {
            setTimeout(() => {
                this.get_data(event)
            }, 100)
        }
    },
    mounted() {
        this.get_data(this.value)
    }
};
</script>

<style>
.badge-success {
    background-color: #2db7f52e;
    color: #fff;
}
.badge-warning {
    background-color: #faad14;
    color: #fff;
}
.badge-error {
    background-color: #f5222d;
    color: #fff;
}
th {
    text-align: end;
}

.ant-picker-calendar-date-content {
    overflow-x: hidden;
    overflow-y: scroll;
}

.ant-badge-status {
    border-radius: 3px;
    padding: 3px !important;
    margin: 0.25rem !important
}

.calendar-container {
    padding: 15px;
}
</style>