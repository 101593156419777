<template>
    <div style="height: 100vh;padding: 15px;">
        <div class="app-content">
            <a-card>
                <a-row style="justify-content: space-between">
                    <h3>จัดการหมายเหตุ</h3>
                    <a-button v-if="editable" @click="add_remark()" style="display: flex" type="primary" size="large">
                        <template #icon><span class="material-symbols-outlined">Add</span></template>
                        เพิ่ม Remark
                    </a-button>
                </a-row>
            </a-card>
            <a-row style="margin:2rem">
                <a-col :span="12">
                        <a-input-search v-model:value="search_input" placeholder="ค้นหาใบเสนอราคา ชื่อลูกค้า ชื่อรถ"
                        enter-button="ค้นหา"/>
                </a-col>
                <a-button type="dashed">CLEAR</a-button>
            </a-row>
            <a-row>
                <a-table :columns="filter_columns([
                    {
                        title: '',
                        dataIndex: 'position',
                        key: 'position',
                        width: 100,
                        align: 'center'
                    },
                    {
                        title: 'ชื่อหมายเหตุ',
                        dataIndex: 'remark',
                        key: 'remark',
                        width: 970
                    },{
                        title: 'เป็นค่าเริ่มต้น',
                        dataIndex: 'is_default',
                        key: 'is_default',
                        align: 'center',
                        width: 100
                    },{
                        title: 'ตัวเลือก',
                        dataIndex: 'id',
                        key: 'id',
                        width: 100,
                        align: 'center'
                    },
                ])" :data-source="getRemarkList(remarks_list)" bordered size="small"
                    :scroll="{ x: 'calc(100px + 50%)', y: '100%' }">
                    <template #bodyCell="{ column, record, index }">
                        <template v-if="column.key === 'id'">
                            <div>
                                <a @click="edit_remark(record.id, record)"><span style="font-size: 18px" class="material-symbols-outlined">drive_file_rename_outline</span></a>
                                <a-divider type="vertical"></a-divider>
                                <a @click="remove_remark(record.id)"><span style="font-size: 18px" class="material-symbols-outlined">delete</span></a>
                            </div>
                        </template>
                        <template v-else-if="column.key === 'is_default'">
                            {{record.is_default ? 'ใช่' : 'ไม่ใช่'}}
                        </template>
                        <template v-else-if="column.key === 'position'">
                            <!-- sort up, down icon button  -->
                            <span>
                                <a class="material-symbols-outlined" @click="moveDown(record)"> 
                                    arrow_downward
                                </a>
                                <a class="material-symbols-outlined" @click="moveUp(record)"> 
                                    arrow_upward
                                </a>
                            </span>
                        </template> 
                    </template>
                </a-table>
            </a-row>
        </div>
        <a-drawer
            :visible="show_add_remark"
            :closable="true"
            title="เพิ่มหมายเหตุ"
            placement="right"
            @close="onClose"
        >
            <a-form :form="create_remark">
                <a-form-item style="margin: 0" label="เป็นค่าเริ่มด้น" name="is_default" />
                <a-switch v-model:checked="create_remark.is_default" />
                <a-form-item  style="margin: 0" label="หมายเหตุ" name="is_default"
                    :rules="[
                        {
                            required: true,
                            message: 'กรุณากรอกหมายเหตุ',
                        },
                    ]"
                />
                <a-textarea v-model:value="create_remark.remark" placeholder="หมายเหตุ" />
                <a-row justify="end">
                    <a-button style="margin: 5px 0" type="primary" @click="remark_submit()">บันทึก</a-button>
                </a-row>
            </a-form>
        </a-drawer>

    </div>
</template>

<script>
import BookingService from '../../api/BookingService';
import Swal from 'sweetalert2';
import SystemService from '../../api/SystemService';
import Utility from '../../utility'
import dragula from "dragula";
import "dragula/dist/dragula.css";
export default {
    name: 'Booking-Remark',
    data () {
        return {
            editable: Utility.get_permission_editable_with_key('quotation'),
            search_input: null,
            remarks_list: [],
            show_add_remark: false,
            is_edit: false,
            create_remark: {
                is_default: false,
                remark: null,
                company_id: JSON.parse(localStorage.getItem('companyData')).id
            },
            text:{
                0: 'ไม่เป็นค่าเริ่มต้น',
                1: 'เป็นค่าเริ่มต้น'
            }
        }
    },
    methods: {
        filter_columns(columns){
            return columns.filter((c)=> !this.editable ? c.key != 'id' : true)
        },
        async remove_remark(id) {
            Swal.fire({
                title: 'คุณต้องการลบหมายเหตุนี้ใช่หรือไม่?',
                text: "คุณจะไม่สามารถกู้คืนหมายเหตุนี้ได้!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'ใช่, ลบหมายเหตุนี้!',
                cancelButtonText: 'ยกเลิก'
            }).then((result) => {
                if (result.isConfirmed) {
                    BookingService.delete_remark(id).then(() => {
                        this.init_remarks()
                        Swal.fire(
                            'ลบหมายเหตุสำเร็จ!',
                            'หมายเหตุของคุณถูกลบแล้ว',
                            'success'
                        )
                    })
                }
            })
        },
        onClose(){
            this.show_add_remark = false
            this.is_edit = false
            this.create_remark = {
                is_default: false,
                remark: null,
                company_id: JSON.parse(localStorage.getItem('companyData')).id
            }
        },
        add_remark() {
            this.show_add_remark = true
        },
        edit_remark(id, record) {
            this.show_add_remark = true
            this.is_edit = id
            this.create_remark = record
        },
        async remark_submit() {
            if (this.is_edit) {
                await SystemService.update_all('BookingRemark', { data: this.create_remark }, this.is_edit)
            } else {
                var index = this.remarks_list.length
                this.create_remark.index = index
                await SystemService.create_all('BookingRemark', { data: [this.create_remark] })
            }

            this.show_add_remark = false
            this.init_remarks()

            Swal.fire({
                icon: 'success',
                title: 'เพิ่ม/แก้ไขหมายเหตุสำเร็จ',
                showConfirmButton: false,
                timer: 1500
            })
        },
        getRemarkList(list){
            return list.sort((a, b) => {
                return a.index - b.index
            })
        },
        async init_remarks() {
            this.remarks_list = await BookingService.get_remarks();

            var index_is_null = this.remarks_list.filter(({index}) => index == null).length
            var indexs_duplicate = this.remarks_list.reduce((acc, cur) => {
                acc[cur.index] = (acc[cur.index] || 0) + 1;
                return acc;
            }, {});

            var index_has_duplicate = Object.keys(indexs_duplicate).filter((key) => indexs_duplicate[key] > 1).length

            if(index_has_duplicate || index_is_null){
                for(let i = 0; i < this.remarks_list.length; i++){
                    await SystemService.update_all('BookingRemark', { data: { index: i } }, this.remarks_list[i].id)
                }
            }
        },
        async moveUp(record){
            ///sync index
            for(let i = 0; i < this.remarks_list.length; i++){
                this.remarks_list[i].index = i
            }
            let old_list = JSON.parse(JSON.stringify(this.remarks_list))
            const find_index = this.remarks_list.findIndex((item) => item.id == record.id)
            this.remarks_list[find_index].index = this.remarks_list[find_index].index - 1
            this.remarks_list[find_index-1].index = this.remarks_list[find_index-1].index + 1

            ///filter  remarks_list != old_list
            var new_list = this.remarks_list.filter((item, i) => {
                return item.index != old_list[i].index
            })
 
            for(let i = 0; i < new_list.length; i++){
                await SystemService.update_all('BookingRemark', { data: { index: new_list[i].index } }, new_list[i].id)
            }
        },
        async moveDown(record){
            ///sync index
            for(let i = 0; i < this.remarks_list.length; i++){
                this.remarks_list[i].index = i
            }
            let old_list = JSON.parse(JSON.stringify(this.remarks_list))
            const find_index = this.remarks_list.findIndex((item) => item.id == record.id)
            this.remarks_list[find_index].index = this.remarks_list[find_index].index + 1
            this.remarks_list[find_index+1].index = this.remarks_list[find_index+1].index - 1

            //filter  remarks_list != old_list
            var new_list = this.remarks_list.filter((item, i) => {
                return item.index != old_list[i].index
            })
 
            for(let i = 0; i < new_list.length; i++){
                await SystemService.update_all('BookingRemark', { data: { index: new_list[i].index } }, new_list[i].id)
            }
        },
        getIndexInParent(el) {
            return Array.from(el.parentNode.children).indexOf(el);
        },
    },
    mounted() {
        this.init_remarks()
    }
}
</script>

<style>
/* .ant-table-row {
    cursor: move;
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
}

.ant-table-row .draggable {
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  transform: rotate(90deg);
}


.gu-mirror {
  background-color: rgba(16, 142, 233, 0.15);
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
    .ant-table-cell:nth-child(1) {
        width: 100px;
    }
    .ant-table-cell:nth-child(2) {
        width: 80%;
    }

    .ant-table-cell:nth-child(3) {
        width: 100px;
    }
} */
</style>