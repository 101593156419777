<template>
    <div style="height: 100vh; padding: 15px">
        <div class="app-content">
            <a-card title="จัดการ Booking">
                <template #extra>
                    <router-link v-if="editable" :to="`/app/quotation/add?edit=true&booking=true`">
                        <a-button style="display: flex" type="primary" size="large">
                            <template #icon><span class="material-symbols-outlined">Add</span></template>
                            เพิ่ม Booking
                        </a-button>
                    </router-link>
                </template>
                <a-row >
                    <a-col :span="8">
                        <a-input-search v-model:value="search_input" placeholder="ค้นหา Booking Number, จุดรับ, จุดส่ง" enter-button="ค้นหา"  />
                    </a-col>
                    <a-range-picker 
                        v-model:value="datetime"
                        picker="day"  
                        style="margin-left: 1rem;"
                    />
                    <a-button @click="clear_search" type="dashed" style="margin-left: 1rem;">CLEAR</a-button>
                    <a-col style="margin-left: 1rem;display: flex;align-items: center">
                        <a-switch v-model:checked="show_need_cars" />
                        <span style="margin-left: 5px">จัดรถไม่ครบ</span>
                    </a-col>
                    <a-col style="margin-left: 1rem;display: flex;align-items: center">
                        <a-switch v-model:checked="require_rollcall" />
                        <span style="margin-left: 5px">อนุญาติเฉพาะรถผ่าน RollCall</span>
                    </a-col>
                </a-row>
                <a-space v-if="editable" style="margin-top: 5px">
                    <a-col style="display: flex;">
                        <a-button style="margin-right: 1rem" @click="export_job_order()" :disabled="!selected_booking" type="primary">
                            ออกใบงาน(คนขับ)
                        </a-button>
                        <a-select v-if="selected_booking" v-model:value="selected_driver_id" :disabled="selected_booking.drivers.length == 0" style="width: 200px" placeholder="เลือกคนขับ">
                            <a-select-option :value="null" >ทั้งหมด</a-select-option>
                            <a-select-option v-for="driver in selected_booking.drivers" :value="driver.id" :key="driver.id">
                                {{ driver?.firstname }} {{ driver?.lastname }}
                            </a-select-option>
                        </a-select>
                    </a-col>
                    <a-col>
                        <a-button @click="show_time_line()" type="primary">
                            Show Timeline
                        </a-button>
                    </a-col>
                    <a-col>
                        <a-button @click="add_to_billing()" type="primary" style="display: flex">
                            <template #icon><span class="material-symbols-outlined">Add</span></template>
                            เพิ่มเป็น Billing
                        </a-button>
                    </a-col>
                    <a-col>
                        <a-button :disabled="!this.selected_booking" @click="export_booking_gas_report()" type="primary" style="display: flex">
                            <template #icon><span class="material-symbols-outlined">Add</span></template>
                            ออกรายงานน้ำมัน/ระยะทาง
                        </a-button>
                    </a-col>
                </a-space>
                <a-space v-if="editable" style="margin-top: 5px">
                    <a-col>
                        <a-button @click="(show_monthly_report = true)" type="primary">
                            ออกรายงานการจอง
                        </a-button>
                    </a-col>
                    <a-col>
                        <a-button @click="export_stops()" type="primary">
                            ออกรายงานจุดแวะ
                        </a-button>
                    </a-col>
                    <a-col>
                        <a-button @click="export_car_used()" type="primary">
                            ออกรายงานการเดินรถ
                        </a-button>
                    </a-col>
                </a-space>
                
                <a-modal v-model:visible="show_monthly_report" title="ออกรายงานประจำเดือน">
                    <a-form-item style="margin: 0" label="เลือกเดือนที่ต้องการออกรายงาน" />
                        <a-range-picker v-model:value="monthly_report_date" :placeholder="['เริ่มต้น','สิ้นสุด']" picker="month" />
                    <template #footer>
                        <a-space>
                            <a-button key="back" @click="show_monthly_report = false">ปิด</a-button>
                            <a-button key="submit" :loading:value="loading" @click="submit_monthly_report()" type="primary">ตกลง</a-button>
                        </a-space>
                    </template>
                </a-modal>

                <a-tabs v-model:activeKey="activeKey">
                    <a-tab-pane v-for="{ icon, title, status } in [
                        { icon: 'menu', title: 'ทั้งหมด', status: null },
                        { icon: 'list', title: 'แนะนำการจัดรถ', status: 0 },
                        { icon: 'list', title: 'รอจัดรถ', status: 1 },
                        { icon: 'list', title: 'จัดรถแล้ว', status: 2 },
                        { icon: 'list', title: 'ถึงจุดรับรถแล้ว', status: 3 },
                        { icon: 'list', title: 'ส่งลูกค้าแล้ว', status: 4 },
                        { icon: 'list', title: 'วิ่งรถเรียบร้อยแล้ว', status: 5 },
                        { icon: 'list', title: 'ตรวจสอบแล้ว', status: 100 },
                        { icon: 'list', title: 'ยกเลิกการเดินทาง', status: 6 },
                        { icon: 'list', title: 'กำลังเดินทาง', status: 7 },
                        { icon: 'list', title: 'รับงาน', status: 8 },
                        { icon: 'list', title: 'เริ่มงาน', status: 9 },
                        { icon: 'list', title: 'รับลูกค้าขึ้นยานพาหนะ', status: 10 },
                        { icon: 'list', title: 'พร้อมเริ่มงาน', status: 11 },//11
                        { icon: 'list', title: 'รับของ', status: 12 },//12
                        { icon: 'list', title: 'รับของเสร็จสิ้น', status: 13 },//13
                    ]" :key="status">
                        <template #tab>
                            <a-badge :count="count_by_status(booking_list, status)">
                                <div class="google-icon">
                                    <span class="material-symbols-outlined">{{ icon }}</span>
                                    <span>{{ title }}</span>
                                </div>
                            </a-badge>
                        </template>
                    </a-tab-pane>
                </a-tabs>
                <a-table rowKey="id" :columns="[
                    {
                        title: 'QTC',
                        dataIndex: 'booking_no',
                        key: 'booking_no',
                        width: 160,
                        sorter: {
                            compare: (a, b) => a.booking_no - b.booking_no,
                            multiple: 1
                        }
                    }, {
                        title: 'วันที่ - เวลา รับ',
                        dataIndex: 'time_start',
                        key: 'time_start',
                        width: 150,
                        align: 'center',
                        sorter: {
                            compare: (a, b) => new Date(a.time_start) - new Date(b.time_start),
                            multiple: 2
                        },
                    }, {
                        title: 'วันที่ - เวลา ส่ง',
                        dataIndex: 'time_end',
                        key: 'time_end',
                        width: 150,
                        align: 'center',
                        sorter: {
                            compare: (a, b) => new Date(a.time_end) - new Date(b.time_end),
                            multiple: 3
                        }
                    }, {
                        title: 'แก้ไขล่าสุด',
                        dataIndex: 'updated_at',
                        key: 'updated_at',
                        width: 200,
                        align: 'center'
                    }, {
                        title: 'จุดรับ',
                        dataIndex: 'start_location_name',
                        key: 'start_location_name',
                        width: 150,
                        align: 'center'
                    }, {
                        title: 'จุดส่ง',
                        dataIndex: 'end_location_name',
                        key: 'end_location_name',
                        width: 150,
                        align: 'center'
                    }, {
                        title: 'ประเภท',
                        dataIndex: 'booking_type',
                        key: 'booking_type',
                        width: 150,
                        align: 'center'
                    }, {
                        title: 'สถานะ',
                        dataIndex: 'status',
                        key: 'status',
                        width: 120,
                        align: 'center'
                    }, {
                        title: 'สถานะงาน',
                        dataIndex: 'active_job',
                        key: 'active_job',
                        width: 120,
                        align: 'center'
                    }, {
                        title: 'สถานะการจัดรถ',
                        dataIndex: 'booking_cars_status',
                        key: 'booking_cars_status',
                        width: 80,
                        align: 'center'
                    },
                    {
                        title: 'จำนวนรถ',
                        dataIndex: 'booking_car_types',
                        key: 'booking_car_types',
                        width: 80,
                        align: 'center'
                    },
                    {
                        title: 'จำนวนรถล่าช้า',
                        dataIndex: 'car_late_amount',
                        key: 'car_late_amount',
                        width: 80,
                        align: 'center'
                    }, {
                        title: 'จำนวนการออกบิล',
                        dataIndex: 'billing_status',
                        key: 'billing_status',
                        width: 120,
                        align: 'center',
                        ellipsis: true
                    }, {
                        title: 'ลูกค้า/บริษัท',
                        dataIndex: 'client_name',
                        key: 'client_name',
                        width: 316
                    }, {
                        title: 'Ref. No',
                        dataIndex: 'booking_no',
                        key: 'refno',
                        width: 140,
                        sorter: {
                            compare: (a, b) => a.booking_no - b.booking_no,
                            multiple: 1
                        }
                    }, {
                        title: 'จำนวนวัน',
                        dataIndex: 'total_days',
                        key: 'total_days',
                        width: 80,
                        align: 'center'
                    }, {
                        title: 'ผู้โดยสาร',
                        dataIndex: 'person_qty',
                        key: 'person_qty',
                        width: 99,
                        align: 'center'
                    }, {
                        title: 'รายได้',
                        dataIndex: 'revenue',
                        key: 'revenue',
                        width: 120,
                        align: 'center',
                        ellipsis: true
                    }, {
                        title: 'รายจ่าย',
                        dataIndex: 'total_cars_expense',
                        key: 'total_cars_expense',
                        width: 99,
                        align: 'center'
                    }, {
                        title: 'กำไรขั้นต้น',
                        dataIndex: 'profit',
                        key: 'profit',
                        width: 99,
                        align: 'center'
                    }, {
                        title: 'สถานะการตรวจสอบ',
                        dataIndex: 'finished',
                        key: 'finished',
                        width: 100,
                        align: 'center'
                    }, {
                        title: 'ตัวเลือก',
                        dataIndex: 'id',
                        key: 'id',
                        width: 160,
                        fixed: 'right',
                        align: 'center',
                    }
                ]" :data-source="filtered_table(booking_list)" bordered size="small" :scroll="{ x: 'calc(100px + 50%)', y: '500px' }"
                    :row-selection="{
                        type: 'radio', onSelect: select_row
                    }"
                    :pagination="{ total: pagination.total, current: pagination.onpage, pageSize: pagination.perPage }"
                    @change="handleTableChange"
                    >
                    <template #bodyCell="{ column, record }">
                        <template v-if="column.key === 'booking_type' && record[column.key]">
                            <span><a-tag :color="tag_option.bookingType[record.booking_type_id]?.color">
                                {{ record.booking_type }}
                            </a-tag></span>
                        </template>
                        <template v-if="(column.key == 'time_start' || column.key == 'time_end') && record[column.key]">
                            <a-tag color="green">{{ render_time(record[column.key]) }}</a-tag>
                        </template>
                        <template v-if="column.key === 'status' && record[column.key] != null">
                            <span><a-tag :color="tag_option.status[record.status]?.color">
                                {{ tag_option.status[record.status]?.txt }}
                            </a-tag></span>
                        </template>
                        <template v-if="column.key == 'profit'">
                            {{ (Number(record.total_price) - Number(sum_car_expense(record.booking_cars))).toLocaleString({ maximumFractionDigits: 2 }) }}
                        </template>
                        <template v-if="column.key === 'active_job'">
                            <a-button type="danger" @click="activate_job(record.id)" :disabled="record.active_job == 1">{{ record.active_job? 'แจ้งงานแล้ว':'แจ้งงาน' }}</a-button>
                        </template>
                        <template v-if="column.key === 'finished'">
                            <a-button type="primary" @click="on_booking_finished(record.id)" :disabled="record.finished==1">{{ record.finished? 'ตรวจสอบแล้ว':'ตรวจสอบ' }}</a-button>
                        </template>
                        <template v-if="column.key === 'total_days'" >
                            {{ record.total_days + 1 }}
                        </template>
                        <template v-if="column.key === 'revenue' && record.total_price">
                            {{ record.total_price.toLocaleString() }}
                        </template>
                        <template v-if="column.key === 'billing_status' && record[column.key]">
                            <a-tooltip placement="topLeft">
                                <template #title>
                                    <a-tag v-for="{ billing_type, billing_type_id }, i in record.billings" :key="i" :color="billing_color[billing_type_id]">
                                        {{ billing_type }}
                                    </a-tag>
                                </template>
                                <a>{{ record.billings.length }}</a>
                            </a-tooltip>
                        </template>
                        <template v-if="column.key === 'total_cars_expense'">
                            <div style="display: flex;align-items: center;justify-content: center;">
                                <span style="font-size: 16px;">{{ sum_car_expense(record.booking_cars).toLocaleString() }} </span>
                                <a @click="show_total_cars_expense(record.id)" :style="`height: 16px; color: ${ allIsApproved(record.booking_cars) && sum_car_expense(record.booking_cars)? 'greenyellow':'red'}`">
                                    <span style="font-size: 16px;line-height: 0;" class="material-symbols-outlined">pie_chart</span>
                                </a>
                            </div>
                        </template>
                        <template v-if="column.key === 'id' && record[column.key]">
                            <div>
                                <a-tooltip>
                                    <template #title>อัพเดทข้อมูล</template>
                                    <a @click="show_update_progress(record.id)"><span style="font-size: 14px"
                                        class="material-symbols-outlined">update</span></a>
                                </a-tooltip>
                                
                                <a-divider type="vertical"></a-divider>
                                <a-tooltip>
                                    <template #title>แก้ไข</template>
                                    <a @click="go_to_edit_quotation(record.id)"><span style="font-size: 14px"
                                            class="material-symbols-outlined">drive_file_rename_outline</span></a>
                                </a-tooltip>
                                <a-divider type="vertical"></a-divider>
                                <a-tooltip>
                                    <template #title>ยกเลิก</template>
                                    <a @click="remove_booking(record.id)"><span
                                        style="font-size: 18px" class="material-symbols-outlined">delete</span></a>
                                </a-tooltip>

                                <a-divider type="vertical"></a-divider>
                                <a-tooltip>
                                    <template #title>คัดลอก {{ record.booking_no }}</template>
                                    <a @click="duplicate_booking(record.id)"><span
                                        style="font-size: 18px" class="material-symbols-outlined">file_copy</span></a>
                                </a-tooltip>
                            </div>
                        </template>
                        <template v-if="column.key === 'booking_car_types' && record[column.key]">
                            <a-tag :color="record.booking_cars.length == geBookingCarMax(record.booking_car_types) ? 'green':'red'">{{ record.booking_cars.length }} / {{ geBookingCarMax(record.booking_car_types) }}</a-tag>
                        </template>
                        <template v-if="column.key === 'booking_cars_status'">
                            <a-tag :color="record.booking_cars.length == geBookingCarMax(record.booking_car_types) ? 'green':'red'">
                                {{record.booking_cars.length == geBookingCarMax(record.booking_car_types)? 'ครบ':
                                record.booking_cars.length > geBookingCarMax(record.booking_car_types)? 'เกิน':'ไม่ครบ'
                            }}
                            </a-tag>
                        </template>
                        <template v-if="column.key == 'updated_at'" >
                           {{ record.updated_at }} <a-tag v-if="compare_time(record.update_at, record.billings)" :bordered="false" color="error">!</a-tag>
                        </template>
                    </template>
                </a-table>
            </a-card>
        </div>

        <a-modal
            v-if="selected_booking"
            v-model:visible="show_timeline"
            :title="`รายละเอียด Booking ID :${selected_booking.booking_no}`"
        >
            <a-timeline direction="vertical" :current="1">
                <a-timeline-item v-for="{ stop_type_id, stop_type, expected_timestamp, name, remark } in selected_booking.stops" >
                    <p>
                        <span><a-tag :color="tag_option.status[stop_type_id]?.color">{{ stop_type}}
                        </a-tag></span> {{ render_time(expected_timestamp) }} {{ name }} <br>
                        โดย {{ selected_booking.sale.username }}<br>
                        หมายเหตุ {{remark?remark:'-' }}
                    </p>
                </a-timeline-item>
            </a-timeline>
            <template #footer>
                <a-space>
                    <a-button key="back" @click="show_timeline = false">ปิด</a-button>
                </a-space>
            </template>
        </a-modal>

        <a-modal
            v-model:visible="update_progress_modal"
            :title="`Booking ID :${selected_booking?.booking_no}`"
            @ok="update_progress"
        >
            <a-card>
                <a-form :model="selected_booking" layout="vertical">
                    <a-form-item label="สถานะ">
                        <a-select v-model:value="selected_booking.status" placeholder="เลือกสถานะ">
                            <a-select-option v-for="{ txt }, i in tag_option.status" :key="i" :value="i" selected>
                                {{ txt }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </a-form>
            </a-card>
        </a-modal>

        <a-modal
            v-model:visible="total_cars_expense_modal"
            :title="`ภาพรวมค่าใช้จ่าย Booking ID :${selected_booking?.booking_no}`"
            width="80%"
            @ok="save_car_expenses"
            :footer="null"
        >
            <div v-if="total_car_expenses">

                <a-collapse v-model:activeKey="total_car_expenses_collapse" expand-icon-position="right" style="background-color: white;font-size: 18px;font-weight: 500">
                    <a-collapse-panel key="1" :header="`1. ค่าทางด่วน - ${total_car_expenses.toll_ways.filter(i => i.granted).length } / ${total_car_expenses.toll_ways.length }`">
                        <template #extra> {{ total_car_expenses.toll_ways.filter(({ granted }) => granted == 1).reduce((a,b) => a + b.amount, 0).toLocaleString() }} บาท</template>
                        <a-button @click="show_create_toll_way = true" type="primary" style="margin-bottom: 10px">เพิ่มค่าทางด่วน</a-button>
                        <a-table :dataSource="total_car_expenses.toll_ways" :pagination="false" :columns="[
                            {
                                title: 'ทะเบียนรถ',
                                dataIndex: 'plate_no',
                                key: 'plate_no'
                            },
                            {
                                title: 'ผู้ชำระ',
                                dataIndex: 'driver_paid',
                                key: 'driver_paid',
                                align: 'center',
                                width: 100,
                            },
                            {
                                title: 'จำนวนเงิน',
                                dataIndex: 'amount',
                                key: 'amount',
                                align: 'center',
                                width: 150,
                            },
                            {
                                title: 'วัน-เวลา',
                                dataIndex: 'created_at',
                                key: 'created_at',
                                align: 'center',
                                width: 180,
                            },
                            {
                                title: 'หมายเหตุ',
                                dataIndex: 'remark',
                                key: 'remark'
                            },
                            {
                                title: 'สถานะ',
                                dataIndex: 'granted',
                                key: 'granted',
                                align: 'center',
                                width: 150,
                            },
                            {
                                title: 'Action',
                                dataIndex: 'id',
                                key: 'id',
                                align: 'center',
                                width: 100,
                            }
                        ]" >
                            <template #bodyCell="{ column, record }">
                                <template v-if="column.key === 'driver_paid'">
                                    {{ record.driver_paid ? 'คนขับรถ' : 'บริษัท' }}
                                </template>
                                <template v-if="column.key === 'created_at'">
                                    {{ render_date(record.created_at) }}
                                </template>
                                <template v-if="column.key === 'granted'">
                                    <a-select v-model:value="record.granted" @change="update_granted(record, 'toll_ways', $event, total_car_expenses.toll_ways)" style="width: 120px" placeholder="เลือกสถานะ">
                                        <a-select-option :value="null" >รออนุมัต</a-select-option>
                                        <a-select-option v-for="{ id, name } in expense_logs" :key="id" :value="id">
                                            {{ name }}
                                        </a-select-option>
                                    </a-select>
                                </template>
                                <template v-if="column.key === 'id' && record[column.key]">
                                <div>
                                    <a-tooltip>
                                        <template #title>แก้ไข</template>
                                        <a @click="edit_expense_log(record, 'toll_ways')"><span style="font-size: 14px"
                                                class="material-symbols-outlined">drive_file_rename_outline</span></a>
                                    </a-tooltip>
                                    <a-divider type="vertical"></a-divider>
                                    <a-tooltip>
                                        <template #title>ยกเลิก</template>
                                        <a @click="remove_expense_log(record.id, 'toll_ways')"><span
                                            style="font-size: 18px" class="material-symbols-outlined">delete</span></a>
                                    </a-tooltip>
                                </div>
                            </template>
                            </template>
                        </a-table>
                    </a-collapse-panel>

                    <a-collapse-panel key="2" :header="`2. ค่าน้ำมัน - ${ total_car_expenses.gas_refills.filter(i => i.granted).length } / ${ total_car_expenses.gas_refills.length } `">
                        <template #extra> {{ total_car_expenses.gas_refills.filter(({ granted }) => granted == 1).reduce((a,b) => a + b.amount, 0).toLocaleString() }} บาท</template>
                        <a-button @click="show_gas_refill_drawer = true" type="primary" style="margin-bottom: 10px">เพิ่มค่าน้ำมัน</a-button>
                        <a-table :dataSource="total_car_expenses.gas_refills" :pagination="false" :columns="[
                            {
                                title: 'ทะเบียนรถ',
                                dataIndex: 'plate_no',
                                key: 'plate_no'
                            },
                            {
                                title: 'การชำระเงิน',
                                dataIndex: 'pay_method_id',
                                key: 'pay_method_id',
                                align: 'center',
                                width: 120,
                            },
                            {
                                title: 'จำนวนเงิน',
                                dataIndex: 'amount',
                                key: 'amount',
                                align: 'center',
                                width: 150,
                            },
                            {
                                title: 'วัน-เวลา',
                                dataIndex: 'created_at',
                                key: 'created_at',
                                align: 'center',
                                width: 180,
                            },
                            {
                                title: 'หมายเหตุ',
                                dataIndex: 'remark',
                                key: 'remark'
                            },
                            {
                                title: 'สถานะ',
                                dataIndex: 'granted',
                                key: 'granted',
                                align: 'center',
                                width: 150,
                            },
                            {
                                title: 'Action',
                                dataIndex: 'id',
                                key: 'id',
                                align: 'center',
                                width: 100,
                            }
                        ]" >
                            <template #bodyCell="{ column, record }">
                                <template v-if="column.key === 'pay_method_id'">
                                    {{ pay_method_list.find((e)=> record.pay_method_id == e.id)?.name }}
                                </template>
                                <template v-if="column.key === 'created_at'">
                                    {{ render_date(record.created_at) }}
                                </template>
                                <template v-if="column.key === 'granted'">
                                    <a-select v-model:value="record.granted" @change="update_granted(record, 'gas_refills', $event, total_car_expenses.gas_refills)" style="width: 120px" placeholder="เลือกสถานะ">
                                        <a-select-option :value="null" >รออนุมัต</a-select-option>
                                        <a-select-option v-for="{ id, name } in expense_logs" :key="id" :value="id">
                                            {{ name }}
                                        </a-select-option>
                                    </a-select>
                                </template>
                                <template v-if="column.key === 'id' && record[column.key]">
                                <div>
                                    <a-tooltip>
                                        <template #title>แก้ไข</template>
                                        <a @click="edit_expense_log(record, 'gas_refills')"><span style="font-size: 14px"
                                                class="material-symbols-outlined">drive_file_rename_outline</span></a>
                                    </a-tooltip>
                                    <a-divider type="vertical"></a-divider>
                                    <a-tooltip>
                                        <template #title>ยกเลิก</template>
                                        <a @click="remove_expense_log(record.id, 'gas_refills')"><span
                                            style="font-size: 18px" class="material-symbols-outlined">delete</span></a>
                                    </a-tooltip>
                                </div>
                            </template>
                            </template>
                        </a-table>
                    </a-collapse-panel>

                    <a-collapse-panel key="3" :header="`3. ค่าใช่จ่ายเพิ่มเติม - ${ total_car_expenses.expenses.filter(i => i.granted).length } / ${ total_car_expenses.expenses.length } `" >
                        <template #extra> {{ total_car_expenses.expenses.filter(({ granted }) => granted == 1).reduce((a,b) => a + b.amount, 0).toLocaleString() }} บาท</template>
                        <a-button @click="show_create_expense = true" type="primary" style="margin-bottom: 10px">เพิ่มค่าใช้จ่ายเพิ่มเติม</a-button>
                        <a-table :dataSource="total_car_expenses.expenses" :pagination="false" :columns="[
                            {
                                title: 'ทะเบียนรถ',
                                dataIndex: 'plate_no',
                                key: 'plate_no'
                            },
                            {
                                title: 'ผู้ชำระ',
                                dataIndex: 'driver_paid',
                                key: 'driver_paid',
                                align: 'center',
                                width: 100,
                            },
                            {
                                title: 'จำนวนเงิน',
                                dataIndex: 'amount',
                                key: 'amount',
                                align: 'center',
                                width: 150,
                            },
                            {
                                title: 'รายการ',
                                dataIndex: 'name',
                                key: 'name',
                                align: 'center',
                            },
                            {
                                title: 'หมายเหตุ',
                                dataIndex: 'remark',
                                key: 'remark'
                            },
                            {
                                title: 'สถานะ',
                                dataIndex: 'granted',
                                key: 'granted',
                                align: 'center',
                                width: 150,
                            },
                            {
                                title: 'Action',
                                dataIndex: 'id',
                                key: 'id',
                                align: 'center',
                                width: 100,
                            }
                        ]" >
                            <template #bodyCell="{ column, record }">
                                <template v-if="column.key === 'driver_paid'">
                                    {{ record.driver_paid ? 'คนขับรถ' : 'บริษัท' }}
                                </template>
                                <template v-if="column.key === 'granted'">
                                    <a-select v-model:value="record.granted" @change="update_granted(record, 'expenses', $event, total_car_expenses.expenses)" style="width: 120px" placeholder="เลือกสถานะ">
                                        <a-select-option :value="null" >รออนุมัต</a-select-option>
                                        <a-select-option v-for="{ id, name } in expense_logs" :key="id" :value="id">
                                            {{ name }}
                                        </a-select-option>
                                    </a-select>
                                </template>
                                <template v-if="column.key === 'id' && record[column.key]">
                                <div>
                                    <a-tooltip>
                                        <template #title>แก้ไข</template>
                                        <a @click="edit_expense_log(record, 'expenses')"><span style="font-size: 14px"
                                                class="material-symbols-outlined">drive_file_rename_outline</span></a>
                                    </a-tooltip>
                                    <a-divider type="vertical"></a-divider>
                                    <a-tooltip>
                                        <template #title>ยกเลิก</template>
                                        <a @click="remove_expense_log(record.id, 'expenses')"><span
                                            style="font-size: 18px" class="material-symbols-outlined">delete</span></a>
                                    </a-tooltip>
                                </div>
                            </template>
                            </template>
                        </a-table>
                    </a-collapse-panel>

                    <a-collapse-panel key="4" :header="`4. ต้นทุนค่าเช่ารถ`" >
                        <template #extra> {{(Number(selected_booking.booking_cars.map(({ driver_cost, rental_price }) => driver_cost + rental_price).reduce((a,b) => a+b,0))).toLocaleString() }} บาท</template>
                        <span v-for="{ car, driver_cost, rental_price } in selected_booking.booking_cars">
                            ทะเบียนรถ: {{ car?.plate_no }} : {{ (Number(driver_cost) + Number(rental_price)).toLocaleString() }} บาท<br>
                        </span>
                    </a-collapse-panel>
                </a-collapse>

                <a-card>
                    <div style="display: flex;justify-content: space-between;">
                            <h4>รวมรายจ่าย</h4>
                        <a-space>
                            <!-- <a-button type="primary" :disabled="selected_booking.paid_expense" @click="on_expense_paided">{{ selected_booking.paid_expense?'จ่ายแล้ว':'จ่าย'}}</a-button> -->
                            <h4 style="margin: auto;">{{ sum_car_expense(selected_booking.booking_cars).toLocaleString() }} บาท</h4>
                        </a-space>
                    </div>
                </a-card>

                <a-drawer
                    v-model:visible="show_gas_refill_drawer"
                    placement="right"
                    title="บันทึกค่าน้ำมัน"
                    @close="create_gas_refill = {}"
                >
                    <a-form
                        name="create_gas_refill" autocomplete="off" layout="vertical"
                        :model="create_gas_refill"
                        @finish="submit_create_gas_refill"
                    >
                        <a-row>
                            <a-col :span="12">
                                <a-form-item label="ทะเบียนรถ" name="booking_car_id" :rules="[{ required: true, message: 'กรุณาเลือกรถ' }]">
                                    <a-select v-model:value="create_gas_refill.booking_car_id" placeholder="เลือกรถ">
                                        <a-select-option v-for="{ id, car: { plate_no } } in selected_booking.booking_cars" :key="id" :value="id">
                                            {{ plate_no }}
                                        </a-select-option>
                                    </a-select>
                                </a-form-item>
                            </a-col>
                        </a-row>

                        <a-switch v-model:checked="create_gas_refill.use_gas_station" checkedChildren="ปั้มที่ร่วมกับบริษัท" unCheckedChildren="ปั้มนอก" />
                        <a-form-item v-if="create_gas_refill.use_gas_station" label="เลือกปั้มน้ำมัน" name="gas_station_id" :rules="[{ required: true, message: 'เลือกปั้มน้ำมัน' }]">
                            <a-select v-model:value="create_gas_refill.gas_station_id" placeholder="เลือกปั้มน้ำมัน">
                                <a-select-option v-for="{ id, name } in gas_station_list" :key="id" :value="id">
                                    {{ name }}
                                </a-select-option>
                            </a-select>   
                        </a-form-item>
                        <a-form-item v-else label="ระบุชื่อปั้ม" name="station_name" :rules="[{ required: true, message: 'เลือกปั้มน้ำมัน' }]">
                            <a-textarea v-model:value="create_gas_refill.station_name" />
                        </a-form-item>

                        <a-row>
                            <a-col :span="12">
                                <a-form-item label="จำนวนลิตร" name="refill_amount" :rules="[{ required: true, message: 'กรุณากรอกจำนวนลิตร' }]">
                                    <a-input-number v-model:value="create_gas_refill.refill_amount" :min="0" :max="1000" />
                                </a-form-item>
                            </a-col>
                            <a-col :span="12">
                                <a-form-item label="ยอดจ่าย" name="amount" :rules="[{ required: true, message: 'กรุณากรอกยอดจ่าย' }]">
                                    <a-input-number v-model:value="create_gas_refill.amount" :min="0" :max="100000" />
                                </a-form-item>
                            </a-col>
                        </a-row>

                        <a-form-item label="ประเภทการจ่ายเงิน" name="pay_method_id" :rules="[{ required: true, message: 'เลือกประเภทการจ่ายเงิน' }]">
                            <a-select v-model:value="create_gas_refill.pay_method_id" placeholder="เลือกประเภทการจ่ายเงิน">
                                <a-select-option v-for="{ id, name } in pay_method_list" :key="id" :value="id">
                                    {{ name }}
                                </a-select-option>
                            </a-select>
                        </a-form-item>

                        <a-form-item label="วันเวลา" name="datetime" :rules="[{ required: true, message: 'กรุณากรอกวันเวลา' }]">
                            <a-date-picker v-model:value="create_gas_refill.datetime" 
                                :show-time="{ format: 'HH:mm' }"
                                format="DD/MM/YYYY HH:mm"
                                placeholder="เลือกเวลา"
                                :minuteStep="1" />
                        </a-form-item>

                        <a-form-item label="หมายเหตุ" name="remark">
                            <a-textarea v-model:value="create_gas_refill.remark" />
                        </a-form-item>

                        <a-row justify="end">
                            <a-button html-type="submit" type="primary" size="large" style="margin: 4px;text-align: right">
                                <span class="material-symbols-outlined"
                                    style="font-size: 16px;margin-right: 4px">Edit</span>
                                บันทึกข้อมูล
                            </a-button>
                        </a-row>
                    </a-form>
                </a-drawer>

                <a-drawer
                    v-model:visible="show_create_toll_way"
                    placement="right"
                    title="เพิ่มรายการค่าทางด่วน"
                >
                    <a-form
                        :model="create_toll_way"
                        layout="vertical"
                        @finish="submit_create_toll_way"
                    >
                        <a-row>
                            <a-col :span="12">
                                <a-form-item label="ทะเบียนรถ" name="booking_car_id" :rules="[{ required: true, message: 'กรุณาเลือกรถ' }]">
                                    <a-select v-model:value="create_toll_way.booking_car_id" placeholder="เลือกรถ">
                                        <a-select-option v-for="{ id, car: { plate_no } } in selected_booking.booking_cars" :key="id" :value="id">
                                            {{ plate_no }}
                                        </a-select-option>
                                    </a-select>
                                </a-form-item>
                            </a-col>
                        </a-row>

                        <a-form-item label="ผู้ชำระ" name="driver_paid" :rules="[{ required: true, message: 'กรุณากรอกผู้ชำระ' }]">
                            <a-radio-group v-model:value="create_toll_way.driver_paid">
                                <a-radio-button value="1">คนขับ</a-radio-button>
                                <a-radio-button value="0">บริษัท</a-radio-button>
                            </a-radio-group>
                        </a-form-item>

                        <a-form-item label="จำนวนเงิน" name="amount" :rules="[{ required: true, message: 'กรุณากรอกจำนวนเงิน' }]">
                            <a-input-number v-model:value="create_toll_way.amount" :min="0" :max="1000000" />
                        </a-form-item>

                        <a-form-item label="วันเวลา" name="datetime" :rules="[{ required: true, message: 'กรุณากรอกวันเวลา' }]">
                            <a-date-picker v-model:value="create_toll_way.datetime" 
                                :show-time="{ format: 'HH:mm' }"
                                format="DD/MM/YYYY HH:mm"
                                placeholder="เลือกเวลา"
                                :minuteStep="1" />
                        </a-form-item>

                        <a-form-item label="หมายเหตุ" name="remark">
                            <a-textarea v-model:value="create_toll_way.remark" />
                        </a-form-item>

                        <a-row justify="end">
                            <a-button html-type="submit" type="primary" size="large" style="margin: 4px;text-align: right">
                                <span class="material-symbols-outlined"
                                    style="font-size: 16px;margin-right: 4px">Edit</span>
                                บันทึกข้อมูล
                            </a-button>
                        </a-row>
                    </a-form>
                </a-drawer>

                <a-drawer
                    v-model:visible="show_create_expense"
                    placement="right"
                    title="เพิ่มค่าใช้จ่ายเพิ่มเติม"
                >
                    <a-form
                        :model="create_expense"
                        layout="vertical"
                        @finish="submit_create_expense"
                    >
                        <a-row justify="space-between">
                            <a-col :span="11">
                                <a-form-item label="ทะเบียนรถ" name="booking_car_id" :rules="[{ required: true, message: 'กรุณาเลือกรถ' }]">
                                    <a-select v-model:value="create_expense.booking_car_id" placeholder="เลือกรถ">
                                        <a-select-option v-for="{ id, car: { plate_no } } in selected_booking.booking_cars" :key="id" :value="id">
                                            {{ plate_no }}
                                        </a-select-option>
                                    </a-select>
                                </a-form-item>
                            </a-col>

                            <a-col :span="11">
                                <a-form-item label="ผู้ชำระ" name="driver_paid" :rules="[{ required: true, message: 'กรุณาเลือกผู้ชำระ' }]">
                                    <a-radio-group v-model:value="create_expense.driver_paid">
                                        <a-radio-button value="1">คนขับ</a-radio-button>
                                        <a-radio-button value="0">บริษัท</a-radio-button>
                                    </a-radio-group>
                                </a-form-item>
                            </a-col>
                        </a-row>

                        <a-form-item label="รายการ" name="expense_id" :rules="[{ required: true, message: 'กรุณากรอกรายการ' }]">
                            <a-select v-model:value="create_expense.expense_id" >
                                <a-select-option v-for="expense in expense_list" :key="expense.id" :value="expense.id">
                                    {{expense.name}}
                                </a-select-option>
                            </a-select>
                        </a-form-item>

                        <a-row justify="space-between">
                            <a-col :span="24">
                                <a-form-item label="จำนวนเงิน" name="amount" :rules="[{ required: true, message: 'กรุณากรอกจำนวนเงิน' }]">
                                    <a-input-number v-model:value="create_expense.amount" />
                                </a-form-item>
                            </a-col>
                        </a-row>

                        <a-form-item label="หมายเหตุ" name="remark">
                            <a-textarea v-model:value="create_expense.remark" />
                        </a-form-item>

                        <a-row justify="end">
                            <a-button html-type="submit" type="primary" size="large" style="margin: 4px;text-align: right">
                                <span class="material-symbols-outlined"
                                    style="font-size: 16px;margin-right: 4px">Edit</span>
                                บันทึกข้อมูล
                            </a-button>
                        </a-row>
                    </a-form>
                </a-drawer>

            </div>
        </a-modal>

        <JobOrder
            v-if="job_order"
            :booking_detail="export_data"
            :user_name="user_name"
            :selected_driver_id="selected_driver_id"
            @close="job_order = false"
        />
    </div>
</template>

<script>
import dayjs from 'dayjs'
import buddhistEra from 'dayjs/plugin/buddhistEra'
dayjs.extend(buddhistEra);
import BookingService from '../../api/BookingService';
import { exportXLSXFile } from '@/components/helpers.js'
import ReportService from '../../api/ReportService';
import Swal from 'sweetalert2';
import JobOrder from '@/components/modals/job_order.vue';
import Utility from '../../utility'
import SystemService from '../../api/SystemService';
import { message } from 'ant-design-vue';

export default {
    components: { JobOrder },
    props: {
        user_name: String,
    },
    name: 'Booking',
    data() {
        return {
            editable: Utility.get_permission_editable_with_key('booking'),
            booking_list: [],
            pagination: {
                total: 0,
                onpage: 1,
                perPage: 20
            },
            ///filter
            search_input: null,
            datetime: null,

            activeKey: null,
            show_need_cars: false,
            require_rollcall: false,
            selected_driver_id: null,
            tag_option: {
                bookingType: {
                    1: { txt: 'เที่ยวเดียว (ขาเดียว)', color: 'pink' },
                    2: { txt: 'ไป-กลับ', color: 'red' },
                    3: { txt: 'รายวัน (เต็มวัน)', color: 'orange'},
                    4: { txt: 'รายวัน (ครึ่งวัน)', color: 'green' },
                    5: { txt: 'เฉพาะวัน (ส่ง-รับ วันกลางไม่ใช้รถ)', color: 'cyan' },
                    6: { txt: 'เฉพาะวัน (ระบุวัน)', color: 'blue' },
                    7: { txt: 'ใช้รถทุกวัน', color: 'purple' },
                    8: { txt: 'รายเดือน', color: 'green' },
                },
                status: [ 
                    { txt: 'แนะนำการจัดรถ', color: 'processing'},
                    { txt: 'รอจัดรถ', color: 'processing'},
                    { txt: 'จัดรถแล้ว', color: 'warning'},
                    { txt: 'ถึงจุดรับรถแล้ว', color: 'warning'},
                    { txt: 'ส่งลูกค้าแล้ว', color: 'success'},
                    { txt: 'วิ่งรถเรียบร้อยแล้ว', color: 'success'},
                    { txt: 'ยกเลิกการเดินทาง', color: 'error'},
                    { txt: 'กำลังเดินทาง', color: 'warning'},
                    { txt: 'รับงาน', color: 'success'},
                    { txt: 'เริ่มงาน', color: 'success'},
                    { txt: 'รับลูกค้าขึ้นยานพาหนะ', color: 'success' },
                    { txt: 'พร้อมเริ่มงาน', color: 'success' },//11
                    { txt: 'รับของ', color: 'success' },//12
                    { txt: 'รับของเสร็จสิ้น', color: 'success' },//13
                ]
            },
            billing_color: {
                1: 'green',
                2: 'red',
                3: 'purple',
                4: 'yellow'
            },
            selected_booking: null,
            export_data: null,
            show_monthly_report: false,
            monthly_report_date: null,
            loading: false,
            show_timeline: false,
            job_order: false,

            /* update_progress_modal */
            update_progress_modal: false,

            /* total_cars_expense_modal */
            total_cars_expense_modal: false,

            pay_method_list: [],
            expense_log: {
                toll_ways: null,
                gas_refills: null,
                extras: null,
            },
            expense_logs: [
                {
                    id: 1,
                    name: 'อนุมัติ',
                },
                {
                    id: 2,
                    name: 'ไม่อนุมัติ',
                }
            ],
            show_gas_refill_drawer: false,
            create_gas_refill: {},
            gas_station_list: [],

            show_create_toll_way: false,
            create_toll_way: {},

            show_create_expense: false,
            create_expense: {},
            expense_list: [],

            total_car_expenses_collapse: null
        }
    },
    computed: {
        total_car_expenses() {
            const cars = this.selected_booking.booking_cars
            const tollways_list = []
            const gas_refills_list = []
            const expenses_list = []

            cars.forEach(({ toll_ways, gas_refills, expenses, car }) => {
                toll_ways.forEach(tollway => {
                    tollway.plate_no = car.plate_no
                    tollways_list.push(tollway)
                })
                
                gas_refills.forEach(gas_refill => {
                    gas_refill.plate_no = car.plate_no
                    gas_refills_list.push(gas_refill)
                })

                expenses.forEach((expense) => {
                    const { expense: { name }, amount, id, granted, driver_paid, remark } = expense
                    expenses_list.push({
                        id,
                        name,
                        amount,
                        plate_no: car.plate_no,
                        granted,
                        remark,
                        driver_paid
                    })
                })
            })

            return {
                toll_ways: tollways_list,
                gas_refills: gas_refills_list,
                expenses: expenses_list
            }
        },
    },
    methods: {
        handleTableChange(pagination, filters, sorter) {
            this.pagination.onpage = pagination.current
            this.pagination.perPage = pagination.pageSize;

            this.init_bookings()
        },
        compare_time(time, array) {
            const lastupdate = array.map(({ created_at }) => created_at)
            //if time is greater than any of lastupdate and no any of lastupdate is greater than time
            return array? lastupdate.some((lastupdate) => dayjs(time).isAfter(lastupdate)):false
        },
        geBookingCarMax(car_types){
            return car_types.map(({ quantity }) => quantity? quantity:0).reduce((a,b) => a+b,0)
        },
        async refresh_total_cars_expense_modal() {
            message.loading({ content: 'กำลังโหลดข้อมูลใหม่...', key: 'refresh_total_cars_expense_modal' })
            // this.total_cars_expense_modal = false
            await this.init_bookings()  
            this.selected_booking = this.booking_list.find(booking => booking.id === this.selected_booking.id)
            // this.total_cars_expense_modal = true
            message.success({ content: 'โหลดข้อมูลเรียบร้อยแล้ว', key: 'refresh_total_cars_expense_modal' })
        },
        async update_granted({ id, remark }, type, value, list) {
            try {
                const result = { 
                    remark,
                    granted: value
                }
                if (value == 2) {
                    await Swal.fire({
                        title: 'กรุณาระบุเหตุผลที่ไม่อนุมัติ',
                        input: 'text',
                        inputAttributes: {
                            autocapitalize: 'off'
                        },
                        showCancelButton: true,
                        confirmButtonText: 'ยืนยัน',
                        cancelButtonText: 'ยกเลิก',
                        showLoaderOnConfirm: true,
                        preConfirm: (value) => {
                            return value
                        },
                        allowOutsideClick: () => !Swal.isLoading(),
                    }).then(({ isConfirmed, value }) => {
                        // console.log({ isConfirmed, value, type });
                        if (value && isConfirmed) {
                            if (result.remark?.includes('ไม่อนุมัติ')) {
                                result.remark = result.remark.split(' (ไม่อนุมัติ')[0]
                                result.remark = `${result.remark} (ไม่อนุมัติ: ${value})`
                            } else {
                                result.remark = `${result.remark? result.remark:''} (ไม่อนุมัติ: ${value})`
                            }

                            list.forEach((item) => {
                                if (item.id === id) {
                                    item.remark = result.remark
                                }
                            })
                        } else {
                            list.forEach((item) => {
                                if (item.id === id) {
                                    item.granted = null
                                }
                            })
                            throw 'ยกเลิกการอัพเดท'
                        }
                    })
                }

                switch (type) {
                    case 'toll_ways':
                        SystemService.update_all('TollWay', { data: result }, id)
                            .then(() => {
                                this.refresh_total_cars_expense_modal()
                                message.success('อัพเดทข้อมูลสำเร็จ')
                            })
                        break;
                    case 'gas_refills':
                        SystemService.update_all('GasRefill', { data: result }, id)
                            .then(() => {
                                this.refresh_total_cars_expense_modal()
                                message.success('อัพเดทข้อมูลสำเร็จ')
                            })
                        break;
                    case 'expenses':
                        SystemService.update_all('BookingCarExpense', { data: result }, id)
                            .then(() => {
                                this.refresh_total_cars_expense_modal()
                                message.success('อัพเดทข้อมูลสำเร็จ')
                            })
                        break;
                }

            } catch (err) {
                console.log(err);
            }
        },
        submit_create_expense() {
            const e = this.create_expense
            delete e.plate_no
            delete e.modelname
            
            const { id } = e
            if (id) {
                SystemService.update_all('BookingCarExpense', { data: e }, id)
                    .then(() => {
                        message.success('อัพเดทข้อมูลสำเร็จ')
                        this.show_create_expense = false

                        this.create_expense = {}
                        this.refresh_total_cars_expense_modal()
                    })
            } else {
                SystemService.create_all('BookingCarExpense', { data: [e] })
                    .then(() => {
                        message.success('เพิ่มข้อมูลสำเร็จ')
                        this.show_create_expense = false

                        this.create_expense = {}
                        this.refresh_total_cars_expense_modal()
                    })
            }
        },
        submit_create_toll_way() {
            const e = this.create_toll_way
            const { id } = e
            e.created_at = e.datetime.format('YYYY-MM-DD HH:mm:ss')
            delete e.datetime
            delete e.plate_no
            delete e.modelname

            if (id) {
                SystemService.update_all('TollWay', { data: e }, id)
                    .then(() => {
                        message.success('อัพเดทข้อมูลสำเร็จ')
                        this.show_create_toll_way = false

                        this.create_toll_way = {}
                        this.refresh_total_cars_expense_modal()
                    })
            } else {
                SystemService.create_all('TollWay', { data: [e] })
                    .then(() => {
                        message.success('เพิ่มข้อมูลสำเร็จ')
                        this.show_create_toll_way = false
                        
                        this.create_toll_way = {}
                        this.refresh_total_cars_expense_modal()
                    })
            }
        },
        submit_create_gas_refill() {
            const e = this.create_gas_refill
            const { id } = e
            e.created_at = e.datetime.format('YYYY-MM-DD HH:mm:ss')
            delete e.datetime
            delete e.use_gas_station
            delete e.plate_no
            delete e.modelname
            
            if (id) {
                SystemService.update_all('GasRefill', { data: e }, id)
                    .then(() => {
                        message.success('อัพเดทข้อมูลสำเร็จ')
                        this.show_gas_refill_drawer = false
                        
                        this.create_gas_refill = {}
                        this.refresh_total_cars_expense_modal()
                    })
            } else {
                SystemService.create_all('GasRefill', { data: [e] })
                    .then(() => {
                        message.success('เพิ่มข้อมูลสำเร็จ')
                        this.show_gas_refill_drawer = false

                        this.create_gas_refill = {}
                        this.refresh_total_cars_expense_modal()
                    })
            }
        },
        edit_expense_log(data, type) {
            switch (type) {
                case 'toll_ways':
                    this.show_create_toll_way = true
                    this.create_toll_way = data
                    this.create_toll_way.datetime = dayjs(data.created_at, 'YYYY-MM-DD HH:mm:ss')
                    break;
                case 'gas_refills':
                    this.show_gas_refill_drawer = true
                    this.create_gas_refill = data
                    this.create_gas_refill.datetime = dayjs(data.created_at, 'YYYY-MM-DD HH:mm:ss')
                    this.create_gas_refill.use_gas_station = data.gas_station_id != null
                    break;
                case 'expenses':
                    this.show_create_expense = true
                    this.create_expense = data
                    this.create_expense.datetime = dayjs(data.created_at, 'YYYY-MM-DD HH:mm:ss')
                    break;
            }
        },
        remove_expense_log(model_id, type) {
            const old_active_key = this.total_car_expenses_collapse
            switch (type) {
                case 'toll_ways':
                    SystemService.delete_all('TollWay', model_id)
                        .then(() => {
                            message.success('ลบข้อมูลสำเร็จ')
                            this.total_car_expenses_collapse = null
                            this.total_car_expenses.toll_ways.splice(this.total_car_expenses.toll_ways.findIndex(({ id }) => id == model_id), 1)
                            this.selected_booking.booking_cars.forEach((car) => {
                                car.toll_ways = car.toll_ways.filter(({ id }) => id != model_id)
                            })
                            this.total_car_expenses_collapse = old_active_key
                        })
                    break;
                case 'gas_refills':
                    SystemService.delete_all('GasRefill', model_id)
                        .then(() => {
                            message.success('ลบข้อมูลสำเร็จ')
                            this.total_car_expenses_collapse = null
                            this.total_car_expenses.gas_refills.splice(this.total_car_expenses.gas_refills.findIndex(({ id }) => id == model_id), 1)
                            this.selected_booking.booking_cars.forEach((car) => {
                                car.gas_refills = car.gas_refills.filter(({ id }) => id != model_id)
                            })
                            this.total_car_expenses_collapse = old_active_key
                        })
                    break;
                case 'expenses':
                    SystemService.delete_all('BookingCarExpense', model_id)
                        .then(() => {
                            message.success('ลบข้อมูลสำเร็จ')
                            this.total_car_expenses_collapse = null
                            this.total_car_expenses.expenses.splice(this.total_car_expenses.expenses.findIndex(({ id }) => id == model_id), 1)
                            this.selected_booking.booking_cars.forEach((car) => {
                                car.expenses = car.expenses.filter(({ id }) => id != model_id)
                            })
                            this.total_car_expenses_collapse = old_active_key
                        })
                    break;
            }
            this.refresh_total_cars_expense_modal()
        },
        async on_expense_paided(){
            SystemService.update_all('Booking', { data: { paid_expense: true } }, this.selected_booking.id, )
                .then(res => {
                    this.total_cars_expense_modal = false
                    this.init_bookings()
                    Swal.fire(
                        'อัพเดทเรียบร้อย',
                        'อัพเดทสถานะเรียบร้อย',
                        'success'
                    )
                })
        },
        render_date(date) {
            return dayjs(date).format('DD/MM/YYYY HH:mm')
        },
        sum_car_expense(booking_cars) {
            return booking_cars.map(({ toll_ways, gas_refills, expenses }) => {
                return toll_ways.reduce((a, b) => a + b.amount, 0) +
                    gas_refills.reduce((a, b) => a + b.amount, 0) +
                    expenses.reduce((a, b) => a + b.amount, 0)
            }).reduce((a, b) => a + b, 0) + (Number(booking_cars.map(({ driver_cost, rental_price }) => driver_cost + rental_price).reduce((a,b) => a+b,0)))
        },
        allIsApproved(booking_cars) {
            return booking_cars.every(({ toll_ways, gas_refills, expenses }) => {
                return toll_ways.every(({ granted }) => granted == 1) &&
                    gas_refills.every(({ granted }) => granted == 1) &&
                    expenses.every(({ granted }) => granted == 1)
            })
        },
        show_billings(bills_array) {
            return bills_array.map(({ billing_type }) => billing_type).join(', ')
        },
        async duplicate_booking(id) {
            message.loading({ content: 'กำลังโหลด...', key: 'loading' });
            const data = await BookingService.duplicate_booking(id)
            await this.init_bookings()
            message.success({ content: 'โหลดข้อมูลสำเร็จ', key: 'loading' });
        },
        async remove_booking(id) {
            Swal.fire({
                title: 'ยืนยันการลบ',
                text: "คุณต้องการลบงานนี้ใช่หรือไม่",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'ยืนยัน',
                cancelButtonText: 'ยกเลิก'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await SystemService.update_all('Booking', { data: { progress: 0 } }, id)
                    this.init_bookings()
                    Swal.fire(
                        'ลบเรียบร้อย',
                        'ลบงานเรียบร้อย',
                        'success'
                    )
                    
                }
            })
        },
        save_car_expenses() {

        },
        show_total_cars_expense(id) {
            this.total_cars_expense_modal = true
            this.selected_booking = this.booking_list.find(booking => booking.id === id)
        },
        activate_job(id) {
            this.$message.loading({ content: 'กำลังโหลด...', key: 'loading' });
            SystemService.update_all('Booking', { data: { active_job: 1 } }, id)
                .then(res => {
                    this.init_bookings()
                    this.$message.success({ content: 'โหลดข้อมูลสำเร็จ', key: 'loading' });
                })
        },
        on_booking_finished(id) {
            this.$message.loading({ content: 'กำลังโหลด...', key: 'loading' });
            SystemService.update_all('Booking', { data: { finished: true } }, id)
                .then(res => {
                    this.init_bookings()
                    this.$message.success({ content: 'โหลดข้อมูลสำเร็จ', key: 'loading' });
                })
        },
        show_update_progress(id) {
            this.update_progress_modal = true
            this.selected_booking = this.booking_list.find(booking => booking.id === id)
        },
        async update_progress() {
            SystemService.update_all('Booking', { data: { status: this.selected_booking.status } }, this.selected_booking.id, )
                .then(res => {
                    this.update_progress_modal = false
                    this.init_bookings()
                    Swal.fire(
                        'อัพเดทเรียบร้อย',
                        'อัพเดทสถานะเรียบร้อย',
                        'success'
                    )
                })
        },
        add_to_billing() {
            SystemService.update_all('Booking', { data: { billing_status: 1 } }, this.selected_booking.id)
            Swal.fire(
                'เพิ่มเรียบร้อย',
                'เพิ่มงานเรียบร้อย',
                'success'
            )
            
        },
        show_time_line() {
            if (this.selected_booking) {
                this.show_timeline = true
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'กรุณาเลือกงาน',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        },
        async export_booking_gas_report() {
            const { id } = this.selected_booking
            const { headers, data } = await ReportService.booking_gas_report(id);
            exportXLSXFile( headers, data, `Booking-Gas-Report-${id}`);
        },
        export_job_order() {
            if (this.selected_booking) {
                this.export_data = JSON.parse(JSON.stringify(this.selected_booking))
                if (this.selected_driver_id) {
                    this.export_data.booking_cars = this.selected_booking.booking_cars.filter(({ driver_id }) => driver_id === this.selected_driver_id)
                    setTimeout(() => {
                        this.job_order = true
                    }, 100)
                } else {
                    setTimeout(() => {
                        this.job_order = true
                    }, 100)
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'กรุณาเลือกงาน',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        },
        async export_car_used() {
            const { headers, data } = await ReportService.car_used();
            exportXLSXFile( headers, data, `CarUsed-Report`);
        },
        async export_stops() {
            const { headers, data } = await ReportService.stops();
            exportXLSXFile( headers, data, `Trip-Report`);
        },
        submit_monthly_report() {
            this.show_monthly_report = false
            const obj = [
                {
                    date: '2021-01-01',
                    incoming: 10,
                    confirmed: 5,
                    confirmed_percentage: 50,
                    cancel: 5,
                    cancel_percentage: 50,
                    turndown: 0,
                    turndown_percentage: 0,
                    urgent: 0,
                    urgent_percentage: 0,
                    advance: 0,
                    advance_percentage: 0,
                    total: 10
                }
            ]

            var headers = {
                    date: 'Month',
                    incoming: 'Incoming',
                    confirmed: 'Confirmed',
                    confirmed_percentage: 'Confirm (%)',
                    cancel: 'Cancel',
                    cancel_percentage: 'Cancel (%)',
                    turndown: 'Turn Down',
                    turndown_percentage: 'TurnDown (%)',
                    urgent: 'Urgent',
                    urgent_percentage: 'Urgent (%)',
                    advance: 'Advance',
                    advance_percentage: 'Advance (&)',
                    total: 'Total'
            }
            
            var fileTitle = `Monthly Report`
            
            exportXLSXFile(headers, obj, fileTitle);
        },
        count_by_status(array, status) {
            return status != null ? array.filter(({ status: booking_status, finished }) => {
                ///finished check
                if(status == 100) return finished;
                else {
                    return booking_status == status && !finished
                }
            }).length: array.length
        },
        clear_search() {
            this.search_input = null
            this.datetime = null
        },
        select_row(selectedRowKeys) {
            this.selected_booking = selectedRowKeys
        },
        select_filter(key, status) {
            console.log({key, status});
        },
        filtered_table(array) {
            return array
                .filter(({ booking_cars, booking_car_types }) => {
                    return this.show_need_cars? booking_cars.length < booking_car_types.map(({ quantity }) => quantity? quantity:0).reduce((a,b) => a+b,0) : true
                })
                .filter(({ booking_no, start_location_name, end_location_name, client_name }) => {
                    return this.search_input? 
                        start_location_name?.includes(this.search_input) ||
                        end_location_name?.includes(this.search_input) ||
                        booking_no.includes(this.search_input) ||
                        client_name.includes(this.search_input)
                        : true
                })
                .filter(({ status, finished }) => {
                    if(this.activeKey != null){
                        ///finished check
                        if(this.activeKey == 100){
                            return finished
                        }else{
                            return status == this.activeKey && !finished
                        }
                    }else{
                        return true
                    }
                })
                .filter(item => {
                    if (this.datetime) {
                        var search_time_start = dayjs(this.datetime[0]).format('YYYY-MM-DD')
                        var search_time_end = dayjs(this.datetime[1]).format('YYYY-MM-DD')
                        return dayjs(item.time_start).unix() >= dayjs(search_time_start).unix() && dayjs(item.time_end).unix() <= dayjs(search_time_end).unix()
                        || (dayjs(item.time_start).unix() >= dayjs(search_time_start).unix() && dayjs(item.time_end).format('YYYY-MM-DD') == dayjs(search_time_end).format('YYYY-MM-DD'))
                    }
                    return true
                })
        },
        render_time(time) {
            return dayjs(time).format('DD/MM/YYYY HH:mm');
        },
        go_to_edit_quotation(id) {
            this.$router.push(`/app/quotation/${id}?edit=true`);
        },
        async init_bookings() {
            this.$message.loading({ content: 'กำลังโหลด...', key: 'loading' });
            const datas = await BookingService.get_bookings(this.pagination.onpage, this.pagination.perPage, 2)
            this.pagination = {
                total: datas.total,
                onpage: datas.page,
                perPage: datas.perPage
            }
            this.booking_list = datas.data
            this.booking_list = this.booking_list.sort((a,b) => dayjs(a.time_start).unix() - dayjs(b.time_start).unix())

            this.require_rollcall = JSON.parse(localStorage.getItem('companyData')).require_rollcall == 1
            this.$message.success({ content: 'โหลดข้อมูลสำเร็จ', key: 'loading' });
        }
    },
    watch: {
        require_rollcall(newVal) {
            if (newVal != null) {
                const { company_id } = JSON.parse(localStorage.getItem('user_profile'))
                SystemService.update_all('Company', { data: { require_rollcall: newVal } }, company_id)
                    .then(res => {
                        const original = JSON.parse(localStorage.getItem('companyData'))
                        original.require_rollcall = newVal
                        localStorage.setItem('companyData', JSON.stringify(original))
                    })
            }
        }
    },
    async mounted() {
        this.init_bookings()
        this.pay_method_list = await SystemService.get_all('PayMethod')
        this.expense_list = await SystemService.get_all('Expense')
        this.gas_station_list = await SystemService.get_all('GasStation')
    }
}
</script>