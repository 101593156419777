
import httpClient from './httpClient';
const prefix = 'core'

export default {
  async get_client_no() {
    const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/client-no`)

    return data
  },
  async check_duplicate_username(modelname, query) {
    const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/check-duplicate-username/${modelname}?${query}`)

    return data
  },
  async check_duplicate(modelname, query) {
    const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/check-duplicate/${modelname}?${query}`)

    return data
  },
  async get_all (modelname, query) {
    const { data } = await httpClient({ requiresAuth: true }).get(`/all/${modelname}${query? '?'+query : ''}`)

    return data
  },
  async get_id (modelname, id) {
    const { data } = await httpClient({ requiresAuth: true }).get(`/id/${modelname}?id=${id}`)

    return data
  },
  async create_all (modelname, body) {
    const { data } = await httpClient({ requiresAuth: true }).post(prefix+`/all/${modelname}`, body)

    return data
  },
  async update_all (modelname, body, id) {
    const { data } = await httpClient({ requiresAuth: true }).put(prefix+`/all/${modelname}/${id}`, body)

    return data
  },
  async delete_all (modelname, id) {
    const { data } = await httpClient({ requiresAuth: true }).delete(prefix+`/all/${modelname}/${id}`)

    return data
  },
  async resource_car () {
    const { data } = await httpClient({ requiresAuth: true }).get('/cars')

    return data
  },
  async places( query ) {
    const { data } = await httpClient({ requiresAuth: true }).get(`/places?name=${query}`)

    return data
  }
}