<template>
    <div class="app-content">
        <a-card :title="title">
            <template #extra>
                <a-button style="display: flex" @click="clear_model_data()" size="large">
                    <template #icon>
                        <span class="material-symbols-outlined">arrow_back_ios</span>
                    </template>
                    ย้อนกลับ
                </a-button>
            </template>
            <a-form :model="model_form" :layout="form_layout" :onFinish="submit_model_data">
                <a-row justify="center">
                    <a-col v-if="default_fields[model_type].photos" :span="8" style="margin: 1rem">
                        <a-card title="รูปภาพ" style="text-align: center">
                            <a-row justify="center"
                                v-for="{ key, label, height, width, description } in default_fields[model_type].photos"
                                style="margin: 1rem 0">
                                <div style="display: flex;flex-direction: column;height: fit-content; margin-bottom: 1rem;">
                                    <p>{{ label }}</p>
                                    <a-upload v-model:file-list="fileList" list-type="picture-card"
                                        class="avatar-uploader" :show-upload-list="false"
                                        action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                                        @change="upload_files($event, key)" method="GET">
                                        <img v-if="model_form[key]" :src="model_form[key]"
                                            :style="`height: ${height}px;width: ${width}px;object-fit: cover`"
                                            alt="avatar" />
                                        <div v-else>
                                            <p class="ant-upload-drag-icon">
                                                <span class="material-symbols-outlined"
                                                    style="font-size: 5rem">upload_file</span>
                                            </p>
                                            คลิกเพื่ออัพโหลดรูปภาพ
                                        </div>
                                    </a-upload>
                                    <a-button danger type="primary" @click="(model_form[key] = '')"
                                        v-if="model_form[key]">
                                        <span class="material-symbols-outlined">remove</span>
                                        ลบ
                                    </a-button>
                                    <p style="color: gray;">{{ description }}</p>
                                </div>
                            </a-row>
                        </a-card>
                    </a-col>

                    <a-col :span="15" style="margin: 1rem; width: 100%;">
                        <a-card title="ข้อมูล">
                            <a-row>
                                <a-col style="margin: 1rem" :span="col_span" v-for="
                                        {
                                            col_span, non_editable, input_type, search_select, modelname, label_key, label, placeholder, required,
                                            target_key, target_modelname, drawer_title, table_columns, drawer_fields, drawer_button_text, drawer_button_text_edit, parent, null_on_change, check_duplicate, check_rule,
                                            on_change
                                        }, key in default_fields[model_type].inputs">
                                    <div v-if="input_type== 'label'">{{ label }}</div>
                                    <a-form-item v-else :name="key" :label="placeholder" :has-feedback="check_duplicate"
                                        :rules="check_duplicate? check_rule: (non_editable ? null : [{ required, message: `โปรดระบุ ${placeholder}` }])">
                                        <a-select v-if="input_type == 'select' && !search_select" v-model:value="model_form[key]" @change="handleChange(null_on_change , on_change)">
                                            <a-select-option v-for="option in selectables[modelname]" :value="option.id"
                                                selected>
                                                {{ option[label_key] }}
                                            </a-select-option>
                                        </a-select>
                                        <a-select
                                            v-else-if="input_type == 'select' && search_select && selectables[modelname]"
                                            v-model:value="model_form[key]"
                                            show-search
                                            :placeholder="placeholder"
                                            :options="selectables[modelname].map((e) => ({ value: e.id, label: e[label_key] }))"
                                            :filter-option="filterOption"
                                            @change="handleChange(null_on_change)"
                                        ></a-select>
                                        <a-select v-else-if="input_type == 'sub_select'"
                                            v-model:value="model_form[key]" :disabled="!model_form[parent.key]">
                                            <a-select-option v-for="option in show_sub_selectables(parent)"
                                                :value="option.id" selected>
                                                {{ option[label_key] }}
                                            </a-select-option>
                                        </a-select>
                                        <a-checkbox-group v-else-if="input_type == 'checkbox'"
                                            v-model:value="model_form[key]"
                                            :options="selectables[modelname]?.map(option => { return { label: option[label_key], value: option.id } })" />
                                        <a-divider v-else-if="input_type == 'divider'" orientation="left">{{ label
                                        }}</a-divider>
                                        <a-divider v-else-if="input_type == 'sub-divider'" orientation="left">
                                            <h5>{{ label }}</h5>
                                        </a-divider>
                                        <TableCreateDrawer v-else-if="input_type == 'table'" :divider="drawer_title"
                                            :table_columns="table_columns" :target_object="model_form"
                                            :target_key="target_key" :drawer_title="drawer_title"
                                            :drawer_fields="drawer_fields.inputs"
                                            :drawer_button_text="drawer_button_text"
                                            :drawer_button_text_edit="drawer_button_text_edit"
                                            :target_modelname="target_modelname" />
                                        <a-date-picker v-else-if="input_type == 'date'" v-model:value="model_form[key]"
                                            :placeholder="placeholder"
                                            :disabled="action_type == 'create' ? false : non_editable" />
                                        <a-date-picker v-else-if="input_type == 'datetime'" v-model:value="model_form[key]"
                                            :placeholder="placeholder"
                                            :show-time="{ format: 'HH:mm' }"
                                            format="DD/MM/YYYY HH:mm"
                                            :disabled="action_type == 'create' ? false : non_editable" />
                                        <a-date-picker v-else-if="input_type == 'month'" v-model:value="model_form[key]"
                                            :placeholder="placeholder"
                                            picker="month"
                                            format="MMMM"
                                            :disabled="action_type == 'create' ? false : non_editable" />
                                        <a-switch v-else-if="input_type == 'switch'" 
                                            v-model:checked="model_form[key]"
                                            :placeholder="placeholder"
                                            :disabled="action_type == 'create' ? false : non_editable" />
                                        <a-textarea v-else-if="input_type == 'textarea'" v-model:value="model_form[key]"
                                            :placeholder="placeholder"
                                            :disabled="action_type == 'create' ? false : non_editable" />
                                        <div v-else-if="input_type == 'photos'" class="clearfix">
                                            <a-upload v-model:file-list="model_form[target_key]"
                                                action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                                                @change="upload_files($event, key)" method="GET"
                                                @remove="remove_uploaded_file($event)"
                                                list-type="picture-card">
                                                <div v-if="model_form[target_key]?.length < 5"
                                                    style="height: 104px; width: 104px; padding: 2rem">
                                                    <span style="font-size: 20px"
                                                        class="material-symbols-outlined">add</span>
                                                    <div style="margin-top: 8px">Upload</div>
                                                </div>
                                            </a-upload>
                                        </div>
                                        <div v-else-if="input_type == 'photo'" style="display: flex; flex-direction: column; align-items: center; gap:1rem">
                                            <div>{{ label }}</div>
                                            <a-upload v-model:file-list="fileList"
                                                    :show-upload-list="false"
                                                    action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                                                    @change="upload_files($event, key)" method="GET">
                                                    <img v-if="model_form[key]" :src="model_form[key]"
                                                        style="width: 100%; height: 100px;"
                                                        alt="avatar" />
                                                    <div v-else style="display: flex; flex-direction: column; align-items: center;">
                                                        <p class="ant-upload-drag-icon">
                                                            <span class="material-symbols-outlined" 
                                                                style="font-size: 5rem;">upload_file</span>
                                                        </p>
                                                        คลิกเพื่ออัพโหลดรูปภาพ
                                                    </div>
                                            </a-upload>
                                        </div>
                                        <!-- upload_file -->
                                        <div v-else-if="input_type == 'file'" style="display: flex; flex-direction: row; align-items: center;">
                                            <a-upload v-model:file-list="fileList" v-if="!model_form[key]"
                                                action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                                                method="GET"
                                                :show-upload-list="false"
                                                @change="upload_files($event, key)"
                                                >
                                                    <a-row>
                                                        <span class="material-symbols-outlined">upload</span>
                                                        อัพโหลดไฟล์
                                                    </a-row>
                                            </a-upload>
                                            <a :href="model_form[key]" v-else="model_form[key]"
                                                target="_blank" rel="doc"
                                            >
                                                {{ model_form[`${key}_file_name`] }}
                                            </a>
                                            <a-button danger type="text" @click="(model_form[key] = '')" style="padding: 0; height: 18px;"
                                                v-if="model_form[key]">
                                                <span class="material-symbols-outlined">close</span>
                                            </a-button>
                                        </div>
                                        <div  v-else-if="input_type == 'table_files'" >
                                            <a-table
                                                :columns="[
                                                    {
                                                        title: 'ชื่อเอกสาร',
                                                        dataIndex: 'name',
                                                        key: 'name',
                                                        width: 400,
                                                    },
                                                    {
                                                        title: 'ไฟล์เอกสาร',
                                                        dataIndex: 'file',
                                                        key: 'file',
                                                    },
                                                    {
                                                        title: 'ลบ',
                                                        dataIndex: 'id',
                                                        key: 'id',
                                                        width: 100,
                                                        align: 'center',
                                                    }
                                                ]"
                                                :dataSource="data_raw_files"
                                                :pagination="false"
                                                :scroll="{ y: 240 }"
                                            >
                                                <template #bodyCell="{ column, record, index}" >
                                                    <template v-if="column.key === 'name'">
                                                        <a-form-item>
                                                            <a-input v-model:value="record.name"
                                                            />
                                                        </a-form-item>
                                                    </template>
                                                    <template v-if="column.key === 'file'">
                                                        <a-form-item>
                                                           <a :href="record.file" target="_blank" rel="doc">{{ record.file_name }}</a>
                                                        </a-form-item>
                                                    </template>
                                                    <template v-if="column.key === 'id'">
                                                        <a-form-item>
                                                            <a-button danger type="text" @click="(data_raw_files.splice(index, 1))">
                                                                <span class="material-symbols-outlined">delete</span>
                                                            </a-button>
                                                        </a-form-item>
                                                    </template>
                                                </template>
                                            </a-table>
                                            <a-upload v-model:file-list="model_form[key]"
                                                action="https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6"
                                                method="GET"
                                                @change="upload_file_multiple($event, key)"
                                                :show-upload-list="false"
                                                >
                                                <a-button style="margin-top: 10px;">
                                                    <span class="material-symbols-outlined">add</span>
                                                    เพิ่มไฟล์
                                                </a-button>
                                            </a-upload>
                                        </div>
                                        <a-input v-else v-model:value="model_form[key]" :placeholder="placeholder"
                                            :type="input_type"
                                            :disabled="action_type == 'create' ? false : non_editable"
                                        />
                                    </a-form-item>
                                </a-col>
                            </a-row>

                            <a-row justify="end">
                                <a-form-item>
                                    <a-button type="primary" size="large" html-type="submit">
                                        <template #icon>
                                            <span style="font-size: 20px" class="material-symbols-outlined">save</span>
                                        </template>
                                        บันทึกข้อมูล
                                    </a-button>
                                </a-form-item>
                            </a-row>
                        </a-card>
                    </a-col>
                </a-row>
            </a-form>
        </a-card>
    </div>
</template>

<script>
import Swal from 'sweetalert2'
import SystemService from '../../api/SystemService'
import TableCreateDrawer from '@/components/table_create_drawer.vue'
import dayjs from 'dayjs'
import buddhistEra from 'dayjs/plugin/buddhistEra'
dayjs.extend(buddhistEra);
import CarField from '../../field_config/car_fields'
export default {
    components: { TableCreateDrawer },
    props: {
        create: Boolean,
        model: String,
        model_data: Object,
    },
    name: 'update_or_create',
    computed: {
        model_type() {
            return this.$route.params.model
        },
        action_type() {
            return this.$route.params.id
        },
        title() {
            return this.default_fields[this.$route.params.model].title[this.$route.params.id] ?
                this.default_fields[this.$route.params.model].title[this.$route.params.id] :
                `${this.default_fields[this.$route.params.model].title['edit']}`
        },
        form_layout() {
            const model_horizontal_form = ['Car']
            if(model_horizontal_form.includes(this.model_type)) {
                return 'horizontal'
            }else{
                return 'vertical'
            }
        },
    },
    data() {
        return {
            company_id: JSON.parse(localStorage.getItem('companyData')).id,
            fileList: [],
            data_raw_files: [],
            default_rules: [],
            default_fields: {
                Sale: {
                    title: {
                        create: 'เพิ่มข้อมูลพนักงานขาย',
                        edit: 'แก้ไขข้อมูลพนักงานขาย'
                    },
                    photos: [
                        {
                            key: 'avatar',
                            label: 'ภาพประจำตัว',
                            height: 275,
                            width: 275
                        }, {
                            key: 'signature',
                            label: 'ลายเซ็นพนักงานขาย',
                            height: 60,
                            width: 300,
                            description: 'ขนาด 400 x 60 px'
                        }
                    ],
                    inputs: {
                        username: {
                            input_type: 'text',
                            placeholder: 'ชื่อผู้ใช้',
                            check_duplicate: true,
                            check_rule: [{
                                required: false,
                                validator: this.validate_duplicate,
                                trigger: 'change',
                            }],
                            required: true,
                            non_editable: true,
                            col_span: 11
                        },
                        password: {
                            input_type: 'password',
                            placeholder: 'รหัสผ่าน',
                            required: true,
                            non_editable: true,
                            col_span: 11
                        },
                        email: {
                            input_type: 'email',
                            placeholder: 'อีเมล',
                            required: false,
                            col_span: 11
                        },
                        firstname: {
                            input_type: 'text',
                            placeholder: 'ชื่อพนักงาน',
                            required: true,
                            col_span: 11
                        },
                        lastname: {
                            input_type: 'text',
                            placeholder: 'นามสกุลพนักงานขาย',
                            required: true,
                            col_span: 11
                        },
                        nickname: {
                            input_type: 'text',
                            placeholder: 'ชื่อเล่น',
                            required: true,
                            col_span: 11
                        },
                        phone_no: {
                            input_type: 'text',
                            placeholder: 'เบอร์โทร',
                            required: true,
                            col_span: 11
                        },
                        phone_backup: {
                            input_type: 'text',
                            placeholder: 'เบอร์โทรสำรอง',
                            required: false,
                            col_span: 11
                        },
                        commission: {
                            input_type: 'number',
                            placeholder: 'คอมมิชชั่น',
                            required: true,
                            col_span: 11
                        },
                    }
                },
                CarRepairType: {
                    title: {
                        create: 'เพิ่มประเภทการซ่อม',
                        edit: 'แก้ไขประเภทการซ่อม'
                    },
                    inputs: {
                        name: {
                            input_type: 'text',
                            placeholder: 'ชื่อประเภท',
                            required: true,
                            col_span: 24
                        }
                    }
                },
                Client: {
                    title: {
                        create: 'เพิ่มข้อมูลลูกค้า',
                        edit: 'แก้ไขข้อมูลลูกค้า'
                    },
                    photos: [
                        {
                            key: 'logo',
                            label: 'ภาพประจำตัว',
                            height: 275,
                            width: 275
                        }
                    ],
                    inputs: {
                        fullname: {
                            input_type: 'text',
                            placeholder: 'ชื่อบริษัท',
                            required: true,
                            col_span: 11
                        },
                        car_company_id: {
                            input_type: 'select',
                            modelname: 'CarCompany',
                            label_key: 'fullname',
                            placeholder: 'บริษัทรถ',
                            required: false,
                            col_span: 11
                        },
                        create_branch: {
                            col_span: 23,
                            input_type: 'table',
                            target_key: 'branches',
                            target_modelname: 'ClientBranch',
                            drawer_title: 'สาขาของบริษัท',
                            drawer_button_text: 'เพิ่มสาขา',
                            drawer_button_text_edit: 'แก้ไขสาขา',
                            table_columns: [
                                {
                                    title: 'ชื่อสาขา',
                                    dataIndex: 'name',
                                    key: 'name',
                                },
                                {
                                    title: 'ที่อยู่',
                                    dataIndex: 'address',
                                    key: 'address',
                                },
                                {
                                    title: 'เบอร์โทร',
                                    dataIndex: 'phone_no',
                                    key: 'phone_no',
                                },
                                {
                                    title: 'ตัวเลือก',
                                    dataIndex: 'id',
                                    key: 'id',
                                    width: 100,
                                    align: 'center',
                                },
                            ],
                            drawer_fields: {
                                title: 'เพิ่มสาขาของบริษัท',
                                show: false,
                                type: 'drawer',
                                instance: {},
                                inputs: {
                                    name: {
                                        input_type: 'text',
                                        placeholder: 'ชื่อสาขา',
                                        required: true,
                                    },
                                    address: {
                                        input_type: 'text',
                                        placeholder: 'ที่อยู่',
                                        required: false,
                                    },
                                    phone_no: {
                                        input_type: 'text',
                                        placeholder: 'เบอร์โทร',
                                        required: false,
                                    }
                                }
                            }
                        },
                        email: {
                            input_type: 'email',
                            placeholder: 'อีเมล',
                            required: false,
                            col_span: 11
                        },
                        tour_type_id: {
                            input_type: 'select',
                            modelname: 'TourType',
                            label_key: 'name',
                            placeholder: 'ประเภทกลุ่มลูกค้า',
                            on_change: this.client_no_on_change,
                            required: true,
                            col_span: 11
                        },
                        client_no: {
                            input_type: 'text',
                            placeholder: 'รหัสลูกค้า',
                            required: false,
                            col_span: 11
                        },
                        tax_id: {
                            input_type: 'text',
                            placeholder: 'เลขประจำตัวผู้เสียภาษี',
                            required: false,
                            col_span: 11
                        },
                        email: {
                            input_type: 'email',
                            placeholder: 'อีเมล',
                            required: false,
                            col_span: 11
                        },
                        phone_no: {
                            input_type: 'text',
                            placeholder: 'เบอร์โทร',
                            required: true,
                            col_span: 11
                        },
                        phone_backup: {
                            input_type: 'text',
                            placeholder: 'เบอร์โทรสำรอง',
                            required: false,
                            col_span: 11
                        },
                        fax_no: {
                            input_type: 'text',
                            placeholder: 'เบอร์แฟกซ์',
                            required: false,
                            col_span: 11
                        },
                        address: {
                            input_type: 'textarea',
                            placeholder: 'ที่อยู่',
                            required: false,
                            col_span: 23
                        },
                        remark: {
                            input_type: 'textarea',
                            placeholder: 'หมายเหตุ',
                            required: false,
                            col_span: 23
                        }
                    }
                },
                CarCompany: {
                    title: {
                        create: 'เพิ่มข้อมูลบริษัทรถ',
                        edit: 'แก้ไขข้อมูลบริษัทรถ'
                    },
                    photos: [
                        {
                            key: 'logo',
                            label: 'Logo บริษัท',
                            height: 275,
                            width: 275
                        }
                    ],
                    inputs: {
                        withholding_id: {
                            input_type: 'select',
                            placeholder: 'ประเภทบริษัท',
                            modelname: 'Withholding',
                            label_key: 'name',
                            required: false,
                            col_span: 19
                        },
                        is_3rd_party: {
                            input_type: 'switch',
                            placeholder: 'บริษัทภายนอก',
                            required: false,
                            col_span: 3
                        },
                        fullname: {
                            input_type: 'text',
                            placeholder: 'ชื่อบริษัทรถ',
                            required: true,
                            col_span: 23
                        },
                        // car_company_type_id: {
                        //     input_type: 'select',
                        //     modelname: 'CarCompanyType',
                        //     label_key: 'name',
                        //     placeholder: 'ประเภทบริษัท',
                        //     required: false,
                        //     col_span: 11
                        // },
                        email: {
                            input_type: 'email',
                            placeholder: 'อีเมล',
                            required: false,
                            col_span: 11
                        },
                        tax_id: {
                            input_type: 'text',
                            placeholder: 'เลขประจำตัวผู้เสียภาษี',
                            required: false,
                            col_span: 11
                        },
                        phone_no: {
                            input_type: 'text',
                            placeholder: 'เบอร์โทร',
                            required: true,
                            col_span: 11
                        },
                        fax_no: {
                            input_type: 'text',
                            placeholder: 'เบอร์แฟกซ์',
                            required: false,
                            col_span: 11
                        },
                        facebook_url: {
                            input_type: 'text',
                            placeholder: 'เฟสบุ๊ค',
                            required: false,
                            col_span: 11
                        },
                        line_id: {
                            input_type: 'text',
                            placeholder: 'ไลน์',
                            required: false,
                            col_span: 11
                        },
                        website: {
                            input_type: 'text',
                            placeholder: 'เว็บไซต์',
                            required: false,
                            col_span: 11
                        },
                        address: {
                            input_type: 'textarea',
                            placeholder: 'ที่อยู่',
                            required: false,
                            col_span: 23
                        },
                        billing_address: {
                            input_type: 'textarea',
                            placeholder: 'ที่อยู่สำหรับออกเอกสาร',
                            required: false,
                            col_span: 23
                        },
                        divider_1: {
                            input_type: 'divider',
                            label: 'ภาษาอื่น ๆ',
                            col_span: 23
                        },
                        fullname_en: {
                            input_type: 'text',
                            placeholder: 'ชื่อบริษัท (ภาษาอื่นๆ)',
                            required: false,
                            col_span: 23
                        },
                        address_en: {
                            input_type: 'textarea',
                            placeholder: 'ที่อยู่ (ภาษาอื่นๆ)',
                            required: false,
                            col_span: 23
                        },
                        billing_address_en: {
                            input_type: 'textarea',
                            placeholder: 'ที่อยู่สำหรับออกเอกสาร (ภาษาอื่นๆ)',
                            required: false,
                            col_span: 23
                        },
                        divider_2: {
                            input_type: 'divider',
                            label: 'รายละเอียดบัญชีธนาคาร',
                            col_span: 23
                        },
                        bank_id: {
                            input_type: 'select',
                            modelname: 'Bank',
                            label_key: 'name',
                            placeholder: 'ชื่อธนาคาร',
                            required: true,
                            col_span: 11
                        },
                        bank_branch: {
                            input_type: 'text',
                            placeholder: 'สาขา',
                            required: true,
                            col_span: 11
                        },
                        bank_account_name: {
                            input_type: 'text',
                            placeholder: 'ชื่อบัญชี',
                            required: true,
                            col_span: 11
                        },
                        bank_account_no: {
                            input_type: 'text',
                            placeholder: 'เลขที่บัญชี',
                            required: true,
                            col_span: 11
                        },
                    }
                },
                CarType: {
                    title: {
                        create: 'เพิ่มชนิดรถ',
                        edit: 'แก้ไขชนิดรถ'
                    },
                    photos: [
                        {
                            key: 'avatar',
                            label: 'รูปชนิดรถ',
                            height: 275,
                            width: 275
                        }
                    ],
                    inputs: {
                        name: {
                            input_type: 'text',
                            placeholder: 'ชื่อชนิดรถ',
                            required: true,
                            col_span: 23
                        }
                    }
                },
                DriverCost: {
                    title: {
                        create: 'เพิ่มเบี้ยเลี้ยง',
                        edit: 'แก้ไขเบี้ยเลี้ยง'
                    },
                    inputs: {
                        name: {
                            input_type: 'text',
                            placeholder: 'ชื่อเบี้ยเลี้ยง',
                            required: true,
                            col_span: 24
                        },
                        booking_type_id: {
                            input_type: 'select',
                            modelname: 'BookingType',
                            label_key: 'name',
                            placeholder: 'ชื่อประเภทการจอง',
                            required: true,
                            col_span: 24
                        },
                        cost_per_day: {
                            input_type: 'number',
                            placeholder: 'ราคาต่อวัน',
                            required: true,
                            col_span: 24
                        }
                    }
                },
                CarGroup: {
                    title: {
                        create: 'เพิ่มประเภทรถ',
                        edit: 'แก้ไขประเภทรถ'
                    },
                    photos: [
                        {
                            key: 'avatar',
                            label: 'รูปประเภทรถ',
                            height: 275,
                            width: 275
                        }
                    ],
                    inputs: {
                        name: {
                            input_type: 'text',
                            placeholder: 'ชื่อประเภทรถ',
                            required: true,
                            col_span: 11
                        },
                        car_type_id: {
                            input_type: 'select',
                            modelname: 'CarType',
                            label_key: 'name',
                            placeholder: 'ชื่อชนิดรถ',
                            required: true,
                            col_span: 11
                        },
                        seats: {
                            input_type: 'number',
                            placeholder: 'จำนวนที่นั่ง',
                            required: true,
                            col_span: 11
                        },
                        price: {
                            input_type: 'number',
                            placeholder: 'ราคาต่อวัน',
                            required: true,
                            col_span: 11
                        },
                    }
                },
                Driver: {
                    title: {
                        create: 'เพิ่มพนักงานขับรถ',
                        edit: 'แก้ไขพนักงานขับรถ'
                    },
                    photos: [
                        {
                            key: 'avatar',
                            label: 'รูปพนักงานขับรถ',
                            height: 275,
                            width: 275
                        },
                        {
                            key: 'driving_license_photo',
                            label: 'รูปใบขับขี่',
                            height: 275,
                            width: 275
                        },
                        {
                            key: 'id_no_photo',
                            label: 'รูปบัตรประชาชน',
                            height: 275,
                            width: 275
                        }
                    ],
                    inputs: {
                        username: {
                            input_type: 'text',
                            placeholder: 'ชื่อผู้ใช้',
                            check_duplicate: true,
                            check_rule: [{
                                required: false,
                                validator: this.validate_duplicate,
                                trigger: 'change',
                            }],
                            required: true,
                            non_editable: true,
                            col_span: 11
                        },
                        password: {
                            input_type: 'password',
                            placeholder: 'รหัสผ่าน',
                            required: true,
                            non_editable: true,
                            col_span: 11
                        },
                        driver_no: {
                            input_type: 'text',
                            check_duplicate: true,
                            check_rule: [{
                                required: true,
                                validator: this.validate_duplicate,
                                trigger: 'change',
                            }],
                            placeholder: 'Driver No.',
                            required: true,
                            col_span: 23
                        },
                        email: {
                            input_type: 'text',
                            placeholder: 'อีเมล',
                            required: false,
                            col_span: 11
                        },
                        id_no: {
                            input_type: 'text',
                            placeholder: 'เลขบัตรประชาชน',
                            required: true,
                            col_span: 11
                        },
                        firstname: {
                            input_type: 'text',
                            placeholder: 'ชื่อ',
                            required: true,
                            col_span: 11
                        },
                        lastname: {
                            input_type: 'text',
                            placeholder: 'นามสกุล',
                            required: true,
                            col_span: 11
                        },
                        bank_accno: {
                            input_type: 'text',
                            placeholder: 'เลขบัญชีธนาคาร',
                            required: false,
                            col_span: 11
                        },
                        salary: {
                            input_type: 'number',
                            placeholder: 'เงินเดือน',
                            required: false,
                            col_span: 11
                        },
                        phone_no: {
                            input_type: 'text',
                            placeholder: 'เบอร์โทรศัพท์',
                            required: true,
                            col_span: 11
                        },
                        divider: {
                            input_type: 'divider',
                            label: 'ใบขับขี่',
                            col_span: 24
                        },
                        driver_license_no: {
                            input_type: 'text',
                            placeholder: 'เลขที่ใบขับขี่',
                            required: false,
                            col_span: 11
                        },
                        driving_license_exp: {
                            input_type: 'date',
                            placeholder: 'วันหมดอายุใบขับขี่',
                            required: false,
                            col_span: 4
                        },
                        driving_license_type_id: {
                            input_type: 'select',
                            modelname: 'DrivingLicenseType',
                            label_key: 'name',
                            placeholder: 'ประเภทใบขับขี่',
                            required: false,
                            col_span: 7

                        },
                        remark: {
                            input_type: 'textarea',
                            placeholder: 'หมายเหตุ',
                            required: false,
                            col_span: 23
                        },
                    }
                },
                Guide: {
                    title: {
                        create: 'เพิ่มไกด์/ผู้ประสานงาน',
                        edit: 'แก้ไขไกด์/ผู้ประสานงาน'
                    },
                    photos: [
                        {
                            key: 'avatar',
                            label: 'รูปไกด์/ผู้ประสานงาน',
                            height: 275,
                            width: 275
                        }
                    ],
                    inputs: {
                        firstname: {
                            input_type: 'text',
                            placeholder: 'ชื่อ',
                            required: true,
                            col_span: 11
                        },
                        lastname: {
                            input_type: 'text',
                            placeholder: 'นามสกุล',
                            required: true,
                            col_span: 11
                        },
                        nickname: {
                            input_type: 'text',
                            placeholder: 'ชื่อเล่น',
                            required: true,
                            col_span: 11
                        },
                        email: {
                            input_type: 'text',
                            placeholder: 'อีเมล',
                            required: true,
                            col_span: 11
                        },
                        phone_no: {
                            input_type: 'text',
                            placeholder: 'เบอร์โทรศัพท์',
                            required: true,
                            col_span: 11
                        },
                        phone_backup: {
                            input_type: 'text',
                            placeholder: 'เบอร์โทรศัพท์สำรอง',
                            required: false,
                            col_span: 11
                        },
                        license: {
                            input_type: 'text',
                            placeholder: 'เลขที่ใบอนุญาต',
                            required: false,
                            col_span: 11
                        },
                        license_expire: {
                            input_type: 'date',
                            placeholder: 'วันหมดอายุใบอนุญาต',
                            required: false,
                            col_span: 11
                        },
                        remark: {
                            input_type: 'textarea',
                            placeholder: 'หมายเหตุ',
                            required: false,
                            col_span: 23
                        },
                    }
                },
                ClientContact: {
                    title: {
                        create: 'เพิ่มผู้ประสานงาน',
                        edit: 'แก้ไขผู้ประสานงาน'
                    },
                    photos: [
                        {
                            key: 'avatar',
                            label: 'รูปผู้ประสานงาน',
                            height: 275,
                            width: 275
                        }
                    ],
                    inputs: {
                        client_id: {
                            input_type: 'select',
                            search_select: true,
                            placeholder: 'เลือกบริษัท',
                            modelname: 'Client',
                            label_key: 'fullname',
                            null_on_change: 'client_branch_id',
                            required: true,
                            col_span: 11,
                        },
                        client_branch_id: {
                            input_type: 'sub_select',
                            placeholder: 'เลือกสาขา',
                            modelname: 'ClientBranch',
                            label_key: 'name',
                            parent: {
                                list: 'Client',
                                sub_list_name: 'branches',
                                key: 'client_id'
                            },
                            required: false,
                            col_span: 11,
                        },
                        firstname: {
                            input_type: 'text',
                            placeholder: 'ชื่อ',
                            required: false,
                            col_span: 11
                        },
                        lastname: {
                            input_type: 'text',
                            placeholder: 'นามสกุล',
                            required: false,
                            col_span: 11
                        },
                        email: {
                            input_type: 'text',
                            placeholder: 'อีเมล',
                            required: true,
                            col_span: 11
                        },
                        phone_no: {
                            input_type: 'text',
                            placeholder: 'เบอร์โทรศัพท์',
                            required: true,
                            col_span: 11
                        },
                        username: {
                            input_type: 'text',
                            placeholder: 'ชื่อผู้ใช้',
                            check_duplicate: true,
                            check_rule: [{
                                required: false,
                                validator: this.validate_duplicate,
                                trigger: 'change',
                            }],
                            non_editable: true,
                            required: true,
                            col_span: 11
                        },
                        password: {
                            input_type: 'password',
                            placeholder: 'รหัสผ่าน',
                            required: true,
                            non_editable: true,
                            col_span: 11
                        },
                    }
                },
                Car: CarField,
                Parking: {
                    title: {
                        create: 'เพิ่มที่จอดยานพาหนะ',
                        edit: 'แก้ไขที่จอดยานพาหนะ',
                    },
                    inputs: {
                        name: {
                            input_type: 'text',
                            placeholder: 'ชื่อที่จอดยานพาหนะ',
                            required: true,
                            col_span: 23
                        },
                        max_lots: {
                            input_type: 'number',
                            placeholder: 'จำนวนที่จอด',
                            required: true,
                            col_span: 23
                        },
                        lat: {
                            input_type: 'number',
                            placeholder: 'ละติจูด',
                            required: true,
                            col_span: 11
                        },
                        lng: {
                            input_type: 'number',
                            placeholder: 'ลองติจูด',
                            required: true,
                            col_span: 11
                        },
                    }
                },
                Expense: {
                    title: {
                        create: 'เพิ่มประเภทค่าใช้จ่าย',
                        edit: 'แก้ไขประเภทค่าใช้จ่าย',
                    },
                    inputs: {
                        name: {
                            input_type: 'text',
                            placeholder: 'ชื่อประเภทค่าใช้จ่าย',
                            required: true,
                            col_span: 23
                        }
                    }
                },
                Extra: {
                    title: {
                        create: 'เพิ่มค่าใช้จ่ายส่วนเกิน',
                        edit: 'แก้ไขค่าใช้จ่ายส่วนเกิน',
                    },
                    inputs: {
                        name: {
                            input_type: 'text',
                            placeholder: 'ชื่อค่าใช้จ่าย',
                            required: true,
                            col_span: 23
                        },
                        price: {
                            input_type: 'number',
                            placeholder: 'ราคา',
                            required: true,
                            col_span: 23
                        },
                    }
                },
                Trip: {
                    title: {
                        create: 'เพิ่มจุดประสงค์การเดินทาง',
                        edit: 'แก้ไขจุดประสงค์การเดินทาง',
                    },
                    inputs: {
                        name: {
                            input_type: 'text',
                            placeholder: 'ชื่อจุดประสงค์การเดินทาง',
                            required: true,
                            col_span: 23
                        }
                    }
                },
                Tour: {
                    title: {
                        create: 'เพิ่มทัวร์',
                        edit: 'แก้ไขทัวร์',
                    },
                    inputs: {
                        name: {
                            input_type: 'text',
                            placeholder: 'ชื่อทัวร์',
                            required: false,
                            col_span: 11
                        },
                        tour_type_id: {
                            input_type: 'select',
                            modelname: 'TourType',
                            label_key: 'name',
                            placeholder: 'ประเภทกลุ่มลูกค้า',
                            required: true,
                            col_span: 11
                        },
                        client_id: {
                            input_type: 'select',
                            modelname: 'Client',
                            label_key: 'fullname',
                            placeholder: 'ลูกค้า/บริษัท',
                            required: true,
                            col_span: 11
                        },
                        description: {
                            input_type: 'text',
                            placeholder: 'สถานที่เที่ยว',
                            required: false,
                            col_span: 11
                        }
                    }
                },
                TourType: {
                    title: {
                        create: 'เพิ่มประเภทกลุ่มลูกค้า',
                        edit: 'แก้ไขประเภทกลุ่มลูกค้า',
                    },
                    inputs: {
                        name: {
                            input_type: 'text',
                            placeholder: 'ชื่อประเภทกลุ่มลูกค้า',
                            required: true,
                            col_span: 11
                        },
                        color_id: {
                            input_type: 'select',
                            modelname: 'Color',
                            label_key: 'name',
                            placeholder: 'สีประเภทกลุ่มลูกค้า',
                            required: false,
                            col_span: 11
                        }
                    }
                },
                PartType: {
                    title: {
                        create: 'เพิ่มประเภทอะไหล่',
                        edit: 'แก้ไขประเภทอะไหล่'
                    },
                    inputs: {
                        name: {
                            input_type: 'text',
                            placeholder: 'ชื่อประเภทอะไหล่',
                            required: true,
                            col_span: 23
                        },
                    }
                },
                PartUnit: {
                    title: {
                        create: 'เพิ่มหน่วยอะไหล่',
                        edit: 'แก้ไขหน่วยอะไหล่'
                    },
                    inputs: {
                        name: {
                            input_type: 'text',
                            placeholder: 'ชื่อหน่วยอะไหล่',
                            required: true,
                            col_span: 23
                        },
                    }
                },
                Vendor: {
                    title: {
                        create: 'เพิ่มผู้ขาย',
                        edit: 'แก้ไขผู้ขาย'
                    },
                    photos: [
                        {
                            key: 'avatar',
                            label: '',
                            height: 275,
                            width: 275
                        },
                    ],
                    inputs: {
                        name: {
                            input_type: 'text',
                            placeholder: 'ชื่อผู้ขาย',
                            required: true,
                            col_span: 11
                        },
                        contact_name: {
                            input_type: 'text',
                            placeholder: 'ชื่อผู้ติดต่อ',
                            required: true,
                            col_span: 11
                        },
                        phone_no: {
                            input_type: 'text',
                            placeholder: 'เบอร์โทร',
                            required: true,
                            col_span: 11
                        },
                        fax_no: {
                            input_type: 'text',
                            placeholder: 'เบอร์แฟกซ์',
                            required: true,
                            col_span: 11
                        },
                        address: {
                            input_type: 'textarea',
                            placeholder: 'ที่อยู่',
                            required: true,
                            col_span: 23
                        },
                    }
                },
                Technician: {
                    title: {
                        create: 'เพิ่มช่าง (Technician)',
                        edit: 'แก้ไขช่าง'
                    },
                    photos: [
                        {
                            key: 'avatar',
                            label: '',
                            height: 275,
                            width: 275
                        },
                    ],
                    inputs: {
                        car_company_id: {
                            input_type: 'select',
                            modelname: 'CarCompany',
                            label_key: 'fullname',
                            placeholder: 'บริษัท',
                            required: true,
                            col_span: 23
                        },
                        firstname: {
                            input_type: 'text',
                            placeholder: 'ชื่อช่าง',
                            required: true,
                            col_span: 11
                        },
                        lastname: {
                            input_type: 'text',
                            placeholder: 'นามสกุล',
                            required: true,
                            col_span: 11
                        },
                        nickname: {
                            input_type: 'text',
                            placeholder: 'ชื่อเล่น',
                            required: true,
                            col_span: 11
                        },
                        phone_no: {
                            input_type: 'text',
                            placeholder: 'เบอร์โทร',
                            required: true,
                            col_span: 11
                        },
                        phone_backup: {
                            input_type: 'text',
                            placeholder: 'เบอร์โทรสำรอง',
                            col_span: 11
                        },
                        email: {
                            input_type: 'text',
                            placeholder: 'อีเมล',
                            col_span: 11
                        },
                    }
                },
                Part: {
                    title: {
                        create: 'เพิ่มอะไหล่',
                        edit: 'แก้ไขอะไหล่'
                    },
                    photos: [
                        {
                            key: 'photo',
                            label: '',
                            height: 275,
                            width: 275
                        },
                    ],
                    inputs: {
                        barcode: {
                            input_type: 'text',
                            placeholder: 'รหัส',
                            required: true,
                            col_span: 11
                        },
                        name: {
                            input_type: 'text',
                            placeholder: 'ชื่ออะไหล่',
                            required: true,
                            col_span: 11
                        },
                        amount: {
                            input_type: 'text',
                            placeholder: 'จำนวน',
                            required: true,
                            col_span: 5
                        },
                        part_unit_id: {
                            input_type: 'select',
                            modelname: 'PartUnit',
                            label_key: 'name',
                            placeholder: 'เลือกหน่วย',
                            required: true,
                            col_span: 5
                        },
                        price: {
                            input_type: 'text',
                            placeholder: 'ราคา',
                            required: true,
                            col_span: 5
                        },
                        part_type_id: {
                            input_type: 'select',
                            modelname: 'PartType',
                            label_key: 'name',
                            placeholder: 'เลือกประเภทอะไหล่',
                            required: true,
                            col_span: 5
                        },
                        vendor_id: {
                            input_type: 'select',
                            modelname: 'Vendor',
                            label_key: 'name',
                            placeholder: 'เลือกผู้ขาย',
                            required: true,
                            col_span: 11
                        },
                        remark: {
                            input_type: 'text',
                            placeholder: 'อื่นๆ',
                            col_span: 11
                        },
                    }
                },
                StockHistory: {
                    title: {
                        create: 'เพิ่มรายการ',
                        edit: 'แก้ไขรายการ'
                    },
                    inputs: {
                        withdraw_type_id: {
                            input_type: 'select',
                            modelname: 'WithdrawType',
                            label_key: 'name',
                            placeholder: 'ประเภทการเบิก',
                            required: true,
                            col_span: 6
                        },
                        withdrawed_at: {
                            input_type: 'date',
                            placeholder: 'ระบุวันที่เบิก',
                            required: true,
                        },
                        remark: {
                            input_type: 'textarea',
                            placeholder: 'หมายเหตุ',
                            col_span: 23
                        },
                        create_part_withdraw: {
                            col_span: 23,
                            input_type: 'table',
                            drawer_title: 'รายการอะไหล่',
                            drawer_button_text: 'เพิ่มอะไหล่',
                            target_key: 'part_histories',
                            target_object: [],
                            table_columns: [
                                {
                                    title: 'ลำดับ',
                                    dataIndex: 'index',
                                    key: 'index',
                                },
                                {
                                    title: 'ชื่ออะไหล่',
                                    dataIndex: 'part_id',
                                    key: 'part_id',
                                },
                                {
                                    title: 'จำนวน',
                                    dataIndex: 'amount',
                                    key: 'amount',
                                },
                                {
                                    title: 'หมายเหตุ',
                                    dataIndex: 'remark',
                                    key: 'remark',
                                },
                                {
                                    title: 'ตัวเลือก',
                                    dataIndex: 'id',
                                    key: 'id',
                                    width: 100,
                                    align: 'center',
                                },
                            ],
                            drawer_fields: {
                                title: 'เพิ่มอะไหล่',
                                show: false,
                                type: 'drawer',
                                inputs: {
                                    part_id: {
                                        input_type: 'select',
                                        modelname: 'Part',
                                        label_key: 'name',
                                        placeholder: 'ชื่ออะไหล่',
                                        required: true,
                                    },
                                    amount: {
                                        input_type: 'number',
                                        placeholder: 'จำนวน',
                                        required: true,
                                    },
                                    remark: {
                                        input_type: 'textarea',
                                        placeholder: 'หมายเหตุ',
                                        required: false,
                                    }
                                }
                            }
                        },
                    },
                },
            },
            selectables: {
            },
            model_form: {},
            check_keys_to_modelname: {
                driver_no: 'Driver',
                username: 'ClientContact',
                plate_no: 'Car'
            },
            delayed: null,
        }
    },
    methods: {
        async client_no_on_change() {
            this.model_form.client_no = (this.model_form.client_type_id? this.model_form.client_type_id:0).toString().padStart(2, '0') + await SystemService.get_client_no()
        },
        async validate_duplicate(_rule, value) {
            return new Promise((resolve, reject) => {
                if(_rule.required && !value) {
                        reject('โปรดระบุ');
                }
                setTimeout(() => {
                    SystemService.check_duplicate(
                        this.model_type,
                        // this.check_keys_to_modelname[_rule.field],
                        `field=${_rule.field}&value=${value}&id=${this.action_type}`
                    ).then((res) => {
                        if (res) {
                            reject('มีข้อมูลนี้อยู่แล้ว');
                        } else {
                            resolve();
                        }
                    });
                }, 500);
            });
        },
        handleChange(value, on_change) {
            if (on_change) on_change()
            if (value) {
                this.model_form[value] = null
            } else {
                return ;
            }
        },
        filterOption (input, option) {
            return option.label.toUpperCase().indexOf(input.toUpperCase()) >= 0;
        },
        async remove_uploaded_file({ uid }) {
            console.log(uid);
            this.model_form[uid] = ''
        },
        show_sub_selectables(parent) {
            try {
                return this.selectables[parent.list].find(({ id }) => id == this.model_form[parent.key])[parent.sub_list_name]
            } catch (er) {
                return []
            }
        },
        async go_remove(id, type) {
            Swal.fire({
                title: `คุณต้องการลบข้อมูลนี้ใช่หรือไม่? [${type}ID: ${id}]`,
                text: "คุณจะไม่สามารถกู้คืนข้อมูลนี้ได้หากลบแล้ว!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'ใช่, ลบข้อมูลนี้!',
                cancelButtonText: 'ยกเลิก'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await SystemService.delete_all(type, id)
                    Swal.fire(
                        'ลบข้อมูลเรียบร้อย!',
                        'ข้อมูลของคุณถูกลบแล้ว',
                        'success'
                    )
                }
            })
        },
        upload_files(e, key) {
            if (e.file.status != 'remove') {
                this.getBase64(e.file.originFileObj, key)
                return `https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6`
            }
        },
        async upload_file_multiple(e, key) {
            if (e.file.status != 'remove') {
                var file = e.file.originFileObj
                if (key) {
                    const vue = this
                    var reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = function () {
                        if(vue.data_raw_files.map(e => e.file_name).includes(file.name)) return
                        vue.data_raw_files.push({
                            file: reader.result,
                            file_name: file.name,
                            name: '',
                        })
                    };
                } else {
                    return await new Promise((resolve, reject) => {
                        if (file) {
                            if (typeof file == 'string') {
                                resolve(file)
                            } else {
                                const reader = new FileReader();
                                reader.readAsDataURL(file);
                                reader.onload = () => resolve(reader.result);
                                reader.onerror = error => reject(error);
                            }
                        }
                    });
                }
                return `https://mocki.io/v1/283f0879-26ca-4020-9a50-e90025fd52c6`
            }
        },
        async getBase64(file, key) {
            if (key) {
                const vue = this
                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function () {
                    vue.model_form[key] = reader.result
                    vue.model_form[`${key}_file_name`] = file.name
                };
            } else {
                return await new Promise((resolve, reject) => {
                    if (file) {
                        if (typeof file == 'string') {
                            resolve(file)
                        } else {
                            const reader = new FileReader();
                            reader.readAsDataURL(file);
                            reader.onload = () => resolve(reader.result);
                            reader.onerror = error => reject(error);
                        }
                    }
                });
            }
        },
        async validate_model_form() {
            try {
                if (this.data_raw_files && this.data_raw_files.length) {
                    this.model_form.data_raw_files = this.data_raw_files
                }
                
                if (this.model_form.create_options) {
                    this.model_form.car_options = this.model_form.create_options.map((id) => {
                        return {
                            option_id: id
                        }
                    })
                    delete this.model_form.create_options
                }

                if (this.model_form.photos) {
                    for (var i = 0; i < this.model_form.photos.length; i++) {
                        this.model_form[`photo_${i + 1}`] = await this.getBase64(this.model_form.photos[i].originFileObj)
                    }
                    delete this.model_form.photos
                    delete this.model_form.create_photos
                }
                this.model_form.company_id = this.company_id

            } catch (err) {
                console.log(err);
            }
        },
        async submit_model_data() {
            const type = this.$route.params.id == 'create' ? 'create_all' : 'update_all'
            Swal.fire({
                title: 'กรุณารอสักครู่',
                text: 'กำลังบันทึกข้อมูล',
                allowOutsideClick: false,
                didOpen: async () => {
                    Swal.showLoading()
                    this.validate_model_form()
                    await SystemService[type](this.model_type, type == 'create_all' ?
                        { data: [this.model_form] } : { data: this.model_form },
                        this.model_form.id)
                            .then(res => {
                                if (res) {
                                    Swal.close()
                                }
                            })
                },
                willClose: () => {
                    Swal.fire({
                        title: 'บันทึกข้อมูลสำเร็จ',
                        icon: 'success',
                        timer: 1000,
                        timerProgressBar: false,
                        showConfirmButton: false,
                    })
                    this.clear_model_data()
                }
            })
        },
        async init_create_edit() {
            if (this.$route.params.id != 'create') {
                var data = await SystemService.get_id(this.model_type, this.$route.params.id)
                this.model_form = this.parse_update_data(data)
            } else {
                this.model_form = this.model_data
            }
        },
        clear_model_data() {
            this.$emit('apply_model_data', {})
            this.$router.go(-1)
        },
        async get_default_fields() {
            for (var key in this.default_fields[this.model_type].inputs) {
                const { input_type, modelname, target_key } = this.default_fields[this.model_type].inputs[key]
                if ((input_type == 'select' || input_type == 'checkbox') && modelname) {
                    SystemService.get_all(modelname)
                        .then(res => {
                            this.selectables[modelname] = res
                        })
                }
                if (input_type == 'photos') {
                    if (!this.model_form[target_key]) {
                        this.model_form[target_key] = []
                    }
                }
                if (key == 'client_no') {
                    this.model_form[key] = (this.model_form.client_type_id? this.model_form.client_type_id:0).toString().padStart(2, '0') + await SystemService.get_client_no()
                }
            }
        },
        clear_drawer_data(key) {
            this.default_fields[this.model_type].inputs[key].create.show = false
        },
        parse_update_data(data) {
            try {
                for (var key in data) {
                    if (!data[key]) continue
                    if(isNaN(data[key]) == false){
                        data[key] = data[key]
                    }else if(dayjs(data[key]).isValid() && key != 'car_no' && key != 'fullname' && key != 'remark') {
                        data[key] = dayjs(data[key])
                    }
                }

                const array_fields = ['options']
                for (var i = 0; i < array_fields.length; i++) {
                    const key = array_fields[i]
                    if (key in data) {
                        data[`create_${key}`] = data[key].map((item) => {
                            return item.id
                        })
                    }
                }

                const photos_fields = ['photo_1', 'photo_2', 'photo_3', 'photo_4', 'photo_5', 'photo_6', 'photo_7', 'photo_8']
                for (var i = 0; i < photos_fields.length; i++) {
                    const key = photos_fields[i]
                    if (key in data) {
                        if (data[key] != null) {
                            if (data.photos) {
                                data.photos.push({ 
                                    uid: key,
                                    name: data[key].split('/').pop(),
                                    status: 'done',
                                    url: data[key]
                                })
                            } else {
                                data.photos = [{ 
                                    uid: key,
                                    name: data[key].split('/').pop(),
                                    status: 'done',
                                    url: data[key]
                                }]
                            }
                        }
                    }
                }

                const boolean_fields = ['is_3rd_party','is_logis']
                for (var i = 0; i < boolean_fields.length; i++) {
                    const key = boolean_fields[i]
                    if (key in data) {
                        if (data[key] != null) {
                            data[key] = data[key] == 1 ? true : false
                        }
                    }
                }
                this.data_raw_files = data.data_raw_files
                this.modelname = this.model_type
                return data
            } catch (err) {
                console.log(err)
            }
        }
    },
    async mounted() {
        this.init_create_edit()
        await this.get_default_fields()
    }
}
</script>