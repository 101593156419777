
import httpClient from './httpClient';

const prefix = 'core'

export default {
  async get_bookings(onpage = 1, perpage = 20, progress, searchBody) {
    var query = ''
    if (searchBody != null) {
      for (var key in searchBody) {
        if (searchBody[key]) {
          query = query != '' ? `${query}&${key}=${searchBody[key]}` : `${key}=${searchBody[key]}`
        }
      }
    }
    
    const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/bookings?onpage=${onpage}&perpage=${perpage}${progress != null ? `&progress=${progress}` : ''}&${query}`);
    
    return data;
  },
  async get_remarks() {
    const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/remarks`);
    
    return data;
  },
  async update_remark(id, body) {
    const { data } = await httpClient({ requiresAuth: true }).put(`${prefix}/remarks/${id}`, body);
    
    return data;
  },
  async delete_remark(id) {
    const { data } = await httpClient({ requiresAuth: true }).delete(`${prefix}/remarks/${id}`);
    
    return data;
  },
  async create_booking(booking) {
    const { data } = await httpClient({ requiresAuth: true }).post(`${prefix}/booking`, booking);

    return data;
  },
  async update_booking(id, booking) {
    const { data } = await httpClient({ requiresAuth: true }).put(`${prefix}/booking/${id}`, booking);

    return data;
  },
  async delete_booking(id) {
    const { data } = await httpClient({ requiresAuth: true }).delete(`${prefix}/booking/${id}`);

    return data;
  },
  async booking_progress(id) {
    const { data } = await httpClient({ requiresAuth: true }).put(`${prefix}/booking/${id}/progress`);

    return data;
  },
  async duplicate_booking(id) {
    const { data } = await httpClient({ requiresAuth: true }).get(`${prefix}/duplicate-booking/${id}`);

    return data;
  }
}