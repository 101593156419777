
import httpClient from './httpClient';
const prefix = 'core'

export default {
  async get_car_companies () {
    const { data } = await httpClient({ requiresAuth: true }).get(prefix+`/car-companies`)

    return data
  },
  async get_car_repair_detail(id) {
    const { data } = await httpClient({ requiresAuth: true }).get(prefix+`/repair/${id}`)

    return data
  },
  async get_car_repair() {
    const { data } = await httpClient({ requiresAuth: true }).get(prefix+`/repair`)

    return data
  },
  async remove_car_repair(id) {
    const { data } = await httpClient({ requiresAuth: true }).delete(prefix+`/repair/${id}`)

    return data
  },
  async edit_repair_status(id, body) {
    const { data } = await httpClient({ requiresAuth: true }).put(prefix+`/repair/${id}`, body)

    return data
  },
  async edit_car_repair(id, body) {
    const { data } = await httpClient({ requiresAuth: true }).put(prefix+`/repair/${id}`, body)

    return data
  },
  async create_car_repair(body) {
    const { data } = await httpClient({ requiresAuth: true }).post(prefix+`/repair`, body)

    return data
  }
}